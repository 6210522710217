import { DrawingState } from '../Redux/Reducers/Drawing';

export const DrawingInitialState: DrawingState = {
  isDrawingModalOpen: false,
  drawingImage: null,
  callBackFunction: null,
  canvasWidth: 500,
  canvasHeight: 500,
  inpaintingImage: null,
  inpaintingMask: null,
};
