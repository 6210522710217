import React from 'react';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { hexToRgba } from '../../../../../Utils/imageProcess';

interface CharacterFeatureItemProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}
const CharacterFeatureItem = ({ label, selected, onClick }: CharacterFeatureItemProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: '4px 16px',
        height: '32px',
        borderRadius: '16px',
        border: `1px solid ${
          selected ? theme.palette.primary.main : theme.palette.surface.tertiary
        }`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
          borderColor: selected
            ? theme.palette.primary.main
            : hexToRgba(theme.palette.object.tertiary, 0.5),
        },
      }}
      onClick={onClick}
    >
      <Text12R color={selected ? theme.palette.primary.main : theme.palette.constant.grey}>
        {label}
      </Text12R>
    </Box>
  );
};
export default CharacterFeatureItem;
