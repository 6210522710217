import { ControlNet, T2IAdapter } from '../../Models/ControlNet';
import { ControlNetActions } from '../Actions/ControlNet';

export interface ControlNetState {
  controlNet: ControlNet[];
  t2iAdapter: T2IAdapter[];
}

export const ControlNetInitialState: ControlNetState = {
  controlNet: [],
  t2iAdapter: [],
};

export interface ControlNetActionRequest {
  type: ControlNetActions;
  payload: any;
}

export const ControlNetReducer = (
  state: ControlNetState = ControlNetInitialState,
  action: ControlNetActionRequest
): ControlNetState => {
  switch (action.type) {
    case ControlNetActions.UPDATE_ALL_CONTROL_NET:
      return { ...state, controlNet: action.payload };
    case ControlNetActions.UPDATE_T2I_ADAPTER:
      return { ...state, t2iAdapter: action.payload };
    default:
      return state;
  }
};
