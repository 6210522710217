import { StoryStateAction, StoryStateActionTypes } from '../Actions/StoryState';
import {
  CanvasStateItem,
  CreatePanelFromStoryResponse,
  CreateStoryFromPromptResponse,
  Dialogue,
  MappedCharacter,
  PanelItem,
  PanelItemV2,
  StoryCharacter,
} from '../../Models/Story';
import { PROMPT2STORYOptionType } from '../../Components/StoryMode/Story/InitialOptionComponent/InitialOptionComponent';

export interface StoryState {
  isStoryMode: boolean;
  storyCanvasState: CanvasStateItem[];
  storyPanels: PanelItemV2[];
  dialogs: Dialogue[];
  showId: string;
  generating: boolean;
  thumbnailUpdated: boolean;
  isFirstStory: boolean;
  storyCharacters: StoryCharacter[];
  mappedCharacters: MappedCharacter[];
  prompt: string;
  storyFromPromptResponse: CreateStoryFromPromptResponse | null;
  storyLoading: boolean;
  isGeneratedStory: boolean;
  story: string;
  storyFetchAttempted: boolean;
  isDone: boolean;
  storyError: string;
  getFeedback: boolean;
}

export const initialStoryState: StoryState = {
  isStoryMode: false,
  storyCanvasState: [],
  showId: '',
  storyPanels: [],
  dialogs: [],
  generating: false,
  thumbnailUpdated: false,
  isFirstStory: false,
  storyCharacters: [],
  mappedCharacters: [],
  prompt: '',
  storyFromPromptResponse: null,
  storyLoading: false,
  isGeneratedStory: false,
  story: '',
  storyFetchAttempted: false,
  isDone: false,
  storyError: '',
  getFeedback: false,
};

export const StoryStateReducer = (
  state: StoryState = initialStoryState,
  action: StoryStateActionTypes
): StoryState => {
  switch (action.type) {
    case StoryStateAction.SET_IS_STORY_MODE:
      return {
        ...state,
        isStoryMode: action.payload.isStoryMode,
        showId: action.payload.showId,
      };
    case StoryStateAction.SET_STORY_CANVAS_STATE:
      return {
        ...state,
        storyCanvasState: action.payload.storyCanvasState,
        storyPanels: action.payload.storyPanels,
        dialogs: action.payload.dialogs,
      };
    case StoryStateAction.SET_GENERATING:
      return {
        ...state,
        generating: action.payload.generating,
      };
    case StoryStateAction.SET_THUMBNAIL_GENERATED:
      return {
        ...state,
        thumbnailUpdated: action.payload.thumbnailUpdated,
      };
    case StoryStateAction.SET_IS_FIRST_STORY:
      return {
        ...state,
        isFirstStory: action.payload.isFirstStory,
      };
    case StoryStateAction.STORY_DONE:
      return {
        ...state,
        storyPanels: [],
        storyCanvasState: [],
        isStoryMode: false,
        isDone: true,
      };
    case StoryStateAction.RESET:
      return initialStoryState;

    case StoryStateAction.SET_STORY_FROM_PROMPT_RESPONSE:
      return {
        ...state,
        storyFromPromptResponse: action.payload.storyFromPromptResponse,
        isGeneratedStory: action.payload.true,
        storyLoading: false,
        story: action.payload.storyFromPromptResponse?.story ?? '',
      };

    case StoryStateAction.SET_STORY:
      return {
        ...state,
        story: action.payload.story,
        prompt: action.payload.prompt,
      };
    case StoryStateAction.SET_STORY_LOADING:
      return {
        ...state,
        storyLoading: action.payload.storyLoading,
      };
    case StoryStateAction.SET_PANEL_FROM_STORY_RESPONSE:
      return {
        ...state,
        storyPanels: action.payload.panelFromStoryResponse.panels,
        storyCanvasState: action.payload.panelFromStoryResponse.canvas_state,
        dialogs: action.payload.panelFromStoryResponse.dialogues,
        storyCharacters: action.payload.storyCharacters,
        story: action.payload.story,
        storyLoading: false,
      };
    case StoryStateAction.SET_MAPPED_CHARACTERS:
      return {
        ...state,
        mappedCharacters: action.payload.mappedCharacters,
      };
    case StoryStateAction.SET_STORY_FETCH_ATTEMPTED:
      return {
        ...state,
        storyFetchAttempted: action.payload.storyFetchAttempted,
      };
    case StoryStateAction.SET_STORY_ERROR:
      return {
        ...state,
        storyError: action.payload.storyError,
      };
    case StoryStateAction.SET_GET_STORY_FEEDBACK:
      return {
        ...state,
        getFeedback: action.payload.getFeedback,
      };

    default:
      return state;
  }
};
