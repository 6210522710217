// ContextMenu.tsx
import React from 'react';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../Redux/ReduxInterface';
import { useTheme } from '@mui/material/styles';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';

interface Items {
  id: string;
  label: string;
  onClick: () => void;
  items?: Items[];
}

interface ContextMenuProps {
  // Define any props you need for your context menu
  items: Items[];
  id: string;
}

const CustomContextMenu = (props: ContextMenuProps) => {
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const theme = useTheme();

  return (
    <Menu
      id={props.id}
      style={{
        backgroundColor: theme.palette.background.paper,
        width: '150px',
      }}
      theme={mode === 'dark' ? 'dark' : 'light'}
    >
      {
        /* Define your menu items here */
        props.items.map(item =>
          !item.items ? (
            <Item
              key={item.id}
              style={{
                color: `${theme.palette.text.primary} !important`,
                fontWeight: '600',
                fontSize: '12px',
              }}
              onClick={() => {
                item.onClick();
                trackEvent(
                  {
                    event: TrackingEvents.rightClickMenu,
                    properties: {
                      name: item.label,
                    },
                  },
                  'CREATOR'
                );
              }}
            >
              {item.label}
            </Item>
          ) : (
            <Submenu
              label={
                <label
                  key={item.id}
                  style={{
                    color: `${theme.palette.text.primary} !important`,
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                  onClick={() => {
                    item.onClick();
                    trackEvent(
                      {
                        event: TrackingEvents.rightClickMenu,
                        properties: {
                          name: item.label,
                        },
                      },
                      'CREATOR'
                    );
                  }}
                >
                  {item.label}
                </label>
              }
              key={item.id}
              style={{
                color: `${theme.palette.text.primary} !important`,
                fontWeight: '600',
                fontSize: '12px',
              }}
              onClick={() => {
                item.onClick();
                trackEvent(
                  {
                    event: TrackingEvents.rightClickMenu,
                    properties: {
                      name: item.label,
                    },
                  },
                  'CREATOR'
                );
              }}
            >
              {item.items?.map((subItem, index) => (
                <Item
                  key={index}
                  style={{
                    color: `${theme.palette.text.primary} !important`,
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                  onClick={() => {
                    subItem.onClick();
                    trackEvent(
                      {
                        event: TrackingEvents.rightClickMenu,
                        properties: {
                          name: subItem.label,
                        },
                      },
                      'CREATOR'
                    );
                  }}
                >
                  {subItem.label}
                </Item>
              ))}
            </Submenu>
          )
        )
      }
    </Menu>
  );
};

export default CustomContextMenu;
