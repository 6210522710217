import React from 'react';
import { ReactComponent as DiscordIcon } from '../../../../Assets/Icons/discord.svg';
import { ReactComponent as ArrowIcon } from '../../../../Assets/Icons/arrow.svg';
import { useTheme } from '@mui/material';
import { t } from 'i18next';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../Redux/ReduxInterface';
import { Link } from 'react-router-dom';

const JoinOurCommunityV2 = () => {
  const theme = useTheme();
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  return (
    <Link
      style={{
        backgroundColor: mode === 'light' ? '#5330FC8D' : '#5330FC4D',
        display: 'flex',
        flexDirection: 'column',
        width: '208px',
        padding: '12px',
        color: theme.palette.constant.white,
        gap: '12px',
        borderRadius: '8px',
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      to="https://discord.com/invite/DwBuquQABM"
      target="_blank"
      onClick={() => {
        trackEvent({ event: TrackingEvents.joinCommunityWidgetClicked, properties: {} }, 'CREATOR');
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <DiscordIcon style={{ width: '24px', height: '24px' }} />
        <div style={{ fontSize: '15px', fontWeight: 600, lineHeight: '20px' }}>
          {t('Join Our Community')}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          //   color: theme.palette.object.tertiary,
        }}
      >
        <div style={{ fontSize: '12px', lineHeight: '16px' }}>
          {t('Engage with other creators in our Discord')}
        </div>

        <ArrowIcon style={{ width: '24px', height: '24px' }} />
      </div>
    </Link>
  );
};

export default JoinOurCommunityV2;
