import { AppStateActions, AppStateActionTypes } from '../Actions/AppState';
import { Show } from '../../Models/Show';
import { ShowDetailsCharacterView } from '../../Screens/Studio/Canvas/ShowDetails/ShowDetailsCharacters';
import {CharacterFromCanvasActions, ShowDetailsCharacterActions, ShowDetailsCharacterActionTypes } from '../Actions/ShowDetailsCharacter';
import { CharacterFromCanvas } from '../../Models/CanvasState';
import { InitialCharacterFromCanvasState } from '../../Models/CanvasState';

export interface ShowDetailsCharacterViewState {
    showDetailsCharacterViewState: ShowDetailsCharacterView
}

export const InitialShowDetailsCharacterViewState: ShowDetailsCharacterViewState = {
    showDetailsCharacterViewState: ShowDetailsCharacterView.LIBRARY
}

export const ShowDetailsCharacterReducer = (
    state: ShowDetailsCharacterViewState = InitialShowDetailsCharacterViewState,
    action: ShowDetailsCharacterActionTypes
): ShowDetailsCharacterViewState => {
    switch(action.type) {
        case ShowDetailsCharacterActions.ADD_CHARACTER:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.CHARACTER_VIEW:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.LIBRARY:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.REVIEW:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.REVIEW_PAGE:
            return {...state, showDetailsCharacterViewState: action.payload}     
        case ShowDetailsCharacterActions.TRAININED_COMPLETED:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.TRAINING_EVAL:
            return {...state, showDetailsCharacterViewState: action.payload}    
        case ShowDetailsCharacterActions.TRAINING_FAILED:
            return {...state, showDetailsCharacterViewState: action.payload}
        case ShowDetailsCharacterActions.TRAINING_IN_PROGRESS:
            return {...state, showDetailsCharacterViewState: action.payload}           
        default:
            return state
    }   
}


export interface CharacterFromCanvasActionsInterface {
    type: CharacterFromCanvasActions;
    payload?: any;
  }
  

export const CharacterFromCanvasReducer = (
    state: CharacterFromCanvas = InitialCharacterFromCanvasState,
    action: CharacterFromCanvasActionsInterface
): CharacterFromCanvas => {
  switch (action.type) {
    case CharacterFromCanvasActions.CHARACTER_FROM_CANVAS:
      return {
        ...state,
        isCharacterFromCanvas: action.payload.isCharacterFromCanvas,
        characterImageLink: action.payload.characterImageLink,
        isLoading: false,
      };
    case CharacterFromCanvasActions.LOADING_CHARACTER:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    default:
      return state;
  }
};
