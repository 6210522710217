import { TextualEmbeddingResponse } from '../../Models/TextualEmbedding';
import { TextualEmbeddingActions, TextualEmbeddingActionTypes } from '../Actions/TextualEmbedding';
import { GenerateActionTypes, GenerationActions } from '../Actions/Generation';

export interface GenerationState {
  isGenerating: boolean;
  batchCount?: number;
}

const initialState: GenerationState = {
  isGenerating: false,
  batchCount: 4,
};

export const GenerationReducer = (
  state: GenerationState = initialState,
  action: GenerateActionTypes
): GenerationState => {
  switch (action.type) {
    case GenerationActions.SET_IS_GENERATING:
      return { ...state, isGenerating: action.payload };
    case GenerationActions.SET_BATCH_COUNT:
      return { ...state, batchCount: action.payload };
    default:
      return state;
  }
};
