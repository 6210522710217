import { DrawingActions, DrawingActionTypes } from '../Actions/Drawing';

export interface DrawingState {
  isDrawingModalOpen: boolean;
  drawingImage: string | null;
  callBackFunction: ((image: string) => void) | null;
  canvasWidth?: number;
  canvasHeight?: number;
  inpaintingImage?: string | null;
  inpaintingMask?: string | null;
}

const initialState: DrawingState = {
  isDrawingModalOpen: false,
  drawingImage: null,
  callBackFunction: null,
  canvasWidth: 500,
  canvasHeight: 500,
  inpaintingImage: null,
  inpaintingMask: null,
};

export const DrawingReducer = (
  state: DrawingState = initialState,
  action: DrawingActionTypes
): DrawingState => {
  switch (action.type) {
    case DrawingActions.SET_DRAWING_IMAGE:
      return { ...state, drawingImage: action.payload };
    case DrawingActions.SET_IS_DRAWING_MODAL_OPEN:
      return { ...state, isDrawingModalOpen: action.payload };
    case DrawingActions.SET_DRAWING_CALLBACK:
      return { ...state, callBackFunction: action.payload };
    case DrawingActions.SET_CANVAS_WIDTH:
      return { ...state, canvasWidth: action.payload };
    case DrawingActions.SET_CANVAS_HEIGHT:
      return { ...state, canvasHeight: action.payload };
    case DrawingActions.SET_INPAINING_IMAGE:
      return { ...state, inpaintingImage: action.payload };
    case DrawingActions.SET_INPAINING_MASK:
      return { ...state, inpaintingMask: action.payload };
    default:
      return state;
  }
};
