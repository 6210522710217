import { Box, Button, Stack, Typography } from '@mui/material';
import { Tag } from '@mui/icons-material';
// TODO: move to asset folder
import CharacterImage from '../../../../../Assets/Images/character-icon.png';
import CustomButton from '../../../../../Components/CustomButton/CustomButton';
import { t } from 'i18next';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import React from 'react';
import { SubscriptionPlan } from '../../../../../Models/User';

interface ProPlanProps {
  onClick: () => void;
  isActive: boolean;
  planDetails?: SubscriptionPlan;
}

const ProPlan: React.FC<ProPlanProps> = ({ onClick, isActive, planDetails }) => {
  const theme = useTheme();
  const ctaButtonText = isActive ? t('Manage Plan') : t('Upgrade Now');
  const chipDetails = isActive
    ? {
        label: t('Current Plan'),
        color: 'primary',
        backgroundColor: 'rgba(252, 252, 252, 0.10)',
        visible: true,
      }
    : {
        label: t('Recommended'),
        color: 'default',
        backgroundColor: theme.palette.constant.purple,
        visible: planDetails?.metadata.RECOMMENDED === 'true',
      };

  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await onClick();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      position={'relative'}
      flexGrow={1}
      sx={{
        borderRadius: 3,
        height: '100%',
        background: 'linear-gradient(113deg, #3090FC 0%, #5330FC 100%)',
        color: 'white',
      }}
    >
      <Stack direction={'column'} sx={{ padding: '24px' }} justifyContent={'space-between'}>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Typography fontSize={'20px'}>Pro</Typography>
          {chipDetails.visible && (
            <Chip
              variant={'filled'}
              color={'primary'}
              label={chipDetails.label}
              size={'small'}
              sx={{
                borderRadius: '6px',
                fontSize: '10px',
                color: 'white',
                backgroundColor: chipDetails.backgroundColor,
              }}
            />
          )}
        </Stack>
        {/*strike text*/}
        <Box
          sx={{
            textDecoration: 'line-through',
            color: 'white',
            opacity: 0.6,
          }}
          fontSize={'20px'}
        >
          {planDetails?.metadata.ORIGINAL_PRICE}
        </Box>
        {/*box in line*/}
        <Stack direction={'row'} alignItems={'end'} flexShrink={1}>
          <Typography lineHeight={'40px'} fontSize={'40px'}>
            {planDetails?.metadata.ACTUAL_PRICE}{' '}
          </Typography>
          <Typography sx={{ opacity: 0.6 }}>/ month</Typography>
        </Stack>

        <Stack spacing={0} sx={{ fontSize: '12px' }}>
          {planDetails?.metadata.DESCRIPTION.split('\n').map((line: string, index: number) => (
            <Box key={line}>{line}</Box>
          ))}
        </Stack>

        <Box justifySelf={'flex-end'}>
          <CustomButton
            width={'100%'}
            height={'48px'}
            isLoading={loading}
            disabled={loading}
            style={{ borderRadius: '24px' }}
            backgroundColor={'white'}
            foregroundColor={theme.palette.constant.blue}
            text={ctaButtonText}
            onClick={handleClick}
            variant={'contained'}
            hasBorder={false}
          />
        </Box>
      </Stack>
      <Stack
        direction={'column'}
        justifyContent={'space-between'}
        sx={{
          height: '100%',
          paddingTop: {
            xs: '12px',
            md: '24px',
          },
        }}
      >
        <Box
          justifySelf={'end'}
          alignSelf={'end'}
          sx={{
            paddingRight: {
              xs: '12px',
              md: '24px',
            },
          }}
        >
          {planDetails?.metadata.LIMITED_TIME_OFFER === 'true' && (
            <Typography
              color={theme.palette.constant.white}
              fontSize={'16px'}
              sx={{ opacity: 0.6 }}
            >
              {t('Limited Time Offer')}
            </Typography>
          )}
        </Box>
        <Box
          p={0}
          position={'absolute'}
          right={0}
          bottom={0}
          sx={{
            display: 'flex',
          }}
        >
          <img src={CharacterImage} alt={'Character'} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProPlan;
