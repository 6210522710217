export enum StudioCommentsActions {
  UPDATE_STUDIO_COMMENTS = 'UPDATE_STUDIO_COMMENTS',
  ADD_STUDIO_COMMENT = 'ADD_STUDIO_COMMENT',
  REMOVE_STUDIO_COMMENT = 'REMOVE_STUDIO_COMMENT',
  CHANGE_COMMENT_TOOL = 'CHANGE_COMMENT_TOOL',
  SELECT_COMMENT_BY_IDS = 'SELECT_COMMENT_BY_IDS',
  SET_SELECTED_COMMENT_ID = 'SET_SELECTED_COMMENT_ID',
  SET_IS_COMMENT_MENU = 'SET_IS_COMMENT_MENU',
}

export enum CommentsMenuFilters {
  ALL = 'All',
  SCREENPLAY = 'Screenplay',
  EDITOR = 'Editor',
}
