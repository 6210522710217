import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ShowDetailsModalSizeActions } from '../../../../../../../Redux/Actions/ShowDetailsModalSize';
import { Character } from '../../../../../../../Models/Character';
import { t } from 'i18next';
import CharacterMetadata from './CharacterMetadata';
import AdditionalCharacteristics from './AdditionalCharacteristics';
import CharacterImage from './CharacterImage';
import CharacterDescription from './CharacterDescription';
import CharacterNameAndStatus from './CharacterNameAndStatus';
import CustomButton from '../../../../../../../Components/CustomButton/CustomButton';
import useCharacters from '../../../../../../../Hooks/useCharacter';
import { trackEvent } from '../../../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../../../Constants/TrackingEvents';
import { ReduxState } from '../../../../../../../Redux/ReduxInterface';
import { useParams } from 'react-router-dom';
import Banner from '../../../../../../../Components/Banner/Banner';

interface CharacterTrainingCompleted {
  character: Character | null;
  setCharacterViewIsOpen?: (characterViewIsOpen: boolean) => void;
}

const CharacterTrainingCompleted: React.FC<CharacterTrainingCompleted> = ({ character }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const characters = useCharacters();
  const [loading, setLoading] = React.useState(false);
  const selectedStyle = useSelector((state: ReduxState) => state.canvasState.selectedStyle);
  const { showId, episodeId } = useParams() as { showId: string; episodeId: string };
  const isAvailableForCurrentStyle = character && characters.isCharacterAvailableInStyle(character);

  useEffect(() => {
    dispatch({ type: ShowDetailsModalSizeActions.SET_BIG_MODAL, payload: true });
    return () => {
      dispatch({ type: ShowDetailsModalSizeActions.SET_SMALL_MODAL, payload: false });
    };
  }, [dispatch]);

  const metadata = [
    { label: t('Casting'), value: character?.generationMetadata?.split(',')[6] },
    { label: t('Age'), value: character?.generationMetadata?.split(',')[1]?.replace(/_/g, ' ') },
    {
      label: t('Ethnicity'),
      value: character?.generationMetadata?.split(',')[2]?.replace(/_/g, ' '),
    },
    {
      label: t('Hair Style'),
      value: character?.generationMetadata?.split(',')[3]?.replace('hair', ''),
    },
    {
      label: t('Hair Color'),
      value: character?.generationMetadata?.split(',')[4]?.replace('hair color', ''),
    },
    {
      label: t('Eye Color'),
      value: character?.generationMetadata?.split(',')[5]?.replace('eye color', ''),
    },
  ];

  if (!character) {
    return null;
  }

  const isCharacterInShow = characters.isCharacterInShow(character.id);

  const handleAddOrRemoveCharacter = async () => {
    try {
      setLoading(true);
      isCharacterInShow
        ? await characters.removeCharacterFromShow(character.id, character.name)
        : await characters.addCharacterToShow(character.id, character.name);
      trackEvent(
        {
          event: isCharacterInShow
            ? TrackingEvents.characterRemovedFromShow
            : TrackingEvents.charactedAddedToShow,
          properties: {
            characterId: character.id,
            characterName: character.name,
            baseModel: character.baseModel,
            currentStyle: selectedStyle,
            showId: showId,
            from: 'CHARACTER_TRAINING_COMPLETED_VIEW',
          },
        },
        'CREATOR'
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
      }}
    >
      {/* Character Name and Training Status */}
      <Box display={'flex'} flexDirection={'column'}>
        <CharacterNameAndStatus character={character} theme={theme} />

        {/* Character Description */}
        <CharacterDescription character={character} theme={theme} />
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Box display={'flex'} flexDirection={'column'} flex={3} gap={2} width="100%">
          {/* Character Metadata */}
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {metadata.map(item => (
              <CharacterMetadata key={item.label} label={item.label} value={item.value} />
            ))}
          </Box>
          <AdditionalCharacteristics character={character} theme={theme} />
          <Box display={'flex'}>
            <Box width={'50%'}>
              {!isAvailableForCurrentStyle ? (
                <Banner
                  message={`Not available for current style. ${
                    character?.styles?.length === 1 &&
                    'Use ' + characters.getStyleNameById(character?.styles[0].styleId)?.name
                  }`}
                  type={'warning'}
                />
              ) : (
                <CustomButton
                  text={isCharacterInShow ? t('Remove from Dashtoon') : t('Add To Dashtoon')}
                  width="100%"
                  height="54px"
                  isLoading={loading}
                  onClick={handleAddOrRemoveCharacter}
                  variant="contained"
                  hasBorder={false}
                  foregroundColor="white"
                  disabled={loading}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1} width="100%">
          {/* Character Image */}
          <CharacterImage
            imageUrl={character.imageUrl !== '' ? character.imageUrl : character.uploadedImage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterTrainingCompleted;
