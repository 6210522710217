// only tour start/stop/pause or step modification
export enum WalkthroughActions {
  ENABLE_WALKTHROUGH = 'ENABLE_WALKTHROUGH',
  START = 'START',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  GO_TO_STEP = 'GO_TO_STEP',
  UPDATE_STEP = 'UPDATE_STEP',
  SET_CHECKPOINT = 'SET_CHECKPOINT',
  SKIP = 'SKIP',
  SET_STEPS = 'SET_STEPS',
}

export interface UpdateWalkthroughStateAction {
  type: WalkthroughActions;
  payload: any;
}

export type WalkthroughStateActionTypes = UpdateWalkthroughStateAction;
