import { CharacterActions, CharacterActionTypes } from '../Actions/Character';
import {
  Character,
  CharacterInitialState,
  CharacterLibraryViewState,
  ShowCharacters,
} from '../../Models/Character';
import { StyleResponseDTO } from '../../Models/Styles';

export interface CharacterState {
  allCharacters: Character[];
  showCharacters: ShowCharacters[];
  trainingCharacters: Character[];
  currentCharacter: Character | null;
  characterModalLibraryViewState: CharacterLibraryViewState;
  allStyles: StyleResponseDTO[];
  styleBaseModelMapper: Record<string, string>;
  episodeCharacters: ShowCharacters[];
}

export const CharacterReducer = (
  state: CharacterState = CharacterInitialState,
  action: CharacterActionTypes
): CharacterState => {
  switch (action.type) {
    case CharacterActions.UPDATE_ALL_CHARACTERS:
      return { ...state, allCharacters: action.payload };
    case CharacterActions.ADD_TO_ALL_CHARACTERS:
      const allCharacters = state.allCharacters;
      const allCharacterIds = allCharacters.map(item => item.id);
      const characters: Character[] = action.payload;
      return {
        ...state,
        allCharacters: allCharacters.concat(
          characters.filter(item => !allCharacterIds.includes(item.id))
        ),
      };
    case CharacterActions.UPDATE_SHOW_CHARACTERS:
      return { ...state, showCharacters: action.payload };
    case CharacterActions.UPDATE_TRAINING_CHARACTERS:
      return { ...state, trainingCharacters: action.payload };
    case CharacterActions.SET_CURRENT_CHARACTER:
      return { ...state, currentCharacter: action.payload };
    case CharacterActions.ADD_TO_TRAINING_CHARACTERS:
      return { ...state, trainingCharacters: [...state.trainingCharacters, action.payload] };
    case CharacterActions.SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE:
      return { ...state, characterModalLibraryViewState: action.payload };
    case CharacterActions.SET_ALL_AVAILABLE_STYLES:
      const mapper: Record<string, string> = {};
      action.payload?.forEach((style: StyleResponseDTO) => {
        mapper[style.value] = style.baseModel;
      });
      return { ...state, allStyles: action.payload, styleBaseModelMapper: mapper };
    case CharacterActions.SET_EPISODE_CHARACTERS:
      return { ...state, episodeCharacters: action.payload };
    case CharacterActions.ADD_TO_EPISODE_CHARACTERS:
      return {
        ...state,
        episodeCharacters: [...state.episodeCharacters, ...action.payload.characters],
      };
    case CharacterActions.ADD_TO_SHOW_CHARACTERS:
      return { ...state, showCharacters: [...state.showCharacters, ...action.payload.characters] };
    case CharacterActions.REMOVE_FROM_SHOW_CHARACTERS:
      return {
        ...state,
        showCharacters: state.showCharacters.filter(sc => !action.payload.includes(sc.id)),
      };
    case CharacterActions.REMOVE_FROM_EPISODE_CHARACTERS:
      return {
        ...state,
        episodeCharacters: state.episodeCharacters.filter(sc => !action.payload.includes(sc.id)),
      };
    default:
      return state;
  }
};
