import { FormHeader, LeftContainer } from './StyledComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '../../../Provider/AuthProvider';
import { Box, Button, Tooltip } from '@mui/material';
import CustomButton from '../../CustomButton/CustomButton';
import useNotification from '../../../Hooks/useNotification';

const NotVerified = () => {
  const theme = useTheme();
  const notify = useNotification();
  const auth = useContext(AuthContext);
  // add a 60seconds timer to prevent spamming
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = React.useRef<number>();
  const [countOfVerificationEmailsSent, setCountOfVerificationEmailsSent] = useState(0);
  const handleSendVerificationEmail = async () => {
    if (countOfVerificationEmailsSent > 2) {
      notify.message('error', t('Please try again after some time'), 3000);
      return;
    }
    setCountOfVerificationEmailsSent(prev => prev + 1);
    setIsLoading(true);
    await auth.sendVerificationEmail();
    setIsTimerRunning(true);
    timerRef.current = window.setInterval(() => {
      setTimer(prev => {
        if (prev === 1) {
          window.clearInterval(timerRef.current);
          setIsTimerRunning(false);
          return 60;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
    setIsLoading(false);
  };
  useEffect(() => {
    return () => {
      window.clearInterval(timerRef.current);
    };
  }, []);

  return (
    <LeftContainer>
      <Box
        style={{
          fontSize: '16px',
          fontWeight: 600,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          height: '300px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>{t('Your account is not verified!')}</Box>
        <Box>
          <Tooltip
            title={
              countOfVerificationEmailsSent > 0 ? t('Please try again after some time.') : undefined
            }
          >
            <CustomButton
              text={'Send Verification Email Again'}
              onClick={handleSendVerificationEmail}
              variant={'contained'}
              isLoading={isLoading}
              disabled={isTimerRunning || countOfVerificationEmailsSent > 2}
              style={{
                // width: '120px',
                height: '32px',
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'none',
                padding: 2,
              }}
            />
          </Tooltip>
        </Box>
        <Box>{isTimerRunning && <Box> Please wait for {timer} seconds</Box>}</Box>
      </Box>
    </LeftContainer>
  );
};

export default NotVerified;
