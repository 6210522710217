import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { FormHelperText, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import { t } from 'i18next';

interface CustomTextFieldProps {
  id?: string;
  label?: string;
  subLabel?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  type?: string;
  noOfLines?: number;
  width?: string;
  height?: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>; // add inputProps prop
  enableAutofill?: boolean; // add enableAutofill prop
  tooltip?: string;
  showToggle?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  isV2?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  labelWidth?: string;
  borderRadius?: string;
  borderColor?: string;
  margin?: string;
  name?: string;
  style?: React.CSSProperties;
  icon?: ReactNode;
  autoFocus?: boolean;
  maxRows?: number;
  endAdornment?: JSX.Element;
  error?: boolean;
  helperText?: string;
  hoverColor?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  inputFieldRef?: React.Ref<HTMLInputElement>;
}

const CustomTextFieldV2: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  placeholder,
  required,
  multiline,
  type,
  onChange,
  onKeyDown,
  onBlur,
  noOfLines,
  width,
  labelWidth,
  height,
  inputProps,
  subLabel,
  enableAutofill = false, // set default value for enableAutofill prop
  tooltip,
  showToggle = false,
  ref,
  isV2 = false,
  disabled = false,
  backgroundColor,
  borderRadius,
  borderColor,
  margin,
  name,
  style,
  icon,
  autoFocus,
  maxRows,
  endAdornment,
  error,
  helperText,
  hoverColor,
  ariaLabel,
  ariaLabelledBy,
  inputFieldRef,
  ...rest
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHasInteracted(true);
    onBlur && onBlur(event);
  };

  const handleTabPress: KeyboardEventHandler<HTMLDivElement> = event => {
    if (
      enableAutofill &&
      event.key === 'Tab' &&
      inputRef.current &&
      inputRef.current.value === ''
    ) {
      event.preventDefault();
      inputRef.current.value = placeholder || '';
    }
  };

  return (
    <div
      style={{
        padding: isV2 ? '0' : '10px 0',
        pointerEvents: 'all',
        display: 'flex',
        alignItems: multiline ? 'start' : 'center',
        margin: margin ? margin : '8px 0',
        width: width ?? 'auto',
      }}
    >
      {label &&
        (tooltip ? (
          <Tooltip title={t(tooltip)}>
            <InputLabel
              role={'label'}
              htmlFor={id}
              aria-label={ariaLabel}
              aria-description={placeholder}
              aria-roledescription={'label'}
              style={{
                fontSize: isV2 ? '12px' : '16px',
                fontWeight: isV2 ? '600' : '500',
                width: isV2 ? labelWidth : 350,
              }}
            >
              {t(label)} {required && <span style={{ color: 'red' }}>*</span>}
            </InputLabel>
          </Tooltip>
        ) : (
          <InputLabel
            role={'label'}
            htmlFor={id}
            aria-label={ariaLabel}
            aria-description={placeholder}
            aria-roledescription={'label'}
            style={{
              fontSize: isV2 ? '12px' : '16px',
              fontWeight: isV2 ? '600' : '500',
              width: isV2 ? labelWidth : 350,
            }}
          >
            {t(label)} {required && <span style={{ color: 'red' }}>*</span>}
          </InputLabel>
        ))}
      <div style={{}}>
        <InputLabel
          role={'label'}
          htmlFor={id}
          aria-label={ariaLabel}
          aria-description={placeholder}
          aria-roledescription={'label'}
          style={{ fontSize: isV2 ? '12px' : '14px', fontWeight: '400' }}
        >
          {subLabel}
        </InputLabel>
      </div>
      <div style={{ width: '100%' }}>
        <TextField
          inputRef={inputFieldRef}
          disabled={disabled}
          id={id}
          value={inputProps?.value}
          minRows={multiline ? noOfLines ?? 5 : 1}
          maxRows={multiline ? (maxRows ? maxRows : noOfLines ?? 5) : 1}
          multiline={multiline}
          ref={ref}
          onChange={onChange}
          name={name}
          onFocus={() => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: true,
            });
          }}
          onBlur={item => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: false,
            });
            handleBlur(item);
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation();
            }
            onKeyDown && onKeyDown(event);
            handleTabPress(event);
          }}
          sx={{
            width: width ?? '300px',
            height: height,
            '& .MuiInputBase-root': {
              fontSize: '14px',
              height: height,
              borderRadius: borderRadius ?? '8px',
              borderColor: `${borderColor ?? theme.palette.divider} !important`,
              padding: '6px 10px',
              backgroundColor: backgroundColor ?? theme.palette.background.paper,
              ...style,
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px !important',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor:
                required && hasInteracted && !inputProps?.value
                  ? 'red'
                  : borderColor ?? theme.palette.divider,
            },
            '&:hover fieldset': {
              borderColor:
                required && hasInteracted && !inputProps?.value
                  ? 'red !important'
                  : (hoverColor ?? borderColor ?? theme.palette.action.hover) + '!important',
            },
            '&:focus-within fieldset': {
              borderColor:
                required && hasInteracted && !inputProps?.value
                  ? 'red !important'
                  : (borderColor ?? theme.palette.primary.main) + '!important',
              borderWidth: '1px !important',
            },
          }}
          placeholder={placeholder}
          inputProps={{
            style: {
              padding: '0px !important',
              WebkitUserSelect: 'text',
              MozUserSelect: 'text',
              // MsUserSelect: 'text',
              // UserSelect: 'text',
            },
            ref: inputRef, // Assign ref to inputRef
            ...inputProps,
            'aria-label': ariaLabel,
            'aria-description': placeholder,
          }} // add custom styles to inputProps
          type={showToggle ? (!showPassword ? 'password' : 'text') : type}
          {...rest}
          InputProps={{
            startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
            endAdornment: showToggle ? ( // display toggle button conditionally
              <InputAdornment position="end">
                <IconButton role={'button'} onClick={handleTogglePasswordVisibility} edge="end">
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : endAdornment ? (
              endAdornment
            ) : null,
            style: {
              WebkitUserSelect: 'text',
              MozUserSelect: 'text',
            },
          }}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          aria-label={
            ariaLabel
              ? ariaLabel
              : label
              ? label
              : placeholder
              ? placeholder
              : subLabel
              ? subLabel
              : 'Custom Text Field'
          }
          aria-labelledby={ariaLabelledBy}
        />
      </div>
    </div>
  );
};

export default CustomTextFieldV2;
