import { Box, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomTextField from '../../../../../../Components/CustomTextfield/CustomTextField';
import CustomSelect from './CustomSelect';
import { t } from 'i18next';
import CustomButton from '../../../../../../Components/CustomButton/CustomButton';
import React, { useEffect } from 'react';
import useCharacters from '../../../../../../Hooks/useCharacter';
import { useParams } from 'react-router-dom';
import { FeedbackType } from '../../../../../../Models/Feedback';
import useNotification from '../../../../../../Hooks/useNotification';
import { AppStateActions } from '../../../../../../Redux/Actions/AppState';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../../Constants/TrackingEvents';
import { auth } from '../../../../../../firebaseConfig';
import { TrackingProperties } from '../../../../../../Constants/TrackingProperties';
import { ReduxState } from '../../../../../../Redux/ReduxInterface';

const questions: {
  question: string;
  answer: string;
  placeholder?: string;
}[] = [
  {
    question: "How do you intend to use the characters you've created?",
    placeholder:
      "Whether it's for a specific project or ongoing storytelling, your use case matters.",
    answer: '',
  },
  {
    question: 'Why do you prefer training characters over using our library?',
    placeholder: 'Your response will help us understand your specific creative preferences.',
    answer: '',
  },
  {
    question: 'How many characters do you plan to create?',
    placeholder: 'Knowing your intended volume allows us to optimize our resources.',
    answer: '',
  },
];

const CharacterLimitReachedForm = () => {
  const [formState, setFormState] = React.useState([...questions]);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const notify = useNotification();
  const params = useParams() as { showId: string };
  const characters = useCharacters();
  const dispatch = useDispatch();
  const trainedCharacters = useSelector((state: ReduxState) => state.character.trainingCharacters);

  useEffect(() => {
    trackEvent(
      {
        event: TrackingEvents.characterRateLimitFormView,
        properties: {
          characters: trainedCharacters.length.toString(),
        } as TrackingProperties,
      },
      'CREATOR'
    );
  }, []);
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
    const newState = formState.map(question => {
      if (question.question === name) {
        return { ...question, answer: value };
      }
      return question;
    });
    setFormState(newState);
  };

  const handleSubmit = async () => {
    try {
      await characters.increaseQuota(
        {
          type: FeedbackType.CHARACTER_TRAINING_QUOTA_REQUEST,
          feedback: '1',
          resourceId: params.showId,
          data: JSON.stringify(
            formState.map(field => ({ question: field.question, answer: field.answer }))
          ),
        },
        params.showId
      );
      notify.message('success', t('Request submitted successfully'), 2000);
      dispatch({
        type: AppStateActions.OPEN_SHOW_DETAILS_MODAL,
        payload: false,
      });
      trackEvent(
        {
          event: TrackingEvents.characterRateLimitFormSubmit,
          properties: {
            characters: trainedCharacters.length.toString(),
            form: JSON.stringify(
              formState.map(field => ({ question: field.question, answer: field.answer }))
            ),
          } as TrackingProperties,
        },
        'CREATOR'
      );
    } catch (err) {
      console.error(err);
      notify.message('error', t('Failed to submit request, please try again later'), 2000);
    }
  };
  return (
    <Box display={'flex'} gap={1} flexDirection={'column'} mb={1}>
      <Typography variant={'h5'}>{t('Character Creation Daily Limit Reached')}</Typography>
      <Box>
        {t(
          'Due to a high volume of character training requests, you can only train 1 characters per day. If you want to train more characters, let us know your requirements.'
        )}
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={1.5}>
        {formState.map((field, index) => (
          <Box key={`${field.question}`} display={'flex'} alignItems={'end'} gap={1}>
            <Box flex={2}>
              <CustomTextField
                name={field.question}
                onChange={handleFormChange}
                inputProps={{ value: field.answer }}
                label={`${field.question}*`}
                placeholder={field.placeholder}
                isV2={true}
                width={'100%'}
                backgroundColor={'none'}
                multiline={true}
                noOfLines={2}
              />
            </Box>
          </Box>
        ))}
        <Box>
          <Box flex={1}>
            <CustomButton
              text={t('Submit Feedback')}
              isLoading={false}
              width="100%"
              height="54px"
              onClick={handleSubmit}
              variant="contained"
              hasBorder={false}
              foregroundColor="white"
              disabled={formState.some(field => field.answer === '')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterLimitReachedForm;
