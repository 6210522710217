import { PromptBoxCharactersActions } from '../Actions/PromptBoxCharacters';

export interface PromptBoxCharactersState {
  characters: string[];
}

export const initialPromptBoxState: PromptBoxCharactersState = {
  characters: [],
};

export interface PromptBoxCharacterStateActionRequest {
  type: PromptBoxCharactersActions;
  payload: any;
}

export const PromptBoxCharactersReducer = (
  state: PromptBoxCharactersState = initialPromptBoxState,
  action: PromptBoxCharacterStateActionRequest
): PromptBoxCharactersState => {
  switch (action.type) {
    case PromptBoxCharactersActions.CHARACTER_ADD:
      return {
        ...state,
        characters: [...state.characters, action.payload],
      };
    case PromptBoxCharactersActions.CHARACTER_REMOVE:
      return {
        ...state,
        characters: state.characters.filter(character => character !== action.payload),
      };
    case PromptBoxCharactersActions.CHARACTER_RESET:
      return {
        ...state,
        characters: action.payload,
      };
    default:
      return state;
  }
};
