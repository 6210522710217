import { CanvasStateItem, Dialogue, PanelItemV2 } from '../../Models/Story';
import {
  StoryCrafterStateAction,
  StoryCrafterStateActionTypes,
} from '../Actions/StoryCrafterState';

export interface StoryCrafterState {
  panels: PanelItemV2[];
  canvasState: CanvasStateItem[];
  dialogs: Dialogue[];
  generating: boolean;
  done: boolean;
  thumbnailUpdated: boolean;
}

export const initialStoryCrafterState: StoryCrafterState = {
  panels: [],
  canvasState: [],
  dialogs: [],
  generating: false,
  done: false,
  thumbnailUpdated: false,
};

export const StoryCrafterStateReducer = (
  state: StoryCrafterState = initialStoryCrafterState,
  action: StoryCrafterStateActionTypes
): StoryCrafterState => {
  switch (action.type) {
    case StoryCrafterStateAction.SET_GENERATION_DETAILS:
      return {
        ...state,
        panels: action.payload.panels,
        canvasState: action.payload.canvasState,
        dialogs: action.payload.dialogs,
      };
    case StoryCrafterStateAction.SET_THUMBNAIL_UPDATED:
      return {
        ...state,
        thumbnailUpdated: action.payload.thumbnailUpdated,
      };
    case StoryCrafterStateAction.SET_GENERATING:
      return {
        ...state,
        generating: action.payload.generating,
      };
    case StoryCrafterStateAction.SET_DONE:
      return {
        ...state,
        done: true,
        generating: false,
        panels: [],
        canvasState: [],
        dialogs: [],
      };
    case StoryCrafterStateAction.RESET:
      return initialStoryCrafterState;
    default:
      return state;
  }
};
