import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SubscriptionPlans, UserQuotaResponse } from '../../Models/User';
import { fetchSubscriptionPlanDetails, fetchUserQuotas } from '../../Requests/Studio/User';
import { ReduxState } from '../ReduxInterface';

export enum UserQuotaSubscriptionActions {
  FETCH_SUBSCRIPTION_DETAILS_REQUEST = 'FETCH_SUBSCRIPTION_DETAILS_REQUEST',
  FETCH_SUBSCRIPTION_DETAILS_SUCCESS = 'FETCH_SUBSCRIPTION_DETAILS_SUCCESS',
  FETCH_SUBSCRIPTION_DETAILS_FAILURE = 'FETCH_SUBSCRIPTION_DETAILS_FAILURE',
  FETCH_USER_QUOTAS_REQUEST = 'FETCH_USER_QUOTAS_REQUEST',
  FETCH_USER_QUOTAS_SUCCESS = 'FETCH_USER_QUOTAS_SUCCESS',
  FETCH_USER_QUOTAS_FAILURE = 'FETCH_USER_QUOTAS_FAILURE',
  UPDATE_CREDITS_BALANCE = 'UPDATE_CREDITS_BALANCE',
  ADD_CREDITS_BALANCE = 'ADD_CREDITS_BALANCE',
}

export const fetchSubscriptionDetails =
  (): ThunkAction<void, ReduxState, unknown, AnyAction> => async dispatch => {
    dispatch({ type: UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_REQUEST });

    try {
      const response: SubscriptionPlans = await fetchSubscriptionPlanDetails();
      dispatch({
        type: UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_FAILURE,
        payload: 'Error fetching user subscription',
      });
    }
  };

export const fetchUserQuota =
  (): ThunkAction<void, ReduxState, unknown, AnyAction> => async dispatch => {
    dispatch({ type: UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_REQUEST });

    try {
      const response: UserQuotaResponse = await fetchUserQuotas();
      dispatch({ type: UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_FAILURE,
        payload: 'Error fetching user quotas',
      });
    }
  };
