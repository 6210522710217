export enum GenerateImageActions {
  UPDATE_PROMPT = 'UPDATE_PROMPT',
  UPDATE_NEGATIVE_PROMPT = 'UPDATE_NEGATIVE_PROMPT',
  UPDATE_EDIT_TYPE = 'UPDATE_EDIT_TYPE',
  UPDATE_PREV_PROMPT = 'UPDATE_PREV_PROMPT',
  UPDATE_SEED = 'UPDATE_SEED',
  UPDATE_CFG = 'UPDATE_CFG',
  UPDATE_STEPS = 'UPDATE_STEPS',
  UPDATE_BATCH_SIZE = 'UPDATE_BATCH_SIZE',
  UPDATE_WIDTH = 'UPDATE_WIDTH',
  UPDATE_HEIGHT = 'UPDATE_HEIGHT',
  UPDATE_STYLE = 'UPDATE_STYLE',
  UPDATE_CHARACTER_NAME = 'UPDATE_CHARACTER_NAME',
  UPDATE_CHARACTER_ID = 'UPDATE_CHARACTER_ID',
  UPDATE_CONTROL_NET = 'UPDATE_CONTROL_NET',
  UPDATE_INIT_IMAGE = 'UPDATE_INIT_IMAGE',
  UPDATE_MASK_IMAGE = 'UPDATE_MASK_IMAGE',
  UPDATE_IS_GENERATING = 'UPDATE_IS_GENERATING',
  UPDATE_AUTO_FACE_FIX = 'UPDATE_AUTO_FACE_FIX',
  UPDATE_SELECTED_CHARACTER_PROMPT = 'UPDATE_SELECTED_CHARACTER_PROMPT',
  UPDATE_STRENGTH = 'UPDATE_STRENGTH',
  UPDATE_BULK = 'UPDATE_BULK',
  UPDATE_INPAINTED_IMAGE = 'UPDATE_INPAINTED_IMAGE',
  UPDATE_INPAINT_FILL_TYPE = 'UPDATE_INPAINT_FILL_TYPE',
  UPDATE_INPAINT_TYPE = 'UPDATE_INPAINT_TYPE',
  UPDATE_IMAGE_SEGMENT = 'UPDATE_IMAGE_SEGMENT',
  UPDATE_COLOR_CONTEXT = 'UPDATE_COLOR_CONTEXT',
  UPDATE_INIT_IMAGE_CROP_BOUNDS = 'UPDATE_INIT_IMAGE_CROP_BOUNDS',
  UPDATE_CHARACTER_WEIGHT = 'UPDATE_CHARACTER_WEIGHT',
  UPDATE_STYLE_WEIGHT = 'UPDATE_STYLE_WEIGHT',
  UPDATE_ALLOW_STYLE_WEIGHT = 'UPDATE_ALLOW_STYLE_WEIGHT',
  RESET_ALL_IMAGE_GENERATION = 'RESET_ALL_IMAGE_GENERATION',
  RESET_IMAGE_GENERATION = 'RESET_IMAGE_GENERATION',
  UPDATE_DEFAULT_STYLE_WEIGHT = 'UPDATE_DEFAULT_STYLE_WEIGHT',
  UPDATE_TEXTUAL_EMBEDDING = 'UPDATE_TEXTUAL_EMBEDDING',
  UPDATE_GREEN_MASK = 'UPDATE_GREEN_MASK',
  UPDATE_A100_INFERENCE = 'UPDATE_A100_INFERENCE',
  UPDATE_ENABLE_CLIP_SKIP = 'UPDATE_ENABLE_CLIP_SKIP',
  UPDATE_ENABLE_HIGH_RES_FIX = 'UPDATE_ENABLE_HIGH_RES_FIX',
  UPDATE_ENABLE_HIGH_RES_FIX_DENOISE = 'UPDATE_ENABLE_HIGH_RES_FIX_DENOISE',
  UPDATE_T2I_ADAPTERS = 'UPDATE_T2I_ADAPTERS',
  UPDATE_IS_T2I_ADAPTERS_SELECTED = 'UPDATE_T2I_ADAPTERS_SELECTED',
}
