import { useSelector } from 'react-redux';
import { ReduxState } from '../Redux/ReduxInterface';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Provider/AuthProvider';
import { trackEvent } from '../Utils/Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { useFlags } from 'flagsmith/react';
import { FlagsmithFeatureFlags } from '../Models/Flags';

const userVersionLowerThanServer = (serverVersion: string, userVersion: string) => {
  // Adjust the regex to optionally allow single-digit month and day
  const regexp = new RegExp(/^\d{2}\.\d{1,2}\.\d{1,2}$/);
  if (!regexp.test(serverVersion) || !regexp.test(userVersion)) {
    return false;
  }

  // Compare versions using localeCompare
  return (
    userVersion.localeCompare(serverVersion, undefined, { numeric: true, sensitivity: 'base' }) < 0
  );
};

export function useVersionCheck() {
  const auth = useContext(AuthContext);
  const [doesUserNeedToUpdate, setDoesUserNeedToUpdate] = useState(false);
  const [serverAppVersion, setServerAppVersion] = useState('');
  const userAppVersion = useSelector((state: ReduxState) => state.appState.userAppVersion);
  const flags = useFlags([FlagsmithFeatureFlags.app_version]);
  const { enabled, value } = flags[FlagsmithFeatureFlags.app_version];
  const getServerVersion = () => {
    if (enabled && value) {
      setServerAppVersion(value.toString());
      handleUpdateVersion(value.toString());
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      getServerVersion();
    }
  }, [auth.currentUser, value, enabled]);

  const handleUpdateVersion = (version: string) => {
    if (userAppVersion === version) {
      setDoesUserNeedToUpdate(false);
      return;
    }

    window.localStorage.setItem('updatedVersion', version);
    setDoesUserNeedToUpdate(false);
    trackEvent(
      {
        event: TrackingEvents.refreshUserPage,
        properties: {
          currentVersion: userAppVersion,
          updatedVersion: version,
        },
      },
      'CREATOR'
    );
    // delay between 1 minutes and 30 minutes
    const delay = Math.floor(Math.random() * 30 + 1) * 60 * 1000;
    setTimeout(() => {
      window.location.reload();
    }, delay);
  };

  useEffect(() => {
    // '24.04.04' - YY.MM.DD
    // check if the version from server is different from the user's version
    if (!serverAppVersion) {
      return;
    }

    if (window.localStorage.getItem('updatedVersion') === serverAppVersion) {
      setDoesUserNeedToUpdate(false);
      return;
    }

    if (userVersionLowerThanServer(serverAppVersion, userAppVersion)) {
      handleUpdateVersion(serverAppVersion);
      setDoesUserNeedToUpdate(true);
    } else {
      setDoesUserNeedToUpdate(false);
    }
  }, [serverAppVersion]);

  return { doesUserNeedToUpdate };
}
