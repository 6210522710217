import { ShowStudioUser } from '../../Models/Show';
import { ShowUsersActions, ShowUsersActionTypes } from '../Actions/ShowUsers';
export interface ShowUsersState {
  showUsers: ShowStudioUser[];
  availableRoles: string[];
}

export const initialShowUsersState: ShowUsersState = {
  showUsers: [],
  availableRoles: [],
};
export const ShowUsersReducer = (
  state: ShowUsersState = initialShowUsersState,
  action: ShowUsersActionTypes
): ShowUsersState => {
  switch (action.type) {
    case ShowUsersActions.SET_SHOW_USERS:
      return { ...state, showUsers: action.payload.showUsers };

    case ShowUsersActions.SET_AVAILABLE_ROLES:
      return { ...state, availableRoles: action.payload.availableRoles };
    default:
      return state;
  }
};
