import { t } from 'i18next';
import { isProduction } from '../Config/Config';
import { CanvasMode } from '../Models/GenerateImageV2';

export const checkOS = (): string => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('Win') != -1) return 'Windows OS';
  if (userAgent.indexOf('Mac') != -1) return 'MacOS';
  if (userAgent.indexOf('Linux') != -1) return 'Linux OS';
  // Add other OS checks as needed
  return 'Unknown OS';
};

export const getModeRoute = (mode: CanvasMode): string => {
  switch (mode) {
    case CanvasMode.STORY:
      return 'story';
    case CanvasMode.SCREENPLAY:
      return 'screenplay';
    default:
      return 'editor';
  }
};

export const getReverseModeRoute = (mode: string): CanvasMode => {
  switch (mode) {
    case 'story':
      return CanvasMode.STORY;
    case 'screenplay':
      return CanvasMode.SCREENPLAY;
    default:
      return CanvasMode.DASHTOON;
  }
};
export const checkURLIncludes = (substring: string): boolean => {
  return window.location.href.includes(substring);
};

export const checkIfProduction = (): boolean => {
  return isProduction;
};

export const getHostUrl = (): string => {
  return `https://${window.location.hostname}`;
};

export const getProductionHostURL = (): string => {
  return `https://studio.dashtoon.com`;
};

export const timeSince = (time: number): string => {
  const timeDiff = Date.now() - time;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  if (months > 0) {
    return `${months} ${months > 1 ? t('months ago') : t('month ago')}`;
  } else if (days > 0) {
    return `${days} ${days > 1 ? t('days ago') : t('day ago')}`;
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? t('hours ago') : t('hour ago')}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? t('minutes ago') : t('minute ago')}`;
  } else {
    return t('just now');
  }
};

export const isIos = (): boolean => {
  const isIosVar =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return isIosVar;
};
