// Define the function to make a single API call and measure its latency
export async function makeApiCall(url: string): Promise<number> {
  const startTime = Date.now();

  // Making a lightweight HEAD request instead of GET
  await fetch(url, { method: 'GET' }).catch(() => {}); // Ignoring any errors just for the ping purpose

  const endTime = Date.now();
  return endTime - startTime; // Return the time taken
}

// Define the function to calculate percentiles
export async function calculatePercentiles(
  url: string,
  numberOfRequests: number
): Promise<{ p50: number; p90: number; p99: number }> {
  let latencies: number[] = [];

  for (let i = 0; i < numberOfRequests; i++) {
    const latency = await makeApiCall(url);
    latencies.push(latency);
  }

  latencies.sort((a, b) => a - b);

  const calculatePercentile = (percentile: number) => {
    const index = Math.ceil(percentile * latencies.length) - 1;
    return latencies[Math.max(0, Math.min(index, latencies.length - 1))];
  };

  return {
    p50: calculatePercentile(0.5),
    p90: calculatePercentile(0.9),
    p99: calculatePercentile(0.99),
  };
}
