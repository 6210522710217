import { CustomStep } from './FirstWalkthroughSteps';

export const getStoryWalkthroughSteps = (pageId: string, frameId: string): CustomStep[] => {
  return [
    {
      target: `#${pageId.replace(':', `\\:`)}-outer`,
      disableBeacon: true,
      placement: 'left-start',
      offset: 0,
      title: 'This is a Page. This is where you place your comic images, bubbles and text.',
      content: undefined,
      stepTitle: pageId,
    },
    {
      target: `#story-page-tool`,
      disableBeacon: true,
      placement: 'left-start',
      offset: 0,
      title:
        'Click on a Page to select it. Then add bubbles and text from the Page tools in the right panel.',
      content: undefined,
      stepTitle: pageId,
    },
    {
      target: `#${frameId.replace(':', `\\:`)}-outer`,
      disableBeacon: true,
      placement: 'left-start',
      offset: 20,
      title:
        'This is a Frame, within which Dashtoon’s AI generates images. You can create Frames on a Page, or anywhere on the blank canvas.',
      content: undefined,
      stepTitle: frameId,
    },
    {
      target: '#story-right-menu',
      disableBeacon: true,
      placement: 'left-start',
      offset: 0,
      title:
        'There are several ways to generate an image within a Frame. Explore different generation modes in the right panel.',
      content: undefined,
      stepTitle: frameId,
    },
    {
      target: `#${frameId.replace(':', `\\:`)}-outer`,
      disableBeacon: true,
      placement: 'left-start',
      offset: 20,
      title:
        'Right click on a frame to download images, create a character, set a comic thumbnail, and more.',
      content: undefined,
      stepTitle: frameId,
    },
    {
      target: '#story-publish',
      disableBeacon: true,
      placement: 'bottom',
      offset: 0,
      title:
        'Publish your pages as a Dashtoon comic. Read it on the Dashtoon mobile app and share it with your friends!',
      content: undefined,
      stepTitle: frameId,
    },
  ];
};
