export enum FeedbackType {
  PROMPT_TO_STORY = 'PROMPT_TO_STORY',
  CHARACTER_TRAINED_USED = 'CHARACTER_TRAINED_USED',
  CHARACTER_TRAINING_QUOTA_REQUEST = 'CHARACTER_TRAINING_QUOTA_REQUEST',
}

export interface FeedbackRequest {
  type: FeedbackType;
  feedback: string;
  resourceId: string;
  data: string;
}
