import { Box, Theme, Typography } from "@mui/material";
import { Character } from "../../../../../../../Models/Character";

  
  const CharacterDescription = ({ character, theme }: {
    character: Character;
    theme: Theme
  }) => (
    <Box style={{ width: '100%', height: '5%', display: 'flex' }}>
      <Typography style={{ fontSize: '14px', color: theme.palette.object.primary }}>
        {character?.gender}
      </Typography>
      <Box style={{ display: 'flex', width: '50%', marginLeft: '10px' }}>
        <li style={{ fontSize: '14px', marginTop: '1px' }}>{character?.description}</li>
      </Box>
    </Box>
  );

  export default CharacterDescription;
  