export interface UserEnabledFeaturesState {
  pww: boolean;
  segmentation: boolean;
  loraWeights: boolean;
  pix2pix: boolean;
  createCharacter: boolean;
  enableNSFW: boolean;
  negativeEmbedding: boolean;
  magicEraser: boolean;
  enableMultiInpaint: boolean;
  enableDiffusersInference: boolean;
  enableHighResolutionGeneration: boolean;
  imgToPromptPoc: boolean;
  enableClipSkip: boolean;
  enableHighResFix: boolean;
  allowDefaultCanvas: boolean;
  allowPostProcess: boolean;
  allowAutoInpaint: boolean;
  allowLiveMode: boolean;
  isDashtoonUser: boolean;
  enableNewPanelling: boolean;
  defaultTurboDisabled?: boolean;
  isDemoHackEnabled?: boolean;
  v3CanvasBetaTest?: boolean;
  isOldPayingUser?: boolean;
  enableCanvasV3?: boolean;
  isLoading?: boolean;
}

export const getUserEnabledFeaturesInitState = (): UserEnabledFeaturesState => {
  return {
    pww: false,
    segmentation: false,
    loraWeights: false,
    pix2pix: false,
    createCharacter: false,
    enableNSFW: false,
    negativeEmbedding: false,
    magicEraser: false,
    enableMultiInpaint: false,
    enableDiffusersInference: false,
    enableHighResolutionGeneration: false,
    imgToPromptPoc: false,
    enableClipSkip: false,
    enableHighResFix: false,
    allowDefaultCanvas: true,
    allowPostProcess: false,
    allowAutoInpaint: false,
    allowLiveMode: true,
    isDashtoonUser: false,
    enableNewPanelling: false,
    defaultTurboDisabled: false,
    isDemoHackEnabled: false,
    v3CanvasBetaTest: false,
    isOldPayingUser: false,
    enableCanvasV3: false,
    isLoading: true,
  };
};

export enum UserEnabledFeaturesTypes {
  PWW = 'PWW',
  SEGMENTATION = 'SEGMENTATION',
  LORA_WEIGHTS = 'LORA_WEIGHTS',
  PIX2PIX = 'PIX2PIX',
  CREATE_CHARACTER = 'CREATE_CHARACTER',
  ENABLE_NSFW = 'ENABLE_NSFW',
  NEGATIVE_EMBEDDING = 'NEGATIVE_EMBEDDING',
  MAGIC_ERASER = 'MAGIC_ERASER',
  ENABLE_MULTI_INPAINT = 'ENABLE_MULTI_INPAINT',
  ENABLE_DIFFUSERS_INPAINT = 'ENABLE_DIFFUSERS_INPAINT',
  ENABLE_HIGH_RESOLUTION_GENERATION = 'ENABLE_HIGH_RESOLUTION_GENERATION',
  ENABLE_LIVE_MODE = 'ENABLE_LIVE_MODE',
  IS_DASHTOON_USER = 'IS_DASHTOON_USER',
  ENABLE_NEW_PANELLING = 'ENABLE_NEW_PANELLING',
  DEMO_FEATURE = 'DEMO_FEATURE',
  V3_CANVAS_BETA_TEST = 'V3_CANVAS_BETA_TEST',
  ENABLE_CANVAS_V3 = 'ENABLE_CANVAS_V3',
}

export interface UserEnabledFeaturesResponse {
  features: UserEnabledFeaturesTypes[];
}
