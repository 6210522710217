import React from 'react';
import { CharacterHairLength } from '../../../../../Models/CharacterCreationOptions';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ReactComponent as BaldIcon } from '../../../../../Assets/Icons/hair-length-bald.svg';
import { ReactComponent as ShortIcon } from '../../../../../Assets/Icons/hair-length-short.svg';
import { ReactComponent as MediumIcon } from '../../../../../Assets/Icons/hair-length-medium.svg';
import { ReactComponent as LongIcon } from '../../../../../Assets/Icons/hair-length-long.svg';
import { ReactComponent as UnsetIcon } from '../../../../../Assets/Icons/unset.svg';
import { useTheme } from '@mui/material/styles';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';

export interface HairLengthOption {
  hairLength: CharacterHairLength | string;
  icon: ReactJSXElement;
}

export const hairLengthOptions: HairLengthOption[] = [
  {
    hairLength: '',
    icon: <UnsetIcon />,
  },
  {
    hairLength: CharacterHairLength.BALD,
    icon: <BaldIcon />,
  },
  {
    hairLength: CharacterHairLength.SHORT,
    icon: <ShortIcon />,
  },
  {
    hairLength: CharacterHairLength.MEDIUM,
    icon: <MediumIcon />,
  },
  {
    hairLength: CharacterHairLength.LONG,
    icon: <LongIcon />,
  },
];

interface CharacterHairLengthFeatureComponentProps {
  hairLength: HairLengthOption;
  setHairLength: (value: HairLengthOption) => void;
}
const CharacterHairLengthFeatureComponent = ({
  hairLength,
  setHairLength,
}: CharacterHairLengthFeatureComponentProps) => {
  const theme = useTheme();
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Hair Length')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {hairLengthOptions.map(option => {
          const selected = hairLength.hairLength === option.hairLength;
          return (
            <CharacterFeatureWithIconItem
              key={option.hairLength || 'hair-length-unset'}
              label={option.hairLength || t('Unset')}
              icon={option.icon}
              selected={selected}
              onClick={() => setHairLength(option)}
            />
          );
        })}
      </div>
      <CustomTextFieldV2
        key={'character-custom-hair-length'}
        width={'100%'}
        inputProps={{ value: hairLength.hairLength }}
        onChange={e => {
          setHairLength({ ...hairLength, hairLength: e.target.value });
        }}
        isV2={true}
        style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
        backgroundColor={theme.palette.surface.primary}
        borderRadius={'16px'}
        ariaLabelledBy={'Character Custom Hair Length'}
        multiline={false}
        placeholder={t('Or Enter Custom Hair Length')}
      />
    </CharacterFeatureContainer>
  );
};
export default CharacterHairLengthFeatureComponent;
