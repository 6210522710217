import { Box, SelectChangeEvent } from '@mui/material';
import { CharacterCreateFormState } from './CharacterCreationView';
import CustomSelect from './CustomSelect';
import CustomTextField from '../../../../../../Components/CustomTextfield/CustomTextField';
import { ReduxState } from '../../../../../../Redux/ReduxInterface';
import { useSelector } from 'react-redux';

const CombinedForm = ({
  characterState,
  handleFormChange,
  footer,
}: {
  characterState: CharacterCreateFormState;
  handleFormChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => void;
  footer: React.ReactNode;
}) => {
  const isDashtoonUser = useSelector(
    (state: ReduxState) => state.userEnabledFeatures.isDashtoonUser
  );

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      {/* page 2 */}
      <Box display={'flex'} gap={2} flexDirection={'column'} mb={3}>
        <Box display={'flex'} alignItems={'end'} gap={3}>
          <Box flex={2}>
            <CustomTextField
              name="name"
              onChange={handleFormChange}
              inputProps={{ value: characterState.name }}
              label={'Character Name *'}
              placeholder={'Jane'}
              isV2={true}
              width={'100%'}
              backgroundColor={'none'}
            />
          </Box>
          <Box flex={1}>
            <CustomSelect
              name="gender"
              value={characterState.gender}
              onChange={handleFormChange}
              label="Gender"
              options={['MALE', 'FEMALE']}
              placeholder="Select"
            />
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} alignItems={'end'} gap={3}>
          {/* Casting Selection */}
          <Box flex={1}>
            <CustomSelect
              name="casting"
              label="Casting"
              options={['Primary', 'Secondary', 'Tertiary']}
              value={characterState.casting}
              onChange={handleFormChange}
              placeholder="Casting"
            />
          </Box>

          {/* Age Selection */}
          <Box flex={1}>
            <CustomSelect
              name="age"
              label="Age"
              options={[
                'Infant',
                'Toddler',
                'Child',
                'Teenager',
                'Young Adult',
                'Adult',
                'Middle Age',
                'Old',
                'Ancient',
              ]}
              value={characterState.age}
              onChange={handleFormChange}
              placeholder="Age"
            />
          </Box>

          {/* Ethnicity Selection */}
          <Box flex={1}>
            <CustomSelect
              name="ethnicity"
              label="Ethnicity"
              options={[
                'African American',
                'Asian American',
                'Indian',
                'Latin American',
                'Middle Eastern',
                'Caucasian',
              ]}
              value={characterState.ethnicity}
              onChange={handleFormChange}
              placeholder="Ethinicity"
            />
          </Box>
        </Box>

        <Box display={'flex'} alignItems={'end'} gap={3}>
          {/* Hair Style Input */}
          <Box flex={1}>
            <CustomTextField
              name="hairStyle"
              label="Hair Style"
              required
              placeholder="eg: Buzz cut, curly hair,"
              inputProps={{ value: characterState.hairStyle }}
              width="100%"
              onChange={handleFormChange}
              isV2={true}
            />
          </Box>

          {/* Hair Color Input */}
          <Box flex={1}>
            <CustomTextField
              name="hairColor"
              label="Hair Color"
              required
              placeholder="eg: Black, Blonde,"
              inputProps={{ value: characterState.hairColor }}
              width="100%"
              onChange={handleFormChange}
              isV2={true}
            />
          </Box>

          <Box flex={1}>
            <CustomTextField
              name="eyeColor"
              label="Eye Color"
              required
              inputProps={{ value: characterState.eyeColor }}
              width="100%"
              onChange={handleFormChange}
              placeholder="eg: Blue, Brown"
              isV2={true}
            />
          </Box>
        </Box>

        <Box display={'flex'} alignItems={'end'} gap={3}>
          {/* Eye Color Selection */}

          {/* Additional Characteristics Input */}
          <Box flex={1}>
            <CustomTextField
              name="additionalCharacteristics"
              label="Additional Characteristics"
              placeholder="eg: Freckled skin, white shirt"
              inputProps={{ value: characterState.additionalCharacteristics }}
              width="100%"
              onChange={handleFormChange}
              isV2={true}
            />
          </Box>

          {isDashtoonUser && (
            <Box flex={1}>
              <CustomTextField
                name="danbooruTags"
                label="Danbooru Tags"
                placeholder="eg: 1girl, portrat, blonde hair, blue eyes"
                inputProps={{ value: characterState.danbooruTags }}
                width="100%"
                onChange={handleFormChange}
                isV2={true}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box display={'flex'} gap={2} mt={3} justifySelf={'end'}>
        {footer}
      </Box>
    </Box>
  );
};

export default CombinedForm;
