import { apiPrefix, socialAccountPrefix, v2Prefix } from '../Constants/Constants';
import { CreatePostRequest, VerifyOauthRequest } from '../Models/UserSocialAccount';
import HttpClient from '../Utils/HttpClient';

export const getAuthUrl = async () => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${socialAccountPrefix}/get-oauth-token`,
      {},
      {},
      false
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const verify = async (verifyOauthRequest: VerifyOauthRequest) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${socialAccountPrefix}/verify`,
      verifyOauthRequest,
      {},
      false
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPost = async (createPostRequest: CreatePostRequest) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${socialAccountPrefix}/create-post`,
      createPostRequest,
      {},
      false
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserConnectedAccounts = async () => {
  try {
    const response = await HttpClient.get(
      `${apiPrefix}${v2Prefix}${socialAccountPrefix}/connected-accounts`,
      {},
      false
    );
    return response;
  } catch (error) {
    throw error;
  }
};
