import HttpClient from '../../Utils/HttpClient';
import { apiPrefix, studioApiPrefix, userApiPrefix } from '../../Constants/Constants';
import {
  SubscriptionPlan,
  UserSubscriptionPaymentRequest,
  WalletTopUpRequest,
} from '../../Models/User';
import { UserData, UserDataDTO } from '../../Models/UserData';
import { UserQuotaUsage } from '../../Models/UserQuota/UserQuota';

export const fetchUserWalletBalance = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/wallet-balance`);
  } catch (error) {
    console.error(`Unable to fetch user wallet balance`);
    return null;
  }
};

export const topUpUserWallet = async (walletTopUpRequest: WalletTopUpRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${userApiPrefix}/topup-wallet`,
      walletTopUpRequest
    );
  } catch (error) {
    console.error(`Unable to initiate payment session`);
    return null;
  }
};

export const startSubscription = async (
  userSubscriptionPaymentRequest: UserSubscriptionPaymentRequest
) => {
  try {
    return HttpClient.post(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/subscription-start`, {
      ...userSubscriptionPaymentRequest,
    });
  } catch (error) {
    console.error(`Unable to start subscription`);
    return null;
  }
};

export const fetchSubscriptionPlanDetails = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/subscription-plans`);
  } catch (error) {
    console.error('Unable to fetch subscriptions');
    throw error;
  }
};

export const fetchSubscriptionPlanByPaymentLink = async (id: string): Promise<SubscriptionPlan> => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/payment-link/${id}`);
  } catch (error) {
    console.error('Unable to fetch subscriptions');
    throw error;
  }
};

export const getCustomerBillingPortal = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/customer-portal`);
  } catch (error) {
    console.error('Unable to fetch customer billing portal');
    throw error;
  }
};

export const fetchUserQuotas = async () => {
  return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/user-quota`);
};

export const fetchUserQuotaUsage = async (): Promise<Array<UserQuotaUsage>> => {
  try {
    return (await HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${userApiPrefix}/user-quota/usage`
    )) as Array<UserQuotaUsage>;
  } catch (err) {
    console.error('Unable to fetch user quota usage');
    throw err;
  }
};

export const fetchWalletTopUpPlans = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/topup-plans`);
  } catch (error) {
    console.error('Unable to fetch wallet top up plans');
    return null;
  }
};

export const fetchWalletTopUpPlansV2 = async () => {
  try {
    return HttpClient.get(`${apiPrefix}/v2${userApiPrefix}/topup-plans`);
  } catch (error) {
    console.error('Unable to fetch v2 wallet top up plans');
    return null;
  }
};

export const updateUserData = async (userData: UserDataDTO) => {
  try {
    return HttpClient.post(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/user-data`, userData);
  } catch (error) {
    console.error('Unable to update user data');
    return null;
  }
};

export const getUserData = async (): Promise<UserData | null> => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${userApiPrefix}/user-data`);
  } catch (error) {
    console.error('Unable to fetch user data');
    return null;
  }
};
