import { DashverseTab } from '../../../Hooks/useHomeScreenTab';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useTheme } from '@mui/material/styles';

function DashverseHeader() {
  const theme = useTheme();
  return (
    <Box
      pl={{ xs: '2rem', md: '17rem' }}
      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
    >
      <div style={{ height: 24, width: 24, color: theme.palette.object.primary }}>
        <DashverseTab.icon />
      </div>
      <Typography
        fontWeight={'600'}
        fontSize={'20px'}
        lineHeight={'24px'}
        color={theme.palette.object.primary}
      >
        {t('Dashverse')}
      </Typography>
    </Box>
  );
}
export default DashverseHeader;
