import { Box, Card, Theme, Typography } from '@mui/material';
import { Character } from '../../../../../../../Models/Character';
import { t } from 'i18next';

const AdditionalCharacteristics = ({
  character,
  theme,
}: {
  character: Character;
  theme: Theme;
}) => (
  <Box style={{ width: '100%', height: '15%', marginTop: '10px' }}>
    <Card
      style={{
        width: '100%',
        height: '100%',
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography style={{ fontWeight: 'bold', color: theme.palette.object.primary }}>
        {character?.generationMetadata?.split(',')[7]}
      </Typography>
      <Typography style={{ fontSize: '12px', color: theme.palette.constant.grey }}>
        {t(`Additional Characteristics`)}
      </Typography>
    </Card>
  </Box>
);

export default AdditionalCharacteristics;
