import { UploadStateActions } from '../Actions/UploadState';
import { getInitialUploadState, UploadState } from '../../Models/UploadState';

export interface UploadStateAction {
  type: UploadStateActions;
  payload?: any;
}

export const UploadStateReducer = (
  state: UploadState = getInitialUploadState(),
  action: UploadStateAction
): UploadState => {
  switch (action.type) {
    case UploadStateActions.SET_ERROR:
      return { ...state, error: action.payload };
    case UploadStateActions.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case UploadStateActions.SET_IS_UPLOADING:
      return { ...state, isUploading: action.payload };
    case UploadStateActions.SET_LOADING_MESSAGE:
      return { ...state, loadingMessage: action.payload };
    case UploadStateActions.SET_UPLOAD_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
