// CustomIcon.tsx
import React from 'react';
import icons, { IconsMap } from '../CanvasV3/Icons/Icons';
import DOMPurify from 'dompurify';

interface IconProps {
  name: keyof IconsMap;
  size?: number;
  fill?: string;
  stroke?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  cursor?: React.CSSProperties['cursor'];
  rotation?: number;
}

const CustomIcon: React.FC<IconProps> = ({
  name,
  size = 24,
  fill = '#000',
  stroke = '#000',
  onClick,
  cursor,
  rotation = 0,
}) => {
  let unsafeSvgMarkup = icons[name];

  // Function to update the SVG markup with new size and fill
  const updateSvgAttributes = (
    svgMarkup: string,
    newSize: number,
    newFill: string,
    newStroke: string
  ): string => {
    // Replace width and height attributes
    svgMarkup = svgMarkup
      .replace(/width="[^"]*"/, `width="${newSize}"`)
      .replace(/height="[^"]*"/, `height="${newSize}"`);

    // Optionally, replace fill attribute if specified
    svgMarkup = svgMarkup.replace(/fill="[^"]*"/g, `fill="${newFill}"`);
    svgMarkup = svgMarkup.replace(/stroke="[^"]*"/g, `stroke="${newStroke}"`);

    return svgMarkup;
  };

  unsafeSvgMarkup = updateSvgAttributes(unsafeSvgMarkup, size, fill, stroke);
  const safeSvgMarkup = DOMPurify.sanitize(unsafeSvgMarkup);

  return (
    <div
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
      style={{
        width: size,
        height: size,
        display: 'flex',
        cursor: cursor,
        transform: `rotate(${rotation}deg)`,
      }}
      dangerouslySetInnerHTML={{ __html: safeSvgMarkup }}
    />
  );
};

export default CustomIcon;
