import { RightClickMenuActions } from '../Actions/RightClickMenu';
import { SaveImageResponse } from '../../Models/SaveImage';

export enum RightClickMenuTypes {
  FRAME = 'FRAME',
  IMAGE = 'IMAGE',
  CANVAS = 'CANVAS',
}

export interface RightClickMenuState {
  type?: RightClickMenuTypes;
  image?: SaveImageResponse;
  selectedShapes?: any;
}

export const RightClickMenuInitialState: RightClickMenuState = {
  type: undefined,
  image: undefined,
  selectedShapes: undefined,
};

export interface RightClickMenuActionRequest {
  type: RightClickMenuActions;
  payload: any;
}

export const RightClickMenuReducer = (
  state: RightClickMenuState = RightClickMenuInitialState,
  action: RightClickMenuActionRequest
): RightClickMenuState => {
  switch (action.type) {
    case RightClickMenuActions.UPDATE_RIGHT_CLICK_MENU_TYPE:
      return { ...state, type: action.payload };
    case RightClickMenuActions.UPDATE_RIGHT_CLICK_MENU_IMAGE:
      return { ...state, image: action.payload };
    case RightClickMenuActions.UPDATE_RIGHT_CLICK_MENU_SELECTED_SHAPES:
      return { ...state, selectedShapes: action.payload };
    default:
      return state;
  }
};
