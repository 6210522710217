import {
  GenerationMenuState,
  GenerationMenuStateList,
  getInitialImageGenerationState,
} from '../../Models/GenerateImageV2';
import { GenerationMenuAction, GenerationMenuActions } from '../Actions/GenerateImageV2';
import { GenerateImageType } from '../../Models/GenerateImage';

const initialState: GenerationMenuStateList = {
  generationMenus: [],
};

export const GenerationMenuReducer = (
  state: GenerationMenuStateList = initialState,
  action: GenerationMenuAction
): GenerationMenuStateList => {
  switch (action.type) {
    case GenerationMenuActions.SET_GENERATION_MENUS:
      const { generationMenus } = action.payload;
      return {
        ...state,
        generationMenus,
      };
    case GenerationMenuActions.SET_CANVAS_MODE:
      const { canvasMode } = action.payload;
      return {
        ...state,
        canvasMode,
      };

    case GenerationMenuActions.DELETE_GENERATION_MENU:
      const { menuId: deleteMenuId } = action.payload;
      const updatedNewMenus = state.generationMenus.filter(menu => menu.id !== deleteMenuId);
      return {
        ...state,
        generationMenus: updatedNewMenus,
      };

    case GenerationMenuActions.ADD_GENERATION_MENU:
      const { menuId: addMenuId, initialProperties } = action.payload;

      const existingMenu = state.generationMenus.find(menu => menu.id === addMenuId);
      if (existingMenu) {
        return state; // Menu with the same id already exists, return the state as is
      }

      const newMenu: GenerationMenuState = {
        id: addMenuId,
        generationState: getInitialImageGenerationState(
          GenerateImageType.TXT2IMG,
          initialProperties.steps,
          initialProperties.characterWeight,
          initialProperties.prompt,
          initialProperties.characterId,
          initialProperties.characterName,
          undefined,
          initialProperties.tabValue,
          undefined,
          initialProperties.enableControlNet
        ),
      };

      return {
        ...state,
        generationMenus: [...state.generationMenus, newMenu],
      };
    case GenerationMenuActions.UPDATE_GENERATION_MENU:
      const { menuId: updateMenuId, updatedProperties } = action.payload;
      const updatedMenus = state.generationMenus.map(menu => {
        if (menu.id === updateMenuId) {
          return {
            ...menu,
            generationState: {
              ...menu?.generationState,
              ...updatedProperties,
            },
          };
        }
        return menu;
      });

      return {
        ...state,
        generationMenus: updatedMenus,
      };
    case GenerationMenuActions.UPDATE_IMAGE_IDS_TO_PREVIEW:
      const { menuId: updateImageIdsMenuId, imageIds } = action.payload;
      const updatedImageIdsMenus = state.generationMenus.map(menu => {
        if (menu.id === updateImageIdsMenuId) {
          return {
            ...menu,
            generationState: {
              ...menu?.generationState,
              imageIdsToPreview: [...(menu?.generationState.imageIdsToPreview ?? []), ...imageIds],
            },
          };
        }
        return menu;
      });

      return {
        ...state,
        generationMenus: updatedImageIdsMenus,
      };

    case GenerationMenuActions.SET_IMAGE_IDS_TO_PREVIEW:
      const { menuId: setImageIdsMenuId, imageIds: setImageIds } = action.payload;
      const setImageIdsMenus = state.generationMenus.map(menu => {
        if (menu.id === setImageIdsMenuId) {
          return {
            ...menu,
            generationState: {
              ...menu?.generationState,
              imageIdsToPreview: setImageIds,
            },
          };
        }
        return menu;
      });

      return {
        ...state,
        generationMenus: setImageIdsMenus,
      };
    // Handle other action types if needed
    default:
      return state;
  }
};
