import { SaveImageResponse } from '../../Models/SaveImage';

export enum ShortcutsActions {
  SET_SHORTCUTS_MODAL_OPEN = 'SET_SHORTCUTS_MODAL_OPEN',
}

export interface SetShortcutsModalAction {
  type: typeof ShortcutsActions.SET_SHORTCUTS_MODAL_OPEN;
  payload: boolean;
}

export type ShortcutsActionTypes = SetShortcutsModalAction;
