import { Show } from '../../Models/Show';
import { Episode } from '../../Models/Episode';
import { AllShowsActions } from '../Actions/AllShows';
import { AllEpisodesActions } from '../Actions/AllEpisodes';
import { AppStateActions } from '../Actions/AppState';

export interface AllEpisodesState {
  allEpisodes: Episode[];
}

export const AllEpisodesInitialState: AllEpisodesState = {
  allEpisodes: [],
};

export interface AllEpisodesActionRequest {
  type: AllEpisodesActions | AppStateActions;
  payload: any;
}

export const AllEpisodesReducer = (
  state: AllEpisodesState = AllEpisodesInitialState,
  action: AllEpisodesActionRequest
): AllEpisodesState => {
  switch (action.type) {
    case AllEpisodesActions.ADD_EPISODE:
      return {
        ...state,
        allEpisodes: [...state.allEpisodes, action.payload],
      };
    case AllEpisodesActions.DELETE_EPISODE:
      return {
        ...state,
        allEpisodes: state.allEpisodes.filter(episode => episode.id !== action.payload),
      };
    case AllEpisodesActions.UPDATE_EPISODE:
      return {
        ...state,
        allEpisodes: [
          ...state.allEpisodes.map(episode =>
            episode.id === action.payload.id ? { ...episode, ...action.payload } : episode
          ),
        ],
      };
    case AllEpisodesActions.CLEAR_ALL_EPISODES:
      return {
        ...state,
        allEpisodes: [],
      };
    case AllEpisodesActions.INITIALIZE_EPISODES:
      return {
        ...state,
        allEpisodes: action.payload,
      };
    case AppStateActions.SET_EPISODE_PROJECT_STATUS:
      return {
        ...state,
        allEpisodes: state.allEpisodes.map(e =>
          e.id === action.payload.episodeId ? { ...e, studioMetadata: [action.payload.data] } : e
        ),
      };
    default:
      return state;
  }
};
