import { color, GradientColors } from '../../Constants/Colors';

declare module '@mui/material/styles' {
  interface Palette {
    review: {
      in_review: string;
      passed_review: string;
      failed_review: string;
    };
    surface: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    object: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    constant: {
      white: string;
      black: string;
      grey: string;
      blue: string;
      red: string;
      green: string;
      yellow: string;
      chip: string;
      purple: string;
      orange: string;
    };
    tab: {
      selectedBackground: string;
    };
    shadow: {
      primary: string;
    };
    gradient: {
      footer: string;
      slider: string;
    };
  }

  interface PaletteOptions {
    review?: {
      in_review?: string;
      passed_review?: string;
      failed_review?: string;
    };
    surface?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
    };
    object?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
    };
    constant?: {
      white?: string;
      black?: string;
      grey?: string;
      blue?: string;
      red?: string;
      green?: string;
      yellow?: string;
      chip?: string;
    };
    tab: {
      selectedBackground: string;
    };
    shadow: {
      primary: string;
    };
    gradient: {
      footer: string;
      slider: string;
    };
  }
}

export const lightModeTheme = {
  // palette values for light mode
  primary: {
    main: '#4D9CF8', // Green color
  },
  review: {
    in_review: '#B09431',
    failed_review: '#EE2A28',
    passed_review: '#39B031',
  },
  background: {
    default: color[100],
    paper: color[90],
  },
  divider: 'rgba(207, 207, 207, 1)', // Light gray color
  text: {
    primary: '#030303', // Dark gray color
    secondary: '#808080', // Light gray color
  },
  banner: {
    text: '#363636',
  },
  input: {
    background: '#fff !important', // Dark gray color
  },
  appbar: {
    background: 'transparent !important',
    color: 'transparent !important',
  },
  action: {
    hover: '#CFCFCF',
    active: '#808080',
    selected: '#363636',
    focus: '#CFCFCF',
  },
  borderColor: '#FCFCFC',
  toolbar: {
    background: '#F4F4F4',
    borderColor: '#FCFCFC',
  },
  submenu: {
    background: '#F4F4F4',
    toggleBackground: '#FCFCFC',
  },
  surface: {
    primary: color[100],
    secondary: color[90],
    tertiary: color[80],
  },
  object: {
    primary: color[0],
    secondary: color[10],
    tertiary: color[20],
  },
  constant: {
    white: color[100],
    black: color[0],
    grey: color[50],
    blue: color['blue'],
    red: color['red'],
    green: color['green'],
    yellow: color['yellow'],
    purple: color['purple'],
    chip: 'rgba(207, 207, 207, 1)',
    orange: color['orange'],
  },
  tab: {
    selectedBackground: color[80],
  },
  shadow: {
    primary: 'rgba(48, 48, 48, 0.12)',
  },
  gradient: {
    footer: GradientColors.light,
    slider:
      'linear-gradient(90deg, rgba(231, 231, 231, 0.00) 0%, rgba(231, 231, 231, 0.60) 50%, rgba(231, 231, 231, 0.00) 100%)',
  },
};

export const darkModeTheme = {
  // palette values for dark mode
  primary: {
    main: '#4D9CF8',
  },
  review: {
    in_review: '#B09431',
    failed_review: '#EE2A28',
    passed_review: '#39B031',
  },
  divider: 'rgba(48, 48, 48, 1)', // Light gray color
  background: {
    default: '#030303', // Dark gray color
    paper: '#181818', // Darker gray color
  },
  input: {
    background: '#030303', // Dark gray color
  },
  text: {
    primary: '#FCFCFC',
    secondary: '#808080',
  },
  banner: {
    text: '#363636',
  },
  appbar: {
    background: 'transparent !important',
    color: 'transparent !important',
  },
  action: {
    hover: '#565656',
    active: '#808080',
    selected: '#FCFCFC',
    focus: '#303030',
  },
  surface: {
    primary: color[0],
    secondary: color[10],
    tertiary: color[20],
  },
  object: {
    primary: color[100],
    secondary: color[90],
    tertiary: color[80],
  },
  constant: {
    white: color[100],
    black: color[0],
    grey: color[50],
    blue: color['blue'],
    red: color['red'],
    green: color['green'],
    yellow: color['yellow'],
    purple: color['purple'],
    chip: 'rgba(48, 48, 48, 1)',
    orange: color['orange'],
  },
  tab: {
    selectedBackground: color[20],
  },
  shadow: {
    primary: 'rgba(48, 48, 48, 0.25)',
  },
  gradient: {
    footer: GradientColors.dark,
    slider:
      'linear-gradient(90deg, rgba(24, 24, 24, 0.00) 0%, rgba(24, 24, 24, 0.60) 50%, rgba(24, 24, 24, 0.00) 100%)',
  },
};
