export enum FlagsmithFeatureFlags {
  enable_new_panelling = 'enable_new_panelling',
  app_version = 'app_version',
  allow_auto_inpaint = 'allow_auto_inpaint',
  new_finpaint = 'new_finpaint',
  enable_creative_upscaler = 'enable_creative_upscaler',
  enable_non_creative_upscaler = 'enable_non_creative_upscaler',
  enable_legacy_upscaler = 'enable_legacy_upscaler',
  v3_canvas_beta_test = 'v3_canvas_beta_test',
}
