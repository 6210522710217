// themeReducer.ts
import { PaletteMode } from '@mui/material';
import { ThemeSelectorActions } from '../Actions/Theme';
import { UpscalerState, UpscalerType } from '../../Models/UpscalerState';

export interface ThemeState {
  mode: PaletteMode;
}

export const preferredThemeMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  ? 'dark'
  : 'light';

export const initialState: ThemeState = {
  mode: preferredThemeMode,
};

export const getInitialThemeState = (): ThemeState => {
  return initialState;
};

export const ThemeReducer = (
  state = initialState,
  action: { type: string; payload: PaletteMode }
) => {
  switch (action.type) {
    case ThemeSelectorActions.SET_THEME_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
};
