import { VideoModalStateAction, VideoModalStateActionRequest } from '../Actions/VideoModalState';

export interface VideoModalState {
  videoModalOpen: boolean;
  videoUrl: string;
  title: string;
}

export const initialVideoModalState: VideoModalState = {
  videoModalOpen: false,
  videoUrl: '',
  title: '',
};

export const VideoModalStateReducer = (
  state: VideoModalState = initialVideoModalState,
  action: VideoModalStateActionRequest
): VideoModalState => {
  switch (action.type) {
    case VideoModalStateAction.SET_VIDEO_MODAL_OPEN:
      return {
        ...state,
        videoModalOpen: true,
        videoUrl: action.payload.videoUrl,
        title: action.payload.title,
      };
    case VideoModalStateAction.CLOSE_VIDEO_MODAL:
      return {
        ...state,
        videoModalOpen: false,
        videoUrl: '',
        title: '',
      };
    default:
      return state;
  }
};
