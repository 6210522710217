import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../Redux/ReduxInterface';
import useAuth from './useAuth';
import { getUserConnectedAccounts } from '../Requests/UserSocialAccount';
import { UserConnectedAccounts } from '../Models/UserSocialAccount';
import { ConnectedAccountsActions } from '../Redux/Actions/ConnectedAccounts';
import { useContext } from 'react';
import { AuthContext } from '../Provider/AuthProvider';

const useUserConnectedAccounts = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const fetchUserConnectedAccounts = async () => {
    if (currentUser && currentUser.uid) {
      const response = (await getUserConnectedAccounts()) as UserConnectedAccounts;
      dispatch({
        type: ConnectedAccountsActions.SET_CONNECTED_ACCOUNTS,
        payload: { userConnectedAccount: response },
      });
    }
  };

  const updateConnectedAccounts = (userConnectedAccount: UserConnectedAccounts) => {
    dispatch({
      type: ConnectedAccountsActions.SET_CONNECTED_ACCOUNTS,
      payload: { userConnectedAccount },
    });
  };

  return { fetchUserConnectedAccounts, updateConnectedAccounts };
};

export default useUserConnectedAccounts;
