import React, { ReactNode, useEffect } from 'react';
import { Modal, useTheme } from '@mui/material';
import CustomIcon from '../CustomIcon/CustomIcon';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  trackEventModalName: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  onClose,
  open,
  children,
  trackEventModalName,
}) => {
  const theme = useTheme();

  useEffect(() => {
    trackEvent(
      {
        event: TrackingEvents.modalOpened,
        properties: {
          modalName: trackEventModalName,
        },
      },
      'CREATOR'
    );
    return () => {
      trackEvent(
        {
          event: TrackingEvents.modalClosed,
          properties: {
            modalName: trackEventModalName,
          },
        },
        'CREATOR'
      );
    };
  }, []);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2147483645, //  to be above navigation zone and zoom
      }}
    >
      <div
        style={{
          height: '90%',
          backgroundColor: theme.palette.surface.primary,
          outline: `1px solid ${theme.palette.surface.tertiary}`,
          borderRadius: '48px 48px 0px 0px',
          boxSizing: 'border-box',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-48px',
            right: '28px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: theme.palette.surface.secondary,
            border: `1px solid ${theme.palette.surface.tertiary}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CustomIconButton
            icon={'cross'}
            fill={theme.palette.object.primary}
            onClick={onClose}
            iconSize={38}
            ariaLabel={'Close'}
            ariaRoleDescription={'button'}
          />
        </div>
        {children}
      </div>
    </Modal>
  );
};
export default CustomModal;
