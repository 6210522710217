import { ThumbnailActions } from '../Actions/Thumbnail';
import { ThumbnailActionTypes } from '../Actions/Thumbnail';

export interface ThumbnailState {
  episodeId: string | null;
  episodeThumbnail: string | null;
  showId: string | null;
  showThumbnail: string | null;
}

export const initialThumbnailState: ThumbnailState = {
  episodeId: null,
  episodeThumbnail: null,
  showId: null,
  showThumbnail: null,
};

export const ThumbnailReducer = (state = initialThumbnailState, action: ThumbnailActionTypes) => {
  switch (action.type) {
    case ThumbnailActions.SET_EPISODE_THUMBNAIL:
      return {
        ...state,
        episodeId: action.payload.episodeId,
        episodeThumbnail: action.payload.episodeThumbnail,
      };
    case ThumbnailActions.SET_SHOW_THUMBNAIL:
      return {
        ...state,
        showId: action.payload.showId,
        showThumbnail: action.payload.showThumbnail,
      };
    default:
      return state;
  }
};
