import { styled } from '@mui/material/styles';
import { Box, Button, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const IconHolder = styled(Box)<{ selected?: boolean }>(({ theme, selected }) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: selected ? theme.palette.object.primary : theme.palette.constant.grey,
  '&:hover': {
    color: theme.palette.object.primary,
  },
}));

export const NavigationItem = styled(NavLink)<{ selected?: boolean }>(({ theme, selected }) => ({
  gap: '10px',
  width: '208px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '8px',
  color: selected ? theme.palette.object.primary : theme.palette.constant.grey,
  borderRadius: '8px',
  '&:hover >.nav-item-child': {
    color: theme.palette.object.primary,
  },
  '&:hover': {
    backgroundColor: theme.palette.surface.tertiary,
  },
}));

export const WidgetItem = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.constant.grey,
  '&:hover >.widget-item-child': {
    color: theme.palette.object.primary,
  },
  fontSize: '12px',
  padding: '0px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  '> *': {
    fontSize: '12px',
  },
}));

export const WidgetContainer = styled(Box)<{ hasBorder?: boolean }>(
  ({ theme, hasBorder = true }) => ({
    padding: '16px',
    borderTop: hasBorder ? `1px solid ${theme.palette.surface.tertiary}` : '',
    display: 'flex',
    flexDirection: 'column',
  })
);
