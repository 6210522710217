import { useState, useEffect, useRef } from 'react';
import { pingLatencyUrl } from '../Constants/Constants';

const usePing = (interval: number, shouldPing: boolean): number | null => {
  const [pingTime, setPingTime] = useState<number | null>(null);
  const intervalRef = useRef<NodeJS.Timeout>();

  const ping = async () => {
    const start = performance.now();
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), 10000)
    );

    try {
      await Promise.race([fetch(pingLatencyUrl, { cache: 'no-store' }), timeoutPromise]);
      const end = performance.now();
      setPingTime(end - start);
    } catch (error) {
      console.error('Error pinging API:', error);
      setPingTime(null);
    }
  };

  useEffect(() => {
    if (shouldPing) {
      intervalRef.current = setInterval(ping, interval);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [interval, shouldPing]);

  return pingTime;
};

export default usePing;
