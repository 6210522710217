import { SavedImagesInitImages, SaveImageType } from './SaveImage';

export interface GazeState {
  gazeMaskLoading: boolean;
}

export const InitialGazeState: GazeState = {
  gazeMaskLoading: false,
};

export interface GazeRequestType {
  prompt: {
    generation_uuid: string | null;
    image: string;
    image_mask: string | null;
    reference_image: string | null;
    rotate_pitch: number;
    rotate_yaw: number;
    rotate_roll: number;
    blink: number;
    eyebrow: number;
    wink: number;
    pupil_x: number;
    pupil_y: number;
    aaa: number;
    eee: number;
    woo: number;
    smile: number;
  };
  type: string;
}

export interface SendRequestInterface {
  userId: string;
  userEmail: string;
  userDisplayName: string;
  image: string;
  mask: string | null;
  referenceImage: string | null;
  rotatePitch: number;
  rotateYaw: number;
  rotateRoll: number;
  blink: number;
  eyeBrow: number;
  wink: number;
  pupilX: number;
  pupilY: number;
  smile: number;
  aaa: number;
  eee: number;
  woo: number;
  frameId: string;
  selectedStyle: string;
}

export enum SocketResponseTypes {
  EXECUTED = 'executed',
  EXECUTING = 'executing',
  STATUS = 'status',
  EXCEPTION_DURING_VALIDATION = 'exception_during_validation',
  EXCEPTION_DURING_EXECUTION = 'exception_during_execution',
}

export enum InferenceStatusTypes {
  CONNECTED = 'connected',
  QUEUED = 'queued',
  INFERENCE_STEP = 'inference_step',
  INFERENCE_END = 'inference_end',
  INFERENCE_START = 'inference_start',
  EXCEPTION_DURING_VALIDATION = 'exception_during_validation',
}

export type ExecutedResponseType = {
  type: SocketResponseTypes.EXECUTED;
  data: {
    image: string;
    status: InferenceStatusTypes.INFERENCE_END;
    client_id: string;
    prompt_id: string;
  };
};
export type ExecutingResponseType = {
  type: SocketResponseTypes.EXECUTING;
  data: {
    image: string;
    status: InferenceStatusTypes.INFERENCE_STEP;
    client_id: string;
    prompt_id: string;
    step: number;
  };
};

export type QueuedResponseType = {
  type: SocketResponseTypes.STATUS;
  data: {
    status: InferenceStatusTypes.QUEUED;
    client_id: string;
    prompt_id: string;
  };
};
export type InferenceStartType = {
  type: SocketResponseTypes.STATUS;
  data: {
    status: InferenceStatusTypes.INFERENCE_START;
    client_id: string;
    prompt_id: string;
  };
};

export type InferenceConnectedType = {
  type: SocketResponseTypes.STATUS;
  data: {
    status: InferenceStatusTypes.CONNECTED;
    client_id: string;
  };
};

export type ErrorType = {
  type:
    | SocketResponseTypes.EXCEPTION_DURING_VALIDATION
    | SocketResponseTypes.EXCEPTION_DURING_EXECUTION;
  data: {
    status: InferenceStatusTypes.EXCEPTION_DURING_VALIDATION;
    client_id: string;
    prompt_id: string;
    details: string;
    extra_info: string;
  };
};

export type WebSocketResponse =
  | InferenceConnectedType
  | ExecutedResponseType
  | ExecutingResponseType
  | QueuedResponseType
  | InferenceStartType
  | ErrorType;

export interface SaveGazeImageRequest {
  contentType?: string;
  fileExtension?: string;
  initImageId?: SavedImagesInitImages[];
  base64Image: string;
  showId: string;
  episodeId: string;
  frameId?: string;
  imageWidth?: number;
  imageHeight?: number;
  createdAt?: Date;
  generationId?: string;
  selectedStyle?: string;
  headY: number;
  headX: number;
  headZ: number;
  blink: number;
  eyeBrow: number;
  wink: number;
  eyeX: number;
  eyeY: number;
  smile: number;
  mouthOpen: number;
  mouthWide: number;
  mouthCircular: number;
}

// extend SaveLiveImageRequestWithTime
export interface SaveGazeImageRequestWithTime extends SaveGazeImageRequest {
  startTime?: number;
  inferenceStartTime?: number;
  inferenceEndTime?: number;
}
