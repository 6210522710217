import { SaveImageResponse } from '../../Models/SaveImage';
import { LibrarySearchActions } from '../Actions/LibrarySearch';

export interface LibrarySearchState {
  searchQuery: string;
  selectedImages?: SaveImageResponse[];
  relevantImages: SaveImageResponse[];
  type: 'ALL' | 'LIBRARY' | 'GENERATED';
  otherImages: SaveImageResponse[];
}

export const LibrarySearchInitialState: LibrarySearchState = {
  searchQuery: '',
  relevantImages: [],
  otherImages: [],
  type: 'ALL',
};

export interface LibrarySearchActionRequest {
  type: LibrarySearchActions;
  payload: any;
}

export const LibrarySearchReducer = (
  state: LibrarySearchState = LibrarySearchInitialState,
  action: LibrarySearchActionRequest
): LibrarySearchState => {
  switch (action.type) {
    case LibrarySearchActions.UPDATE_LIBRARY_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload };
    case LibrarySearchActions.UPDATE_LIBRARY_SEARCH_IMAGES:
      return { ...state, relevantImages: action.payload };
    case LibrarySearchActions.UPDATE_LIBRARY_SEARCH_IMAGES_ALL:
      return { ...state, otherImages: action.payload };
    case LibrarySearchActions.UPDATE_LIBRARY_SEARCH_SELECTED_IMAGES:
      return { ...state, selectedImages: action.payload };
    case LibrarySearchActions.UPDATE_LIBRARY_SEARCH_TYPE:
      return { ...state, type: action.payload };
    default:
      return state;
  }
};
