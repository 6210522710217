import { SaveImageResponse } from '../../Models/SaveImage';

export enum InpaintActions {
  SET_INPAINT_IMAGE = 'SET_INPAINT_IMAGE',
  UPDATE_INPAINT_IMAGE = 'UPDATE_INPAINT_IMAGE',
}

export interface SetInpaintImageAction {
  type: typeof InpaintActions.SET_INPAINT_IMAGE;
  payload: SaveImageResponse;
}

export interface UpdateInpaintImageAction {
  type: typeof InpaintActions.UPDATE_INPAINT_IMAGE;
  payload: SaveImageResponse;
}

export const updateInpaintImage = (image: SaveImageResponse) => {
  const updatedImage = { ...image }; // Create a new object with the same properties
  return {
    type: InpaintActions.UPDATE_INPAINT_IMAGE,
    payload: updatedImage,
  };
};

export type InpaintImageActionTypes = SetInpaintImageAction | UpdateInpaintImageAction;
