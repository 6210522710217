import { User } from 'firebase/auth';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { TrackingProperties } from '../Constants/TrackingProperties';
import { TimedTrackingEvents } from '../Constants/TimedTrackingEvents';
import { DEFAULT_IMAGE_EXPORT_FORMAT } from '../Constants/ImageExport';

let dashUser: User | undefined = undefined;

export const configureDashUser = (user: User) => {
  dashUser = user;
};

export const replaceSubstringInJson = (
  obj: any,
  targetSubstring: string = `;base64`,
  replacement: string = ''
): any => {
  // If obj is a string, check for the target substring and replace it
  if (typeof obj === 'string' && obj.includes(targetSubstring)) {
    return replacement;
  }

  // If obj is an array, iterate through its elements
  if (Array.isArray(obj)) {
    return obj.map(item => replaceSubstringInJson(item, targetSubstring, replacement));
  }

  // If obj is an object, iterate through its properties
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      obj[key] = replaceSubstringInJson(obj[key], targetSubstring, replacement);
    });
  }

  return obj;
};

export const dashTimedEventsDictionary: { [key: string]: any } = {};

export const trackEventsQueue: any[] = [];

export const getUserAgentData = () => {
  return JSON.stringify({
    browser: navigator.userAgent,
    appCodeName: navigator.appCodeName,
    platform: navigator.platform,
    language: navigator.language,
    cookieEnabled: navigator.cookieEnabled,
    onLine: navigator.onLine,
    hardwareConcurrency: navigator.hardwareConcurrency,
    // @ts-ignore
    deviceMemory: navigator.deviceMemory,
    doNotTrack: navigator.doNotTrack,
    vendor: navigator.vendor,
    vendorSub: navigator.vendorSub,
    product: navigator.product,
    productSub: navigator.productSub,
    maxTouchPoints: navigator.maxTouchPoints,
    appVersion: navigator.appVersion,
    appName: navigator.appName,
  });
};

export const getFirebaseUserId = () => {
  return dashUser?.uid ?? '';
};

export const callTrackEventAPI = (trackEventBody: any) => {
  // fetch(`${config.dashTrackingUrl}/api/creator/events`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(trackEventBody),
  // });
  return;
};

export const trackDashUserEvent = (trackEventBody: any) => {
  trackEventsQueue.push(trackEventBody);
};

setInterval(() => {
  if (trackEventsQueue.length > 0) {
    const trackEventBody = trackEventsQueue.splice(
      0,
      trackEventsQueue.length > 30 ? 30 : trackEventsQueue.length
    );
    callTrackEventAPI(trackEventBody);
  }
}, 5000);

window.addEventListener('beforeunload', () => {
  if (trackEventsQueue.length > 0) {
    const trackEventBody = trackEventsQueue.splice(0, trackEventsQueue.length);
    callTrackEventAPI([
      ...trackEventBody,
      {
        userId: getFirebaseUserId(),
        eventName: TrackingEvents.refreshScreen,
        userAgent: getUserAgentData(),
        type: 'CONSUMER',
        properties: JSON.stringify(
          replaceSubstringInJson(
            {
              page: window.location.pathname,
              url: window.location.href,
            },
            `data:image/${DEFAULT_IMAGE_EXPORT_FORMAT};base64`,
            ''
          )
        ),
        recordedAt: new Date().getTime(),
      },
    ]);
  }
});

export const trackDashEvent = (
  event: TrackingEvents | TimedTrackingEvents,
  properties: TrackingProperties,
  type: 'CONSUMER' | 'CREATOR'
) => {
  const trackEventBody = {
    userId: getFirebaseUserId(),
    eventName: event,
    userAgent: getUserAgentData(),
    type: type,
    properties: JSON.stringify(
      replaceSubstringInJson(JSON.parse(JSON.stringify(properties)), ';base64', '')
    ),
    recordedAt: new Date().getTime(),
  };
  trackDashUserEvent(trackEventBody);
};

// function to generate uuid from time
export const generateUUID = (): string => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const initiateDashTimedEvent = (uuid: string) => {
  dashTimedEventsDictionary[uuid] = {
    startTime: new Date().getTime(),
  };
  return uuid;
};

export const trackDashTimedEvent = (
  event: TimedTrackingEvents,
  properties: TrackingProperties,
  type: 'CONSUMER' | 'CREATOR',
  uuid: string
) => {
  trackDashEvent(
    event,
    {
      ...properties,
      duration: (
        new Date().getTime() - (dashTimedEventsDictionary[uuid]?.startTime ?? new Date().getTime())
      ).toString(),
    },
    type
  );
};
