// ProtectedRoutesWrapper.tsx
import { Navigate } from 'react-router-dom';

interface ProtectedRoutesWrapperProps {
  isAuthenticated: boolean;
  redirectTo?: string;
  children?: React.ReactNode;
}

const ProtectedRoutesWrapper: React.FC<ProtectedRoutesWrapperProps> = ({
  isAuthenticated,
  redirectTo = '/home',
  children,
}) => {
  return isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />;
};

export default ProtectedRoutesWrapper;
