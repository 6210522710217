import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from '../AppBar/AppBar';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';

interface LayoutWithoutSidebarProps {
  isV2: boolean;
  showAppBar: boolean;
  disableVerticalScroll?: boolean;
}

const LayoutWithoutSidebar: React.FC<LayoutWithoutSidebarProps> = ({
  isV2,
  showAppBar,
  disableVerticalScroll,
}) => {
  const mode = useSelector((state: ReduxState) => state.theme.mode);

  const canvasPalette = {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#4D9CF8', // Green color
          },
          background: {
            default: '#EBEBEB',
            paper: '#FCFCFC',
          },
          divider: '#B4B4B4', // Light gray color
          text: {
            primary: '#363636', // Dark gray color
            secondary: '#B4B4B4', // Light gray color
          },
          input: {
            background: '#fff !important', // Dark gray color
          },
          appbar: {
            background: 'transparent !important',
            color: 'transparent !important',
          },
          action: {
            hover: '#CFCFCF',
            active: '#CFCFCF',
            selected: '#CFCFCF',
            focus: '#CFCFCF',
          },
          tab: {
            selectedBackground: '',
          },
          shadow: {
            primary: '',
          },
          gradient: {
            footer: '',
            slider: '',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#4D9CF8',
          },
          divider: '#B4B4B4', // Light gray color
          background: {
            default: '#030303', // Dark gray color
            paper: '#363636', // Darker gray color
          },
          input: {
            background: '#030303', // Dark gray color
          },
          text: {
            primary: '#EBEBEB',
            secondary: '#B4B4B4',
          },
          appbar: {
            background: 'transparent !important',
            color: 'transparent !important',
          },
          action: {
            hover: '#565656',
            active: '#808080',
            selected: '#FCFCFC',
            focus: '#303030',
          },
          tab: {
            selectedBackground: '',
          },
          shadow: {
            primary: '',
          },
          gradient: {
            footer: '',
            slider: '',
          },
        }),
  };

  const customThemeOptions = (theme: ThemeOptions) =>
    createTheme({
      ...theme,
      palette: !isV2 ? theme.palette : canvasPalette,
      components: !isV2
        ? theme.components
        : {
            MuiAppBar: {
              styleOverrides: {
                root: {
                  color: 'transparent !important',
                  backgroundColor: 'transparent !important',
                  backgroundImage: 'none',
                },
              },
            },
            MuiTabs: {
              styleOverrides: {
                indicator: {
                  backgroundColor: 'transparent',
                },
              },
            },
            MuiTab: {
              defaultProps: {
                disableRipple: true,
              },
              styleOverrides: {
                root: ({ _, theme }) => ({
                  '&.Mui-selected': {
                    color: theme.palette.text.primary,
                  },
                  padding: '0px 16px 0px 0px',
                }),
              },
            },
          },
    });

  return (
    <div
      style={
        disableVerticalScroll
          ? { overflowY: 'hidden', overscrollBehaviorY: 'none', height: window.innerHeight }
          : {}
      }
    >
      {showAppBar && (
        <ResponsiveAppBar isCanvas={isV2} search={''} handleSearch={e => {}} filter={false} />
      )}
      <div style={{ marginTop: showAppBar ? '4%' : 0, width: '100%' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutWithoutSidebar;
