import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { t } from 'i18next';
import { Button, CircularProgress, Tooltip, TooltipComponentsPropsOverrides } from '@mui/material';
import * as React from 'react';
import { color } from '../../Constants/Colors';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';

interface CustomButtonProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backgroundColor?: string | undefined;
  foregroundColor?: string | undefined;
  hasIcon?: boolean;
  icon?: ReactJSXElement;
  endIcon?: ReactJSXElement;
  height?: string;
  width?: string;
  minWidth?: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  isLoading?: boolean;
  hasBorder?: boolean;
  disabled?: boolean;
  borderColor?: string;
  shortcutText?: string;
  padding?: string;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  disableElevation?: boolean;
  tooltip?: string;
  hoverOpacity?: number;
  ariaRoleDescription?: string;
  tooltipComponentProps?: TooltipComponentsPropsOverrides;
}

const CustomButton = ({
  text,
  onClick,
  backgroundColor,
  foregroundColor,
  hasIcon = false,
  icon,
  endIcon,
  height,
  width,
  minWidth,
  variant,
  isLoading = false,
  hasBorder = true,
  disabled = false,
  borderColor,
  shortcutText,
  padding,
  style,
  hoverStyle,
  disableElevation,
  tooltip = '',
  tooltipComponentProps,
  hoverOpacity,
  ariaRoleDescription = 'button',
}: CustomButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isLoading) {
      trackEvent(
        {
          event: TrackingEvents.buttonClicked,
          properties: {
            buttonName: text,
          },
        },
        'CREATOR'
      );
      onClick(e);
    }
  };
  return (
    <Tooltip title={tooltip} componentsProps={tooltipComponentProps}>
      <Button
        variant={variant}
        disabled={disabled || isLoading}
        sx={{
          textTransform: 'none',
          height: height,
          width: width,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: shortcutText ? 'space-between' : 'center',
          alignContent: 'center',
          backgroundColor: backgroundColor,
          color: foregroundColor,
          padding: padding ? padding : hasIcon ? '0px 8px 0 8px' : '0px',
          gap: endIcon ? 1 : 0,
          alignItems: 'center',
          border: hasBorder ? '1px solid' : 'none',
          borderColor: borderColor,
          minWidth: minWidth ?? '64px',
          ':hover': {
            backgroundColor: backgroundColor,
            color: foregroundColor,
            borderColor: borderColor,
            opacity: hoverOpacity ?? 0.8,
            ...hoverStyle,
          },
          ...style,
        }}
        onClick={handleClick}
        disableElevation={disableElevation ?? false}
        aria-roledescription={ariaRoleDescription}
        role={ariaRoleDescription}
        aria-label={text}
      >
        {!isLoading && hasIcon && icon}
        {isLoading && hasIcon && (
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ color: foregroundColor }} size={16} />{' '}
          </div>
        )}
        {isLoading && !hasIcon ? (
          <div
            style={{
              width: '100%',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ color: foregroundColor }} size={16} />
          </div>
        ) : shortcutText ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '0px 8px 0px 8px',
            }}
          >
            <div>{t(text)}</div>
            <div
              style={{
                fontSize: '14px',
                color: color['20'],
                backgroundColor: backgroundColor,
                fontWeight: 600,
              }}
            >
              {shortcutText}
            </div>
          </div>
        ) : (
          <div>{t(text)}</div>
        )}
        {!isLoading && endIcon && endIcon}
      </Button>
    </Tooltip>
  );
};

export default CustomButton;
