import { AnalyticsRegistry, TrackingClient } from '@dashtoon/studio-components/dist/utils/analytics/models';
import { TrackingRequest } from './Analytics';
import { trackMixpanelCreatorEvent } from './MixpanelCreator';
import { trackDashEvent } from './DashAnalytics';

const MixpanelCreatorClient: TrackingClient<TrackingRequest> = {
  name: "Mixpanel Creator",
  track: request => trackMixpanelCreatorEvent(request.event, request.properties)
}


const DashClient: TrackingClient<TrackingRequest> = {
  name: "Dash",
  track: request =>trackDashEvent(request.event, request.properties, 'CREATOR')
}

export const ANALYTICS_REGISTRY: AnalyticsRegistry = {
  clients: [
    MixpanelCreatorClient,
    DashClient
  ]
}

export default {};
