import React, { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Modal, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CheckIcon } from './Components/check-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../Assets/Icons/cross.svg';
import { UserSubscriptionPlanEnum } from '../../../../Models/User';
import { t } from 'i18next';
import { AuthContext } from '../../../../Provider/AuthProvider';
import Avatar from '@mui/material/Avatar';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import Chip from '@mui/material/Chip';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import useNotification from '../../../../Hooks/useNotification';
import { useAppDispatch } from '../../../../Hooks/useTypedDispatch';
import { fetchUserQuota } from '../../../../Redux/Actions/UserQuotaSubscription';
import planToColorMapping from './data/planToColorMapping';

const SubscriptionPaymentSuccessModal: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = useNotification();
  const visible = searchParams.get('success') === 'true';
  const planType = searchParams.get('planType') as UserSubscriptionPlanEnum;
  const creditsForPlan = searchParams.get('creditsForPlan');

  const auth = useContext(AuthContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    height: '512px',
    width: '480px',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.surface.tertiary,
    '&:focus': {
      outline: 'none',
    },
  };

  const handleModalClose = () => {
    setSearchParams({}, { replace: true });
  };

  useEffect(() => {
    if (visible) {
      notify.message('success', 'Your payment was successful!');
      trackEvent(
        {
          event:
            planType === UserSubscriptionPlanEnum.TOP_UP
              ? TrackingEvents.paymentTopupRedirectSuccess
              : TrackingEvents.paymentSubscriptionRedirectSuccess,
          properties: {
            credits: creditsForPlan ?? '',
            planType: planType ?? '',
          },
        },
        'CREATOR'
      );
    }
  }, [auth.currentUser?.uid]);

  return (
    <Modal open={visible} hideBackdrop={true}>
      <Stack sx={style}>
        <Stack
          p={3}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Box flexGrow={1}>
            <Typography fontWeight={600} variant={'h4'}>
              {t('Congratulations!')}
            </Typography>
          </Box>
          <CrossIcon onClick={handleModalClose} />
        </Stack>
        <Stack
          spacing={2}
          direction={'column'}
          justifyContent={'space-between'}
          sx={{ height: '100%', backgroundColor: theme.palette.background.default }}
          px={4}
          pb={4}
        >
          <Stack flexGrow={1} spacing={4} direction={'row'} justifyContent={'space-between'}>
            <Stack flex={7} justifyContent={'center'} alignItems={'start'}>
              <Box pl={1} position={'relative'}>
                <Avatar
                  variant={'circular'}
                  sx={{
                    width: '128px',
                    height: '128px',
                    border: '5px solid ' + planToColorMapping(planType),
                  }}
                  src={auth.currentUser?.photoURL ?? ''}
                />
                {planType === UserSubscriptionPlanEnum.TOP_UP ? null : (
                  <Box
                    position={'absolute'}
                    bottom={-10}
                    left={'50%'}
                    style={{ transform: 'translateX(-50%)' }}
                  >
                    <Chip
                      variant={'filled'}
                      color={'primary'}
                      label={planType}
                      size={'medium'}
                      sx={{
                        borderRadius: '6px',
                        fontSize: '24px',
                        fontWeight: 900,
                        color: 'white',
                        '& .MuiChip-label': {
                          backgroundColor: planToColorMapping(planType),
                          borderRadius: '6px',
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
            <Stack flex={10} spacing={2} direction={'column'} justifyContent={'center'}>
              <Box>
                <Typography fontSize={'12px'}>Hi {auth.currentUser?.displayName},</Typography>
              </Box>
              <Box>
                <Typography fontWeight={600} fontSize={'24px'} lineHeight={'28px'}>
                  {planType === UserSubscriptionPlanEnum.TOP_UP
                    ? t(`You have purchased ${creditsForPlan}!`)
                    : t(`Your ${planType}\n subscription \nhas started!`)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        {planType === UserSubscriptionPlanEnum.TOP_UP ? (
          <Stack px={4} pb={4} pt={4}>
            <Box mt={3}>
              <CustomButton
                width="100%"
                height="48px"
                foregroundColor="white"
                hasBorder={false}
                text={t('Continue')}
                onClick={handleModalClose}
                variant="contained"
              />
            </Box>
          </Stack>
        ) : (
          <Stack px={4} pb={4} pt={4}>
            <Grid container spacing={2}>
              {[creditsForPlan, t('Use images Commercially')]
                .filter(item => !!item)
                .map(text => (
                  <Grid xs={6} key={text}>
                    <Stack direction="row" gap={1.5} alignItems="center">
                      <CheckIcon />
                      <Typography fontSize={16} lineHeight="16px">
                        {text}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
            <Box mt={3}>
              <CustomButton
                width="100%"
                height="48px"
                foregroundColor="white"
                hasBorder={false}
                text={t('Get Started')}
                onClick={handleModalClose}
                variant="contained"
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default SubscriptionPaymentSuccessModal;
