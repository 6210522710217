import React from 'react';
import { CharacterEyeColor } from '../../../../../Models/CharacterCreationOptions';
import { ReactComponent as UnsetIcon } from '../../../../../Assets/Icons/unset.svg';
import { ReactComponent as BlueIcon } from '../../../../../Assets/Icons/eye-color-blue.svg';
import { ReactComponent as BrownIcon } from '../../../../../Assets/Icons/eye-color-brown.svg';
import { ReactComponent as GreenIcon } from '../../../../../Assets/Icons/eye-color-green.svg';
import { ReactComponent as RedIcon } from '../../../../../Assets/Icons/eye-color-red.svg';
import { ReactComponent as BlackIcon } from '../../../../../Assets/Icons/eye-color-black.svg';
import { ReactComponent as WhiteIcon } from '../../../../../Assets/Icons/eye-color-white.svg';
import { ReactComponent as GoldenIcon } from '../../../../../Assets/Icons/eye-color-golden.svg';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material/styles';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';
export interface EyeColorOption {
  eyeColor: CharacterEyeColor | string;
  icon: ReactJSXElement;
}

export const eyeColorOptions: EyeColorOption[] = [
  {
    eyeColor: '',
    icon: <UnsetIcon />,
  },
  {
    eyeColor: CharacterEyeColor.BLACK,
    icon: <BlackIcon />,
  },
  {
    eyeColor: CharacterEyeColor.BLUE,
    icon: <BlueIcon />,
  },
  {
    eyeColor: CharacterEyeColor.BROWN,
    icon: <BrownIcon />,
  },
  {
    eyeColor: CharacterEyeColor.GOLDEN,
    icon: <GoldenIcon />,
  },
  {
    eyeColor: CharacterEyeColor.WHITE,
    icon: <WhiteIcon />,
  },
  {
    eyeColor: CharacterEyeColor.GREEN,
    icon: <GreenIcon />,
  },
  {
    eyeColor: CharacterEyeColor.RED,
    icon: <RedIcon />,
  },
];
interface CharacterEyeColorFeatureComponentProps {
  eyeColor: EyeColorOption;
  setEyeColor: (value: EyeColorOption) => void;
}
const CharacterEyeColorFeatureComponent = ({
  eyeColor,
  setEyeColor,
}: CharacterEyeColorFeatureComponentProps) => {
  const theme = useTheme();
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Eye Color')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {eyeColorOptions.map(option => (
          <CharacterFeatureWithIconItem
            key={`eye-color-${option.eyeColor}` || 'eye-color-unset'}
            label={option.eyeColor || 'unset'}
            icon={option.icon}
            selected={eyeColor.eyeColor === option.eyeColor}
            onClick={() => setEyeColor(option)}
          />
        ))}
      </div>
      <CustomTextFieldV2
        key={'character-custom-eye-color'}
        width={'100%'}
        inputProps={{ value: eyeColor.eyeColor }}
        onChange={e => {
          setEyeColor({ ...eyeColor, eyeColor: e.target.value });
        }}
        isV2={true}
        style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
        backgroundColor={theme.palette.surface.primary}
        borderRadius={'16px'}
        ariaLabelledBy={'Character Custom Eye Color'}
        multiline={false}
        placeholder={t('Or Enter Custom Eye Color')}
      />
    </CharacterFeatureContainer>
  );
};
export default CharacterEyeColorFeatureComponent;
