import { UserConnectedAccounts } from '../../Models/UserSocialAccount';
import {
  ConnectedAccountsActionTypes,
  ConnectedAccountsActions,
} from '../Actions/ConnectedAccounts';

export interface ConnectedAccountsState {
  userConnectedAccount: UserConnectedAccounts | null;
}

export const initialConnectedAccountsState: ConnectedAccountsState = {
  userConnectedAccount: null,
};

export const ConnectedAccountsReducer = (
  state = initialConnectedAccountsState,
  action: ConnectedAccountsActionTypes
) => {
  switch (action.type) {
    case ConnectedAccountsActions.SET_CONNECTED_ACCOUNTS:
      return {
        ...state,
        userConnectedAccount: action.payload.userConnectedAccount,
      };

    default:
      return state;
  }
};
