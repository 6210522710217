export interface UploadState {
  error: boolean;
  errorMessage: string | null;
  isUploading: boolean;
  loadingMessage: string | null;
}

export const getInitialUploadState = (): UploadState => ({
  error: false,
  errorMessage: null,
  isUploading: false,
  loadingMessage: null,
});
