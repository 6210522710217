import { getInitialUpscalerState, UpscalerState } from '../../Models/UpscalerState';
import { UpscalerActionsTypes } from '../Actions/Upscaler';

export interface UpscalerAction {
  type: UpscalerActionsTypes;
  payload?: any;
}

export const UpscalerReducer = (
  state: UpscalerState = getInitialUpscalerState(),
  action: UpscalerAction
): UpscalerState => {
  switch (action.type) {
    case UpscalerActionsTypes.SET_UPSCALER_IMAGE:
      return { ...state, image: action.payload };
    case UpscalerActionsTypes.SET_UPSCALER_TYPE:
      return { ...state, upscalerType: action.payload };
    case UpscalerActionsTypes.SET_UPSCALER_WIDTH:
      return { ...state, width: action.payload };
    case UpscalerActionsTypes.SET_UPSCALER_HEIGHT:
      return { ...state, height: action.payload };
    case UpscalerActionsTypes.SET_UPSCALER_SCALE_BY:
      return { ...state, scaleBy: action.payload };
    case UpscalerActionsTypes.SET_UPSCALED_RESULT:
      return { ...state, result: action.payload };
    case UpscalerActionsTypes.SET_UPSCALER_INIT_IMAGE:
      return { ...state, initImage: action.payload };
    case UpscalerActionsTypes.RESET_UPSCALER:
      return getInitialUpscalerState();
    default:
      return state;
  }
};
