import { NotificationInstance, NotificationPlacement } from 'antd/es/notification/interface';
import { notification } from 'antd';
import { trackEvent } from './Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openSizeNotification = (
  placement: NotificationPlacement,
  message: string,
  desctiption: string,
  typeOfShape: 'frame' | 'image'
) => {
  notification.info({
    message: `${message}`,
    description: `${desctiption}`,
    placement: 'bottomLeft',
  });
  trackEvent(
    {
      event: TrackingEvents.sizeLimitExceeded,
      properties: {
        message: `${message}`,
        typeOfShape: `${typeOfShape}`,
      },
    },
    'CREATOR'
  );
};

export const openGenerationNotification = (
  placement: NotificationPlacement,
  desctiption: string,
  notificationType: NotificationType,
  duration: number,
  title: string
) => {
  notification[notificationType]({
    message: `${title}`,
    description: `${desctiption}`,
    placement: 'bottomLeft',
    duration: duration,
    style: {
      zIndex: 130005,
    },
  });
};
export const openNotificationWithIcon = (
  api: NotificationInstance,
  type: NotificationType,
  placement: NotificationPlacement,
  msg?: string,
  title?: string,
  duration?: number,
  onClick?: () => void | undefined
) => {
  api[type]({
    message: type == 'success' ? title ?? 'Request Successful' : title ?? 'Request Failed',
    description:
      type == 'success'
        ? msg ?? 'Request is successful.'
        : msg ?? 'Request Failed, please try again.',
    placement: 'bottomLeft',
    duration: duration ?? type === 'success' ? 2 : 2,
    style: {
      zIndex: 130005,
    },
    onClick: onClick,
  });
};
