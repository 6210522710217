import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { t } from 'i18next';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import * as React from 'react';
import { color } from '../../Constants/Colors';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';
import { instanceOf } from 'prop-types';

interface CustomButtonProps {
  text: string | React.ReactElement | React.ReactNode;
  onClick: () => void;
  backgroundColor?: string | undefined;
  foregroundColor?: string | undefined;
  hasIcon?: boolean;
  icon?: ReactJSXElement;
  height?: string;
  width?: string;
  minWidth?: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  isLoading?: boolean;
  hasBorder?: boolean;
  disabled?: boolean;
  borderColor?: string;
  padding?: string;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  disableElevation?: boolean;
  tooltip?: string;
  borderRadius?: string;
}

const CustomGenerateButton = ({
  text,
  onClick,
  backgroundColor,
  foregroundColor,
  hasIcon = false,
  icon,
  height,
  width,
  minWidth,
  variant,
  isLoading = false,
  hasBorder = true,
  disabled = false,
  borderColor,
  padding,
  style,
  hoverStyle,
  disableElevation,
  tooltip = '',
  borderRadius,
}: CustomButtonProps) => {
  const handleClick = () => {
    if (!isLoading) {
      trackEvent(
        {
          event: TrackingEvents.buttonClicked,
          properties: {
            buttonName: 'Generate',
          },
        },
        'CREATOR'
      );
      onClick();
    }
  };

  const isReactElement = (content: string | React.ReactElement | React.ReactNode) =>
    React.isValidElement(content);

  return (
    <Tooltip title={tooltip}>
      <Button
        variant={variant}
        disabled={disabled || isLoading}
        sx={{
          textTransform: 'none',
          height: height,
          width: width,
          borderRadius: borderRadius ?? '8px',
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          alignContent: 'center',
          backgroundColor: backgroundColor,
          color: foregroundColor,
          padding: padding ? padding : hasIcon ? '0px 8px 0 0' : '0px',
          alignItems: 'center',
          border: hasBorder ? '1px solid' : 'none',
          borderColor: borderColor,
          minWidth: minWidth ?? '64px',
          ':hover': {
            backgroundColor: backgroundColor,
            color: foregroundColor,
            borderColor: borderColor,
            opacity: 0.8,
            ...hoverStyle,
          },
          ...style,
        }}
        onClick={() => handleClick()}
        disableElevation={disableElevation ?? false}
      >
        {!isLoading && hasIcon && icon}
        {isLoading && hasIcon && (
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ color: foregroundColor }} size={16} />{' '}
          </div>
        )}
        {isLoading && !hasIcon ? (
          <div
            style={{
              width: '100%',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ color: foregroundColor }} size={16} />
          </div>
        ) : isReactElement(text) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: '8px',
              alignItems: 'center',
              width: '100%',
              padding: '0px 8px 0px 8px',
            }}
          >
            {text}
          </div>
        ) : (
          <div>{t(text)}</div>
        )}
      </Button>
    </Tooltip>
  );
};

export default CustomGenerateButton;
