import { UserMetadataType } from '../../Models/UserMetadata';
import { UserMetadataActionTypes, UserMetadataActions } from '../Actions/UserMetadata';

export interface UserMetadataState {
  userId: string | undefined;
  userMetadata?: Record<UserMetadataType, string> | undefined;
  createdAt?: Date;
}

export const initialUserMetadataState: UserMetadataState = {
  userId: undefined,
  userMetadata: undefined,
};

export const UserMetadataReducer = (
  state: UserMetadataState = initialUserMetadataState,
  action: UserMetadataActionTypes
): UserMetadataState => {
  switch (action.type) {
    case UserMetadataActions.SET_USER_METADATA:
      return {
        ...state,
        userId: action.payload.userId,
        userMetadata: action.payload.userMetadata,
        createdAt: action.payload.createdAt,
      };

    default:
      return state;
  }
};
