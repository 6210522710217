export enum UserActions {
  SET_WALLET_BALANCE = 'SET_WALLET_BALANCE',
  RESET_WALLET_BALANCE = 'RESET_WALLET_BALANCE',
  USER_QUOTA_LOADING = 'USER_QUOTA_LOADING',
  REDUCE_GENERATION_QUOTA_COUNT = 'REDUCE_GENERATION_QUOTA_COUNT',
  USER_QUOTA_SUCCESS = 'USER_QUOTA_SUCCESS',
  USER_QUOTA_ERROR = 'USER_QUOTA_ERROR',
  USER_SUBSCRIPTION_LOADING = 'USER_SUBSCRIPTION_LOADING',
  USER_SUBSCRIPTION_SUCCESS = 'USER_SUBSCRIPTION_SUCCESS',
  USER_SUBSCRIPTION_ERROR = 'USER_SUBSCRIPTION_ERROR',
}
