import { LiveLCMActions } from '../Actions/LiveLCMAction';

export interface LCMState {
  liveLCMEnabled: boolean,
  strength: number,
  lcmPrompt: string,
  lcmNegativePrompt: string,
  canvasImage: string,
  seed: number,
}

export function getLCMInitState() {
  return {
    liveLCMEnabled: false,
    strength: 50,
    lcmPrompt: "",
    lcmNegativePrompt: "",
    canvasImage: "",
    seed: 1
  }
}

export interface LCMAction {
  type: LiveLCMActions;
  payload?: LCMState;
}

export const LiveLCMReducer = (
  state: LCMState = getLCMInitState(),
  action: LCMAction
): LCMState => {
  switch (action.type) {
    case LiveLCMActions.UPDATE_LCM_STATE:
      return { ...state, ...action?.payload };
    case LiveLCMActions.UPDATE_CANVAS_IMAGE:
      return { ...state, canvasImage: action?.payload?.canvasImage || "" };
    default:
      return state;
  }
};
