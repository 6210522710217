import { getUserMetadata, updateUserMetadata } from '../Requests/Studio/FTUE';
import { UserMetadataResponse, UserMetadataType } from '../Models/UserMetadata';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../Redux/ReduxInterface';
import { FirstWalkthroughStateActions } from '../FTUE/Redux/Actions/FirstWalkthroughActions';
import useAuth from './useAuth';
import { StoryStateAction } from '../Redux/Actions/StoryState';
import { UserMetadataActions } from '../Redux/Actions/UserMetadata';
import { useContext } from 'react';
import { AuthContext } from '../Provider/AuthProvider';

const useUserMetadata = () => {
  const dispatch = useDispatch();
  const userMetadata = useSelector((state: ReduxState) => state.userMetadataState.userMetadata);
  const userId = useSelector((state: ReduxState) => state.userMetadataState.userId);
  const { currentUser, email } = useContext(AuthContext);

  const fetchMetadata = async () => {
    if (currentUser) {
      const response = (await getUserMetadata(currentUser.uid)) as UserMetadataResponse;
      dispatch({
        type: UserMetadataActions.SET_USER_METADATA,
        payload: {
          userId: response.userMetadata?.userId,
          userMetadata: response.userMetadata?.metadata,
          createdAt: response.userMetadata?.createdAt,
        },
      });
      dispatch({
        type: FirstWalkthroughStateActions.SET_USER_ID,
        payload: { userId: response.userMetadata?.userId },
      });
      setIsFTUE(response.userMetadata?.metadata);
      setIsFirstStory(response.userMetadata?.metadata);
    }
  };

  const setIsFTUE = async (metadata?: Record<UserMetadataType, string> | null) => {
    if (metadata) {
      const isftue =
        metadata[UserMetadataType.IS_FTUE].valueOf() === 'true' &&
        metadata[UserMetadataType.FTUE_SHOW_ID] === undefined;
      dispatch({
        type: FirstWalkthroughStateActions.SET_IS_FTUE,
        payload: isftue,
      });
    }
  };

  const setIsFirstStory = async (metadata?: Record<UserMetadataType, string> | null) => {
    if (metadata) {
      const isFirstStory =
        !metadata[UserMetadataType.IS_FIRST_STORY] ||
        metadata[UserMetadataType.IS_FIRST_STORY].valueOf() === 'true';
      dispatch({
        type: StoryStateAction.SET_IS_FIRST_STORY,
        payload: { isFirstStory: isFirstStory },
      });
    }
  };

  const updateMetadata = async (metadata: Partial<Record<UserMetadataType, string>>) => {
    if (userMetadata && currentUser) {
      const response = (await updateUserMetadata(currentUser.uid, {
        metadata: { ...userMetadata, ...metadata },
      })) as UserMetadataResponse;
      dispatch({
        type: UserMetadataActions.SET_USER_METADATA,
        payload: {
          userId: response.userMetadata?.userId,
          userMetadata: response.userMetadata?.metadata,
        },
      });
      setIsFTUE(response.userMetadata?.metadata);
    }
  };

  return { setIsFTUE, setIsFirstStory, updateMetadata, fetchMetadata };
};

export default useUserMetadata;
