import {
  GenerateAnchorImageRequest,
  ImageGenerationResponse,
  Img2ImgRequest,
  InpaintRequest,
  InstructPix2PixRequest,
  PaintWithWordsRequest,
  PreprocessorRequest,
  Text2ImgRequest,
} from '../../Models/GenerateImage';
import {
  aiApiPrefix,
  apiPrefix,
  imageApiPrefix,
  saveImageApiPrefix,
  studioApiPrefix,
  v2Prefix,
  saveSelectedImageApiPrefix,
  controlNetModels,
  adminApiPrefix,
  characterApiPrefix,
} from '../../Constants/Constants';
import HttpClient from '../../Utils/HttpClient';
import {
  EditedSavedImageRequest,
  SaveImageRequest,
  SaveImageBase64Request,
  SaveImageResponse,
  DownloadImageRequest,
  DownloadImageResponse,
  SaveLiveImageRequest,
  AutoInpaintRequest,
  AutoInpaintResponse,
} from '../../Models/SaveImage';
import { SaveGazeImageRequest } from '../../Models/Gaze';

export const fetchText2Img = async (request: Text2ImgRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-image`,
      request
    );
  } catch (error) {
    console.error(`fetchText2Img failed with error: ${error}`);
    return null;
  }
};

export const fetchImg2Img = async (request: Img2ImgRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-image2image`,
      request
    );
  } catch (error) {
    console.error(`fetchImg2Img failed with error: ${error}`);
    return null;
  }
};

export const fetchPaintWithWords = async (request: PaintWithWordsRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-image2image`,
      request
    );
  } catch (error) {
    console.error(`fetchPaintWithWords failed with error: ${error}`);
    return null;
  }
};

export const fetchInpaint = async (request: InpaintRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-image2image/`,
      request
    );
  } catch (error) {
    console.error(`fetchInpaint failed with error: ${error}`);
    return null;
  }
};

export const fetchMultiInpaint = async (request: InpaintRequest[]) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-multi-inpaint/`,
      request
    );
  } catch (error) {
    console.error(`fetch multi Inpaint failed with error: ${error}`);
    throw new Error('Multi inpaint failed');
  }
};

export const fetchInstructPix2Pix = async (request: InstructPix2PixRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${imageApiPrefix}/prompt-image2image/`,
      request
    );
  } catch (error) {
    console.error(`fetchInstructPix2Pix failed with error: ${error}`);
    return null;
  }
};

export const saveImage = async (request: SaveImageRequest) => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}`, request);
  } catch (error) {
    console.error(`saveImage failed with error: ${error}`);
    return null;
  }
};

export const saveImageBulk = async (request: SaveImageRequest[]) => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/bulk`, request);
  } catch (error) {
    console.error(`saveImage failed with error: ${error}`);
    return null;
  }
};

export const downloadedImage = async (request: SaveImageRequest) => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveSelectedImageApiPrefix}`, request);
  } catch (error) {
    console.error(`download failed with error: ${error}`);
    return null;
  }
};

export const getControlNetModels = async (showId: string) => {
  try {
    return HttpClient.get(`${studioApiPrefix}${controlNetModels}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`fetching controlnet models failed with error: ${error}`);
    return null;
  }
};

export const getT2IAdapters = async (showId: string) => {
  try {
    return HttpClient.get(`${studioApiPrefix}${controlNetModels}/t2i-adapters`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`fetching t2i adapters failed with error: ${error}`);
    return null;
  }
};

export const saveImages = async (request: SaveImageRequest[]) => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/multiple`, request);
  } catch (error) {
    console.error(`saveImage failed with error: ${error}`);
    return null;
  }
};

export const fetchPreprocessedImage = async (request: PreprocessorRequest) => {
  try {
    return HttpClient.post(`${aiApiPrefix}${imageApiPrefix}/preprocessor`, request);
  } catch (error) {
    console.error(`fetchPreprocessedImage failed with error: ${error}`);
    return null;
  }
};

export const saveEditedSavedImage = async (request: EditedSavedImageRequest) => {
  try {
    return HttpClient.post(`${apiPrefix}${studioApiPrefix}${saveImageApiPrefix}/edited`, request);
  } catch (error) {
    console.error(`Unable to save edited image: ${error}`);
    return null;
  }
};

export const saveBase64Image = async (
  request: SaveImageBase64Request
): Promise<SaveImageResponse> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/base64`, request);
  } catch (error) {
    console.error(`saveBase64Image failed with error: ${error}`);
    throw error;
  }
};

export const saveLiveImage = async (request: SaveLiveImageRequest): Promise<SaveImageResponse> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/liveImage`, request);
  } catch (error) {
    console.error(`saveBase64Image failed with error: ${error}`);
    throw error;
  }
};

export const saveGazeImage = async (request: SaveGazeImageRequest): Promise<SaveImageResponse> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/gazeImage`, request, {
      resourceId: request.showId!,
    });
  } catch (error) {
    console.error(`saveBase64Image failed with error: ${error}`);
    throw error;
  }
};
export const downloadImageFormatted = async (
  request: DownloadImageRequest
): Promise<DownloadImageResponse> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${saveImageApiPrefix}/convert`, request);
  } catch (error) {
    console.error(`downloadImageFormatted failed with error: ${error}`);
    throw error;
  }
};

export const fetchAnchorImage = async (
  request: GenerateAnchorImageRequest
): Promise<ImageGenerationResponse> => {
  try {
    return HttpClient.post(
      `${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/generate/anchor-image`,
      request,
      {},
      false,
      true
    );
  } catch (error) {
    console.error(`fetchAnchorImage failed with error: ${error}`);
    throw error;
  }
};
