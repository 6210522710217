export enum StoryStateAction {
  SET_IS_STORY_MODE = 'SET_IS_STORY_MODE',
  SET_STORY_CANVAS_STATE = 'SET_STORY_CANVAS_STATE',
  SET_GENERATING = 'SET_GENERATING',
  SET_THUMBNAIL_GENERATED = 'SET_THUMBNAIL_GENERATED',
  RESET = 'RESET',
  SET_IS_FIRST_STORY = 'SET_IS_FIRST_STORY',
  SET_STORY = 'SET_STORY',
  STORY_DONE = 'STORY_DONE',
  SET_STORY_FROM_PROMPT_RESPONSE = 'SET_STORY_FROM_PROMPT_RESPONSE',
  SET_STORY_LOADING = 'SET_STORY_LOADING',
  SET_PANEL_FROM_STORY_RESPONSE = 'SET_PANEL_FROM_STORY_RESPONSE',
  SET_MAPPED_CHARACTERS = 'SET_MAPPED_CHARACTERS',
  SET_STORY_FETCH_ATTEMPTED = 'SET_STORY_FETCH_ATTEMPTED',
  SET_STORY_ERROR = 'SET_STORY_ERROR',
  SET_GET_STORY_FEEDBACK = 'SET_GET_STORY_FEEDBACK',
}

export interface UpdateStoryStateAction {
  type: StoryStateAction;
  payload: any;
}

export type StoryStateActionTypes = UpdateStoryStateAction;
