import { UserSubscriptionPlanEnum } from '../../../../../Models/User';
import { color, GradientColors } from '../../../../../Constants/Colors';

function planToColorMapping(plan: UserSubscriptionPlanEnum): string {
  switch (plan) {
    case UserSubscriptionPlanEnum.CREATOR:
      return color['green'];
    case UserSubscriptionPlanEnum.EXPLORER:
      return color['chrome'];
    default:
      return color['blue'];
  }
}

export default planToColorMapping;
