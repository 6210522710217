import { CharacterState } from '../Redux/Reducers/Character';
import {
  CharacterAge,
  CharacterBodyType,
  CharacterEthnicity,
  CharacterEyeColor,
  CharacterGender,
  CharacterHairColor,
  CharacterHairLength,
  CharacterHairStyle,
} from './CharacterCreationOptions';

export enum CharacterLibraryViewState {
  CHARACTER_LIBRARY = 'CHARACTER_LIBRARY',
  MY_CHARACTERS = 'MY_CHARACTERS',
}

export enum CharacterStatus {
  CREATED = 'CREATED',
  DATASET_GENERATION_ENQUEUED = 'DATASET_GENERATION_ENQUEUED',
  COMFY_GENERATING = 'COMFY_GENERATING',
  COMFY_GENERATED = 'COMFY_GENERATED',
  TRAINING_ENQUEUED = 'TRAINING_ENQUEUED',
  IN_TRAINING = 'IN_TRAINING',
  TRAINED = 'TRAINED',
  VALIDATION = 'VALIDATION',
  QC_CREATED = 'QC_CREATED',
  REVIEWED = 'REVIEWED',
  PUBLISHED = 'PUBLISHED',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}

export enum TrainingEvalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum CharacterQCStatus {
  QC_PENDING = 'QC_PENDING',
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export interface CharacterMetadata {
  chosenDataset: string[];
  diffusersPrompt: string;
  fileName: string;
  generatedAt: string;
  generatedDataSet: string[];
  trainingFileLink: string;
  trainingTaskId: string;
  updatedAt: string;
  updatedBy: string;
  uploadedImages: string[];
}

interface CharacterStyle {
  id: string;
  characterId: string;
  styleId: string;
  thumbnailImage: null | string;
  characterMetadata?: string | null;
}

export interface Character {
  id: string;
  name: string;
  imageUrl: string;
  description: string;
  gender: string;
  uploadedImage: string;
  baseModel: string;
  state: string;
  generationMetadata?: string;
  age?: string;
  ethnicity?: string;
  casting?: string;
  hairColor?: string;
  hairStyle?: string;
  eyeColor?: string;
  additionalCharacteristics?: string;
  createdAtEpoch: number;
  updatedAtEpoch: number;
  createdAt?: number;
  updatedAt?: number;
  characterTrainingEndTimeEpoch?: number;
  characterTrainingTimeLeftMs?: number;
  styles?: CharacterStyle[];
  organisation?: string;
  feedbackDone?: boolean;
  actor?: string | null;
  baseStyleName?: string | null;
  isCreator?: boolean;
}

export enum Organisation {
  DASHTOON = 'dashtoon.com',
  USER = 'USER',
  ALL = 'ALL',
}

export enum CharacterFilters {
  GENDER = 'genders',
  AGE = 'age',
  ETHNICITY = 'ethnicity',
}

export interface ShowCharacters {
  id: string;
  name: string;
  image_url: string;
  show_id: string;
  character_id: string;
  metadata: string;
  in_use: boolean;
  gender: string;
  character_story_name?: string | null;
  metadata_details: Record<CharacterShowMetadataType, string | null>;
}

export interface UpdateCharacterQCRequest {
  characterId: String;
  chosenLinks: String[];
}

export interface AddShowCharacterRequest {
  showId: string;
  characterId: string;
  name: string;
  inUse: boolean;
  storyCharacterName?: string | null;
}

export interface AddShowCharactersToEpisodeRequest {
  showId: string;
  episodeId: string;
  showCharacterIds: string[];
}

export interface DeleteShowCharacterRequest {
  showId: string;
  characterId: string;
}

export interface DeleteCharacterRequest {
  characterId: string;
}

export interface DeleteCharacterFromShowAndAllEpisodesRequest {
  showId: string;
  showCharacterIds: string[];
}

export interface AddCharactersToShowAndEpisodeRequest {
  characters: AddShowCharacterRequest[];
  episodeId: string;
  showId: string;
}

export interface ShowCharacterDetailsUpdateRequest {
  showId: string;
  showCharacter: ShowCharacters;
}

export interface CharacterTrainingRequest {
  name: string;
  description: string;
  gender: string;
  images: string[];
  showId: string;
}

export interface GetCharactersByFilterRequest {
  visibility: string;
  gender: string;
  age: string;
  ethnicity: string;
  showId: string;
}

export enum ProcessType {
  SEMI_MANUAL_V2 = 'SEMI_MANUAL_V2',
  AUTOMATED_V2 = 'AUTOMATED_V2',
  MANUAL = 'MANUAL',
  AUTOMATED_DIFFUSERS = 'AUTOMATED_DIFFUSERS',
  SEMI_MANUAL_DIFFUSERS = 'SEMI_MANUAL_DIFFUSERS',
  AUTOMATED_V4 = 'AUTOMATED_V4',
  SEMI_MANUAL_V4 = 'SEMI_MANUAL_V4',
  AUTOMATED_V3_CS = 'AUTOMATED_V3_CS',
  SEMI_MANUAL_V3_CS = 'SEMI_MANUAL_V3_CS',
}

export interface CreateCharacterWithMetadataRequest {
  name: string;
  description: string;
  age:
    | 'Infant'
    | 'Toddler'
    | 'Child'
    | 'Teenager'
    | 'Young Adult'
    | 'Adult'
    | 'Middle Age'
    | 'Old'
    | 'Ancient';
  casting: 'Primary' | 'Secondary' | 'Tertiary';
  ethnicity:
    | 'African American'
    | 'Asian American'
    | 'Indian'
    | 'Latin American'
    | 'Middle Eastern'
    | 'White';
  hairStyle: string;
  hairColor: string;
  eyeColor: string;
  additionalCharacteristics: string;
  gender: 'MALE' | 'FEMALE';
  images: string[];
  showId: string;
  processType?: ProcessType;
  requiresCaptions?: boolean;
  danbooruTags?: string;
  type?: DashCharacterTypes;
  addToAllStyles?: boolean;
}

export interface CreateCharacterWithMetadataRequestV2 {
  name: string;
  description: string;
  age: CharacterAge;
  casting: 'Primary' | 'Secondary' | 'Tertiary';
  ethnicity: CharacterEthnicity;
  hairStyle: CharacterHairStyle | string;
  hairColor: CharacterHairColor | string;
  hairLength: CharacterHairLength | string;
  eyeColor: CharacterEyeColor | string;
  bodyType: CharacterBodyType | string;
  additionalCharacteristics: string;
  gender: CharacterGender;
  images: string[];
  showId: string | null;
  processType?: ProcessType;
  requiresCaptions?: boolean;
  danbooruTags?: string;
  type?: DashCharacterTypes;
  styleId: string;
  characterImageLink: string;
  anchorImageCacheId?: string | null;
}
export enum DashCharacterTypes {
  PROMPT = 'PROMPT',
  TRAINED = 'TRAINED',
}

export interface CharacterUpdateRequest {
  characterId: string;
  name: string;
  description: string;
  gender: string;
}

export const CharacterInitialState: CharacterState = {
  allCharacters: [],
  showCharacters: [],
  trainingCharacters: [],
  currentCharacter: null,
  characterModalLibraryViewState: CharacterLibraryViewState.CHARACTER_LIBRARY,
  allStyles: [],
  styleBaseModelMapper: {},
  episodeCharacters: [],
};

export interface ShowCharacterMetadataUpdateRequest {
  characterId: string;
  showId: string;
  name: string;
  description: string;
}

export enum CharacterShowMetadataType {
  CHARACTER_DESCRIPTION = 'CHARACTER_DESCRIPTION',
  ACTOR = 'ACTOR',
  ALIAS = 'ALIAS',
  DRESS = 'DRESS',
}

export interface AvailableCharactersWithFiltersRequest {
  gender?: string[];
  age?: string[];
  ethnicity?: string[];
  filterText?: string;
  initialCharacterIds?: string[];
  organisation?: Organisation;
}

export enum CharacterTrainingStatus {
  PRE_TRAINING = 'Pre-Training',
  DATASET_READY = 'Dataset Ready',
  TRAINING = 'Training',
  TRAINING_FAILED = 'Training Failed',
  PUBLISHED = 'Published',
}

export interface CharacterDatasetResponse {
  id: string;
  name: string;
  imageUrl: string;
  generationMetadata: string;
  model: string;
  description: string;
  uploadedImage: string;
  baseModel?: string | null;
  generatedDataSet: string[];
  gender: string;
  state: CharacterStatus;
  createdAtEpoch: number;
  updatedAtEpoch: number;
  organisation: string;
  uploadedImages: string[];
  chosenDataset: string[];
}

export interface CharacterMetadataResponse {
  name: string;
  age: string | null;
  ethnicity: string | null;
  gender: string | null;
  hair_color: string | null;
  hair_length: string | null;
  hair_style: string | null;
  eye_color: string | null;
  body_type: string | null;
  dress: string | null;
  danbooru_tags: string | null;
}
