import { Box, Modal } from '@mui/material';
import CustomTextFieldV2 from '../../../../Components/CustomTextFieldV2/CustomTextFieldV2';
import IconButton from '@mui/material/IconButton';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import { TrackingProperties } from '../../../../Constants/TrackingProperties';
import { t } from 'i18next';
import CustomMultiSelect from '../../../../Components/CustomMultiSelect/CustomMultiSelect';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import { uploadFileRequest } from '../../../../Requests/Studio/Canvas';
import UploadFileComponent from '../../../../Components/UploadFile/UploadFileComponent';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { BugState } from '../../../../Models/BugReport';
import { sendBugReport } from '../../../../Requests/Studio/BugReport';
import { calculatePercentiles } from '../../../../Utils/pingLatency';
import { pingLatencyUrl } from '../../../../Constants/Constants';
import useAuth from '../../../../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { AppStateActions } from '../../../../Redux/Actions/AppState';
import { useDispatch } from 'react-redux';
import { AuthContext } from '../../../../Provider/AuthProvider';

interface BugModalProps {
  showModal: boolean;
  onClose: () => void;
  showToast: (type: 'success' | 'error', message: string) => void;
  trackBugEvent: (bugState: BugState) => void;
}

interface UploadFileType {
  file: File;
  progress: number;
  isError?: boolean;
}

const BugModal = (props: BugModalProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = React.useState<UploadFileType[]>([]);
  const [bugState, setBugState] = React.useState<BugState>({
    title: '',
    description: '',
    type: undefined,
    files: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [ping, setPing] = React.useState('');
  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();

  const bugList = [
    'Account',
    'Character',
    'Image Loading',
    'Functionality',
    'UI/UX',
    'Slow Generations',
    'Other',
  ];
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '90vh',
    width: '30vw',
    maxWidth: '750px',
    overflow: 'scroll',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    border: '1px solid',
    borderColor: 'divider',
    boxShadow: 24,
    p: 2,
    zIndex: 9999,
  };

  useEffect(() => {
    calculatePercentiles(`${pingLatencyUrl}`, 10)
      .then(percentiles => {
        setPing(
          `P50: ${percentiles.p50} ms, P90: ${percentiles.p90} ms, P99: ${percentiles.p99} ms`
        );
      })
      .catch(error => {
        console.error('Error calculating percentiles:', error);
      });
  }, []);
  const handleDropFiles = (files: File[]) => {
    // Filter out files larger than 100MB
    const validFiles = files.filter(file => file.size / 1024 / 1024 <= 100);
    const invalidFilesCount = files.length - validFiles.length;

    // Show error message if there are files that are too large
    if (invalidFilesCount > 0) {
      props.showToast('error', t(`Some files are too large. Only files under 100MB are allowed.`));
    }

    // Calculate the number of files that can be added
    const filesCanBeAdded = 5 - uploadFiles.length;

    // Check if adding the new files will exceed the limit
    if (validFiles.length > filesCanBeAdded) {
      props.showToast('error', t('You can only upload up to 5 files'));
      // Slice the valid files to only include as many as can be added
      validFiles.splice(filesCanBeAdded, validFiles.length - filesCanBeAdded);
    }

    // Update the state with the valid files
    setUploadFiles(prevState => [
      ...prevState,
      ...validFiles.map(file => ({
        file,
        progress: 0,
      })),
    ]);

    // Proceed with the upload logic for the valid files
    const uploadPromises = validFiles.map(
      file =>
        uploadFileRequest(file, progress => {
          setUploadFiles(prevState => {
            const fileIndex = prevState.findIndex(f => f.file === file);
            if (fileIndex === -1) {
              return prevState;
            }
            const newState = [...prevState];
            newState[fileIndex] = {
              ...prevState[fileIndex],
              progress,
            };
            return newState;
          });
        }).then(response => ({ name: file.name, url: response.url })) // Map the response here
    );

    Promise.all(uploadPromises)
      .then(fileObjects => {
        // Update state with the response of uploaded files
        setBugState(prevState => ({
          ...prevState,
          files: [...prevState.files, ...fileObjects],
        }));
      })
      .catch(error => {
        console.error('There was an error uploading files:', error);
        // Handle any errors here
      });
  };

  const handleDelete = (uploadFile: UploadFileType) => {
    setUploadFiles(prevState => {
      const fileIndex = prevState.findIndex(f => f.file === uploadFile.file);
      if (fileIndex === -1) {
        return prevState;
      }
      return [...prevState.slice(0, fileIndex), ...prevState.slice(fileIndex + 1)];
    });
    setBugState(prevState => ({
      ...prevState,
      files: prevState.files.filter(file => file.name !== uploadFile.file.name),
    }));
  };

  const handleSend = async () => {
    setIsLoading(true);
    try {
      // append ping and current url to description
      const bugStateWithPing = {
        ...bugState,
        description: `${bugState.description}\n\nPing: ${ping}\n\nURL: ${window.location.href}`,
      };

      await sendBugReport(bugStateWithPing);
      props.showToast('success', t('Your bug report has been submitted successfully!'));
      props.trackBugEvent(bugState);
      props.onClose();
    } catch (error) {
      setIsLoading(false);
      props.showToast('error', t('Failed to submit bug report'));
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentUser.currentUser === null && !currentUser.loading) {
      dispatch({
        type: AppStateActions.OPEN_LOGIN_MODAL,
        payload: true,
      });
      props.onClose();
    }
  }, [currentUser]);

  return (
    <Modal
      sx={{ borderRadius: '16px !important' }}
      open={props.showModal}
      onClose={props.onClose}
      aria-labelledby="publish-modal-title"
    >
      <Box sx={style}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontSize: '20px', fontWeight: '600', marginBottom: '8px' }}>
              Report a bug
            </div>
            <div>
              <IconButton
                onClick={props.onClose}
                aria-label={'Close'}
                aria-roledescription={'Close'}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '50vh',
              overflow: 'scroll',
            }}
          >
            <CustomTextFieldV2
              onChange={e => {
                setBugState({
                  ...bugState,
                  title: e.target.value,
                });
              }}
              isV2={true}
              width={'100%'}
              enableAutofill={false}
              placeholder={'Add a short title describing your issue'}
              autoFocus={true}
              ariaLabel={'Describe the issue you are facing'}
            />
            <CustomMultiSelect
              ariaLabel={'Select the type of bug you are reporting'}
              id={'bug-type-select'}
              placeHolderColor={theme.palette.text.disabled}
              maxOptions={1}
              isV2={true}
              hasLabel={false}
              selectedValues={bugState?.type === '' ? undefined : bugState?.type}
              onChange={e => {
                trackEvent(
                  {
                    event: TrackingEvents.selectedBugType,
                    properties: {
                      bugType: e,
                    } as TrackingProperties,
                  },
                  'CREATOR'
                );
                setBugState({
                  ...bugState,
                  type: e,
                });
              }}
              placeholder={t('What kind of bug are you trying to report?')}
              label={''}
              options={bugList}
            />

            <CustomTextFieldV2
              label={''}
              onChange={e => {
                setBugState({
                  ...bugState,
                  description: e.target.value,
                });
              }}
              multiline={true}
              isV2={true}
              width={'100%'}
              enableAutofill={false}
              placeholder={'Please describe the issue you are facing'}
              ariaLabel={'Describe the issue you are facing'}
            />
            <div style={{ marginTop: '8px', marginBottom: '8px' }}>
              <UploadFileComponent
                showPreview={false}
                onFileUpload={files => handleDropFiles(files)}
                label={t('Please upload images or videos indicating the issue')}
                accept={'png, webp, jpg, jpeg, mov, *'}
                multiple={true}
                requiredWidth={612}
                requiredHeight={200}
                enforceWidth={false}
                enforceHeight={false}
                style={{ overflow: 'hidden', height: '40px' }}
                showIcon={true}
              />
            </div>
            {uploadFiles.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {uploadFiles.map((uploadFile, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <ProgressBar
                      fileName={uploadFile.file.name}
                      progress={uploadFile.progress}
                      onDelete={() => handleDelete(uploadFile)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '8px',
          }}
        >
          <CustomButton
            hasBorder={false}
            height={'32px'}
            text={'Cancel'}
            onClick={() => props.onClose()}
            variant={'contained'}
            width={'48%'}
            backgroundColor={theme.palette.divider}
            foregroundColor={theme.palette.text.primary}
          />
          <CustomButton
            hasBorder={false}
            height={'32px'}
            text={'Send'}
            onClick={() => handleSend()}
            variant={'contained'}
            width={'48%'}
            foregroundColor={'white'}
            disabled={
              bugState.title === '' || bugState.description === '' || bugState.type === undefined
            }
            isLoading={isLoading}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default BugModal;
