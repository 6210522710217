import { UploadStateActions } from '../Actions/UploadState';
import { GenerationStepsActions } from '../Actions/GenerationSteps';
import { GenerationSteps, getInitialGenerationSteps } from '../../Models/GenerationSteps';

export interface GenerationStepsAction {
  type: GenerationStepsActions;
  payload?: any;
}

export const GenerationStepsReducer = (
  state: GenerationSteps[] = getInitialGenerationSteps(),
  action: GenerationStepsAction
): GenerationSteps[] => {
  switch (action.type) {
    case GenerationStepsActions.SET_GENERATIONS_STEPS_ADD:
      if (state.find(step => step.frameId === action.payload.frameId)) {
        return state;
      }
      return [...state, action.payload];
    case GenerationStepsActions.SET_GENERATIONS_STEPS_REMOVE:
      return state.filter(step => step.frameId !== action.payload);
    case GenerationStepsActions.SET_GENERATIONS_STEPS_EDIT:
      return state.map(step =>
        step.frameId === action.payload.frameId ? { ...step, ...action.payload } : step
      );
    case GenerationStepsActions.SET_GENERATIONS_STEPS_ITERATE:
      return state.map(step =>
        step.frameId === action.payload.frameId
          ? { ...step, currentStep: step.currentStep + 1 }
          : step
      );
    default:
      return state;
  }
};
