import { TextualEmbeddingResponse } from '../../Models/TextualEmbedding';
import { TextualEmbeddingActions, TextualEmbeddingActionTypes } from '../Actions/TextualEmbedding';

export interface TextualEmbeddingState {
  availableTextualEmbeddings: TextualEmbeddingResponse[];
}

const initialState: TextualEmbeddingState = {
  availableTextualEmbeddings: [],
};

export const TextualEmbeddingReducer = (
  state: TextualEmbeddingState = initialState,
  action: TextualEmbeddingActionTypes
): TextualEmbeddingState => {
  switch (action.type) {
    case TextualEmbeddingActions.UPDATE_TEXTUAL_EMBEDDINGS:
      return { ...state, availableTextualEmbeddings: action.payload };
    default:
      return state;
  }
};
