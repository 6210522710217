import { StoryCrafterEntity } from './StoryCrafter';

export interface StoryCrafterBlock {
  entityId: string;
  panels: StoryCrafterEntity[];
  dialogs: StoryCrafterEntity[];
  episodeId: string;
}

export interface StoryCrafterBulkEditingState {
  isBulkEditing: boolean;
  selectedPanelProperty: PanelDetailProperty | null;
  selectedCharacterProperty: CharacterDetailProperty | null;
  selectedShowCharacterId: string | null;
  selectedValue: string | null;
}

export const defaultBulkEditingState: StoryCrafterBulkEditingState = {
  isBulkEditing: false,
  selectedPanelProperty: null,
  selectedCharacterProperty: null,
  selectedShowCharacterId: null,
  selectedValue: null,
};

export enum PanelDetailProperty {
  description = 'description',
  location = 'location',
  timeOfDay = 'timeOfDay',
  cameraAngle = 'cameraAngle',
  cameraShot = 'cameraShot',
}

export enum CharacterDetailProperty {
  dress = 'dress',
  expression = 'expression',
  pose = 'pose',
}
