import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ShowDetailsCharacterActions } from "../../../../../../Redux/Actions/ShowDetailsCharacter";
import { ShowDetailsCharacterView } from "../../ShowDetailsCharacters";
import { ArrowBack } from "@mui/icons-material";

const DashCharacterCreatorTitleBlock: React.FC<{showTitle?:boolean}> = ({
    showTitle = true
}) => {
    const dispatch = useDispatch();
    return (
        <Box
            style={{
                marginLeft: '1rem',
                display: 'flex',
                padding: '0px',
                alignContent: 'center',
                gap: "0.5rem"
            }}

        >
            <Box style={{ cursor: "pointer" }} onClick={() => {
                dispatch({
                    type: ShowDetailsCharacterActions.LIBRARY,
                    payload: ShowDetailsCharacterView.LIBRARY,
                });
            }}>
                <ArrowBack fontSize='small' />
            </Box>
            {showTitle && <Box>
                Dash Character Creator
            </Box>}
        </Box>
    )
}

export default DashCharacterCreatorTitleBlock;