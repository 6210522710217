export enum FirstWalkthroughStateActions {
  SET_FRAME_ID1 = 'SET_FRAME_ID1',
  SET_FRAME_ID2 = 'SET_FRAME_ID2',
  SET_PAGE_ID = 'SET_PAGE_ID',
  SET_PROMPT = 'SET_PROMPT',
  SET_USER_ID = 'SET_USER_ID',
  SET_IS_FTUE = 'SET_IS_FTUE',
  SET_TEXT_ADDED = 'SET_TEXT_ADDED',
  SET_SPEECH_BUBBLE_ADDED = 'SET_SPEECH_BUBBLE_ADDED',
}

export interface UpdateFirstWalkthroughStateAction {
  type: FirstWalkthroughStateActions;
  payload: any;
}

export type FirstWalkthroughStateActionTypes = UpdateFirstWalkthroughStateAction;
