export enum CanvasStateActions {
  SET_COPIED_SHAPE_IDS = 'SET_COPIED_SHAPE_IDS',
  SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE',
  SET_CANVAS_STATE_SAVING = 'SET_CANVAS_STATE_SAVING',
  SET_GENERATION_STATE_SAVING = 'SET_GENERATION_STATE_SAVING',
  SET_DID_CANVAS_SAVED = 'SET_DID_CANVAS_SAVED',
  SET_DID_GENERATION_SAVED = 'SET_DID_GENERATION_SAVED',
  SET_SELECTED_STYLE = 'SET_SELECTED_STYLE',
  SET_STYLE_LIVE_CONFIG = 'SET_STYLE_LIVE_CONFIG',
  SET_CURRENT_FRAMES = 'SET_CURRENT_FRAMES',
  SET_SELECTED_FRAMES = 'SET_SELECTED_FRAMES',
  SET_SELECTED_COMMENTS = 'SET_SELECTED_COMMENTS',
  SET_TAB_VALUE = 'SET_TAB_VALUE',
  SET_MINIMAP_EXPANDED = 'SET_MINIMAP_EXPANDED',
  SET_PUBLISH_PAGES = 'SET_PUBLISH_PAGES',
  UPDATE_PUBLISH_PAGE = 'UPDATE_PUBLISH_PAGE',
  SET_HAS_CANVAS_STATE_CHANGES = 'SET_HAS_CANVAS_STATE_CHANGES',
  SET_HAS_GENERATION_STATE_CHANGES = 'SET_HAS_GENERATION_STATE_CHANGES',
  SET_IS_RIGHT_MENU_OPEN = 'SET_IS_RIGHT_MENU_OPEN',
  SET_SELECTED_SHAPES = 'SET_SELECTED_SHAPES',
  SET_IS_REFERENCE_LIBRARY_OPEN = 'SET_IS_REFERENCE_LIBRARY_OPEN',
  SET_CURRENT_STROKE_COLOR = 'SET_CURRENT_STROKE_COLOR',
  SET_CURRENT_MASK_STROKE_WIDTH = 'SET_CURRENT_MASK_STROKE_WIDTH',
  SET_CURRENT_MAGIC_ERASE_STROKE_WIDTH = 'SET_CURRENT_MAGIC_ERASE_STROKE_WIDTH',
  SET_CURRENT_PAINT_STROKE_WIDTH = 'SET_CURRENT_PAINT_STROKE_WIDTH',
  SET_CURRENT_BRUSH_STROKE_WIDTH = 'SET_CURRENT_BRUSH_STROKE_WIDTH',
  SET_CURRENT_ERASER_WIDTH = 'SET_CURRENT_ERASER_WIDTH',
  SET_CURRENT_OPACITY = 'SET_CURRENT_OPACITY',
  SET_LAST_9_COLORS = 'SET_LAST_9_COLORS',
  SET_CANVAS_STATUS = 'SET_CANVAS_STATUS',
  SET_ALL_SPEECH_BUBBLES = 'SET_ALL_SPEECH_BUBBLES',
  SET_IS_SHORTCUTS_MODAL_OPEN = 'SET_IS_SHORTCUTS_MODAL_OPEN',
  SET_PANEL_TEMPLATES = 'SET_PANEL_TEMPLATES',
  SET_IS_PANEL_TEMPLATES_OPEN = 'SET_IS_PANEL_TEMPLATES_OPEN',
  SET_CURRENT_SELECTED_RIGHT_MENU_TOOL = 'SET_CURRENT_SELECTED_RIGHT_MENU_TOOL',
  SET_CURRENT_BRUSH_TYPE = 'SET_CURRENT_BRUSH_TYPE',
  SET_CURRENT_BRUSH_DISTANCE = 'SET_CURRENT_BRUSH_DISTANCE',
  SET_CURRENT_BRUSH_HARDNESS = 'SET_CURRENT_BRUSH_HARDNESS',
  SET_PATTERN_IMAGES = 'SET_PATTERN_IMAGES',
  SET_IS_PUBLISH_SCREEN_LOADING = 'SET_IS_PUBLISH_SCREEN_LOADING',
  SET_IS_PICKER_OPEN = 'SET_IS_PICKER_OPEN',
  SET_RIGHT_MENU_TAB = 'SET_RIGHT_MENU_TAB',
  SET_IS_FOCUS_MODE = 'SET_IS_FOCUS_MODE',
  SET_IS_PREVIOUS_EPISODE_PUBLISHED = 'SET_IS_PREVIOUS_EPISODE_PUBLISHED',
  SET_SHOW_EMPTY_FRAME_WARNING = 'SET_SHOW_EMPTY_FRAME_WARNING',
}
