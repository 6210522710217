export enum TabSelectorActions {
  SET_IMAGE_GENERATION_TAB = 'SET_IMAGE_GENERATION_TAB',
  SET_CONTROLNET_TAB_SELECTOR = 'SET_CONTROLNET_TAB_SELECTOR',
  SET_MULTI_INPAINT_OPEN = 'SET_MULTI_INPAINT_OPEN',
  SET_MULTI_INPAINT_SELECTION = 'SET_MULTI_INPAINT_SELECTION',
  SET_CAROUSEL_MODAL_OPEN = 'SET_CAROUSEL_MODAL_OPEN',
  SET_MULTI_INPAINT_DRAW = 'SET_MULTI_INPAINT_DRAW',
  SHOW_INPAINT_MODEL = 'SHOW_INPAINT_MODEL',
  CAN_EDIT_INPAINT_MODEL = 'CAN_EDIT_INPAINT_MODEL',
  UPDATE_ON_FOCUS = 'UPDATE_ON_FOCUS',
  UPDATE_LIBRARY_SEARCH_TAB = 'UPDATE_LIBRARY_SEARCH_TAB',
  UPDATE_CANVAS_STATE = 'UPDATE_CANVAS_STATE',
  UPDATE_SHOW_DETAILS_MODAL_TAB = 'UPDATE_SHOW_DETAILS_MODAL_TAB',
  UPDATE_DASHBOARD_SEARCH = 'UPDATE_DASHBOARD_SEARCH',
  OPEN_CREATE_COLLECTION = 'OPEN_CREATE_COLLECTION',
}
