const hostname = window.location.hostname;
// !TODO: Needs to go through .env itself. set it on cicd dev and prod
export const isProduction =
  hostname === 'studio.dashtoon.ai' ||
  hostname === 'studio.dashtoon.com' ||
  hostname === 'dashtoon.com' ||
  hostname === 'www.dashtoon.com';
const isLocal = hostname === 'localhost';

export const config = {
  baseUrl: isProduction
    ? process.env.REACT_APP_API_BASE_URL
    : isLocal
    ? process.env.REACT_APP_LOCAL_API_BASE_URL
    : process.env.REACT_APP_DEV_API_BASE_URL,
  mixpanelConsumerToken: isProduction
    ? process.env.REACT_APP_CONSUMER_PROD_MIXPANEL_TOKEN
    : process.env.REACT_APP_CONSUMER_DEV_MIXPANEL_TOKEN,
  mixpanelCreatorToken: isProduction
    ? process.env.REACT_APP_CREATOR_PROD_MIXPANEL_TOKEN
    : process.env.REACT_APP_CREATOR_DEV_MIXPANEL_TOKEN,
  flagsmithEnvironmentId: isProduction
    ? process.env.REACT_APP_PROD_FLAGSMITH_ENVIRONMENT_ID
    : process.env.REACT_APP_DEV_FLAGSMITH_ENVIRONMENT_ID,
  flagsmithEventSourceUrl: isProduction
    ? process.env.REACT_APP_PROD_FLAGSMITH_EVENT_SOURCE_URL
    : process.env.REACT_APP_DEV_FLAGSMITH_EVENT_SOURCE_URL,
  dashTrackingUrl: isProduction
    ? process.env.REACT_APP_PROD_DASH_TRACKING_URL
    : isLocal
    ? process.env.REACT_APP_LOCAL_DASH_TRACKING_URL
    : process.env.REACT_APP_DEV_DASH_TRACKING_URL,
  inferenceLiveUrl: isProduction
    ? process.env.REACT_APP_LIVE_LCM_INFERENCE_PROD
    : process.env.REACT_APP_LIVE_LCM_INFERENCE,
  inferenceLiveSDXLUrl: isProduction
    ? process.env.REACT_APP_LIVE_LCM_SDXL_INFERENCE_PROD
    : process.env.REACT_APP_LIVE_LCM_SDXL_INFERENCE_DEV,
  gazeUrl: isProduction ? process.env.REACT_APP_GAZE_PROD : process.env.REACT_APP_GAZE_DEV,
  clarityId: isProduction
    ? process.env.REACT_APP_CLARITY_ID_PROD
    : process.env.REACT_APP_CLARITY_ID_DEV,
  defaultStyle: 'SDXL_NEW_ANIME_FORGE',
  defaultStyleName: 'Anime Forge',
  googleAnalytics: isProduction
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_PROD
    : process.env.REACT_APP_GOOGLE_ANALYTICS_DEV,
  publishedEpisodeLinkPrefix: isProduction
    ? 'https://dashtoon.com/show/'
    : 'https://dev-web.dashtoon.com/show/',
  appsFlyerDeepLinkPrefix: 'app://dashtoon/show/',
  publicPosthogKey: isProduction
    ? process.env.REACT_APP_PUBLIC_POSTHOG_KEY_PROD
    : process.env.REACT_APP_PUBLIC_POSTHOG_KEY_DEV,
  posthogApiHost: isProduction
    ? process.env.REACT_APP_POSTHOG_API_HOST_PROD
    : process.env.REACT_APP_POSTHOG_API_HOST_DEV,
  posthogTenant: isProduction
    ? process.env.REACT_APP_POSTHOG_TENENT_PROD
    : process.env.REACT_APP_POSTHOG_TENENT_DEV,
  userAppVersion: process.env.REACT_APP_VERSION,
};
