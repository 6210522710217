import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTextField from '../../../../Components/CustomTextfield/CustomTextField';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import CharacterLibraryView from './ShowDetailsCharacter/CharacterLibraryView';
import AddCharacterToShow from './ShowDetailsCharacter/AddCharacterToShow';
import CharacterView, { CharacterViewStates } from './ShowDetailsCharacter/CharacterView';
import TrainingEvalPage from './ShowDetailsCharacter/TrainingEvalPage';
import { Character, CharacterStatus } from '../../../../Models/Character';
import { CharacterActions } from '../../../../Redux/Actions/Character';
import { ReactComponent as BackIcon } from '../../../../Assets/Icons/Back.svg';
import {
  fetchAllCharacterAvailable,
  fetchCharactersForShow,
  fetchTrainingCharactersForShow,
} from '../../../../Requests/Studio/Character';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import useNotification from 'antd/es/notification/useNotification';
import { openNotificationWithIcon } from '../../../../Utils/Notification';
import ReviewCharacterPage from './ShowDetailsCharacter/ReviewCharacterPage';
import { ReduxState } from '../../../../Redux/ReduxInterface';
import { TabSelectorActions } from '../../../../Redux/Actions/TabSelector';
import CharacterInTraining from './ShowDetailsCharacter/CharacterInTraining';
import CharacterTrainingCompleted from './ShowDetailsCharacter/CharacterSection/CharacterTrainingCompleted/CharacterTrainingCompleted';
import CharacterFailedTraining from './ShowDetailsCharacter/CharacterFailedTraining';
import { ShowDetailsCharacterActions } from '../../../../Redux/Actions/ShowDetailsCharacter';
import { ArrowBack } from '@mui/icons-material';
import CharacterCreationView from './ShowDetailsCharacter/CharacterSection/CharacterCreationView';
import DashCharacterCreatorTitleBlock from './ShowDetailsCharacter/CharacterSection/DashCharacterCreatorTitleBlock';
import NewTrainingWorkflowGenerated from '../../../../Components/NewTrainingWorkflow/NewTrainingWorkflowGenerated';
import ManualWorkflowCharacterComponent from '../../../../Components/NewTrainingWorkflow/ManualWorkflowCharacterComponent';

export interface ShowCharactersProps {
  showId: string;
  userCanEdit: boolean;
  isScreen?: boolean;
}

export enum CharactersVisible {
  PUBLISHED,
  MY_CHARACTERS,
  GLOBAL_CHARACTERS,
}

export enum ShowDetailsCharacterView {
  LIBRARY = 'LIBRARY',
  ADD_CHARACTER = 'ADD_CHARACTER',
  TRAINING_EVAL = 'TRAINING_EVAL',
  CHARACTER_VIEW = 'CHARACTER_VIEW',
  TRAINING_IN_PROGRESS = 'TRAINING_IN_PROGRESS',
  TRAINING_FAILED = 'TRAINING_FAILED',
  TRAININED_COMPLETED = 'TRAININED_COMPLETED',
  REVIEW = 'REVIEW',
  REVIEW_PAGE = 'REVIEW_PAGE',
  CREATE_CHARACTER = 'CREATE_CHARACTER',
}

export enum CharacterDisplayViewStates {
  ALL_CHARACTERS,
  ADDED_TO_SHOW,
}

export enum CharacterViewType {
  CREATE,
  UPDATE,
  REVIEW,
}

const CharacterVisibilityBox = styled(Box)(({ theme }) => ({
  padding: '0px',
  cursor: 'pointer',
}));

const ShowDetailsCharacters: React.FC<ShowCharactersProps> = ({
  showId,
  userCanEdit,
  isScreen,
}) => {
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState<string>('');
  const [currentCharacterView, setCurrentCharacterView] = useState<CharactersVisible>(
    CharactersVisible.GLOBAL_CHARACTERS
  );
  const currentCharacter = useSelector((state: ReduxState) => state.character.currentCharacter);

  const setCurrentCharacter = (character: Character) => {
    dispatch({
      type: CharacterActions.SET_CURRENT_CHARACTER,
      payload: character ?? null,
    });
  };
  const showDetailsModalTab = useSelector(
    (state: ReduxState) => state.tabSelector.showDetailsModalTab
  );
  const showDetailsModalSize = useSelector(
    (state: ReduxState) => state.showDetailsModalSizeState.isModalSizeBig
  );

  const currentView = useSelector(
    (state: ReduxState) => state.showDetailsCharacterViewState.showDetailsCharacterViewState
  );

  useEffect(() => {
    if (showDetailsModalTab) {
      setCurrentCharacterView(CharactersVisible.GLOBAL_CHARACTERS);
      dispatch({
        type: TabSelectorActions.UPDATE_SHOW_DETAILS_MODAL_TAB,
        payload: undefined,
      });
    }
  }, [showDetailsModalTab]);

  const setCharacterView = (
    characterView: ShowDetailsCharacterView = ShowDetailsCharacterView.CHARACTER_VIEW
  ) => {
    dispatch({
      type: ShowDetailsCharacterActions.LIBRARY,
      payload: characterView,
    });
  };

  return (
    <Box
      style={{
        ...{
          boxShadow: 'none',
        },
        ...(!isScreen
          ? {
              marginTop: '1.38rem',
              width: showDetailsModalSize === true ? '95%' : '100%',
              height: showDetailsModalSize === true ? '510px' : '100%',
            }
          : {}),
      }}
    >
      {currentView === ShowDetailsCharacterView.LIBRARY && (
        <CharacterLibraryView
          setCurrentCharacter={setCurrentCharacter}
          showId={showId}
          setCharacterView={setCharacterView}
          currentCharacterView={currentCharacterView}
          setCurrentCharacterView={setCurrentCharacterView}
          userCanEdit={userCanEdit}
          isScreen={isScreen}
        />
      )}
      {currentView === ShowDetailsCharacterView.CHARACTER_VIEW && (
        <DashCharacterCreatorTitleBlock />
      )}
      {currentView === ShowDetailsCharacterView.CREATE_CHARACTER && (
        <>
          <CharacterCreationView />
        </>
      )}
      {currentView === ShowDetailsCharacterView.CHARACTER_VIEW ? (
        [
          CharacterStatus.CREATED,
          CharacterStatus.COMFY_GENERATING,
          CharacterStatus.IN_TRAINING,
          CharacterStatus.VALIDATION,
          CharacterStatus.TRAINING_ENQUEUED,
          CharacterStatus.DATASET_GENERATION_ENQUEUED,
          // @ts-ignore
        ].includes(currentCharacter?.state) ? (
          <CharacterInTraining character={currentCharacter} />
        ) : [
            CharacterStatus.QC_CREATED,
            CharacterStatus.TRAINED,
            CharacterStatus.PUBLISHED,
            CharacterStatus.REVIEWED,
            // @ts-ignore
          ].includes(currentCharacter?.state) ? (
          <CharacterTrainingCompleted character={currentCharacter} />
        ) : // @ts-ignore
        [CharacterStatus.FAILED].includes(currentCharacter?.state) ? (
          <CharacterFailedTraining character={currentCharacter} />
        ) : (
          CharacterStatus.COMFY_GENERATED === currentCharacter?.state && (
            <ManualWorkflowCharacterComponent character={currentCharacter} />
          )
        )
      ) : null}
    </Box>
  );
};

export default ShowDetailsCharacters;
