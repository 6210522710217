import { Step } from 'react-joyride';
import { CustomStep, FIRST_WALKTHROUGH_STEPS } from '../../FirstWalkthroughSteps';
import { WalkthroughActions, WalkthroughStateActionTypes } from '../Actions/WalkthroughActions';
import { trackEvent } from '../../../Utils/Analytics';
import { TrackingEvents } from '../../../Constants/TrackingEvents';
import { getStoryWalkthroughSteps } from '../../StoryTooltipsSteps';

export interface WalkthroughState {
  enabled: boolean; // enabled only once for first time user
  run: boolean; // whether it is running or done
  stepIndex: number; //current step index
  steps: CustomStep[]; // list of steps as accepted by react-joyride library
  pausedAt: number; // step at which next action needs to be disabled
  checkPoint: number; // marking checkpoint of any kind in context to the walkthrough
}

// currently assuming only FTUE walkthrough
export const InitialWalkthroughState: WalkthroughState = {
  enabled: false,
  run: false,
  stepIndex: 0,
  steps: getStoryWalkthroughSteps('', ''),
  pausedAt: 0,
  checkPoint: -1,
};

// handles current ongoing walkthrough steps
export const WalkthroughReducer = (
  state: WalkthroughState = InitialWalkthroughState,
  action: WalkthroughStateActionTypes
): WalkthroughState => {
  switch (action.type) {
    case WalkthroughActions.ENABLE_WALKTHROUGH:
      return { ...InitialWalkthroughState, enabled: true };
    case WalkthroughActions.SET_STEPS:
      return { ...state, steps: action.payload.steps };
    case WalkthroughActions.START:
      return { ...state, run: true };
    case WalkthroughActions.STOP:
      trackEvent(
        {
          event: TrackingEvents.ftueDone,
          properties: {
            stepIndex: state.stepIndex.toString(),
          },
        },
        'CREATOR'
      );
      return { ...state, run: false, enabled: false };
    case WalkthroughActions.SKIP:
      trackEvent(
        {
          event: TrackingEvents.ftueSkip,
          properties: {
            stepIndex: state.stepIndex.toString(),
          },
        },
        'CREATOR'
      );
      return { ...state, run: false, enabled: false };
    case WalkthroughActions.PAUSE:
      return { ...state, pausedAt: action.payload.pausedAt };
    case WalkthroughActions.RESUME:
      return { ...state, pausedAt: -1 };
    case WalkthroughActions.GO_TO_STEP:
      if (action.payload.stepIndex < state.stepIndex) {
        trackEvent(
          {
            event: TrackingEvents.ftueBack,
            properties: {
              stepIndex: state.stepIndex.toString(),
              // "stepTitle": state.steps[state.stepIndex].stepTitle
            },
          },
          'CREATOR'
        );
      } else {
        trackEvent(
          {
            event: TrackingEvents.ftueNext,
            properties: {
              stepIndex: state.stepIndex.toString(),
              // "stepTitle": state.steps[state.stepIndex].stepTitle
            },
          },
          'CREATOR'
        );
      }

      return { ...state, stepIndex: action.payload.stepIndex };
    case WalkthroughActions.UPDATE_STEP:
      const updatedSteps = [...state.steps];
      updatedSteps[action.payload.index] = action.payload.step;
      return { ...state, steps: updatedSteps };
    case WalkthroughActions.SET_CHECKPOINT:
      return { ...state, checkPoint: action.payload.checkPoint };
    default:
      return state;
  }
};
