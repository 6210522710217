import { Box } from '@mui/material';
import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { message } from 'antd';
import ScreensMenu from './components/ScreensMenu/ScreensMenu';
import SocialsWidget from './components/SocialsWidget/SocialsWidget';
import ReportBugAndFAQWidget from './components/BugAndFaq/ReportBugAndFAQWidget';
import { WidgetContainer } from './components/CommonStyledComponents';
import Logo from './components/Logo';
import { BugState } from '../../Models/BugReport';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';
import { TrackingProperties } from '../../Constants/TrackingProperties';
import JoinOurCommunityV2 from './components/JoinCommunityWidget/JoinOurCommunityV2';
import CreatorProgramWidget from './components/CreatorProgramWidget/CreatorProgramWidget';
import BugModalHome from '../../Screens/Studio/Canvas/BugModal/BugModalHome';

const LayoutSidebarV2: React.FC = () => {
  const theme = useTheme();
  const [bugModalOpen, setBugModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleToggleBugModal = () => {
    setBugModalOpen(!bugModalOpen);
  };

  const showToast = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
      duration: 1,
    });
  };

  const trackBugReport = (bugState: BugState) => {
    trackEvent(
      {
        event: TrackingEvents.bugReport,
        properties: {
          type: bugState.type,
          description: bugState.description,
          title: bugState.title,
          files: bugState.files.join(', '),
        } as TrackingProperties,
      },
      'CREATOR'
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '240px',
        height: window.innerHeight - 32,
        borderRadius: '16px',
        border: `1px solid ${theme.palette.surface.tertiary}`,
        backgroundColor: theme.palette.surface.secondary,
        position: 'fixed',
        top: 16,
        left: 16,
      }}
    >
      {bugModalOpen && (
        <BugModalHome
          showModal={bugModalOpen}
          onClose={handleToggleBugModal}
          showToast={showToast}
          trackBugEvent={trackBugReport}
        />
      )}
      <Logo />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'scroll',
          flexGrow: 1,
        }}
      >
        <div>
          <WidgetContainer hasBorder={false}>
            <ScreensMenu />
          </WidgetContainer>
        </div>

        <div>
          <div
            style={{
              padding: '0px 16px 16px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <CreatorProgramWidget />
            <JoinOurCommunityV2 />
          </div>

          <ReportBugAndFAQWidget toggleModal={handleToggleBugModal} />
          <SocialsWidget />
        </div>
      </Box>
    </Box>
  );
};

export default LayoutSidebarV2;
