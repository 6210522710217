import { CanvasStateActions } from '../Actions/Canvas';
import { CanvasState, InitialCanvasState } from '../../Models/CanvasState';

export interface CanvasStateAction {
  type: CanvasStateActions;
  payload?: any;
}

export const CanvasStateReducer = (
  state: CanvasState = InitialCanvasState,
  action: CanvasStateAction
): CanvasState => {
  switch (action.type) {
    case CanvasStateActions.SET_COPIED_SHAPE_IDS:
      return { ...state, copiedShapeIds: action.payload! };
    case CanvasStateActions.SET_CURRENT_IMAGE:
      return { ...state, currentSelectedImage: action.payload! };
    case CanvasStateActions.SET_CANVAS_STATE_SAVING:
      return { ...state, isCanvasStateSaving: action.payload! };
    case CanvasStateActions.SET_GENERATION_STATE_SAVING:
      return { ...state, isGenerationStateSaving: action.payload! };
    case CanvasStateActions.SET_DID_CANVAS_SAVED:
      return { ...state, isGenerationStateSaving: action.payload! };
    case CanvasStateActions.SET_DID_GENERATION_SAVED:
      return { ...state, isGenerationStateSaving: action.payload! };
    case CanvasStateActions.SET_SELECTED_STYLE:
      return {
        ...state,
        selectedStyle: action.payload.selectedStyle,
        selectedStyleName: action.payload.selectedStyleName,
      };
    case CanvasStateActions.SET_STYLE_LIVE_CONFIG:
      return { ...state, styleLiveConfig: action.payload! };
    case CanvasStateActions.SET_CURRENT_FRAMES:
      if (action.payload !== state.currentFrames) {
        return { ...state, currentFrames: action.payload! };
      } else return state;
    case CanvasStateActions.SET_SELECTED_FRAMES:
      if (action.payload !== state.selectedFrames) {
        return { ...state, selectedFrames: action.payload! };
      } else return state;
    case CanvasStateActions.SET_SELECTED_COMMENTS:
      if (action.payload !== state.selectedComments) {
        return { ...state, selectedComments: action.payload! };
      } else return state;
    case CanvasStateActions.SET_TAB_VALUE:
      return { ...state, tabValue: action.payload! };
    case CanvasStateActions.SET_MINIMAP_EXPANDED:
      return { ...state, minimapExpanded: action.payload! };
    case CanvasStateActions.SET_PUBLISH_PAGES:
      return { ...state, publishPages: action.payload! };
    case CanvasStateActions.UPDATE_PUBLISH_PAGE:
      return {
        ...state,
        publishPages: state.publishPages.map(page => {
          if (page.pageId === action.payload.pageId) {
            return { ...page, ...action.payload };
          } else return page;
        }),
      };
    case CanvasStateActions.SET_HAS_CANVAS_STATE_CHANGES:
      return { ...state, hasCanvasStateChanges: action.payload! };
    case CanvasStateActions.SET_HAS_GENERATION_STATE_CHANGES:
      return { ...state, hasGenerationStateChanges: action.payload! };
    case CanvasStateActions.SET_IS_RIGHT_MENU_OPEN:
      return { ...state, isRightMenuOpen: action.payload! };
    case CanvasStateActions.SET_SELECTED_SHAPES:
      return { ...state, selectedShapes: action.payload! };
    case CanvasStateActions.SET_IS_REFERENCE_LIBRARY_OPEN:
      return { ...state, isReferenceLibraryOpen: action.payload! };
    case CanvasStateActions.SET_CURRENT_STROKE_COLOR:
      return { ...state, currentStrokeColor: action.payload! };
    case CanvasStateActions.SET_CURRENT_MASK_STROKE_WIDTH:
      return { ...state, currentMaskStrokeWidth: action.payload! };
    case CanvasStateActions.SET_CURRENT_MAGIC_ERASE_STROKE_WIDTH:
      return { ...state, currentMagicEraseStrokeWidth: action.payload! };
    case CanvasStateActions.SET_CURRENT_PAINT_STROKE_WIDTH:
      return { ...state, currentPaintStrokeWidth: action.payload! };
    case CanvasStateActions.SET_CURRENT_BRUSH_STROKE_WIDTH:
      return { ...state, currentBrushStrokeWidth: action.payload! };
    case CanvasStateActions.SET_CURRENT_ERASER_WIDTH:
      return { ...state, currentEraserWidth: action.payload! };
    case CanvasStateActions.SET_CURRENT_OPACITY:
      return { ...state, currentOpacity: action.payload! };
    case CanvasStateActions.SET_LAST_9_COLORS:
      return { ...state, last9Colors: action.payload! };
    case CanvasStateActions.SET_CANVAS_STATUS:
      return { ...state, canvasStatus: action.payload! };
    case CanvasStateActions.SET_ALL_SPEECH_BUBBLES:
      return { ...state, allSpeechBubbles: action.payload! };
    case CanvasStateActions.SET_PANEL_TEMPLATES:
      return { ...state, panelTemplates: action.payload! };
    case CanvasStateActions.SET_IS_PANEL_TEMPLATES_OPEN:
      return { ...state, isPanelTemplatesOpen: action.payload! };
    case CanvasStateActions.SET_IS_SHORTCUTS_MODAL_OPEN:
      return { ...state, isShortcutsModalOpen: action.payload! };
    case CanvasStateActions.SET_CURRENT_SELECTED_RIGHT_MENU_TOOL:
      return { ...state, currentSelectedRightMenuTool: action.payload! };
    case CanvasStateActions.SET_CURRENT_BRUSH_TYPE:
      return { ...state, currentBrushType: action.payload! };
    case CanvasStateActions.SET_CURRENT_BRUSH_DISTANCE:
      return { ...state, currentBrushDistance: action.payload! };
    case CanvasStateActions.SET_CURRENT_BRUSH_HARDNESS:
      return { ...state, currentBrushHardness: action.payload! };
    case CanvasStateActions.SET_PATTERN_IMAGES:
      return { ...state, patternImages: action.payload! };
    case CanvasStateActions.SET_IS_PUBLISH_SCREEN_LOADING:
      return { ...state, isPublishScreenLoading: action.payload! };
    case CanvasStateActions.SET_IS_PICKER_OPEN:
      return { ...state, isPickerOpen: action.payload! };
    case CanvasStateActions.SET_RIGHT_MENU_TAB:
      return { ...state, activeRightTab: action.payload! };
    case CanvasStateActions.SET_IS_FOCUS_MODE:
      return { ...state, isFocusMode: action.payload! };
    case CanvasStateActions.SET_IS_PREVIOUS_EPISODE_PUBLISHED:
      return { ...state, isPreviousEpisodePublished: action.payload! };
    case CanvasStateActions.SET_SHOW_EMPTY_FRAME_WARNING:
      return { ...state, showEmptyFrameWarning: action.payload! };
    default:
      return state;
  }
};
