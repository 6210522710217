import { processValue } from './mathUtils';
import {
  DownloadImageRequest,
  ImageOperation,
  SavedImagesInitImages,
  SaveImageBase64Request,
  SaveImageResponse,
  SaveImageType,
} from '../Models/SaveImage';
import { downloadImageFormatted, saveBase64Image } from '../Requests/Studio/GenerateImage';
import { TLShapeId } from '@dashtoon/tldraw';
import { addDownloadAction } from '../Requests/Studio/ImageActions';
import {
  DEFAULT_IMAGE_EXPORT_FORMAT,
  DEFAULT_IMAGE_EXPORT_QUALITY,
} from '../Constants/ImageExport';
import {
  BANNER_THUMBNAIL_V2_ASPECT_RATIO,
  CONTINUE_READING_THUMBNAIL_V2_ASPECT_RATIO,
  WIDGET_THUMBNAIL_V2_ASPECT_RATIO,
} from '../Constants/Thumbnails';
import { getCDNImageUrl } from './cdnImage';
import { Crop } from '../Models/GenerateImageV2';

export interface CropV2 {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface MaxDimensions {
  max: number;
  min: number;
  step: number;
}

export const hexToRgb = (hex: string) => {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  return {
    red: parseInt(hex.slice(0, 2), 16),
    green: parseInt(hex.slice(2, 4), 16),
    blue: parseInt(hex.slice(4, 6), 16),
  };
};

export async function urlToBase64Image(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        const base64String = reader.result as string;
        const base64StringWithoutPrefix = base64String.substring(base64String.indexOf(',') + 1);
        resolve(base64StringWithoutPrefix);
      } else {
        reject('Error converting image to base64.');
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function urlToBase64ImageFull(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        const base64String = reader.result as string;
        resolve(base64String);
      } else {
        reject('Error converting image to base64.');
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function loadImage(image: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = error => {
      reject(new Error('Failed to load the image.'));
    };
    img.src = image;
  });
}

function loadImageV2(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export function downloadImageV2(base64Image: string) {
  let link = document.createElement('a');
  link.href = base64Image;
  link.download = `dash-img-${Date.now()}.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadImage(url: string, fileName: number, id: number, showId: string) {
  const downloadImageResponse = await downloadImageFormatted({
    imageURL: url,
    contentType: `image/png`,
    fileExtension: 'png',
  } as DownloadImageRequest);
  const image = await fetch(downloadImageResponse.imageURL);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  let link = document.createElement('a');
  link.href = imageURL;
  link.download = fileName.toString();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  await addDownloadAction(id, showId);
}

export const hex2rgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
};

export const getBase64ImageSize = (base64Data: string): any => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height, image: img });
    };
    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };
    img.crossOrigin = 'Anonymous';
    img.src = base64Data;
  });
};

export const getBase64ResizedImage = async (base64Data: string, width: number, height: number) => {
  const newImage: any = await resizeBase64Image(base64Data, width, height);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };
    img.crossOrigin = 'Anonymous';
    img.src = newImage;
  });
};

export const getCroppedThumbnails = async (image: SaveImageResponse): Promise<string[]> => {
  const height = image.imageHeight ? image.imageHeight : 480;
  const width = image.imageWidth ? image.imageWidth : 400;
  const ar = width / height;

  // continue reading thumbnail
  let newAR = CONTINUE_READING_THUMBNAIL_V2_ASPECT_RATIO;
  let newWidth = ar >= 1 ? height : width;
  let newHeight = ar >= 1 ? height : width;
  const continueReadingThumbnail = await getCroppedBase64Image(image.imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: ar > 1 ? (width - height) / 2 : 0,
    y: 0,
  });

  // banner thumbnail
  newAR = BANNER_THUMBNAIL_V2_ASPECT_RATIO;
  newWidth = ar > newAR ? height * newAR : width;
  newHeight = ar > newAR ? height : width / newAR;
  const bannerThumbnail = await getCroppedBase64Image(image.imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: 0,
    y: 0,
  });

  // widget thumbnail
  newAR = WIDGET_THUMBNAIL_V2_ASPECT_RATIO;
  newWidth = ar < newAR ? width : height * newAR;
  newHeight = ar < newAR ? width / newAR : height;
  const widgetThumbnail = await getCroppedBase64Image(image.imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: ar > 1 ? (width - height) / 2 : 0,
    y: 0,
  });

  return [continueReadingThumbnail, bannerThumbnail, widgetThumbnail];
};

export const getCroppedThumbnailsForImageUrl = async (imageURL: string): Promise<string[]> => {
  const cdnImageUrl = getCDNImageUrl(imageURL, undefined);
  const dimension = await getBase64ImageSize(cdnImageUrl);
  const height = dimension.height;
  const width = dimension.width;
  const ar = width / height;

  // continue reading thumbnail
  let newAR = CONTINUE_READING_THUMBNAIL_V2_ASPECT_RATIO;
  let newWidth = ar >= 1 ? height : width;
  let newHeight = ar >= 1 ? height : width;
  const continueReadingThumbnail = await getCroppedBase64Image(imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: ar > 1 ? (width - height) / 2 : 0,
    y: 0,
  });

  // banner thumbnail
  newAR = BANNER_THUMBNAIL_V2_ASPECT_RATIO;
  newWidth = ar > newAR ? height * newAR : width;
  newHeight = ar > newAR ? height : width / newAR;
  const bannerThumbnail = await getCroppedBase64Image(imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: 0,
    y: 0,
  });

  // widget thumbnail
  newAR = WIDGET_THUMBNAIL_V2_ASPECT_RATIO;
  newWidth = ar < newAR ? width : height * newAR;
  newHeight = ar < newAR ? width / newAR : height;
  const widgetThumbnail = await getCroppedBase64Image(imageURL, {
    unit: '%',
    width: newWidth,
    height: newHeight,
    x: ar > 1 ? (width - height) / 2 : 0,
    y: 0,
  });

  return [continueReadingThumbnail, bannerThumbnail, widgetThumbnail];
};

export const getCroppedBase64Image = async (imageURL: string, crop: Crop): Promise<string> => {
  const dimensions: any = await getBase64ImageSize(imageURL);
  const originalCanvas = document.createElement('canvas');
  originalCanvas.width = dimensions.width;
  originalCanvas.height = dimensions.height;
  const canvas = document.createElement('canvas');
  canvas.width = Math.floor(crop.width);
  canvas.height = Math.floor(crop.height);
  const ctx = canvas.getContext('2d');
  let base64Image: string = '';
  if (ctx) {
    ctx.drawImage(
      dimensions.image,
      Math.floor(crop.x),
      Math.floor(crop.y),
      Math.floor(crop.width),
      Math.floor(crop.height),
      0,
      0,
      Math.floor(crop.width),
      Math.floor(crop.height)
    );
    base64Image = canvas.toDataURL(
      `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
      DEFAULT_IMAGE_EXPORT_QUALITY
    );
  }
  return base64Image.substring(base64Image.indexOf(',') + 1);
};

export async function combineImagesWithFrame(
  clippedImageBase64: string,
  originalImageProps: Crop,
  frameProps: Crop
): Promise<string> {
  const dimensions: any = await getBase64ImageSize(clippedImageBase64);

  const clippedImage = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Load the clipped image
  return await new Promise<string>(resolve => {
    clippedImage.onload = () => {
      canvas.width = frameProps.width;
      canvas.height = frameProps.height;

      if (ctx) {
        // Fill canvas with white color
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the clipped image on the canvas at the provided position
        ctx.drawImage(
          clippedImage,
          originalImageProps.x < 0 ? 0 : originalImageProps.x,
          originalImageProps.y < 0 ? 0 : originalImageProps.y,
          dimensions.width,
          dimensions.height
        );
      }
      // Convert the canvas to base64 image
      const resultBase64 = canvas.toDataURL(
        `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
        DEFAULT_IMAGE_EXPORT_QUALITY
      );

      resolve(resultBase64);
    };

    clippedImage.src = clippedImageBase64;
  });
}

export interface ImageWithPosition {
  imageBase64: string;
  id: TLShapeId;
  imageProps: CropV2;
}

export async function combineMultipleImagesWithFrame(
  frameProps: CropV2,
  images: ImageWithPosition[]
): Promise<string> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Load all the images and get their dimensions
  const loadedImages: HTMLImageElement[] = await Promise.all(
    images.map(imageData => loadImageV2(imageData.imageBase64))
  );

  // Calculate the size of the canvas based on the frameProps
  const canvasWidth = frameProps.width;
  const canvasHeight = frameProps.height;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  if (ctx) {
    // Fill canvas with white color
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Draw each image on the canvas at the provided positions
    for (const imageData of images) {
      const { imageBase64, imageProps } = imageData;
      const dimensions = await getBase64ImageSize(imageBase64);
      const image = loadedImages.find(img => img.src === imageBase64);

      if (image) {
        ctx.drawImage(
          image,
          imageProps.x < 0 ? 0 : imageProps.x,
          imageProps.y < 0 ? 0 : imageProps.y,
          dimensions.width,
          dimensions.height
        );
      }
    }
  }

  // Convert the canvas to base64 image
  const resultBase64 = canvas.toDataURL(
    `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
    DEFAULT_IMAGE_EXPORT_QUALITY
  );
  return resultBase64;
}

export const getImageFromMaxScaling = (width: number, height: number, maxDimension: number) => {
  if (width > height) {
    if (width <= maxDimension) return { width, height };
    return { width: maxDimension, height: (height / width) * maxDimension };
  } else {
    if (height <= maxDimension) return { width, height };
    return { width: (width / height) * maxDimension, height: maxDimension };
  }
};

export const getScaledDimensions = (
  width: number,
  height: number,
  maxDimensions: MaxDimensions
) => {
  const imageDimensions = getImageFromMaxScaling(width, height, maxDimensions.max);
  return {
    width: processValue(
      imageDimensions.width,
      maxDimensions.min,
      maxDimensions.max,
      maxDimensions.step
    ),
    height: processValue(
      imageDimensions.height,
      maxDimensions.min,
      maxDimensions.max,
      maxDimensions.step
    ),
  };
};

export const getScaledDimensionsFromImage = (
  width: number,
  height: number,
  maxDimensions: MaxDimensions
) => {
  let getScale: number = width > height ? maxDimensions.max / width : maxDimensions.max / height;
  return {
    width: processValue(width * getScale, maxDimensions.min, maxDimensions.max, maxDimensions.step),
    height: processValue(
      height * getScale,
      maxDimensions.min,
      maxDimensions.max,
      maxDimensions.step
    ),
  };
};

export const resizeBase64Image = (base64: string, newWidth: number, newHeight: number) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      resolve(
        canvas.toDataURL(`image/${DEFAULT_IMAGE_EXPORT_FORMAT}`, DEFAULT_IMAGE_EXPORT_QUALITY)
      );
    };

    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };

    img.crossOrigin = 'Anonymous';

    img.src = base64;
  });
};

export const processImageMask = async (
  initImage: string,
  initMaks: string,
  width: number,
  height: number
) => {
  return new Promise(resolve => {
    const canvas: any = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.crossOrigin = 'Anonymous';

    let imagesLoaded = 0;

    image.onload = function () {
      imagesLoaded++;
      ctx.drawImage(image, 0, 0);
      if (imagesLoaded == 2) {
        process();
      }
    };
    image.src = initImage;

    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = canvas.width;
    maskCanvas.height = canvas.height;
    const maskCtx: any = maskCanvas.getContext('2d');

    const mask = new Image();
    mask.crossOrigin = 'Anonymous';
    mask.onload = function () {
      imagesLoaded++;
      maskCtx.drawImage(mask, 0, 0);
      if (imagesLoaded == 2) {
        process();
      }
    };
    mask.src = initMaks;

    function process() {
      const idata = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
      const data32 = new Uint32Array(idata.data.buffer);
      let i = 0;
      while (i < data32.length) {
        data32[i] = data32[i++] << 8;
      }
      maskCtx.putImageData(idata, 0, 0);
      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(maskCanvas, 0, 0);
      resolve(canvas.toDataURL());
    }
  });
};

// Function to process the image mask
export const processImageMaskV2 = async (
  initImage: string,
  initMask: string,
  x: number,
  y: number,
  width: number,
  height: number
): Promise<{ newImage: unknown; offsetX: number; offsetY: number }> => {
  const extractedImage = await processImageMask(initImage, initMask, width, height);

  return new Promise(resolve => {
    const image = new Image();
    const mask = new Image();
    image.crossOrigin = 'Anonymous';
    mask.crossOrigin = 'Anonymous';

    let imagesLoaded = 0;

    image.onload = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) process();
    };
    mask.onload = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) process();
    };

    image.src = extractedImage as string;
    mask.src = initMask;

    function process() {
      // Create a canvas for the mask
      const maskCanvas = document.createElement('canvas');
      maskCanvas.width = width;
      maskCanvas.height = height;
      const maskCtx = maskCanvas.getContext('2d')!;
      maskCtx.drawImage(mask, 0, 0);

      // Calculate the bounding box of the non-transparent (white) pixels in the mask
      const idata = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
      const data = idata.data;

      let minX = maskCanvas.width;
      let minY = maskCanvas.height;
      let maxX = 0;
      let maxY = 0;

      for (let i = 0; i < data.length; i += 4) {
        const r = data[i]; // Red channel
        const g = data[i + 1]; // Green channel
        const b = data[i + 2]; // Blue channel
        const alpha = data[i + 3]; // Alpha channel

        // Check if the pixel is white (mask area) and non-transparent
        if (r === 255 && g === 255 && b === 255 && alpha > 0) {
          const pixelX = (i / 4) % maskCanvas.width;
          const pixelY = Math.floor(i / 4 / maskCanvas.width);

          if (pixelX < minX) minX = pixelX;
          if (pixelY < minY) minY = pixelY;
          if (pixelX > maxX) maxX = pixelX;
          if (pixelY > maxY) maxY = pixelY;
        }
      }

      const newWidth = maxX - minX + 1;
      const newHeight = maxY - minY + 1;

      // Create a new canvas with the calculated dimensions
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d')!;

      // Draw the image on the new canvas with adjusted position
      ctx.drawImage(image, -x - minX, -y - minY);
      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(maskCanvas, -minX, -minY);

      resolve({ newImage: canvas.toDataURL(), offsetX: minX, offsetY: minY });
    }
  });
};
export const deleteMaskedAreaFromImage = async (
  initImage: string,
  initMask: string,
  width: number,
  height: number
) => {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.crossOrigin = 'Anonymous';

    let imagesLoaded = 0;

    image.onload = function () {
      imagesLoaded++;
      ctx!.drawImage(image, 0, 0);
      if (imagesLoaded === 2) {
        process();
      }
    };
    image.src = initImage;

    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = canvas.width;
    maskCanvas.height = canvas.height;
    const maskCtx = maskCanvas.getContext('2d');

    const mask = new Image();
    mask.crossOrigin = 'Anonymous';
    mask.onload = function () {
      imagesLoaded++;
      maskCtx!.drawImage(mask, 0, 0);
      if (imagesLoaded === 2) {
        process();
      }
    };
    mask.src = initMask;

    function process() {
      const idata = maskCtx!.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
      const data32 = new Uint32Array(idata.data.buffer);
      let i = 0;
      while (i < data32.length) {
        data32[i] = data32[i++] << 8;
      }
      maskCtx!.putImageData(idata, 0, 0);
      ctx!.globalCompositeOperation = 'destination-out';
      ctx!.drawImage(maskCanvas, 0, 0);
      resolve(canvas.toDataURL());
    }
  });
};

export const changeColorOfMask = (
  src: string,
  initColor: [number, number, number, number],
  finalColor: [number, number, number, number]
): Promise<string> => {
  return new Promise(resolve => {
    const image = new window.Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixelData = imageData.data;
        for (let i = 0; i < pixelData.length!; i += 4) {
          if (
            pixelData &&
            pixelData[i] === initColor[0] &&
            pixelData[i + 1] === initColor[1] &&
            pixelData[i + 2] === initColor[2] &&
            pixelData[i + 3] === initColor[3]
          ) {
            pixelData[i] = finalColor[0];
            pixelData[i + 1] = finalColor[1];
            pixelData[i + 2] = finalColor[2];
            pixelData[i + 3] = finalColor[3];
          }
        }
        context?.putImageData(imageData as ImageData, 0, 0);
        return resolve(
          canvas.toDataURL(`image/${DEFAULT_IMAGE_EXPORT_FORMAT}`, DEFAULT_IMAGE_EXPORT_QUALITY)
        );
      }
      return resolve(src);
    };
    image.src = src;
  });
};

export const changeColorOfMaskLoaded = (
  image: any,
  initColor: [number, number, number, number],
  finalColor: [number, number, number, number]
): Promise<any> => {
  return new Promise(async resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(image, 0, 0);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const pixelData = imageData.data;
      for (let i = 0; i < pixelData.length!; i += 4) {
        if (
          pixelData &&
          pixelData[i] === initColor[0] &&
          pixelData[i + 1] === initColor[1] &&
          pixelData[i + 2] === initColor[2] &&
          pixelData[i + 3] === initColor[3]
        ) {
          pixelData[i] = finalColor[0];
          pixelData[i + 1] = finalColor[1];
          pixelData[i + 2] = finalColor[2];
          pixelData[i + 3] = finalColor[3];
        }
      }
      context?.putImageData(imageData as ImageData, 0, 0);
      resolve(
        await loadImage(
          canvas.toDataURL(`image/${DEFAULT_IMAGE_EXPORT_FORMAT}`, DEFAULT_IMAGE_EXPORT_QUALITY)
        )
      );
    }
    return resolve(image);
  });
};

export const getSizeOfMaks = (
  src: string,
  color: [number, number, number, number]
): Promise<number> => {
  return new Promise(resolve => {
    const image = new window.Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixelData = imageData.data;
        let size = 0;
        for (let i = 0; i < pixelData.length; i += 4) {
          if (pixelData[i] > 0) {
            size = size + 0.1;
          }
        }
        return resolve(size);
      }
      return resolve(0);
    };
    image.src = src;
  });
};

export const getPixelArray = (src: string): Promise<Uint8ClampedArray | undefined> => {
  return new Promise(resolve => {
    const image = new window.Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixelData = imageData.data;
        return resolve(pixelData);
      }
      return resolve(new Uint8ClampedArray());
    };
    image.src = src;
  });
};

export const hexToRgba = (hex: string, opacity: number) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
};

export function isImageInFrame(
  frame: { x: any; y: number; width: number; height: number },
  image: { x: number; y: number; width: number; height: number }
): boolean {
  const frameLeft = frame.x;
  const frameRight = frame.x + frame.width; // Assuming frame has a width property
  const frameTop = frame.y;
  const frameBottom = frame.y + frame.height; // Assuming frame has a height property

  const imageLeft = image.x;
  const imageRight = image.x + image.width; // Assuming image has a width property
  const imageTop = image.y;
  const imageBottom = image.y + image.height; // Assuming image has a height property

  // Check if any part of the image is within the frame
  if (
    imageRight >= frameLeft &&
    imageLeft <= frameRight &&
    imageBottom >= frameTop &&
    imageTop <= frameBottom
  ) {
    return true;
  }

  return false;
}

export const getBase64Image = async (initImage: string): Promise<string> => {
  const loadedImage = await getBase64ImageSize(initImage!);
  const canvas = document.createElement('canvas');
  canvas.width = loadedImage.width;
  canvas.height = loadedImage.height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(loadedImage.image, 0, 0, loadedImage.width, loadedImage.height);
  }
  return canvas.toDataURL(`image/${DEFAULT_IMAGE_EXPORT_FORMAT}`, DEFAULT_IMAGE_EXPORT_QUALITY);
};

export async function changeGreenToMask(base64ImageMask: string): Promise<string> {
  // Load the base64 image mask
  const image = await loadImage(base64ImageMask);

  // Create a canvas to manipulate the image
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return base64ImageMask;
  }
  // Draw the image on the canvas
  ctx.drawImage(image, 0, 0);

  // Get the image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Process the image data
  for (let i = 0; i < imageData.data.length; i += 4) {
    const red = imageData.data[i];
    const green = imageData.data[i + 1];
    const blue = imageData.data[i + 2];
    const alpha = imageData.data[i + 3] / 255; // Normalize alpha to a range of 0 to 1

    // Check if the pixel is green (you can adjust the tolerance)
    if (green > red && green > blue && alpha > 0.5) {
      // Change the green area to white (255, 255, 255) with the same opacity
      imageData.data[i] = 255; // Red
      imageData.data[i + 1] = 255; // Green
      imageData.data[i + 2] = 255; // Blue
      imageData.data[i + 3] = alpha * 255; // Restore alpha
    } else {
      // Change non-green area to black (0, 0, 0) with full opacity
      imageData.data[i] = 0; // Red
      imageData.data[i + 1] = 0; // Green
      imageData.data[i + 2] = 0; // Blue
      imageData.data[i + 3] = 255; // Full opacity
    }
  }

  // Put the modified image data back on the canvas
  ctx.putImageData(imageData, 0, 0);

  // Convert the canvas to a base64 image
  const modifiedBase64 = canvas.toDataURL(
    `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
    DEFAULT_IMAGE_EXPORT_QUALITY
  );

  return modifiedBase64;
}

export async function changeBlueToMask(base64ImageMask: string): Promise<string> {
  // Load the base64 image mask
  const image = await loadImage(base64ImageMask);

  // Create a canvas to manipulate the image
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return base64ImageMask;
  }
  // Draw the image on the canvas
  ctx.drawImage(image, 0, 0);

  // Get the image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Process the image data
  for (let i = 0; i < imageData.data.length; i += 4) {
    const red = imageData.data[i];
    const green = imageData.data[i + 1];
    const blue = imageData.data[i + 2];
    const alpha = imageData.data[i + 3] / 255; // Normalize alpha to a range of 0 to 1

    // Check if the pixel is green (you can adjust the tolerance)
    if (blue > red && blue > green && alpha > 0.5) {
      // Change the green area to white (255, 255, 255) with the same opacity
      imageData.data[i] = 255; // Red
      imageData.data[i + 1] = 255; // Green
      imageData.data[i + 2] = 255; // Blue
      imageData.data[i + 3] = alpha * 255; // Restore alpha
    } else {
      // Change non-green area to black (0, 0, 0) with full opacity
      imageData.data[i] = 0; // Red
      imageData.data[i + 1] = 0; // Green
      imageData.data[i + 2] = 0; // Blue
      imageData.data[i + 3] = 255; // Full opacity
    }
  }

  // Put the modified image data back on the canvas
  ctx.putImageData(imageData, 0, 0);

  // Convert the canvas to a base64 image
  const modifiedBase64 = canvas.toDataURL(
    `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
    DEFAULT_IMAGE_EXPORT_QUALITY
  );

  return modifiedBase64;
}

export function rotatePoint(x: number, y: number, angle: number) {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const newX = x * cos - y * sin;
  const newY = x * sin + y * cos;
  return { x: newX, y: newY };
}

export function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = e => {
      reject(e);
    };

    const reader = new FileReader();
    reader.onload = e => {
      img.src = (e.target as FileReader).result as string;
    };
    reader.onerror = e => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}

export function resizeImageAndReturnBase64(
  input: File | string
): Promise<{ base64: string; width: number; height: number }> {
  return new Promise((resolve, reject) => {
    // Determine if the input is a File object or a base64 string
    const isBase64 = typeof input === 'string' && input.startsWith('data:');
    const src = isBase64 ? input : URL.createObjectURL(input as File);

    const img = new Image();
    img.onload = () => {
      // Check if resizing is necessary
      let width = img.width;
      let height = img.height;
      const needsResize = width % 8 !== 0 || height % 8 !== 0;

      if (!needsResize) {
        // Return original image data if no resize is needed
        resolve({
          base64: isBase64 ? (input as string) : img.src,
          width: width,
          height: height,
        });
        return;
      }

      // Resize the image
      width = width + ((8 - (width % 8)) % 8);
      height = height + ((8 - (height % 8)) % 8);

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas to base64
        const resizedImageData = canvas.toDataURL(
          `image/${DEFAULT_IMAGE_EXPORT_FORMAT}`,
          DEFAULT_IMAGE_EXPORT_QUALITY
        );
        resolve({
          base64: resizedImageData,
          width: width,
          height: height,
        });
      } else {
        reject(new Error('Failed to get 2D context'));
      }

      // Revoke the object URL if input is a File
      if (!isBase64) {
        URL.revokeObjectURL(src);
      }
    };
    img.onerror = e => reject(e);
    img.src = src;
  });
}
