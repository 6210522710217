export enum UserEnabledFeaturesActions {
  SET_PWW = 'SET_PWW',
  SET_SEGMENTATION = 'SET_SEGMENTATION',
  SET_LORA_WEIGHTS = 'SET_LORA_WEIGHTS',
  SET_CREATE_CHARACTER = 'SET_CREATE_CHARACTER',
  SET_PIX2PIX = 'SET_PIX2PIX',
  SET_ENABLE_NSFW = 'SET_ENABLE_NSFW',
  SET_NEGATIVE_EMBEDDING = 'SET_NEGATIVE_EMBEDDING',
  SET_MAGIC_ERASER = 'SET_MAGIC_ERASER',
  SET_USER_FEATURE_ENABLED = 'SET_USER_FEATURE_ENABLED',
  RESET_USER_FEATURE_ENABLED = 'RESET_USER_FEATURE_ENABLED',
  SET_ENABLE_MULTI_INPAINT = 'SET_ENABLE_MULTI_INPAINT',
  SET_ENABLE_DIFFUSERS_INPAINT = 'SET_ENABLE_DIFFUSERS_INPAINT',
  SET_ENABLE_HIGH_RESOLUTION_GENERATION = 'SET_ENABLE_HIGH_RESOLUTION_GENERATION',
  SET_IMG_TO_PROMPT_POC = 'SET_IMG_TO_PROMPT_POC',
  SET_ENABLE_CLIP_SKIP = 'SET_ENABLE_CLIP_SKIP',
  SET_ENABLE_HIGH_RES_FIX = 'SET_ENABLE_HIGH_RES_FIX',
  SET_ALLOW_DEFAULT_CANVAS = 'SET_ALLOW_DEFAULT_CANVAS',
  SET_ALLOW_POST_PROCESS = 'SET_ALLOW_POST_PROCESS',
  SET_ALLOW_AUTO_INPAINT = 'SET_ALLOW_AUTO_INPAINT',
  SET_ENABLE_LIVE_MODE = 'SET_ENABLE_LIVE_MODE',
  SET_IS_DASHTOON_USER = 'SET_IS_DASHTOON_USER',
  SET_ENABLE_NEW_PANELLING = 'SET_ENABLE_NEW_PANELLING',
  SET_DEFAULT_TURBO_DISABLED = 'SET_DEFAULT_TURBO_DISABLED',
  SET_DEMO_FEATURE = 'SET_DEMO_FEATURE',
  SET_V3_CANVAS_BETA_TEST = 'SET_V3_CANVAS_BETA_TEST',
  SET_IS_OLD_PAYING_USER = 'SET_IS_OLD_PAYING_USER',
  SET_ENABLE_CANVAS_V3 = 'SET_ENABLE_CANVAS_V3',
  SET_LOADING = 'SET_LOADING',
}
