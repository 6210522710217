class AlertStyle {
  private baseStyle = {
    borderRadius: 24,
    border: '1px solid',
    filter: 'drop-shadow(0px 4px 8px rgba(48, 48, 48, 0.12))',
    backdropFilter: 'blur(16px)',
    height: 40,
    maxWidth: 800,
  };

  private createStyle(background: string, color: string, borderColor: string) {
    return {
      ...this.baseStyle,
      background,
      color,
      borderColor,
    };
  }

  public get warningStyle() {
    return this.createStyle(
      'rgba(217, 143, 25, 0.1)',
      'rgba(217, 143, 25, 1)',
      'rgba(217, 143, 25, 0.6)'
    );
  }

  public get successStyle() {
    return this.createStyle(
      'rgba(0, 192, 66, 0.1)',
      'rgba(0, 192, 66, 1)',
      'rgba(0, 192, 66, 0.6)'
    );
  }

  public get loadingStyle() {
    return this.createStyle(
      'rgba(48, 144, 252, 0.1)',
      'rgba(48, 144, 252, 1)',
      'rgba(48, 144, 252, 0.6)'
    );
  }

  public get errorStyle() {
    return this.createStyle(
      'rgba(250, 33, 72, 0.1)',
      'rgba(250, 33, 72, 1)',
      'rgba(250, 33, 72, 0.6)'
    );
  }
}

const alertStyle = new AlertStyle();

export const getWarningAlertStyle = () => alertStyle.warningStyle;
export const getSuccessStyle = () => alertStyle.successStyle;
export const getLoadingStyle = () => alertStyle.loadingStyle;
export const getErrorStyle = () => alertStyle.errorStyle;
