import { FunctionComponent, SVGProps, useContext, useEffect, useState } from 'react';
import { ReactComponent as HomeIcon } from '../Assets/Icons/home.svg';
import { ReactComponent as GetInspiredIcon } from '../Assets/Icons/dash_explorer.svg';
import { ReactComponent as TutorialsIcon } from '../Assets/Icons/tutorials2.svg';
import { ReactComponent as MyShowsIcon } from '../Assets/Icons/my_shows.svg';
import { ReactComponent as PopularShowsIcon } from '../Assets/Icons/crown.svg';
import { ReactComponent as DashverseIcon } from '../Assets/Icons/dashverse.svg';
import { ReactComponent as MyCharactersIcon } from '../Assets/Icons/smiling-face.svg';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../Provider/AuthProvider';

export enum TabName {
  HOME = 'Home',
  MY_SHOWS = 'My Profile',
  MY_CHARACTERS = 'My Characters',
  DASHVERSE = 'Dashverse',
  GET_INSPIRED = 'Get Inspired',
  POPULAR_SHOWS = 'Popular Dashtoons',
  TUTORIALS = 'Tutorials',
}

export interface HomeScreenTab {
  id: TabName;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  navigateTo: string;
  display: string;
  externalLink: boolean;
}

export const HomeTab: HomeScreenTab = {
  id: TabName.HOME,
  icon: HomeIcon,
  navigateTo: '/',
  display: TabName.HOME.valueOf(),
  externalLink: false,
};

export const MyShowsTab: HomeScreenTab = {
  id: TabName.MY_SHOWS,
  icon: MyShowsIcon,
  navigateTo: '/dashboard',
  display: TabName.MY_SHOWS.valueOf(),
  externalLink: false,
};

export const MyCharactersTab: HomeScreenTab = {
  id: TabName.MY_CHARACTERS,
  icon: MyCharactersIcon,
  navigateTo: '/characters/my-characters',
  display: TabName.MY_CHARACTERS.valueOf(),
  externalLink: false,
};

export const DashverseTab: HomeScreenTab = {
  id: TabName.DASHVERSE,
  icon: DashverseIcon,
  navigateTo: '/dashverse',
  display: TabName.DASHVERSE.valueOf(),
  externalLink: false,
};

export const GetInspiredTab: HomeScreenTab = {
  id: TabName.GET_INSPIRED,
  icon: GetInspiredIcon,
  navigateTo: '/get_inspired',
  display: TabName.GET_INSPIRED.valueOf(),
  externalLink: false,
};

export const PopularShowsTab: HomeScreenTab = {
  id: TabName.POPULAR_SHOWS,
  icon: PopularShowsIcon,
  navigateTo: '/popular_shows',
  display: TabName.POPULAR_SHOWS.valueOf(),
  externalLink: false,
};

export const TutorialsTab: HomeScreenTab = {
  id: TabName.TUTORIALS,
  icon: TutorialsIcon,
  navigateTo: 'https://www.youtube.com/playlist?list=PLf5_EN2BltjD9DTK5rxaGQLnjurSdcVOy',
  display: TabName.TUTORIALS.valueOf(),
  externalLink: true,
};

interface HomeScreenTabState {
  currentTab: HomeScreenTab;
  setCurrentTab: (value: ((prevState: HomeScreenTab) => HomeScreenTab) | HomeScreenTab) => void;
  allTabs: HomeScreenTab[];
}

export function useHomeScreenTab(): HomeScreenTabState {
  const defaultTabs: HomeScreenTab[] = [
    HomeTab,
    MyShowsTab,
    MyCharactersTab,
    TutorialsTab,
    GetInspiredTab,
    PopularShowsTab,
  ];
  const location = useLocation();
  const { currentUser, loading } = useContext(AuthContext);

  const getScreenTab = (): HomeScreenTab => {
    if (location.pathname.includes(MyShowsTab.navigateTo)) {
      return MyShowsTab;
    } else if (location.pathname.includes(GetInspiredTab.navigateTo)) {
      return GetInspiredTab;
    } else if (location.pathname.includes(PopularShowsTab.navigateTo)) {
      return PopularShowsTab;
    } else if (location.pathname.includes(TutorialsTab.navigateTo)) {
      return TutorialsTab;
    } else if (location.pathname.includes(DashverseTab.navigateTo)) {
      return DashverseTab;
    } else if (location.pathname.includes('/characters')) {
      return MyCharactersTab;
    }
    return HomeTab;
  };
  const [currentTab, setCurrentTab] = useState<HomeScreenTab>(getScreenTab());
  const [allTabs, setAllTabs] = useState(defaultTabs);

  useEffect(() => {
    const currentTab = getScreenTab();
    setCurrentTab(currentTab);
  }, [location]);

  useEffect(() => {
    if (
      !loading &&
      currentUser != null &&
      currentUser.email?.includes('dashtoon') &&
      !allTabs.find(s => s.id === DashverseTab.id)
    ) {
      setAllTabs([...defaultTabs, DashverseTab]);
    }
  }, [currentUser, loading]);

  return { currentTab, setCurrentTab, allTabs };
}
