import {
  configureMixpanelConsumerUser,
  deRegisterMixpanelConsumerUser,
  initMixpanelConsumer,
  trackMixpanelConsumerEvent,
} from './MixpanelConsumer';
import { TrackingProperties } from '../Constants/TrackingProperties';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { trackFirebaseEvent } from './FirebaseAnalytics';
import { trackMetaEvent } from './MetaPixelTracking';
import { User } from 'firebase/auth';
import {
  configureMixpanelCreatorUser,
  deRegisterMixpanelCreatorUser,
  initMixpanelCreator,
  timeMixpanelCreatorEvent,
  trackMixpanelCreatorEvent,
  trackTimedMixpanelCreatorEvent,
} from './MixpanelCreator';
import { TimedTrackingEvents } from '../Constants/TimedTrackingEvents';
import { initiateDashTimedEvent, trackDashEvent, trackDashTimedEvent } from './DashAnalytics';
import {
  posthogInit,
  registerUserProfile,
  trackPostHogEvent,
  trackTimedPostHogEvent,
} from './PostHog';

export const initTracking = () => {
  initMixpanelConsumer();
  initMixpanelCreator();
  posthogInit();
};

export const configureTracking = (user: User, browserLang: string) => {
  configureMixpanelConsumerUser(user);
  configureMixpanelCreatorUser(user, browserLang);
  registerUserProfile(user);
};

export const resetTracking = () => {
  deRegisterMixpanelConsumerUser();
  deRegisterMixpanelCreatorUser();
};

export interface TrackingRequest {
  event: TrackingEvents;
  properties: TrackingProperties;
}

export interface TimedTrackingRequest {
  event: TimedTrackingEvents;
  properties: TrackingProperties;
  eventName?: string;
}

export const trackEvent = (request: TrackingRequest, type: 'CONSUMER' | 'CREATOR') => {
  if (type === 'CONSUMER') {
    trackMixpanelConsumerEvent(request.event, request.properties);
    trackFirebaseEvent(request.event, request.properties);
    trackMetaEvent(request.event, request.properties);
    trackDashEvent(request.event, request.properties, 'CONSUMER');
    trackPostHogEvent(request.event, request.properties);
    return;
  }
  if (type === 'CREATOR') {
    trackMixpanelCreatorEvent(request.event, request.properties);
    trackDashEvent(request.event, request.properties, 'CREATOR');
    trackPostHogEvent(request.event, request.properties);
    return;
  }
};

export const initiateTiming = (
  request: TimedTrackingRequest,
  type: 'CONSUMER' | 'CREATOR',
  uuid: string
) => {
  if (type === 'CONSUMER') {
    initiateDashTimedEvent(uuid);
    return;
  }
  if (type === 'CREATOR') {
    timeMixpanelCreatorEvent(request.event, request.eventName);
    initiateDashTimedEvent(uuid);
    return;
  }
};

export const trackTimedEvent = (
  request: TimedTrackingRequest,
  type: 'CONSUMER' | 'CREATOR',
  uuid: string
) => {
  if (type === 'CONSUMER') {
    trackDashTimedEvent(request.event, request.properties, type, uuid);
    return;
  }
  if (type === 'CREATOR') {
    trackTimedMixpanelCreatorEvent(request.event, request.properties, uuid, request.eventName);
    trackDashTimedEvent(request.event, request.properties, type, uuid);
    trackTimedPostHogEvent(request.event, request.properties, uuid, request.eventName);
  }
};
