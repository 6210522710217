export enum ControlNetType {
  SCRIBBLE_ADVANCED = 'SCRIBBLE_ADVANCED',
  SCRIBBLE = 'SCRIBBLE',
  OPENPOSE = 'OPENPOSE',
  EXPRESSION = 'EXPRESSION',
  CANNY = 'CANNY',
  DEPTH = 'DEPTH',
  EXPRESSION_V2 = 'EXPRESSION_V2',
  LINEART = 'LINEART',
  SOFTEDGE = 'SOFTEDGE',
}

export const controlNetTypeDisplayNames = {
  SCRIBBLE_ADVANCED: 'Storyboard 2 Comic',
  SCRIBBLE: 'Scribble',
  OPENPOSE: 'Pose',
  EXPRESSION: 'Expression',
  CANNY: 'Edge',
  DEPTH: 'Depth',
  EXPRESSION_V2: 'Expression V2',
  LINEART: 'Line Art',
  SOFTEDGE: 'Soft Edge',
};

export const controlNetTypeDisplayNamesReverseMap: { [key: string]: string } = {
  'scribble advanced': 'Storyboard2Comic',
  scribble: 'Scribble',
  openpose: 'Pose',
  expression: 'Expression',
  'expression v2': 'Expression V2',
  canny: 'Edge',
  depth: 'Depth',
  lineart: 'Line Art',
  softedge: 'Soft Edge',
};

export interface T2IAdapter {
  id: string;
  name: string;
  preprocessor: string;
  preprocessorModel: string;
  model: string;
  createdAt: number;
  updatedAt: number;
}

export interface ControlNet {
  id: string;
  displayName: string;
  name: string;
  model: string;
  preprocessor: string;
  createdAt: number;
  updatedAt: number;
}
