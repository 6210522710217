import {
  CanvasMode,
  GenerationMenuState,
  ImageGenerationState,
} from '../../Models/GenerateImageV2';

export enum GenerationMenuActions {
  SET_GENERATION_MENUS = 'SET_GENERATION_MENUS',
  SET_CANVAS_MODE = 'SET_CANVAS_MODE',
  ADD_GENERATION_MENU = 'ADD_GENERATION_MENU',
  UPDATE_GENERATION_MENU = 'UPDATE_GENERATION_MENU',
  DELETE_GENERATION_MENU = 'DELETE_GENERATION_MENU',
  UPDATE_IMAGE_IDS_TO_PREVIEW = 'UPDATE_IMAGE_IDS_TO_PREVIEW',
  SET_IMAGE_IDS_TO_PREVIEW = 'SET_IMAGE_IDS_TO_PREVIEW',
  // Add other action types as needed
}

export interface AddGenerationMenuAction {
  type: GenerationMenuActions.ADD_GENERATION_MENU;
  payload: {
    menuId: string;
    initialProperties: Partial<ImageGenerationState>;
  };
}

export interface UpdateGenerationMenuAction {
  type: GenerationMenuActions.UPDATE_GENERATION_MENU;
  payload: {
    menuId: string;
    updatedProperties: Partial<ImageGenerationState>;
  };
}

export interface SetGenerationMenusAction {
  type: GenerationMenuActions.SET_GENERATION_MENUS;
  payload: {
    generationMenus: GenerationMenuState[];
  };
}

export interface DeleteGenerationMenuAction {
  type: GenerationMenuActions.DELETE_GENERATION_MENU;
  payload: {
    menuId: string;
  };
}

export interface SetCanvasModeAction {
  type: GenerationMenuActions.SET_CANVAS_MODE;
  payload: {
    canvasMode: CanvasMode;
  };
}

export interface UpdateImageIdsToPreviewAction {
  type: GenerationMenuActions.UPDATE_IMAGE_IDS_TO_PREVIEW;
  payload: {
    menuId: string;
    imageIds: number[];
  };
}

export interface SetImageIdsToPreviewAction {
  type: GenerationMenuActions.SET_IMAGE_IDS_TO_PREVIEW;
  payload: {
    menuId: string;
    imageIds: number[];
  };
}

export type GenerationMenuAction =
  | AddGenerationMenuAction
  | UpdateGenerationMenuAction
  | SetGenerationMenusAction
  | SetCanvasModeAction
  | DeleteGenerationMenuAction
  | UpdateImageIdsToPreviewAction
  | SetImageIdsToPreviewAction;
