import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Text12R, Text14R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CustomButton from '../../../../../Components/CustomButton/CustomButton';
import CustomIcon from '../../../../../Components/CustomIcon/CustomIcon';
import { styled } from '@mui/system';
import CharacterAgeFeatureComponent, { AgeOption } from './CharacterAgeFeatureComponent';
import CharacterHairColorFeatureComponent, {
  HairColorOption,
} from './CharacterHairColorFeatureComponent';
import CharacterHairLengthFeatureComponent, {
  HairLengthOption,
} from './CharacterHairLengthFeatureComponent';
import CharacterEyeColorFeatureComponent, {
  EyeColorOption,
} from './CharacterEyeColorFeatureComponent';
import {
  CharacterBodyType,
  CharacterCreationType,
  CharacterEthnicity,
  CharacterHairLength,
  CharacterHairStyle,
} from '../../../../../Models/CharacterCreationOptions';
import CharacterEthnicityFeatureComponent from './CharacterEthnicityFeatureComponent';
import CharacterHairStyleFeatureComponent from './CharacterHairStyleFeatureComponent';
import CharacterBodyTypeFeatureComponent from './CharacterBodyTypeFeatureComponent';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';
import { CharacterCreationState } from '../../../../../Models/CharacterCreation';
import {
  randomiseCharacterFeatures,
  trackFeatureSetEvent,
} from '../../../../../Utils/CharacterCreationUtils';
import { trackEvent } from '../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../Constants/TrackingEvents';
import { Box } from '@mui/material';
import CustomTextField from '../../../../../Components/CustomTextfield/CustomTextField';
import { ReduxState } from '../../../../../Redux/ReduxInterface';
import { useSelector } from 'react-redux';

interface CharacterFeaturesTabComponentProps {
  characterCreationState: CharacterCreationState;
  setCharacterCreationState: React.Dispatch<React.SetStateAction<CharacterCreationState>>;
  option: CharacterCreationType;
}
export const CharacterFeatureContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingBottom: '16px',
  borderBottom: `1px solid ${theme.palette.surface.tertiary}`,
  width: '100%',
}));

const CharacterFeaturesTabComponent = ({
  characterCreationState,
  setCharacterCreationState,
  option,
}: CharacterFeaturesTabComponentProps) => {
  const theme = useTheme();
  const {
    age,
    hairColor,
    hairLength,
    eyeColor,
    ethnicity,
    hairStyle,
    bodyType,
    dress,
    otherFeatures,
    danbooruTags,
  } = characterCreationState;
  const isDashtoonUser = useSelector(
    (state: ReduxState) => state.userEnabledFeatures.isDashtoonUser
  );

  const setAge = (age: AgeOption) => {
    trackFeatureSetEvent('age', age.age ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, age }));
  };
  const setHairColor = (hairColor: HairColorOption) => {
    trackFeatureSetEvent('hairColor', hairColor.hairColor ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, hairColor }));
  };
  const setHairLength = (hairLength: HairLengthOption) => {
    trackFeatureSetEvent('hairLength', hairLength.hairLength ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, hairLength }));
  };

  const setEyeColor = (eyeColor: EyeColorOption) => {
    trackFeatureSetEvent('eyeColor', eyeColor.eyeColor ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, eyeColor }));
  };
  const setEthnicity = (ethnicity: CharacterEthnicity | null) => {
    trackFeatureSetEvent('ethnicity', ethnicity ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, ethnicity }));
  };

  const setBodyType = (bodyType: CharacterBodyType | string) => {
    trackFeatureSetEvent('bodyType', bodyType ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, bodyType }));
  };

  const setHairStyle = (hairStyle: CharacterHairStyle | string) => {
    trackFeatureSetEvent('hairStyle', hairStyle ?? '', option);
    setCharacterCreationState(prev => ({ ...prev, hairStyle }));
  };

  const setDress = (dress: string) => {
    setCharacterCreationState(prev => ({ ...prev, dress }));
  };

  const setOtherFeatures = (otherFeatures: string) => {
    setCharacterCreationState(prev => ({ ...prev, otherFeatures }));
  };

  const setDanbooruTags = (danbooruTags: string) => {
    setCharacterCreationState(prev => ({ ...prev, danbooruTags }));
  };

  const handleRandomise = () => {
    const features = randomiseCharacterFeatures();
    trackEvent(
      {
        event: TrackingEvents.characterFeaturesRandomised,
        properties: {
          option: option,
        },
      },
      'CREATOR'
    );
    setCharacterCreationState({
      ...characterCreationState,
      ...features,
    });
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text14R color={theme.palette.object.secondary}>{t('Select character features')}</Text14R>
        <CustomButton
          text={t('Randomise')}
          onClick={handleRandomise}
          variant={'contained'}
          hasBorder={false}
          height={'32px'}
          foregroundColor={theme.palette.object.tertiary}
          backgroundColor={theme.palette.surface.secondary}
          endIcon={<CustomIcon name={'dice'} size={24} fill={theme.palette.object.tertiary} />}
          padding={'4px 4px 4px 12px'}
          style={{
            borderRadius: '12px',
          }}
          disableElevation={true}
        />
      </div>

      {/*  Age */}
      <CharacterAgeFeatureComponent age={age} setAge={setAge} />

      {/*  Ethnicity*/}
      <CharacterEthnicityFeatureComponent ethnicity={ethnicity} setEthnicity={setEthnicity} />

      {/*Hair Length*/}
      <CharacterHairLengthFeatureComponent hairLength={hairLength} setHairLength={setHairLength} />

      {/*Hair Color*/}
      <CharacterHairColorFeatureComponent hairColor={hairColor} setHairColor={setHairColor} />

      {/*Hair Style*/}
      {hairLength.hairLength !== CharacterHairLength.BALD && (
        <CharacterHairStyleFeatureComponent hairStyle={hairStyle} setHairStyle={setHairStyle} />
      )}

      {/*Eye Color*/}
      <CharacterEyeColorFeatureComponent eyeColor={eyeColor} setEyeColor={setEyeColor} />

      {/*  Body Type */}
      <CharacterBodyTypeFeatureComponent bodyType={bodyType} setBodyType={setBodyType} />

      {/*  Dress */}
      <CharacterFeatureContainer>
        <Text12R color={theme.palette.constant.grey}>{t('Dress')}</Text12R>
        <CustomTextFieldV2
          key={'character-dress'}
          width={'100%'}
          inputProps={{ value: dress }}
          onChange={e => {
            setDress(e.target.value);
          }}
          isV2={true}
          style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
          backgroundColor={theme.palette.surface.primary}
          borderRadius={'16px'}
          ariaLabelledBy={'Character Dress'}
          multiline={true}
          noOfLines={1}
          maxRows={4}
        />
      </CharacterFeatureContainer>

      {/*  Other Features */}
      <CharacterFeatureContainer>
        <Text12R color={theme.palette.constant.grey}>{t('Other Features')}</Text12R>
        <CustomTextFieldV2
          key={'character-other-features'}
          width={'100%'}
          inputProps={{ value: otherFeatures }}
          onChange={e => {
            setOtherFeatures(e.target.value);
          }}
          isV2={true}
          style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
          backgroundColor={theme.palette.surface.primary}
          borderRadius={'16px'}
          ariaLabelledBy={'Character Other Features'}
          multiline={true}
          noOfLines={1}
          maxRows={4}
        />
      </CharacterFeatureContainer>

      {/*Danbooru tags*/}
      {isDashtoonUser && (
        <CharacterFeatureContainer>
          <Text12R color={theme.palette.constant.grey}>{t('Danbooru Tags')}</Text12R>
          <CustomTextFieldV2
            key={'character-danbooru-tags'}
            width={'100%'}
            inputProps={{ value: danbooruTags }}
            onChange={e => {
              setDanbooruTags(e.target.value);
            }}
            isV2={true}
            style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
            backgroundColor={theme.palette.surface.primary}
            borderRadius={'16px'}
            ariaLabelledBy={'Character Danbooru Tags'}
            multiline={true}
            noOfLines={1}
            maxRows={4}
          />
        </CharacterFeatureContainer>
      )}
    </div>
  );
};
export default CharacterFeaturesTabComponent;
