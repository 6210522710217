
export enum ShowDetailsActions {
    SHOW_DETAILS = "SHOW_DETAILS",
    SHOW_STYLE = "SHOW_STYLE",
    SHOW_CHARACTERS = "SHOW_CHARACTERS",
    SHOW_THUMBNAILS = "SHOW_THUMBNAILS",
}

export interface ShowDetailsActionTypes {
    type: ShowDetailsActions,
    payload: any
}