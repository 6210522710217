import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoOnly } from '../../../Assets/Images/logo-only.svg';
import { ReactComponent as BetaTag } from '../../../Assets/Images/BetaTag.svg';

import { useTheme } from '@mui/material/styles';

const Logo = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      style={{
        cursor: 'pointer',
        display: 'flex',
        padding: '16px',
        alignItems: 'center',
        gap: '12px',
        borderBottom: `1px solid ${theme.palette.surface.tertiary}`,
      }}
      onClick={() => navigate('/home')}
    >
      <IconButton sx={{ p: 0 }} aria-label={'Dashtoon Studio'} aria-roledescription={'button'}>
        <LogoOnly style={{ width: '32x', height: '32px' }} />
      </IconButton>
      <Typography
        fontWeight={'600'}
        fontSize={'20px'}
        lineHeight={'24px'}
        color={theme.palette.object.primary}
        style={{ paddingLeft: '4px' }}
      >
        Dashtoon
      </Typography>
      <BetaTag style={{ width: '40px', height: '40px' }} />
    </Box>
  );
};

export default Logo;
