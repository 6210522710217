import { Box, Checkbox, FormControlLabel, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import DropAreaV3 from '../../../../../../Components/DropAreaV3/DropAreaV3';
import CustomButton from '../../../../../../Components/CustomButton/CustomButton';
import Banner from '../../../../../../Components/Banner/Banner';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../Redux/ReduxInterface';
import {
  CharacterFromCanvasActions,
  ShowDetailsCharacterActions,
} from '../../../../../../Redux/Actions/ShowDetailsCharacter';
import useCharacters from '../../../../../../Hooks/useCharacter';
import { useParams } from 'react-router-dom';
import PartTwoForm from './PartTwoForm';
import { AppStateActions } from '../../../../../../Redux/Actions/AppState';
import { ShowDetailsCharacterView } from '../../ShowDetailsCharacters';
import { DashCharacterTypes, ProcessType } from '../../../../../../Models/Character';
import { trackEvent } from '../../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../../Constants/TrackingEvents';
import CustomSelect from './CustomSelect';
import { useUpload } from '../../../../../../Hooks/useUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ProgressBar from '../../../../../../Components/ProgressBar/ProgressBar';
import CombinedForm from './CombinedForm';
import PartOneForm from './PartOneForm';
import LoadingScreen from '../../../../../../Components/LoadingScreen/LoadingScreen';
import { APIError } from '../../../../../../Utils/HttpClient';
import { t } from 'i18next';
import CharacterLimitReachedForm from './CharacterLimitReachedForm';
import DashCharacterCreatorTitleBlock from './DashCharacterCreatorTitleBlock';
import { UserSubscriptionPlanEnum } from '../../../../../../Models/User';
import { TriggerTextPayments } from '../../../PaymentPlans/Components/triggerTextPayments';
import CustomGenerateButton from '../../../../../../Components/CustomButton/CustomGenerateButton';
import { ReactComponent as BillingIcon } from '../../../../../../Assets/Icons/billing-vector.svg';
import creditsUsage, {
  calculateCreditsRequiredForCharacters,
} from '../../../PaymentPlans/data/creditUsage';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface CharacterCreateFormState {
  name: string;
  gender: 'MALE' | 'FEMALE' | '';
  age:
    | 'Infant'
    | 'Toddler'
    | 'Child'
    | 'Teenager'
    | 'Young Adult'
    | 'Adult'
    | 'Middle Age'
    | 'Old'
    | 'Ancient'
    | '';
  casting: 'Primary' | 'Secondary' | 'Tertiary' | '';
  ethnicity:
    | 'African American'
    | 'Asian American'
    | 'Indian'
    | 'Latin American'
    | 'Middle Eastern'
    | 'White'
    | '';
  hairStyle: string;
  hairColor: string;
  eyeColor: string;
  additionalCharacteristics: string;
  processType: ProcessType;
  danbooruTags: string;
  type: DashCharacterTypes;
  requiresCaptions: boolean;
  addToAllStyles: boolean;
}

enum Part {
  ONE = 'ONE',
  TWO = 'TWO',
}

const setImageCharacter = (image: string) => {
  return {
    type: CharacterFromCanvasActions.CHARACTER_FROM_CANVAS,
    payload: {
      isCharacterFromCanvas: false,
      characterImageLink: image,
    },
  };
};

const CharacterCreationView = () => {
  const dispatch = useDispatch();
  const isDashtoonUser = useSelector(
    (state: ReduxState) => state.userEnabledFeatures.isDashtoonUser
  );
  const characters = useCharacters();
  const [isLoading, setIsLoading] = useState(false);
  const selectedStyle = characters.getStyleDetailsFromSelectedStyle();
  const [characterState, setCharacterState] = useState<CharacterCreateFormState>({
    name: '',
    gender: '',
    casting: '',
    ethnicity: '',
    age: '',
    hairStyle: '',
    hairColor: '',
    eyeColor: '',
    additionalCharacteristics: '',
    processType: ProcessType.AUTOMATED_V2,
    danbooruTags: '',
    type: selectedStyle?.defaultCharacterCreationType ?? DashCharacterTypes.TRAINED,
    requiresCaptions: true,
    addToAllStyles: isDashtoonUser,
  });
  const [characterQuotaLimitReached, setCharacterQuotaLimitReached] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<File | null>(null);
  const [fileImageUrl, setFileImageUrl] = useState<string | null>(null);
  const [part, setPart] = useState<Part>(Part.ONE);
  const [countForHiddenButton, setCountForHiddenButton] = useState<number>(0);
  const [processType, setProcessType] = useState<ProcessType>(ProcessType.AUTOMATED_V2);
  const characterImageLink = useSelector(
    (state: ReduxState) => state.characterFromCanvasState.characterImageLink
  );
  const params = useParams() as { showId: string; episodeId: string };
  // do better here
  const isCharacterFromCanvas = useSelector(
    (state: ReduxState) => state.characterFromCanvasState.isCharacterFromCanvas
  );
  const isLoadingCharacter = useSelector(
    (state: ReduxState) => state.characterFromCanvasState.isLoading
  );
  const trainingCharacterList = useSelector(
    (state: ReduxState) => state.character.trainingCharacters
  );
  const [isAiGeneratedMetadataLoading, setIsAiGeneratedMetadataLoading] = useState<boolean>(false);
  const [hasGeneratedMetadata, setHasGeneratedMetadata] = useState<
    'idle' | 'loading' | 'success' | 'failure'
  >('idle');
  const nsfwFilter = !useSelector((state: ReduxState) => state.userEnabledFeatures.enableNSFW);
  const quotaState = useSelector((state: ReduxState) => state.quotaState);
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const name = (event.target as HTMLInputElement).name;
    const type = (event.target as HTMLInputElement).type;
    const value =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;

    setCharacterState({
      ...characterState,
      [name]: value,
    });
  };
  const isFirstPageFilled =
    !!characterState.name && !!characterState.gender && (!!characterImageLink || !!fileImageUrl);
  const validateFormStateFilled = () => {
    // remove additionalCharacteristics from requiredState
    let requiredState = { ...characterState } as { [key: string]: string | boolean };
    delete requiredState['additionalCharacteristics'];
    delete requiredState['danbooruTags'];
    let isValid =
      (!!characterImageLink || !!imageFiles) &&
      !Object.values(requiredState).some(value => value === '');
    if (characterState.processType === ProcessType.MANUAL) {
      isValid = isValid && uploadHook.uploadFiles.length >= 30;
    }
    return isValid;
  };
  const uploadHook = useUpload(30, 5, nsfwFilter);
  const userEnabledFeatures = useSelector((state: ReduxState) => state.userEnabledFeatures);
  const enabledCanvasV3 = userEnabledFeatures.enableCanvasV3;
  // if prompt or trained, calculate credits
  const estimatedTokens = calculateCreditsRequiredForCharacters(characterState.type);
  const theme = useTheme();
  const getDerivedDescription = () => {
    const prompt =
      characterState.ethnicity +
      ', ' +
      characterState.gender +
      ', ' +
      characterState.age +
      ', ' +
      characterState.hairStyle +
      ' ' +
      characterState.hairColor +
      ' Hair, ' +
      characterState.eyeColor +
      ' eye color' +
      (characterState.additionalCharacteristics
        ? ', ' + characterState.additionalCharacteristics
        : '');
    return prompt;
  };

  const handleCreateCharacter = async () => {
    try {
      setIsLoading(true);
      const url = fileImageUrl ?? characterImageLink;
      if (!url) return;
      if (
        !characterState.age ||
        !characterState.casting ||
        !characterState.ethnicity ||
        !characterState.gender
      )
        return;
      trackEvent(
        {
          event: TrackingEvents.characterCreationTrainCharacterButton,
          properties: {
            currentTrainedCharLength: trainingCharacterList.length.toString(),
            rightClickMenuSelected: isCharacterFromCanvas ? 'true' : 'false',
          },
        },
        'CREATOR'
      );
      const response = await characters.createCharacter({
        url,
        character: {
          showId: params.showId,
          name: characterState.name,
          description: getDerivedDescription(),
          age: characterState.age,
          casting: characterState.casting,
          ethnicity: characterState.ethnicity,
          hairStyle: characterState.hairStyle,
          hairColor: characterState.hairColor,
          eyeColor: characterState.eyeColor,
          additionalCharacteristics: characterState.additionalCharacteristics,
          gender: characterState.gender,
          // empty since request needs it, legacy
          images: characterState.processType === ProcessType.MANUAL ? uploadHook.imageUrls : [],
          processType: characterState.processType,
          requiresCaptions: characterState.requiresCaptions,
          danbooruTags: characterState.danbooruTags,
          type: characterState.type,
          addToAllStyles: characterState.addToAllStyles,
        },
      });

      trackEvent(
        {
          event: TrackingEvents.createdCharacterUsingMetadataAi,
          properties: {
            characterId: response.id,
            rightClickMenuSelected: isCharacterFromCanvas ? 'true' : 'false',
            characterName: characterState.name,
            showId: params.showId,
            episodeId: params.episodeId,
            type: characterState.type,
            processType: characterState.processType,
            style: selectedStyle?.name ?? '',
            allStyles: characterState.addToAllStyles.toString(),
          },
        },
        'CREATOR'
      );

      if (quotaState.data.currentPlan === UserSubscriptionPlanEnum.BASIC) {
        dispatch({
          type: AppStateActions.OPEN_SHOW_DETAILS_MODAL,
          payload: false,
        });
        dispatch({
          type: enabledCanvasV3
            ? AppStateActions.OPEN_PAYMENTS_MODAL_V3
            : AppStateActions.OPEN_PAYMENTS_MODAL,
          payload: true,
        });
        dispatch({
          type: AppStateActions.SET_PAYMENTS_MODAL_TEXT,
          payload: TriggerTextPayments.ONE_CHARACTER_CREATED,
        });
        trackEvent(
          {
            event: TrackingEvents.userBillingPopupTriggered,
            properties: {
              reason: 'oneCharacterCreated',
            },
          },
          'CREATOR'
        );
      }

      revokeURL();
      dispatch({
        type: AppStateActions.OPEN_SHOW_DETAILS_MODAL,
        payload: false,
      });
      setIsLoading(false);
      characters.getTrainedInShowAndUserTrainedCharacters();
    } catch (e) {
      if (e instanceof APIError) {
        if (e.status === 429) {
          setCharacterQuotaLimitReached(true);
        }
      }
      setIsLoading(false);
      console.error(e);
    }
  };
  const revokeURL = () => {
    if (fileImageUrl) {
      URL.revokeObjectURL(fileImageUrl);
      setFileImageUrl(null);
      setImageFiles(null);
    }
  };
  useEffect(() => {
    (async () => {
      const imageUrl = isCharacterFromCanvas ? characterImageLink : fileImageUrl;
      if (!imageUrl) return;
      if (hasGeneratedMetadata !== 'idle') return;
      setHasGeneratedMetadata('loading');
      setIsAiGeneratedMetadataLoading(true);
      characters
        .getMetadataOfImage(imageUrl)
        .then(metadata => {
          // set metadata
          setHasGeneratedMetadata('success');
          for (const key in metadata) {
            if (characterState.hasOwnProperty(key)) {
              const value = metadata[key];
              if (value) {
                setCharacterState(prev => {
                  // retrieve object property prev[key]
                  const state = prev as Record<string, any>;
                  // ignoring description since it is going to be a derived value of other fields
                  const val = key !== 'description' ? state[key] : null;
                  return {
                    ...prev,
                    [key]: val ? val : value,
                  };
                });
              }
            }
          }

          trackEvent(
            {
              event: TrackingEvents.characterMetadataWithAiSuccess,
              properties: {
                rightClickMenuSelected: isCharacterFromCanvas ? 'true' : 'false',
                characterMetadata: JSON.stringify(metadata),
              },
            },
            'CREATOR'
          );
          setIsAiGeneratedMetadataLoading(false);
        })
        .catch(e => {
          console.error(e);
          setHasGeneratedMetadata('failure');
          setIsAiGeneratedMetadataLoading(false);
          trackEvent(
            {
              event: TrackingEvents.characterMetadataWithAiFailure,
              properties: {
                error: JSON.stringify(e),
              },
            },
            'CREATOR'
          );
        });
    })();
  }, [fileImageUrl, isCharacterFromCanvas]);

  useEffect(() => {
    return () => {
      dispatch(setImageCharacter(''));
      dispatch({
        type: ShowDetailsCharacterActions.LIBRARY,
        payload: ShowDetailsCharacterView.LIBRARY,
      });
      revokeURL();
    };
  }, []);

  const { quotaLeft = 0, timeLeftForReset } = characters?.getQuota();
  const currentPlan = quotaState.data.currentPlan ?? UserSubscriptionPlanEnum.BASIC;
  if (
    (characterQuotaLimitReached || quotaLeft <= 0) &&
    currentPlan !== UserSubscriptionPlanEnum.BASIC
  ) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          marginTop: '2px',
          display: 'flex',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          gap: '0.5rem',
        }}
      >
        <Box
          display={'flex'}
          height={'100%'}
          flexDirection={'column'}
          flex={3}
          pr={5}
          mt={0}
          gap={4}
        >
          <Box>
            <CharacterLimitReachedForm />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <DashCharacterCreatorTitleBlock />
      <Box
        style={{
          width: '100%',
          height: '100%',
          marginTop: '2px',
          display: 'flex',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          gap: '0.5rem',
        }}
      >
        {/* left panel */}
        {isAiGeneratedMetadataLoading || isLoadingCharacter ? (
          <LoadingScreen
            p={4}
            label={t(
              'Going all out to generate info on your character! \nWe currently support human characters only. Humanoid and non-human character creation capabilities coming soon!'
            )}
          />
        ) : (
          <Box display={'flex'} height={'100%'} flexDirection={'column'} flex={3} pr={5} mt={3}>
            {characterState.processType !== ProcessType.MANUAL &&
            hasGeneratedMetadata === 'success' ? (
              <CombinedForm
                characterState={characterState}
                handleFormChange={handleFormChange}
                footer={
                  <Box flex={1}>
                    <CustomGenerateButton
                      text={
                        <Stack direction={'row'} width={'100%'} justifyContent={'space-evenly'}>
                          <Box
                            sx={{
                              fontSize: '14px',
                              fontWeight: 600,
                            }}
                            flex={3}
                          >
                            {t('Train Character')}
                          </Box>
                          <Box flex={1}>
                            <Tooltip
                              title={
                                'Number of generation tokens it will consume. This is equal to the number of images in each generation'
                              }
                            >
                              <Stack
                                marginLeft={'auto'}
                                justifySelf={'end'}
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <Box>{estimatedTokens}</Box>
                                <BillingIcon
                                  style={{ width: '16px', height: '16px' }}
                                  fill={theme.palette.text.primary}
                                />
                              </Stack>
                            </Tooltip>
                          </Box>
                        </Stack>
                      }
                      isLoading={isLoading}
                      width="100%"
                      height="54px"
                      onClick={handleCreateCharacter}
                      variant="contained"
                      hasBorder={false}
                      foregroundColor="white"
                      disabled={!validateFormStateFilled()}
                    />
                  </Box>
                }
              />
            ) : part === Part.ONE ? (
              <PartOneForm
                characterState={characterState}
                handleFormChange={handleFormChange}
                footer={
                  <CustomButton
                    text={t('Next')}
                    width="100%"
                    height="54px"
                    isLoading={isLoading}
                    onClick={() => {
                      trackEvent(
                        {
                          event: TrackingEvents.characterCreationPageNextButton,
                          properties: {
                            currentTrainedCharLength: trainingCharacterList.length.toString(),
                            rightClickMenuSelected: isCharacterFromCanvas ? 'true' : 'false',
                          },
                        },
                        'CREATOR'
                      );
                      isFirstPageFilled && setPart(Part.TWO);
                    }}
                    variant="contained"
                    hasBorder={false}
                    foregroundColor="white"
                    disabled={!isFirstPageFilled}
                  />
                }
              />
            ) : (
              <PartTwoForm
                characterState={characterState}
                handleFormChange={handleFormChange}
                confirmDisabled={validateFormStateFilled()}
                footer={
                  <>
                    <Box flex={1}>
                      <CustomGenerateButton
                        text={
                          <Stack direction={'row'} width={'100%'} justifyContent={'space-evenly'}>
                            <Box
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                              }}
                              flex={3}
                            >
                              {t('Train Character')}
                            </Box>
                            <Box flex={1}>
                              <Tooltip
                                title={
                                  'Number of generation tokens it will consume. This is equal to the number of images in each generation'
                                }
                              >
                                <Stack
                                  marginLeft={'auto'}
                                  justifySelf={'end'}
                                  direction={'row'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Box>{estimatedTokens}</Box>
                                  <BillingIcon
                                    style={{ width: '16px', height: '16px' }}
                                    fill={theme.palette.text.primary}
                                  />
                                </Stack>
                              </Tooltip>
                            </Box>
                          </Stack>
                        }
                        isLoading={isLoading}
                        width="100%"
                        height="54px"
                        onClick={handleCreateCharacter}
                        variant="contained"
                        hasBorder={false}
                        foregroundColor="white"
                        disabled={!validateFormStateFilled()}
                      />
                    </Box>
                    <Box flex={1} width={'100%'}>
                      <CustomButton
                        text={t('Back')}
                        width="100%"
                        height="54px"
                        isLoading={isLoading}
                        onClick={() => {
                          setPart(Part.ONE);
                          trackEvent(
                            {
                              event: TrackingEvents.characterCreationPageBackButton,
                              properties: {
                                currentTrainedCharLength: trainingCharacterList.length.toString(),
                                rightClickMenuSelected: isCharacterFromCanvas ? 'true' : 'false',
                              },
                            },
                            'CREATOR'
                          );
                        }}
                        variant="text"
                        hasBorder={false}
                        foregroundColor="white"
                      />
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        )}

        {/* right panel */}
        <Box flex={2} px={4} display={'flex'} gap={2} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            {characterState.processType !== ProcessType.MANUAL && (
              <>
                <Box>{t('Upload Character Image')}</Box>
                <Box
                  justifySelf={'center'}
                  alignSelf={'center'}
                  style={{ borderRadius: '12px', overflow: 'hidden' }}
                >
                  {characterImageLink || fileImageUrl ? (
                    <img
                      onClick={() => setCountForHiddenButton(prev => prev + 1)}
                      src={fileImageUrl ?? characterImageLink}
                      alt="character"
                      style={{ width: 'auto', height: '280px' }}
                    />
                  ) : (
                    <DropAreaV3
                      color={'blueGrey'}
                      style={{ backgroundColor: 'pink' }}
                      width={'280px'}
                      height={'280px'}
                      validator={() => {
                        return null;
                      }}
                      fileTypes={{ 'image/png': ['.jpg', '.png', '.jpeg', '.webp'] }}
                      removeAllFiles={revokeURL}
                      removeSpecificFile={revokeURL}
                      onChange={async file => {
                        trackEvent(
                          {
                            event: TrackingEvents.characterCreationUploadImage,
                            properties: {
                              rightClickMenuSelected: 'false',
                            },
                          },
                          'CREATOR'
                        );
                        if (file.length > 0) {
                          setImageFiles(file[0]);
                          const url = await characters.saveCharacterImage(file[0]);
                          setFileImageUrl(url?.url ?? null);
                        }
                        return [file];
                      }}
                    />
                  )}
                </Box>
              </>
            )}

            {countForHiddenButton > 10 && (
              <Box>
                <Box style={{ display: 'flex' }}>
                  <FormControlLabel
                    label=""
                    control={
                      // processType
                      <CustomSelect
                        label={'Process Type'}
                        name="processType"
                        options={[
                          ProcessType.MANUAL,
                          ProcessType.AUTOMATED_V2,
                          ProcessType.SEMI_MANUAL_V2,
                          ProcessType.AUTOMATED_DIFFUSERS,
                          ProcessType.SEMI_MANUAL_DIFFUSERS,
                          ProcessType.AUTOMATED_V4,
                          ProcessType.SEMI_MANUAL_V4,
                        ]}
                        value={characterState.processType}
                        onChange={handleFormChange}
                        placeholder="Process Type"
                      />
                    }
                  />
                  <Box>
                    <FormControlLabel
                      label=""
                      control={
                        <CustomSelect
                          label={'Character Type'}
                          name="type"
                          options={[DashCharacterTypes.TRAINED, DashCharacterTypes.PROMPT]}
                          value={characterState.type}
                          onChange={handleFormChange}
                          placeholder="Character Type"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box display={'flex'}>
                  <Box>
                    Captions
                    <Checkbox
                      name={'requiresCaptions'}
                      sx={{ height: '24px', width: '24px', marginRight: '12px' }}
                      onChange={handleFormChange}
                      checked={characterState.requiresCaptions}
                    />
                  </Box>
                  <Box>
                    Add to all styles
                    <Checkbox
                      name={'addToAllStyles'}
                      sx={{ height: '24px', width: '24px', marginRight: '12px' }}
                      onChange={handleFormChange}
                      checked={characterState.addToAllStyles}
                    />
                  </Box>
                </Box>
                {characterState.processType === ProcessType.MANUAL && (
                  <Box display={'flex'} flexDirection={'column'} mt={2}>
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                      Upload 30 files. Remaining {`${30 - uploadHook.uploadFiles.length}`}
                      <VisuallyHiddenInput
                        onChange={async e => {
                          if (!e.target.files) return;
                          uploadHook.handleDropFileList(e.target.files);
                        }}
                        type="file"
                        multiple={true}
                      />
                    </Button>
                    <Stack maxHeight={'200px'} overflow={'scroll'} direction={'column'}>
                      {uploadHook.uploadFiles.length > 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {uploadHook.uploadFiles.map((uploadFile, index) => (
                            <div key={uploadFile.tempId} style={{ margin: '8px 0' }}>
                              <ProgressBar
                                fileName={uploadFile.file.name}
                                progress={uploadFile.progress}
                                onDelete={() => uploadHook.handleDelete(uploadFile)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </Stack>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box flex={1} justifySelf={'end'}>
            <Box width={'100%'}>
              <Banner
                message={t(
                  'We currently support human characters only. Humanoid and non-human character creation capabilities coming soon!'
                )}
                type="warning"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CharacterCreationView;
