import { ShowDetailsActionTypes, ShowDetailsActions } from "../Actions/ShowDetails"

export enum ShowDetailsTabState {
    SHOW_DETAILS,
    SHOW_STYLE,
    SHOW_CHARACTERS,
    SHOW_THUMBNAILS,
    SHOW_TEAM
}

export interface ShowDetailsState {
    showDetailsState: ShowDetailsTabState,
}

export const InitialShowDetailsState: ShowDetailsState = {
    showDetailsState: ShowDetailsTabState.SHOW_DETAILS,
}

export const ShowDetailsReducer = (
    state: ShowDetailsState = InitialShowDetailsState,
    action: ShowDetailsActionTypes
): ShowDetailsState => {
    switch(action.type) {
        case ShowDetailsActions.SHOW_DETAILS:
            return {...state, showDetailsState: action.payload}
        case ShowDetailsActions.SHOW_CHARACTERS:
            return {...state, showDetailsState: action.payload}
        case ShowDetailsActions.SHOW_STYLE:
            return {...state, showDetailsState: action.payload}
        case ShowDetailsActions.SHOW_THUMBNAILS:
            return {...state, showDetailsState: action.payload}
        default:
            return state
    }   
}
