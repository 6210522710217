import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../AppBar/AppBar';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { checkURLIncludes } from '../../Utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import LayoutSidebarV2 from '../LayoutSidebar/LayoutSidebarV2';
import usePhoneLayout from '../../Hooks/usePhoneLayout';
import LoginModal from '../LoginModal/LoginModal';

const Layout: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSelector((state: ReduxState) => state.tabSelector.dashboardSearch);
  const { isSmallScreen } = usePhoneLayout();
  const showLoginModal = useSelector((state: ReduxState) => state.appState.showLoginModal);
  const handleSearch = (e: any) => {
    dispatch({
      type: TabSelectorActions.UPDATE_DASHBOARD_SEARCH,
      payload: e.target.value,
    });
  };

  return (
    <div
      style={{
        background: theme.palette.surface.primary,
        overflowY: 'hidden',
        overscrollBehaviorY: 'none',
        height: window.innerHeight,
      }}
    >
      <ResponsiveAppBar
        isCanvas={false}
        search={search ?? ''}
        filter={true}
        handleSearch={handleSearch}
      />
      {isSmallScreen ? null : <LayoutSidebarV2 />}
      <Box
        style={{
          padding: 0,
          display: 'flex',
          margin: `96px 16px 0px ${isSmallScreen ? '16px' : '272px'}`,
          overflowX: 'hidden',
        }}
      >
        {/*<LayoutSidebar />*/}
        <Box
          style={{
            height: '94vh',
            overflow: 'scroll',
            paddingBottom: '84px',
            width: '100%',
          }}
        >
          <LoginModal open={showLoginModal} />
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
