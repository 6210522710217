import { SegmentRequest } from '../../Models/Segment';
import HttpClient from '../../Utils/HttpClient';
import {
  aiApiPrefix,
  apiPrefix,
  bugReportApiPrefix,
  removeBgApiPrefix,
  segmentApiPrefix,
  studioApiPrefix,
  v2Prefix,
} from '../../Constants/Constants';
import { BugState } from '../../Models/BugReport';

export const sendBugReport = async (bugReportRequest: BugState) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${bugReportApiPrefix}/upload`,
      bugReportRequest
    );
    return response;
  } catch (error) {
    console.error(`Unable to Send Bug Report`);
    throw error;
    return null;
  }
};
