import HttpClient from '../../Utils/HttpClient';
import { aiApiPrefix, apiPrefix, v2Prefix } from '../../Constants/Constants';
import { User } from 'firebase/auth';
import { UserEnabledFeaturesActions } from '../../Redux/Actions/UserEnabledFeatures';
import { UserEnabledFeaturesResponse } from '../../Models/UserEnabledFeatures';
import { ThunkAction } from 'redux-thunk';
import { ReduxState } from '../../Redux/ReduxInterface';
import { AnyAction } from 'redux';

export const fetchUserEnabledFeatures = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}${aiApiPrefix}/features`);
  } catch (error) {
    console.error(`Character fetch user features failed with error: ${error}`);
    return null;
  }
};

export const fetchAndSetUserEnabledFeatures =
  (): ThunkAction<void, ReduxState, unknown, AnyAction> => async (dispatch, getState) => {
    try {
      const userEnabledFeatures: UserEnabledFeaturesResponse = await fetchUserEnabledFeatures();
      for (const feature of userEnabledFeatures.features) {
        dispatch({ type: ('SET_' + feature) as UserEnabledFeaturesActions, payload: true });
      }
    } catch (error) {
      dispatch({ type: UserEnabledFeaturesActions.RESET_USER_FEATURE_ENABLED });
    }
  };
