import { Box } from "@mui/material";

const CharacterImage : React.FC<{imageUrl: string}> = ({
    imageUrl
  }) => (
    <Box style={{ width: '100%', height: "100%", marginBottom: '20px' }}>
      <img src={imageUrl} style={{ width: '100%', height: '100%', objectFit:"contain" }} />
    </Box>
  );

export default CharacterImage;