import React from 'react';
import {
  CharacterEthnicity,
  CharacterHairStyle,
} from '../../../../../Models/CharacterCreationOptions';
import { useTheme } from '@mui/material/styles';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CharacterFeatureItem from './CharacterFeatureItem';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';

interface CharacterHairStyleFeatureProps {
  hairStyle: CharacterHairStyle | string;
  setHairStyle: (value: CharacterHairStyle | string) => void;
}
const CharacterHairStyleFeatureComponent = ({
  hairStyle,
  setHairStyle,
}: CharacterHairStyleFeatureProps) => {
  const theme = useTheme();

  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Hair Style')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        <CharacterFeatureItem
          label={t('Unset')}
          selected={hairStyle === ''}
          onClick={() => setHairStyle('')}
        />
        {Object.values(CharacterHairStyle).map(item => {
          const selected = item === hairStyle;
          return (
            <CharacterFeatureItem
              key={item}
              label={item}
              selected={selected}
              onClick={() => setHairStyle(item)}
            />
          );
        })}
      </div>
      <CustomTextFieldV2
        key={'character-custom-hair-style'}
        width={'100%'}
        inputProps={{ value: hairStyle }}
        onChange={e => {
          setHairStyle(e.target.value);
        }}
        isV2={true}
        style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
        backgroundColor={theme.palette.surface.primary}
        borderRadius={'16px'}
        ariaLabelledBy={'Character Custom Hair Style'}
        multiline={false}
        placeholder={t('Or Enter Custom Hair Style')}
      />
    </CharacterFeatureContainer>
  );
};
export default CharacterHairStyleFeatureComponent;
