import { SavedImagesActions, SavedImagesActionTypes } from '../Actions/SavedImages';
import { SaveImageResponse } from '../../Models/SaveImage';
import { InpaintActions, InpaintImageActionTypes } from '../Actions/Inpaint';

export interface InpaintImageState {
  inpaintImage: SaveImageResponse | null;
}

const initialState: InpaintImageState = {
  inpaintImage: null,
};

export const InpaintImageReducer = (
  state: InpaintImageState = initialState,
  action: InpaintImageActionTypes
): InpaintImageState => {
  switch (action.type) {
    case InpaintActions.SET_INPAINT_IMAGE:
      return { ...state, inpaintImage: action.payload, ...state.inpaintImage };
    case InpaintActions.UPDATE_INPAINT_IMAGE:
      return { ...state, inpaintImage: action.payload, ...state.inpaintImage };
    default:
      return state;
  }
};
