import { DashverseState } from '../Redux/Reducers/Dashverse';

export enum UniverseType {
  SHOWS = 'SHOWS',
  CHARACTERS = 'CHARACTER',
}

export interface ShowUniverseFilterValues {
  is: boolean;
}

export interface DashverseShowCharacterInterface {
  characterId: string;
  characterName: string;
  characterImage: string;
  isEditorsChoice: string;
}

export interface DashverseShowDetailsInterface {
  showId: string;
  description: string;
  style: string;
  model: string;
  createdAt: number;
  publishedAt: number;
  charactersInShow: DashverseShowCharacterInterface[];
}

export interface DashverseCharacterShowInterface {
  showId: string;
  casting: string;
  showName: string;
  alias: string;
  style: string;
  bannerImage: string;
  generations: string;
}

export interface DashverseCharacterDetailsInterface {
  listOfShows: DashverseCharacterShowInterface[];
}

export interface AvailableShowFilters {
  genres: string[];
  creators: string[];
  models: string[];
  styles: string[];
}

export interface AvailableCharacterFilters {
  genders: string[];
  age: string[];
  race: string[];
  hairStyle: string[];
  castingType: string[];
  editorsChoice: string[];
  generations: string[];
  baseModels: string[];
  organisations: string[];
}

export interface SelectedShowFilters {
  genres: string[];
  creators: string[];
  models: string[];
  styles: string[];
}

export interface SelectedCharacterFilters {
  is: boolean;
  genders: string[];
  age: string[];
  race: string[];
  hairStyle: string[];
  castingType: string[];
  editorsChoice: string;
  generations: string;
  baseModel: string;
  organisations: string[];
}

export interface DashverseShowSummaries {
  showId: string;
  showName: string;
  creatorName: string;
  genres: string[];
  episodeCount: number;
  thumbnailUrl: string;
}

export interface DashverseCharacterSummaries {
  characterId: string;
  characterName: string;
  numberOfShows: number;
  generations: string;
  showsCasted: { [key: string]: string };
  gender: string;
  attributes: { [key: string]: string };
  isEditorsChoice: boolean;
  characterImage: string;
}

export interface ShowSummariesRequest {
  selectedGenres: string[];
  selectedCreators: string[];
  selectedModels: string[];
  selectedStyles: string[];
}

export interface CharacterSummariesRequest {
  negateResults: boolean;
  gender: string[];
  age: string[];
  race: string[];
  hairStyle: string[];
  casting: string[];
  editorsChoice: boolean;
  generations: string;
  query: string;
  baseModel: string;
  organisations: string[];
}

export const DashverseInitialState: DashverseState = {
  universeType: UniverseType.SHOWS,
  searchText: '',
  availableShowFilters: {
    genres: [],
    creators: [],
    models: [],
    styles: [],
  },
  selectedShowFilters: { genres: [], creators: [], models: [], styles: [] },
  availableCharacterFilters: {
    genders: [],
    age: [],
    race: [],
    hairStyle: [],
    castingType: [],
    editorsChoice: [],
    generations: [],
    baseModels: [],
    organisations: [],
  },
  selectedCharacterFilters: {
    is: false,
    genders: [],
    age: [],
    race: [],
    hairStyle: [],
    castingType: [],
    editorsChoice: '',
    generations: '',
    baseModel: '',
    organisations: [],
  },
};

export enum DashverseAttributes {
  HAIR_STYLE = 'Hair Style',
  GENDER = 'Gender',
  RACE = 'Ethnicity',
  AGE = 'Age',
}

export enum DashverseImageData {
  T2I = 't2i',
  GUIDE = 'control-net',
  EXPRESSIONS = 'expressions',
}

export const DashverseImageDataHeadingText = {
  t2i: 'Text to Image',
  'control-net': 'Guide',
  expressions: 'Expressions',
};
