import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../Config/Config';
import useAuth from '../Hooks/useAuth';
import { AuthContext } from '../Provider/AuthProvider';

const ClarityScript: React.FC = () => {
  const clarityId = config.clarityId;
  const user = useContext(AuthContext);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (user && user.currentUser) {
      setUserId(user.currentUser?.uid!);
    }
  }, [user]);

  if (!clarityId) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            clarity("set", "userId", "${userId}");
          })(window, document, "clarity", "script", "${clarityId}");
        `}
      </script>
    </Helmet>
  );
};

export default ClarityScript;
