import { User } from 'firebase/auth';

export interface WalletBalanceResponse {
  userId: string;
  tenant: string;
  balance: number;
}

export interface UserRecordDTO {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoUrl: string | null;
  emailVerified: boolean;
  phoneNumber: string | null;
  disabled: boolean;
}

export interface WalletTopUpRequest {
  amount: number;
  quantity: number;
  productId: string;
  paymentSuccessLink: string;
  paymentCancelLink: string;
}

export interface WalletTopUpResponse {
  checkoutSessionLink: string;
}
export interface UserSubscriptionPaymentRequest {
  amount: number;
  quantity: number;
  productId: string;
  priceId: string;
  paymentSuccessLink: string;
  paymentCancelLink: string;
  email?: string;
}

export interface UserSubscriptionPaymentResponse {
  checkoutSessionLink: string;
}
export interface WalletTopUpPlan {
  id: string;
  price: number;
  image: string;
  name: string;
  quantity: number;
  unitName: string;
}

export interface WalletTopUpPlans {
  plans: WalletTopUpPlan[];
}
interface QuotaInterface {
  quotaLeft: number;
  timeLeftForReset: number;
}
interface CharacterCreationQuotaResponse extends QuotaInterface {}
interface GenerationQuotaResponse extends QuotaInterface {}

export interface UserQuotaResponse {
  characters: CharacterCreationQuotaResponse;
  generations: GenerationQuotaResponse;
  currentPlan: UserSubscriptionPlanEnum;
  walletBalance: number;
}

export interface QuotaState {
  loading: boolean;
  error: string;
  data: UserQuotaResponse;
}

export enum UserSubscriptionPlanEnum {
  BASIC = 'BASIC',
  PRO = 'PRO',
  PRO_AUG_2024 = 'PRO_AUG_2024',
  CREATOR = 'CREATOR',
  EXPLORER = 'EXPLORER',
  STUDIO = 'STUDIO',
  ADMIN = 'ADMIN',
  ENTERPRISE = 'ENTERPRISE',
  TOP_UP = 'Top-Up',
}

export interface SubscriptionDetailsState {
  plans: SubscriptionPlan[];
  subscribedPlans: UserSubscription[];
  loading: boolean;
  error: string;
}

export interface UserState {
  walletBalance: number;
  quota: QuotaState;
  subscriptionDetails: SubscriptionDetailsState;
}

export const getUserInitState = (): UserState => {
  return {
    walletBalance: 0,
    quota: {
      loading: false,
      data: {
        characters: {
          quotaLeft: 0,
          timeLeftForReset: 0,
        },
        generations: {
          quotaLeft: 0,
          timeLeftForReset: 0,
        },
        currentPlan: UserSubscriptionPlanEnum.BASIC,
        walletBalance: 0,
      },
      error: '',
    },
    subscriptionDetails: {
      plans: [],
      subscribedPlans: [],
      loading: false,
      error: '',
    },
  };
};

export interface UserWalletTopUpPlansResponse {
  plans: TopUpPlan[];
}

export enum SubscriptionPlanMetadataKeys {
  CHARACTER_DAILY_LIMIT = 'CHARACTER_DAILY_LIMIT',
  GENERATION_DAILY_LIMIT = 'GENERATION_DAILY_LIMIT',
  PANELS_DAILY_LIMIT = 'PANELS_DAILY_LIMIT',
  DESCRIPTION = 'DESCRIPTION',
  LIMITED_TIME_OFFER = 'LIMITED_TIME_OFFER',
  ORIGINAL_PRICE = 'ORIGINAL_PRICE',
  ACTUAL_PRICE = 'ACTUAL_PRICE',
  PLAN_TITLE = 'PLAN_TITLE',
  RECOMMENDED = 'RECOMMENDED',
  TENANT = 'TENANT',
  SUBTITLE = 'SUBTITLE',
  TYPE = 'TYPE',
  POSITION = 'POSITION',
  CREDITS = 'CREDITS',
  VISIBILITY = 'VISIBILITY',
}

export interface SubscriptionPlan {
  id: string;
  name: string;
  image: string;
  price: number;
  priceId: string;
  quantity: number;
  unitName: string;
  metadata: Record<SubscriptionPlanMetadataKeys, string>;
}

export interface UserSubscription {
  id: string;
  state: string;
  priceId: string;
  productId: string;
  startDate: number;
  validThroughDate: number;
}

export interface SubscriptionPlans {
  plans: SubscriptionPlan[];
  subscribedPlans: UserSubscription[];
}

export enum TopUpPlanMetadata {
  CREDITS = 'CREDITS',
  GENERATION = 'GENERATION',
  PANELS = 'PANELS',
  POPULAR = 'POPULAR',
}

export interface TopUpPlan {
  id: string;
  name: string;
  price: number;
  metadata: { [TopUpPlanMetadata: string]: string };
}
