import { UserEnabledFeaturesActions } from '../Actions/UserEnabledFeatures';
import {
  getUserEnabledFeaturesInitState,
  UserEnabledFeaturesState,
} from '../../Models/UserEnabledFeatures';

export interface UserEnabledFeaturesAction {
  type: UserEnabledFeaturesActions;
  payload?: boolean;
}

export const UserEnabledFeaturesReducer = (
  state: UserEnabledFeaturesState = getUserEnabledFeaturesInitState(),
  action: UserEnabledFeaturesAction
): UserEnabledFeaturesState => {
  switch (action.type) {
    case UserEnabledFeaturesActions.SET_PWW:
      return { ...state, pww: action.payload! };
    case UserEnabledFeaturesActions.SET_SEGMENTATION:
      return { ...state, segmentation: action.payload! };
    case UserEnabledFeaturesActions.SET_PIX2PIX:
      return { ...state, pix2pix: action.payload! };
    case UserEnabledFeaturesActions.SET_NEGATIVE_EMBEDDING:
      return { ...state, negativeEmbedding: action.payload! };
    case UserEnabledFeaturesActions.SET_LORA_WEIGHTS:
      return { ...state, loraWeights: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_NSFW:
      return { ...state, enableNSFW: action.payload! };
    case UserEnabledFeaturesActions.SET_CREATE_CHARACTER:
      return { ...state, createCharacter: action.payload! };
    case UserEnabledFeaturesActions.SET_MAGIC_ERASER:
      return { ...state, magicEraser: action.payload! };
    case UserEnabledFeaturesActions.RESET_USER_FEATURE_ENABLED:
      return getUserEnabledFeaturesInitState();
    case UserEnabledFeaturesActions.SET_ENABLE_MULTI_INPAINT:
      return { ...state, enableMultiInpaint: action.payload! };
    case UserEnabledFeaturesActions.SET_IS_DASHTOON_USER:
      return { ...state, isDashtoonUser: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_DIFFUSERS_INPAINT:
      return { ...state, enableDiffusersInference: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_HIGH_RESOLUTION_GENERATION:
      return { ...state, enableHighResolutionGeneration: action.payload! };
    case UserEnabledFeaturesActions.SET_IMG_TO_PROMPT_POC:
      return { ...state, imgToPromptPoc: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_CLIP_SKIP:
      return { ...state, enableClipSkip: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_HIGH_RES_FIX:
      return { ...state, enableHighResFix: action.payload! };
    case UserEnabledFeaturesActions.SET_ALLOW_DEFAULT_CANVAS:
      return { ...state, allowDefaultCanvas: action.payload! };
    case UserEnabledFeaturesActions.SET_ALLOW_POST_PROCESS:
      return { ...state, allowPostProcess: action.payload! };
    case UserEnabledFeaturesActions.SET_ALLOW_AUTO_INPAINT:
      return { ...state, allowAutoInpaint: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_LIVE_MODE:
      return { ...state, allowLiveMode: action.payload! };
    case UserEnabledFeaturesActions.SET_ENABLE_NEW_PANELLING:
      return { ...state, enableNewPanelling: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_DEFAULT_TURBO_DISABLED:
      return { ...state, defaultTurboDisabled: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_DEMO_FEATURE:
      return { ...state, isDemoHackEnabled: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_V3_CANVAS_BETA_TEST:
      return { ...state, v3CanvasBetaTest: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_IS_OLD_PAYING_USER:
      return { ...state, isOldPayingUser: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_ENABLE_CANVAS_V3:
      return { ...state, enableCanvasV3: action.payload ? action.payload : false };
    case UserEnabledFeaturesActions.SET_LOADING:
      return { ...state, isLoading: action.payload ? action.payload : false };
    default:
      return state;
  }
};
