import { Box, MenuItem, MenuProps, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

// Define a type for the props
export type CustomSelectProps = {
  name: string;
  label: string;
  options: string[];
  value: string;
  placeholder: string;
  onChange: (event: SelectChangeEvent) => void;
  style?: React.CSSProperties;
  renderValue?: (value: string) => React.ReactNode;
  menuProps?: Partial<MenuProps>;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  placeholder,
  label,
  options,
  value,
  onChange,
  style,
  renderValue,
  menuProps,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      {label && <label style={{ fontSize: '12px', fontWeight: '600' }}> {label} *</label>}
      <Select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        style={{
          padding: '0px',
          margin: '0px',
          width: '100%',
          fontSize: '12px',
          height: '35px',
          ...style,
        }}
        displayEmpty={true}
        renderValue={renderValue}
        MenuProps={menuProps}
      >
        {placeholder && (
          <MenuItem key={`${name}-placeholder`} value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CustomSelect;
