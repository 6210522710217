import { Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { CharacterCreateFormState } from './CharacterCreationView';
import CustomTextField from '../../../../../../Components/CustomTextfield/CustomTextField';
import CustomSelect from './CustomSelect';

const PartOneForm = ({
  characterState,
  handleFormChange,
  footer,
}: {
  characterState: CharacterCreateFormState;
  handleFormChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => void;
  footer: React.ReactNode;
}) => {
  return (
    <Box display={'flex'} gap={2} flexDirection={'column'} mb={8}>
      <Box>Character Details</Box>
      <Box>
        Fill in the following details to proceed forward. Please describe the character to the best
        of your abilities.
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} alignItems={'end'} gap={3}>
          <Box flex={2}>
            <CustomTextField
              name="name"
              onChange={handleFormChange}
              inputProps={{ value: characterState.name }}
              label={'Character Name *'}
              placeholder={'Jane'}
              isV2={true}
              width={'100%'}
              backgroundColor={'none'}
            />
          </Box>
          <Box flex={1}>
            <CustomSelect
              name="gender"
              value={characterState.gender}
              onChange={handleFormChange}
              label="Gender"
              options={['MALE', 'FEMALE']}
              placeholder="Select"
            />
          </Box>
        </Box>
      </Box>
      <Box justifySelf={'end'}>{footer}</Box>
    </Box>
  );
};

export default PartOneForm;
