export enum PollImagesActions {
  UPDATE_POLL_IMAGES = 'UPDATE_POLL_IMAGES',
  REMOVE_POLL_IMAGE = 'REMOVE_POLL_IMAGE',
  ADD_PENDING_IMAGE = 'ADD_PENDING_IMAGE',
  REMOVE_PENDING_IMAGE = 'REMOVE_PENDING_IMAGE',
  UPDATE_PENDING_IMAGE = 'UPDATE_PENDING_IMAGE',

  UPDATE_MULTI_INPAINT_PENDING_STATE = 'UPDATE_MULTI_INPAINT_PENDING_STATE',
  REMOVE_MULTI_INPAINT_PENDING_STATE = 'REMOVE_MULTI_INPAINT_PENDING_STATE',

  ADD_PENDING_UPSCALER_IMAGE_GENERATION_ID = 'ADD_PENDING_UPSCALER_IMAGE_GENERATION_ID',
  REMOVE_PENDING_UPSCALER_IMAGE_GENERATION_IDS = 'REMOVE_PENDING_UPSCALER_IMAGE_GENERATION_IDS',
  ADD_UPSCALER_POLL_RESULTS = 'ADD_UPSCALER_POLL_RESULTS',

  ADD_PENDING_PREPROCESSOR_IMAGES = 'ADD_PENDING_PREPROCESSOR_IMAGES',
  REMOVE_PENDING_PREPROCESSOR_IMAGES = 'REMOVE_PENDING_PREPROCESSOR_IMAGES',

  ADD_PENDING_SEGMENTATION_IMAGES_GENERATION_ID = 'ADD_PENDING_SEGMENTATION_IMAGES_GENERATION_ID',
  REMOVE_PENDING_SEGMENTATION_IMAGES_GENERATION_IDS = 'REMOVE_PENDING_SEGMENTATION_IMAGES_GENERATION_IDS',
  ADD_SEGMENTATION_POLL_RESULTS = 'ADD_SEGMENTATION_POLL_RESULTS',
  ADD_BACKGROUND_REMOVAL_SEGMENT_POLL_RESULTS = 'ADD_BACKGROUND_REMOVAL_SEGMENT_POLL_RESULTS',

  ADD_PENDING_MAGIC_ERASE_IMAGES_GENERATION_ID = 'ADD_PENDING_MAGIC_ERASE_IMAGES_GENERATION_ID',
  REMOVE_PENDING_MAGIC_ERASE_IMAGES_GENERATION_IDS = 'REMOVE_PENDING_MAGIC_ERASE_IMAGES_GENERATION_IDS',
  ADD_MAGIC_ERASE_POLL_RESULTS = 'ADD_MAGIC_ERASE_POLL_RESULTS',
}
