import HttpClient from '../../Utils/HttpClient';
import {
  aiApiPrefix,
  apiPrefix,
  canvasApiPrefix,
  panelApiPrefix,
  panelTemplatePrefix,
  removeBgApiPrefix,
  segmentApiPrefix,
  speechBubblePrefix,
  studioApiPrefix,
  v2Prefix,
} from '../../Constants/Constants';
import {
  CanvasStateDiffRequest,
  CanvasStateRequest,
  GenerationStateRequest,
} from '../../Models/CanvasState';
import { SegmentRequest } from '../../Models/Segment';
import { UploadBuckets } from '../../Models/UploadBuckets';
import { PanelTemplate, PanelTemplateDTO } from '../../Models/PanelTemplate';

export const insertCanvasState = async (canvasRequest: CanvasStateRequest) => {
  try {
    const response = await HttpClient.post(
      `${studioApiPrefix}${canvasApiPrefix}/episode/${canvasRequest.episodeId}/insert`,
      canvasRequest,
      {
        resourceId: canvasRequest.showId,
      }
    );
    return response;
  } catch (error) {
    console.error(`Unable to insert canvas state`);
    return null;
  }
};

export const updateCanvasState = async (canvasRequest: CanvasStateRequest) => {
  try {
    const response = await HttpClient.post(
      `${studioApiPrefix}${canvasApiPrefix}/episode/${canvasRequest.episodeId}/edit`,
      canvasRequest,
      {
        resourceId: canvasRequest.showId,
      },
      false
    );
    return response;
  } catch (error) {
    console.error(`Unable to update canvas state`);
    return null;
  }
};

export const updateCanvasStateWithDiff = async (canvasRequest: CanvasStateDiffRequest) => {
  try {
    const response = await HttpClient.post(
      `${studioApiPrefix}${canvasApiPrefix}/episode/${canvasRequest.episodeId}/canvasState/edit`,
      canvasRequest,
      {
        resourceId: canvasRequest.showId,
      },
      false
    );
    return response;
  } catch (error) {
    console.error(`Unable to update canvas state`);
    throw error;
  }
};

export const updateGenerationState = async (canvasRequest: GenerationStateRequest) => {
  try {
    if (!canvasRequest.generationState) throw new Error('Generation state is not defined');
    const response = await HttpClient.post(
      `${studioApiPrefix}${canvasApiPrefix}/episode/${canvasRequest.episodeId}/generationState/edit`,
      canvasRequest,
      {
        resourceId: canvasRequest.showId,
      },
      false
    );
    return response;
  } catch (error) {
    console.error(`Unable to update generation state`);
    return null;
  }
};

export const getCanvasState = async (episodeId: string, showId: string) => {
  try {
    const response = await HttpClient.get(
      `${studioApiPrefix}${canvasApiPrefix}/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      }
    );
    return response;
  } catch (error) {
    console.error(`Unable to get canvas state`);
    throw error;
  }
};

export const getTutorialCanvasState = async () => {
  try {
    const response = await HttpClient.get(`${studioApiPrefix}${canvasApiPrefix}/tutorial`, {});
    return response;
  } catch (error) {
    console.error(`Unable to get canvas state`);
    throw error;
  }
};

export const autoMaskImage = async (autoMaskRequest: SegmentRequest) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${segmentApiPrefix}${removeBgApiPrefix}`,
      autoMaskRequest
    );
    return response;
  } catch (error) {
    console.error(`Unable to Auto Inpaint Image`);
    return null;
  }
};

export const uploadFileRequest = async (
  file: File,
  onProgress: (progress: number) => void,
  nsfwFilter?: boolean,
  bucketName?: UploadBuckets,
  id?: string
) => {
  if (bucketName && id) {
    try {
      const response = await HttpClient.uploadFile(
        `${studioApiPrefix}/fileUpload/upload?bucketName=${bucketName}&id=${id}&allowNsfw=${!nsfwFilter}`,
        file,
        progress => onProgress(progress)
      );
      return response;
    } catch (error) {
      console.error(`Unable to upload file`);
      return null;
    }
  } else {
    try {
      const response = await HttpClient.uploadFile(
        `${studioApiPrefix}/fileUpload/upload?&allowNsfw=${!nsfwFilter}`,
        file,
        progress => onProgress(progress)
      );
      return response;
    } catch (error) {
      console.error(`Unable to upload file`);
      return null;
    }
  }
};

export const getAllSpeechBubbles = async () => {
  try {
    const response = await HttpClient.get(`${studioApiPrefix}${speechBubblePrefix}`);
    return response;
  } catch (error) {
    console.error(`Unable to get speech bubble`);
    throw error;
  }
};

export const getAllPanelTemplates = async (): Promise<PanelTemplate[]> => {
  try {
    const response = await HttpClient.get(`${studioApiPrefix}${panelTemplatePrefix}`, {});
    return response;
  } catch (error) {
    console.error(`Unable to get panel templates`);
    return [
      {
        id: 'initialPanelTemplate',
        previewImage:
          'https://dashtoon.ai/cdn-cgi/image/fit=scale-down/https://dev-content.dashtoon.ai/user-uploaded-files/UPLl0CSrAX7DTu1PxfhOReFWl5QoE45dCnz.webp?retry=1',
        contents: [],
      },
    ];
  }
};
