import { CommentsMenuFilters, StudioCommentsActions } from '../Actions/StudioComments';
import { UserComment } from '../../Models/CommentRequests';
import { TLShape } from '@dashtoon/tldraw';

export interface StudioCommentsState {
  allComments: UserComment[];
  commentTool: boolean;
  selectedComment: TLShape[];
  selectedCommentId?: string | null;
  showCommentMenu?: boolean;
  selectedFilter: CommentsMenuFilters;
}

export const StudioCommentsInitialState: StudioCommentsState = {
  allComments: [],
  commentTool: false,
  selectedComment: [],
  selectedCommentId: null,
  showCommentMenu: false,
  selectedFilter: CommentsMenuFilters.ALL,
};

export interface StudioCommentsActionRequest {
  type: StudioCommentsActions;
  payload: any;
}

export const StudioCommentsReducer = (
  state: StudioCommentsState = StudioCommentsInitialState,
  action: StudioCommentsActionRequest
): StudioCommentsState => {
  switch (action.type) {
    case StudioCommentsActions.UPDATE_STUDIO_COMMENTS:
      return { ...state, allComments: action.payload };
    case StudioCommentsActions.ADD_STUDIO_COMMENT:
      return {
        ...state,
        allComments: [...state.allComments, action.payload].sort(
          (a, b) => a.createdAt - b.createdAt
        ),
      };
    case StudioCommentsActions.REMOVE_STUDIO_COMMENT:
      return {
        ...state,
        allComments: state.allComments?.filter(comment => comment.id !== action.payload),
      };
    case StudioCommentsActions.CHANGE_COMMENT_TOOL:
      return { ...state, commentTool: action.payload };
    case StudioCommentsActions.SELECT_COMMENT_BY_IDS:
      return { ...state, selectedComment: action.payload };
    case StudioCommentsActions.SET_SELECTED_COMMENT_ID:
      return { ...state, selectedCommentId: action.payload };
    case StudioCommentsActions.SET_IS_COMMENT_MENU:
      return { ...state, showCommentMenu: action.payload };
    default:
      return state;
  }
};
