import { SaveImageResponse } from '../../Models/SaveImage';

export enum DrawingActions {
  SET_DRAWING_IMAGE = 'SET_DRAWING_IMAGE',
  SET_IS_DRAWING_MODAL_OPEN = 'SET_IS_DRAWING_MODAL_OPEN',
  SET_CANVAS_WIDTH = 'SET_CANVAS_WIDTH',
  SET_CANVAS_HEIGHT = 'SET_CANVAS_HEIGHT',
  SET_DRAWING_CALLBACK = 'SET_DRAWING_CALLBACK',
  SET_INPAINING_IMAGE = 'SET_INPAINING_IMAGE',
  SET_INPAINING_MASK = 'SET_INPAINING_MASK',
  SET_DRAWING_BULK = 'SET_DRAWING_BULK',
}

export interface SetDrawingImageAction {
  type: typeof DrawingActions.SET_DRAWING_IMAGE;
  payload: string;
}

export interface UpdateDrawingModalAction {
  type: typeof DrawingActions.SET_IS_DRAWING_MODAL_OPEN;
  payload: boolean;
}

export interface SetDrawingCallbackAction {
  type: typeof DrawingActions.SET_DRAWING_CALLBACK;
  payload: (image: string) => void;
}

export interface SetCanvasWidthAction {
  type: typeof DrawingActions.SET_CANVAS_WIDTH;
  payload: number;
}

export interface SetCanvasHeightAction {
  type: typeof DrawingActions.SET_CANVAS_HEIGHT;
  payload: number;
}

export interface SetInpaintingImageAction {
  type: typeof DrawingActions.SET_INPAINING_IMAGE;
  payload: string;
}

export interface SetInpaintingMaskAction {
  type: typeof DrawingActions.SET_INPAINING_MASK;
  payload: string;
}

export type DrawingActionTypes =
  | SetDrawingImageAction
  | UpdateDrawingModalAction
  | SetDrawingCallbackAction
  | SetCanvasWidthAction
  | SetCanvasHeightAction
  | SetInpaintingImageAction
  | SetInpaintingMaskAction;
