import { DrawingState } from '../Redux/Reducers/Drawing';
import { TextualEmbeddingState } from '../Redux/Reducers/TextualEmbedding';

export interface TextualEmbeddingResponse {
  baseModel: string;
  defaultWeights: number;
  id: string;
  name: string;
  negativePrompt: string;
  prompt: string;
  value: string;
}

export interface TextualEmbeddingDTO {
  name: string;
  value: string;
  weight: number;
}
export const TextualEmbeddingInitialState: TextualEmbeddingState = {
  availableTextualEmbeddings: [],
};
