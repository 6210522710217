import React, { useEffect, useState } from 'react';
import { Upload, Modal, Button } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { RcFile } from 'antd/es/upload';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { ReactComponent as AttachIcon } from '../../Assets/Icons/attach.svg';
import './UploadFile.css';

interface UploadFileComponentProps {
  showPreview?: boolean;
  onFileUpload: (files: File[]) => void;
  onFileDelete?: (files: File[]) => void;
  label: string;
  accept: string;
  multiple: boolean;
  requiredWidth: number;
  requiredHeight: number;
  enforceWidth: boolean;
  enforceHeight: boolean;
  imageUrl?: string;
  style?: object;
  enforceSquareAspectRatio?: boolean;
  sizeScale?: number;
  padding?: string;
  showIcon?: boolean;
}

interface FileWithPreview extends File {
  preview?: string;
}

const UploadFileComponent: React.FC<UploadFileComponentProps> = ({
  onFileUpload,
  onFileDelete,
  label,
  accept,
  multiple,
  requiredWidth,
  requiredHeight,
  enforceWidth,
  enforceHeight,
  imageUrl,
  style,
  enforceSquareAspectRatio = false,
  showPreview = true,
  sizeScale,
  padding,
  showIcon = true,
}) => {
  const [fileList, setFileList] = useState<FileWithPreview[]>([]);
  const [validFiles, setValidFiles] = useState<FileWithPreview[]>([]); // new state variable
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const theme = useTheme();

  const fileListFromImageUrl: FileWithPreview[] = [];
  if (imageUrl !== undefined && imageUrl !== '') {
    fileListFromImageUrl.push({
      preview: imageUrl,
      name: 'selectedImage',
      lastModified: 0,
      webkitRelativePath: '',
      size: 0,
      type: '',
      arrayBuffer: function (): Promise<ArrayBuffer> {
        throw new Error('Function not implemented.');
      },
      slice: function (
        start?: number | undefined,
        end?: number | undefined,
        contentType?: string | undefined
      ): Blob {
        throw new Error('Function not implemented.');
      },
      stream: function (): ReadableStream<Uint8Array> {
        throw new Error('Function not implemented.');
      },
      text: function (): Promise<string> {
        throw new Error('Function not implemented.');
      },
    });
  }

  useEffect(() => {
    if (imageUrl !== undefined && imageUrl !== '') {
      setFileList(fileListFromImageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (validFiles.length > 0) {
      setFileList(current => (multiple ? [...current, ...validFiles] : validFiles));
      onFileUpload(validFiles);
      setValidFiles([]); // reset the validFiles
    }
  }, [validFiles]);

  const props = {
    name: 'file',
    multiple: multiple,
    accept: accept, // Update the 'accept' prop to include image, video, and other file types
    beforeUpload: (file: RcFile, fileList: RcFile[]) => {
      let processedFilesCount = 0;
      let tempValidFiles: FileWithPreview[] = [];

      fileList.forEach(file => {
        // Check file type and process accordingly
        if (file.type.startsWith('image/')) {
          // Image processing
          const image = new Image();
          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (
              (enforceWidth && width !== requiredWidth) ||
              (enforceHeight && height !== requiredHeight)
            ) {
              setModalContent(
                `Please upload an image with correct dimensions: ${requiredWidth}x${requiredHeight}`
              );
              setIsModalVisible(true);
            } else if (enforceSquareAspectRatio && width / height !== 1) {
              setModalContent(`Please upload an image with a square aspect ratio`);
              setIsModalVisible(true);
            } else {
              // Valid image file
              const fileWithPreview: FileWithPreview = file as unknown as FileWithPreview;
              fileWithPreview.preview = URL.createObjectURL(fileWithPreview);
              tempValidFiles.push(fileWithPreview);
            }
            processedFilesCount++;
            if (processedFilesCount === fileList.length) {
              setValidFiles(tempValidFiles);
            }
          };
          image.src = URL.createObjectURL(file);
        } else if (file.type.startsWith('video/')) {
          // Video processing
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.onloadedmetadata = () => {
            const width = video.videoWidth;
            const height = video.videoHeight;

            if (
              (enforceWidth && width !== requiredWidth) ||
              (enforceHeight && height !== requiredHeight)
            ) {
              setModalContent(
                `Please upload a video with correct dimensions: ${requiredWidth}x${requiredHeight}`
              );
              setIsModalVisible(true);
            } else if (enforceSquareAspectRatio && width / height !== 1) {
              setModalContent(`Please upload a video with a square aspect ratio`);
              setIsModalVisible(true);
            } else {
              // Valid video file
              const fileWithPreview: FileWithPreview = file as unknown as FileWithPreview;
              fileWithPreview.preview = URL.createObjectURL(fileWithPreview);
              tempValidFiles.push(fileWithPreview);
            }
            processedFilesCount++;
            if (processedFilesCount === fileList.length) {
              setValidFiles(tempValidFiles);
            }
          };
          video.src = URL.createObjectURL(file);
        } else {
          // Invalid file type
          setModalContent(`Please upload a valid file type: ${accept}`);
          setIsModalVisible(true);
          processedFilesCount++;
          if (processedFilesCount === fileList.length) {
            setValidFiles(tempValidFiles);
          }
        }
      });

      return false; // prevent upload
    },

    showUploadList: false,
  };

  const deleteFile = (file: FileWithPreview) => {
    setFileList(current => current.filter(f => f !== file));
    if (onFileDelete) {
      onFileDelete([]);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={t('Error')}
        open={isModalVisible}
        onOk={handleModalOk}
        centered // align the modal to center
        footer={[
          // remove cancel button and leave only confirm button
          <Button key="ok" type="primary" onClick={handleModalOk}>
            {t('OK')}
          </Button>,
        ]}
      >
        <p>{modalContent}</p>
      </Modal>
      {fileList.length > 0 && showPreview ? (
        <div style={{ position: 'relative', padding: padding ?? '10px' }}>
          <img
            src={fileList[0].preview}
            alt="Preview"
            style={
              style
                ? style
                : {
                    width: requiredWidth / (sizeScale ?? 5),
                    height: requiredHeight / (sizeScale ?? 5),
                    borderRadius: '8px',
                  }
            }
          />
          <button className="delete-button" onClick={() => deleteFile(fileList[0])}>
            <DeleteOutline />
          </button>
        </div>
      ) : (
        <div
          style={
            style
              ? style
              : {
                  width: requiredWidth / (sizeScale ?? 5),
                  height: requiredHeight / (sizeScale ?? 5),
                  padding: padding ?? '10px',
                }
          }
        >
          <Upload.Dragger {...props} className={'custom-upload'}>
            {showIcon && (
              <div style={{ height: '32px' }}>
                <AttachIcon />
              </div>
            )}
            <div style={{ fontSize: '14px' }}>
              {label.split('\n').map((line, index) => (
                <div key={index}>
                  {t(line)}
                  <br />
                </div>
              ))}
            </div>
          </Upload.Dragger>
        </div>
      )}
    </>
  );
};

export default UploadFileComponent;
