import { SavedImagesInitImages, SaveImageResponse } from './SaveImage';
import { MAX_IMAGE_GENERATION_SIZE } from '../Constants/ImageGeneration';

export enum UpscalerType {
  SCALE_BY = 'SCALE_BY',
  SCALE_TO = 'SCALE_TO',
}

export interface UpscalerState {
  image?: string;
  initImage?: SaveImageResponse;
  upscalerType: UpscalerType;
  width?: number;
  height?: number;
  scaleBy?: number;
  result?: SaveImageResponse;
}

export const getInitialUpscalerState = (): UpscalerState => {
  return {
    upscalerType: UpscalerType.SCALE_BY,
    width: MAX_IMAGE_GENERATION_SIZE,
    height: MAX_IMAGE_GENERATION_SIZE,
    scaleBy: 2,
  };
};

export enum UpscalerModel {
  DEFAULT = 'DEFAULT',
  NON_CREATIVE = 'NON_CREATIVE',
  CREATIVE_UPSCALER = 'CREATIVE_UPSCALER',
}

export interface UpscalerRequest {
  image: SavedImagesInitImages;
  width: number;
  height: number;
  times: number;
  type: string;
  showId: string;
  episodeId: string;
  frameId?: string;
  model?: UpscalerModel;
}

export interface BatchUpscalerResult {
  generationId: string;
  savedImages?: SaveImageResponse;
}

export interface BatchUpscalerResponse {
  successfulGenerations: BatchUpscalerResult[];
  failedGenerations: BatchUpscalerResult[];
  inProgressGenerations: BatchUpscalerResult[];
}

export interface BatchUpscalerRequest {
  generationIds: string[];
}

export interface UpscalerQueueResponse {
  generationId: string;
  estimatedTime: number;
}
