import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import CustomButton from '../../../../../Components/CustomButton/CustomButton';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../Redux/ReduxInterface';
import React from 'react';
import Chip from '@mui/material/Chip';

interface BasicPlanProps {
  isActive: boolean;
  onClick: () => void;
  features: string[];
}
const BasicPlan: React.FC<BasicPlanProps> = ({ isActive, onClick, features }) => {
  const ctaButtonText = isActive ? t('Current Plan') : t('Downgrade');
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const isDark = mode === 'dark';
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await onClick();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        borderRadius: 3,
        height: '100%',
        background: isDark
          ? 'linear-gradient(150deg, #202020 0%, #2C2A2A 100%)'
          : 'linear-gradient(149deg, rgba(128, 186, 255, 0.05) 0%, rgba(30, 85, 151, 0.05) 100%)',
        padding: '24px',
        color: isDark ? theme.palette.constant.white : theme.palette.constant.black,
      }}
    >
      <Stack
        spacing={2}
        direction={'column'}
        justifyContent={'space-between'}
        sx={{ height: '100%' }}
      >
        <Box flexGrow={1}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Box>
              <Typography fontSize={'20px'}>{t('Basic')}</Typography>
            </Box>
            {isActive && (
              <Chip
                variant={'filled'}
                color={'primary'}
                label={t('Current Plan')}
                size={'small'}
                sx={{
                  borderRadius: '6px',
                  fontSize: '10px',
                  color: 'white',
                  backgroundColor: theme.palette.surface.tertiary,
                }}
              />
            )}
          </Stack>
        </Box>

        <Stack flexGrow={1} direction={'column'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'end'} flexShrink={1}>
            <Typography lineHeight={'40px'} fontSize={'40px'}>
              {t('$0')}{' '}
            </Typography>
            <Typography sx={{ opacity: 0.6 }}>/ month</Typography>
          </Stack>

          <Stack sx={{ fontSize: '11px' }}>
            {features.map(feature => (
              <Box key={feature}>{feature}</Box>
            ))}
          </Stack>

          <Box justifySelf={'end'}>
            <CustomButton
              isLoading={loading}
              disabled={loading || isActive}
              width={'100%'}
              height={'48px'}
              style={{ borderRadius: '24px' }}
              backgroundColor={
                isActive ? theme.palette.surface.secondary : 'rgba(255, 131, 137, 0.10)'
              }
              foregroundColor={
                isActive ? theme.palette.object.tertiary : theme.palette.constant.red
              }
              text={ctaButtonText}
              onClick={handleClick}
              variant={'contained'}
              hasBorder={false}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BasicPlan;
