import React, {FunctionComponent, ReactNode, SVGProps} from "react";
import {ReactComponent as InstaIcon} from "../../../../Assets/Icons/instagram.svg";
import {ReactComponent as YoutubeIcon} from "../../../../Assets/Icons/youtube.svg";
import {ReactComponent as FacebookIcon} from "../../../../Assets/Icons/facebook.svg";
import {ReactComponent as XIcon} from "../../../../Assets/Icons/x.svg";
import {ReactComponent as RedditIcon} from "../../../../Assets/Icons/reddit.svg";
import {Touchable} from "@dashtoon/studio-components/dist/components/Touchable";
import {TrackingEvents} from "../../../../Constants/TrackingEvents";
import {t} from "i18next";
import {IconHolder, WidgetContainer} from "../CommonStyledComponents";
import {useTheme} from "@mui/material/styles";


enum Socials{
	INSTAGRAM = 'INSTAGRAM',
	YOUTUBE = 'YOUTUBE',
	FACEBOOK = 'FACEBOOK',
	X = "X",
	REDDIT = "REDDIT"
}
interface Social{
	id: Socials,
	icon: FunctionComponent<SVGProps<SVGSVGElement> & {   title?: string | undefined; }>,
	navigateTo: string
}

const SocialList: Social[] = [
	{
		id: Socials.INSTAGRAM,
		icon: InstaIcon,
		navigateTo: "https://www.instagram.com/dashtoonstudio/"
	},
	{
		id: Socials.YOUTUBE,
		icon: YoutubeIcon,
		navigateTo: "https://www.youtube.com/@dashtoonstudio"
	},
	{
		id: Socials.FACEBOOK,
		icon: FacebookIcon,
		navigateTo: "https://www.facebook.com/groups/414812484208696/"
	},
	{
		id: Socials.X,
		icon: XIcon,
		navigateTo: "https://twitter.com/dashstudioai"
	},
	{
		id: Socials.REDDIT,
		icon: RedditIcon,
		navigateTo: "https://www.reddit.com/r/DashtoonStudio"
	},
]

const SocialsWidget = () => {
	const theme = useTheme()
	return (
		<WidgetContainer theme={theme}>
			<div style={{marginBottom: 8, fontSize: '12px', fontWeight: 600}}>
				{t('Stay Tuned!')}
			</div>
			<div className={"socials-container"} style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '12px'
			}}>
				{SocialList.map(social => (
					<Touchable
						key={social.id}
						eventTrackingRequest={{event: TrackingEvents.socialIconClicked, properties: {'social': social.id}}}
						onClick={() => window.open(social.navigateTo)}>
						<IconHolder theme={theme}>
							<social.icon className={"social-svg"} />
						</IconHolder>
					</Touchable>
				))}
			</div>
		</WidgetContainer>

	)
}

export default SocialsWidget