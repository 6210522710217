import React, { SetStateAction } from 'react';
import { CharacterAge } from '../../../../../Models/CharacterCreationOptions';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { t } from 'i18next';
import { ReactComponent as InfantIcon } from '../../../../../Assets/Icons/character-age-infant.svg';
import { ReactComponent as UnsetIcon } from '../../../../../Assets/Icons/unset.svg';
import { ReactComponent as ChildIcon } from '../../../../../Assets/Icons/character-age-kid.svg';
import { ReactComponent as TeenagerIcon } from '../../../../../Assets/Icons/character-age-teenager.svg';
import { ReactComponent as AdultIcon } from '../../../../../Assets/Icons/character-age-adult.svg';
import { ReactComponent as OldIcon } from '../../../../../Assets/Icons/character-age-old.svg';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { useTheme } from '@mui/material/styles';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';

export interface AgeOption {
  age: CharacterAge | null;
  label: string;
  icon: ReactJSXElement;
}
export const ageOptions: AgeOption[] = [
  {
    age: null,
    label: t('Unset'),
    icon: <UnsetIcon />,
  },
  {
    age: CharacterAge.INFANT,
    label: CharacterAge.INFANT,
    icon: <InfantIcon />,
  },
  {
    age: CharacterAge.CHILD,
    label: CharacterAge.CHILD,
    icon: <ChildIcon />,
  },
  {
    age: CharacterAge.TEENAGER,
    label: CharacterAge.TEENAGER,
    icon: <TeenagerIcon />,
  },
  {
    age: CharacterAge.ADULT,
    label: CharacterAge.ADULT,
    icon: <AdultIcon />,
  },
  {
    age: CharacterAge.OLD,
    label: CharacterAge.OLD,
    icon: <OldIcon />,
  },
];

interface CharacterAgeFeatureComponentProps {
  age: AgeOption;
  setAge: (value: AgeOption) => void;
}
const CharacterAgeFeatureComponent = ({ age, setAge }: CharacterAgeFeatureComponentProps) => {
  const theme = useTheme();
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Character Age*')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {ageOptions.map(option => {
          const selected = age.age === option.age;
          return (
            <CharacterFeatureWithIconItem
              key={option.label}
              label={option.label}
              icon={option.icon}
              selected={selected}
              onClick={() => setAge(option)}
            />
          );
        })}
      </div>
    </CharacterFeatureContainer>
  );
};
export default CharacterAgeFeatureComponent;
