import { ErrorState, getErrorInitState } from '../../Models/Error';
import { ErrorActions, ErrorActionTypes } from '../Actions/Error';

export const ErrorReducer = (
  state: ErrorState = getErrorInitState(),
  action: ErrorActionTypes
): ErrorState => {
  switch (action.type) {
    case ErrorActions.UPDATE_ERROR:
      return { ...state, error: action.payload.error, errorCode: action.payload.errorCode };
    default:
      return state;
  }
};
