import { AppState } from '../Redux/Reducers/AppState';

export interface Expression {
  id: number;
  expression: string;
  prompt: string;
}

export interface ExpressionsState {
  expressions: Expression[];
}

export const InitialExpressionsState: ExpressionsState = {
  expressions: [],
};
