import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../Redux/ReduxInterface';
import { uploadFileRequest } from '../Requests/Studio/Canvas';
import { useContext, useState } from 'react';
import {
  addShowCharacters,
  characterIncreaseQuotaRequest,
  createCharacterWithMetadata,
  createCharacterWithMetadataV2,
  deleteShowCharacters,
  deleteTrainedCharacter,
  deleteTrainedCharacterV2,
  fetchAllCharacterAvailable,
  fetchAllCharacterAvailableFilteredPaginated,
  fetchCharactersForShow,
  fetchTrainingCharactersByUser,
  fetchTrainingCharactersByUserForShow,
  getCharacterMetadataDescription,
  getCharacterMetadataDescriptionV2,
  sendCharacterForDatasetGenerations,
  trainCharacterUsingImgUrls,
  updateChosenLinksComfy,
} from '../Requests/Studio/Character';
import {
  AddShowCharacterRequest,
  AvailableCharactersWithFiltersRequest,
  Character,
  CharacterMetadataResponse,
  CreateCharacterWithMetadataRequest,
  CreateCharacterWithMetadataRequestV2,
  DashCharacterTypes,
  DeleteCharacterRequest,
  DeleteShowCharacterRequest,
  Organisation,
  ProcessType,
  ShowCharacters,
} from '../Models/Character';
import useNotification from './useNotification';
import { useParams } from 'react-router-dom';
import { CharacterActions } from '../Redux/Actions/Character';
import { trackEvent } from '../Utils/Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { StyleResponseDTO } from '../Models/Styles';
import { getCDNImageUrl } from '../Utils/cdnImage';
import { AuthContext } from '../Provider/AuthProvider';
import { APIError } from '../Utils/HttpClient';
import { FeedbackRequest } from '../Models/Feedback';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useAppDispatch } from './useTypedDispatch';
import { fetchUserQuota } from '../Redux/Actions/UserQuotaSubscription';
import { getCharacterCreationType } from '../Utils/CharacterCreationUtils';

const transformMetaData = (input: string) => {
  const regex = /\{.*?\}/s;
  const match = input.match(regex);

  if (match) {
    const json = match[0];
    try {
      const parsedJson = JSON.parse(json);
      return parsedJson;
    } catch (e) {
      console.error('Failed to parse JSON:', e);
      throw e;
    }
  } else {
    console.error('No JSON found');
    throw new Error('No JSON found');
  }
};

/**
 * the goal of this hook is to work with character flows
 * this may need to be used within canvas.
 */
function useCharacters() {
  const auth = useContext(AuthContext);
  const dipatchTyped = useAppDispatch();
  const allStyles = useSelector((state: ReduxState) => state.character.allStyles);
  const styleBaseModelMapper = useSelector(
    (state: ReduxState) => state.character.styleBaseModelMapper
  );
  const selectedStyle = useSelector((state: ReduxState) => state.canvasState.selectedStyle);
  const currentStyleId = allStyles.find(style => style.value === selectedStyle)?.id ?? null;
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotification();
  const dispatch = useDispatch();
  const allCharacter = useSelector((state: ReduxState) => state.character.allCharacters);
  const trainingCharcters = useSelector((state: ReduxState) => state.character.trainingCharacters);
  const showCharacters = useSelector((state: ReduxState) => state.character.showCharacters);
  const nsfwFilter = !useSelector((state: ReduxState) => state.userEnabledFeatures.enableNSFW);
  const userQuota = useSelector((state: ReduxState) => state.quotaState);

  const getQuota = () => {
    return userQuota.data.characters;
  };
  const getCharactersByOrganisation = (organisation: Organisation) => {
    if (organisation === Organisation.DASHTOON) {
      return allCharacter.filter(character => character.organisation === Organisation.DASHTOON);
    } else if (organisation === Organisation.USER) {
      const uid = auth.currentUser?.uid;
      return allCharacter.filter(character => character.organisation === uid);
    } else {
      return allCharacter;
    }
  };

  const filterCharactersByOrganisation = (characters: Character[], organisation: Organisation) => {
    if (organisation === Organisation.DASHTOON) {
      return characters.filter(character => character.organisation === Organisation.DASHTOON);
    } else if (organisation === Organisation.USER) {
      const uid = auth.currentUser?.uid;
      return characters.filter(character => character.organisation === uid);
    } else {
      return characters;
    }
  };

  const params = useParams<{ showId: string; episodeId: string }>();
  const saveCharacterImage = async (file: File) => {
    try {
      notify.message('info', 'Uploading Image');
      const image = (await uploadFileRequest(file, progress => {}, nsfwFilter)) as { url: string };
      notify.message('success', 'Image Uploaded');
      return image;
    } catch (e) {
      notify.message('error', 'Error Uploading Image');
    }
  };

  const createCharacter = async ({
    url,
    character,
  }: {
    url: string;
    character: CreateCharacterWithMetadataRequest;
  }) => {
    try {
      notify.message('info', 'Creating Character');
      const response = await createCharacterWithMetadata(character, url);
      notify.message(
        'success',
        'Character Details Populated. Your Character will get trained soon'
      );

      if (
        character.processType !== ProcessType.MANUAL &&
        character.type !== DashCharacterTypes.PROMPT
      ) {
        await sendCharacterForDatasetGenerations(response.id);
      }
      if (character.type === DashCharacterTypes.PROMPT) {
        await getShowCharacters();
      }
      await getTrainedInShowAndUserTrainedCharacters();
      dipatchTyped(fetchUserQuota());
      notify.message('success', 'Character has been sent to queue!');
      trackEvent(
        {
          event: TrackingEvents.characterCreationAPISuccess,
          properties: {
            characterId: response.id,
            characterName: response.name,
            baseModel: response.baseModel,
            currentStyle: selectedStyle,
            showId: params.showId ?? '',
          },
        },
        'CREATOR'
      );
      dispatch({
        type: CharacterActions.ADD_TO_TRAINING_CHARACTERS,
        payload: response,
      });
      notify.message('success', 'Character Training Started');
      return response;
    } catch (e) {
      if (e instanceof APIError) {
        notify.message('error', e.message);
        throw e;
      } else {
        notify.message('error', 'An unexpected error occurred');
      }
      console.error(e);
    }
  };

  const createCharacterV2 = async ({
    character,
  }: {
    character: CreateCharacterWithMetadataRequestV2;
  }) => {
    try {
      toast.loading("We're creating your Character");
      const response = await createCharacterWithMetadataV2(character);
      if (
        character.processType !== ProcessType.MANUAL &&
        character.type !== DashCharacterTypes.PROMPT
      ) {
        await sendCharacterForDatasetGenerations(response.id);
      }
      await getMyTrainedCharacters();
      dipatchTyped(fetchUserQuota());
      trackEvent(
        {
          event: TrackingEvents.characterCreationAPISuccess,
          properties: {
            characterId: response.id,
            characterName: response.name,
            baseModel: response.baseModel,
            currentStyle: response.baseStyleName ?? '',
            showId: params.showId ?? '',
            characterCreationType: getCharacterCreationType(
              character.processType ?? ProcessType.AUTOMATED_V2,
              character.type ?? DashCharacterTypes.PROMPT
            ),
          },
        },
        'CREATOR'
      );
      if (character.type === DashCharacterTypes.TRAINED) {
        toast.success('Character Training Started');
      } else {
        toast.success('Character Created');
      }
      toast.dismiss();
      return response;
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        throw e;
      } else {
        toast.error('An unexpected error occurred');
      }
      console.error(e);
    }
  };

  const getStyleDetailsFromSelectedStyle = () => {
    return allStyles.find(style => style.value === selectedStyle);
  };

  // Used for your character.
  const isCharacterFromMyLibrary = (id: string) => {
    return trainingCharcters.some(character => character.id === id);
  };

  const needFeedback = (id: string) => {
    const character = allCharacter.find(character => character.id === id);
    return isCharacterFromMyLibrary(id) && character && !character?.feedbackDone;
  };

  const getShowCharacters = async () => {
    try {
      if (!params.showId) {
        return;
      }
      const response: ShowCharacters[] = await fetchCharactersForShow(params.showId);
      dispatch({
        type: CharacterActions.UPDATE_SHOW_CHARACTERS,
        payload: response,
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  // legacy code: need to make it better
  const getTrainedInShowAndUserTrainedCharacters = async () => {
    if (!params.showId) return;
    const result: Character[] = await fetchTrainingCharactersByUserForShow(params.showId);
    dispatch({ type: CharacterActions.UPDATE_TRAINING_CHARACTERS, payload: result });
  };

  const getMyTrainedCharacters = async () => {
    try {
      const response = await fetchTrainingCharactersByUser();
      dispatch({
        type: CharacterActions.UPDATE_TRAINING_CHARACTERS,
        payload: response,
      });
    } catch (err) {
      console.error();
    }
  };

  const trainCharacter = () => {
    // future
  };

  const isCharacterInShow = (characterId: string) => {
    return showCharacters.some(showCharacter => showCharacter.character_id === characterId);
  };

  const addCharacterToShow = async (
    charId: string,
    characterName: string,
    shouldNotify: boolean = true
  ) => {
    try {
      if (!params.showId) {
        shouldNotify && notify.message('error', 'Error Adding Character to Show');
        return;
      }
      shouldNotify && notify.message('info', 'Adding Character to Show');
      const request: AddShowCharacterRequest = {
        showId: params.showId,
        characterId: charId,
        name: characterName,
        inUse: true,
      };
      const response = await addShowCharacters([request]);
      shouldNotify && notify.message('success', 'Character Added to Show');
      await getShowCharacters();
    } catch (err) {
      shouldNotify && notify.message('error', 'Error Adding Character to Show');
    }
  };

  const addCharactersToShow = async (
    addShowCharacterRequests: AddShowCharacterRequest[],
    shouldNotify: boolean = true
  ) => {
    try {
      if (!params.showId) {
        shouldNotify && notify.message('error', 'Error Adding Character to Show');
        return;
      }
      shouldNotify && notify.message('info', 'Adding Character to Show');

      const response = await addShowCharacters(addShowCharacterRequests);
      shouldNotify && notify.message('success', 'Character Added to Show');
      await getShowCharacters();
    } catch (err) {
      shouldNotify && notify.message('error', 'Error Adding Character to Show');
    }
  };

  const removeCharacterFromShow = async (charId: string, characterName: string) => {
    try {
      if (!params.showId) {
        notify.message('error', 'Error Removing Character to Show');
        return;
      }
      notify.message('info', 'Removing Character from Show');
      const request: DeleteShowCharacterRequest = {
        showId: params.showId,
        characterId: charId,
      };
      const response = await deleteShowCharacters([request]);
      notify.message('success', 'Character removed from Show');
      await getShowCharacters();
    } catch (err) {
      notify.message('error', 'Error removing Character from Show');
      console.error(err);
    }
  };

  const removeTrainedCharacterForUser = async (charId: string, characterName: string) => {
    try {
      if (!params.showId) {
        notify.message('error', 'Error Removing Character!');
        return;
      }
      notify.message('info', 'Deleting Character');
      const request: DeleteShowCharacterRequest = {
        showId: params.showId,
        characterId: charId,
      };
      const response = await deleteTrainedCharacter(charId, params.showId);
      if (!response) {
        throw new Error('Error deleting character');
      }
      notify.message('success', 'Character Deleted!');
      dispatch({
        type: CharacterActions.UPDATE_TRAINING_CHARACTERS,
        payload: trainingCharcters.filter(character => charId !== character.id),
      });
    } catch (err) {
      notify.message('error', 'Error deleting Character!');
      console.error(err);
    }
  };

  const selectImagesAndTrainCharacters = async (charId: string, images: string[]) => {
    try {
      const response = await updateChosenLinksComfy(charId, images);
      if (!response) {
        throw new Error('Error updating links');
      }
      const character = (await trainCharacterUsingImgUrls(charId)) as Character;
      if (!character) {
        throw new Error('Error training character');
      }
      return character;
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      } else {
        toast.error(t('Error Training Character'));
      }
      console.error(err);
    }
  };

  const removeTrainedCharacterForUserV2 = async (charId: string) => {
    try {
      toast.loading('Deleting Character', {
        id: 'delete-character',
      });
      const request: DeleteCharacterRequest = {
        characterId: charId,
      };
      const response = await deleteTrainedCharacterV2(request);
      toast.dismiss('delete-character');
      if (!response) {
        throw new Error('Error deleting character');
      }
      trackEvent(
        {
          event: TrackingEvents.characterDeleted,
          properties: {
            characterId: charId,
            characterName: response.name,
          },
        },
        'CREATOR'
      );
      toast.success('Character Deleted');
      dispatch({
        type: CharacterActions.UPDATE_TRAINING_CHARACTERS,
        payload: trainingCharcters.filter(character => charId !== character.id),
      });
    } catch (err) {
      toast.error('Error deleting Character!');
      console.error(err);
    }
  };

  const getMetadataOfImage = async (image: string) => {
    try {
      const response = (await getCharacterMetadataDescription(image)) as { message: string };
      const json = transformMetaData(response.message) as Record<string, string>;
      return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getMetadataOfImageV2 = async (image: string): Promise<CharacterMetadataResponse> => {
    try {
      return await getCharacterMetadataDescriptionV2(image);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchAllCharactersAvailable = async (showId: string): Promise<Character[]> => {
    try {
      setIsLoading(true);
      const characters: Character[] = await fetchAllCharacterAvailable(showId);
      dispatch({
        type: CharacterActions.UPDATE_ALL_CHARACTERS,
        payload: characters,
      });
      return characters;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCharactersAvailableFilteredPaginated = async (
    filterRequest: AvailableCharactersWithFiltersRequest,
    page: number
  ): Promise<Character[]> => {
    try {
      if (!params.showId) {
        toast.error(t('Error while adding Character'));
        return [];
      }
      setIsLoading(true);
      const characters: Character[] = await fetchAllCharacterAvailableFilteredPaginated(
        params.showId,
        page,
        filterRequest
      );
      const ids = allCharacter.map(item => item.id);

      dispatch({
        type: CharacterActions.UPDATE_ALL_CHARACTERS,
        payload: allCharacter.concat(characters.filter(item => !ids.includes(item.id))),
      });
      return characters;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const isCharacterAvailableInStyle = (character: Character): boolean => {
    const isSameBaseModel = styleBaseModelMapper[selectedStyle] === character.baseModel;
    const characterStyles = character.styles ?? [];
    const isAvailableInStyle = characterStyles.some(item => item.styleId === currentStyleId);
    return isSameBaseModel && isAvailableInStyle;
  };

  const getThumbnailImageForCharacter = (character: Character, width: string = '160') => {
    const url =
      character?.styles?.find(item => item.styleId === currentStyleId)?.thumbnailImage ??
      character.imageUrl ??
      character.uploadedImage;

    return url ? getCDNImageUrl(url, width) : null;
  };

  const getThumbnailImageForShowCharacter = (
    showCharacter: ShowCharacters,
    width: string = '160'
  ) => {
    const character = getCharacterById(showCharacter.character_id);
    const url = character
      ? character?.styles?.find(item => item.styleId === currentStyleId)?.thumbnailImage ??
        character?.imageUrl ??
        character?.uploadedImage
      : showCharacter.image_url;

    return url ? getCDNImageUrl(url, width) : null;
  };

  const updateCharacterFeedback = async (characterId: string) => {
    dispatch({
      type: CharacterActions.UPDATE_TRAINING_CHARACTERS,
      payload: trainingCharcters.map(character => {
        if (character.id === characterId) {
          return { ...character, feedbackDone: true };
        }
        return character;
      }),
    });
    dispatch({
      type: CharacterActions.UPDATE_ALL_CHARACTERS,
      payload: allCharacter.map(character => {
        if (character.id === characterId) {
          return { ...character, feedbackDone: true };
        }
        return character;
      }),
    });
  };

  const increaseQuota = async (request: FeedbackRequest, showId: string) => {
    try {
      const response = await characterIncreaseQuotaRequest(request, showId);
      return response;
    } catch (e) {
      if (e instanceof APIError) {
        notify.message('error', e.message);
        throw e;
      } else {
        notify.message('error', 'An unexpected error occurred');
      }
      console.error(e);
    }
  };

  const getCharacterById = (id: string) => {
    return allCharacter.find(character => character.id === id);
  };

  const getStyleByValue = (value: string) => {
    return allStyles.find(style => style.value === value);
  };

  const getStyleNameById = (id: string): StyleResponseDTO | undefined => {
    return allStyles.find(style => style.id === id);
  };

  const getCharacterStyleMetadata = (character: Character) => {
    return character.styles?.find(item => item.styleId === currentStyleId)?.characterMetadata;
  };
  return {
    isLoading,
    trainingCharcters,
    getQuota,
    saveCharacterImage,
    createCharacter,
    getShowCharacters,
    addCharacterToShow,
    addCharactersToShow,
    removeCharacterFromShow,
    removeTrainedCharacterForUser,
    selectImagesAndTrainCharacters,
    getTrainedInShowAndUserTrainedCharacters,
    isCharacterInShow,
    getMetadataOfImage,
    getMetadataOfImageV2,
    fetchAllCharactersAvailable,
    isCharacterAvailableInStyle,
    getThumbnailImageForCharacter,
    getThumbnailImageForShowCharacter,
    isCharacterFromMyLibrary,
    getCharactersByOrganisation,
    needFeedback,
    updateCharacterFeedback,
    increaseQuota,
    getStyleByValue,
    getStyleDetailsFromSelectedStyle,
    getCharacterById,
    getStyleNameById,
    filterCharactersByOrganisation,
    getCharacterStyleMetadata,
    fetchCharactersAvailableFilteredPaginated,
    createCharacterV2,
    removeTrainedCharacterForUserV2,
  };
}

export default useCharacters;
