import { Card, Typography } from "@mui/material";

// Smaller component for Character Metadata
const CharacterMetadata = ({ label, value }: {
    label?: string;
    value?: string;
  }) => (
    <Card style={{ width: '180px', margin: '10px 10px 0 0', padding: '12px' }}>
      <Typography style={{ fontWeight: 'bold' }}>{value}</Typography>
      <Typography style={{ fontSize: '12px', color: 'grey' }}>{label}</Typography>
    </Card>
  );
  
export default CharacterMetadata;