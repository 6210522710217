import React, { useEffect } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';
import { ReactComponent as LogoIcon } from '../../Assets/Images/logo-only.svg';

interface LoadingScreenProps {
  backgroundColor?: string;
  label?: string;
  p?: number;
}
const LoadingScreen = ({ backgroundColor, label, p }: LoadingScreenProps) => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 50;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const mode = useSelector((state: ReduxState) => state.theme.mode);
  return (
    <Box
      p={p}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        backgroundColor: backgroundColor
          ? backgroundColor
          : mode === 'light'
          ? 'rgba(255, 255, 255, 0.8)'
          : 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <LogoIcon height={108} width={108} />
      <LinearProgress
        sx={{ width: '160px', marginTop: '64px' }}
        variant={'determinate'}
        value={progress}
      />
      {label && (
        <Typography variant={'h6'} style={{ marginTop: '16px', whiteSpace: 'pre-wrap' }}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingScreen;
