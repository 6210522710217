import { config } from '../Config/Config';

export const apiPrefix = '/api';
export const v2Prefix = '/v2';
export const appApiPrefix = '/app';
export const cmsApiPrefix = '/cms';
export const studioApiPrefix = '/studio';

export const pingLatencyUrl = `${config.baseUrl}${apiPrefix}${v2Prefix}/liveliness`;

export const liveApiPrefix = '/live';
// temp
export const liveCharacterStylePrefix = `/get-character-style-details`;

export const bugReportApiPrefix = '/bug-report';
export const textualEmbeddingPrefix = '/textual-embeddings';
export const adminApiPrefix = '/admin';
export const aiApiPrefix = '/ai';
export const readerApiPrefix = '/reader';

export const layoutApiPrefix = '/layout';
export const layoutSectionApiPrefix = '/layout/section';
export const layoutSubSectionApiPrefix = '/layout/sub-section';
export const trainingEvalPrefix = '/qc/training-images';
export const characterQCPrefix = '/character-qc';
export const challengeApiPrefix = '/challenge';
export const characterApiPrefix = '/character';
export const getCharactersByFiltersPrefix = '/get-characters';
export const episodeApiPrefix = '/episode';
export const showApiPrefix = '/show';
export const panelApiPrefix = '/panel';
export const canvasApiPrefix = '/canvas';
export const saveImageApiPrefix = '/saveImage';
export const styleApiPrefix = '/style';
export const stylesApiPrefix = '/styles';
export const saveSelectedImageApiPrefix = '/saveSelectedImage';
export const userRatingApiPrefix = '/user-rating';
export const userCommentApiPrefix = '/user-comment';
export const userHistoryApiPrefix = '/user-history';
export const userListApiPrefix = '/user-list';
export const userRoleApiPrefix = '/user-role';
export const controlNetModels = '/controlnet';

export const entityImageDetailsApiPrefix = '/entity-image-details';

export const genreApiPrefix = '/genre';
export const tropeApiPrefix = '/trope';
export const segmentApiPrefix = '/segment';
export const removeBgApiPrefix = '/remove-background';

export const imageApiPrefix = '/image';
export const eraseApiPrefix = '/erase';
export const modelApiPrefix = '/model';

export const userApiPrefix = '/user';

export const platformApiPrefix = '/platform';

export const userDataApiPrefix = '/user-data';
export const imageLibraryPrefix = '/image-library';
export const expressionsApiPrefix = '/expressions';

export const dashverseApiPrefix = '/dashverse';

export const spacing = 2;
export const DEFAULT_IMAGE_URL =
  'https://content.dashtoon.ai/imported-images/8e66950a-c67f-4963-92c6-ea6345e1b6a4.png';

export const DEFAULT_SHOW_THUMBNAIL_URL =
  'https://content.dashtoon.ai/user-uploaded-files/UPLFQgodtdjL70QsdEUECZBLyM1f3LTvPK7.webp';

export const cdnUrl = 'https://dashtoon.ai/cdn-cgi/image/';

export const strokeWidthMultiple = 400;

export const collectionPrefix = '/collection';

export const imageActionPrefix = '/imageActions';

export const CHARACTER_TRAINING_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

export const CHARACTER_ADDITIONAL_CHARA_DEFAULT = 'Blue Shirt in an office background';

export const speechBubblePrefix = '/speech-bubbles';

export const panelTemplatePrefix = '/panel-templates';

export const chatPrefix = '/chat';

export const storyPrefix = '/story';

export const comicPilotPrefix = '/comic-pilot';

export const storyCrafterPrefix = '/story-crafter';

export const feedbackPrefix = '/feedback';

export const socialAccountPrefix = '/social-account';

export const instantComicPrefix = 'https://meta-event-comic.dashtoon.ai';

export const instantReaderComicPOCPrefix = 'https://insta-comic-reader.dashtoon.ai';
