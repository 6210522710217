import { Accept, DropEvent, FileError, useDropzone } from 'react-dropzone';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { ReactComponent as UploadIcon } from '../../Assets/Icons/drag_and_upload.svg';
import { ReactComponent as MinusIcon } from '../../Assets/Icons/remove_minus.svg';

export interface DropAreaV3Props {
  color: string;
  width: string;
  height: string;
  disabled?: boolean;
  fileTypes: Accept;
  validator?: (file: any) => FileError | FileError[] | null;
  customFileHandler?: (event: any) => Promise<Array<File | DataTransferItem>>;
  removeAllFiles: () => void;
  removeSpecificFile: (file: File) => void;
  style?: any;
  receivedFiles?: File[];
  onDrop?: (acceptedFiles: File[]) => void;
  onChange?: (file: File[]) => void;
  hideEditOption?: boolean;
}

const defaultValidator = <T extends File>(file: T) => {
  return null;
};

const defaultFileGetter = async (event: DropEvent) => {
  const files: File[] = [];
  let fileList: FileList | null = null;
  if ('dataTransfer' in event && event?.dataTransfer?.files) {
    // Handle drag and drop files
    fileList = event.dataTransfer.files;
  } else if (event.target && 'files' in event.target && event.target.files) {
    // Handle files from input
    fileList = event.target.files;
  } else if (Array.isArray(event)) {
    // Handle files from react-dropzone for chrome
    const [f] = event as FileSystemFileHandle[];
    const file = await f?.getFile();
    // ignore fileList
    if (file) files.push(file);
  }

  if (fileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        files.push(file);
      }
    }
  }

  return files;
};

const DropAreaV3: React.FC<DropAreaV3Props> = ({
  width,
  color,
  height,
  fileTypes,
  disabled,
  validator,
  customFileHandler,
  removeAllFiles,
  removeSpecificFile,
  style,
  receivedFiles,
  onDrop,
  onChange,
  hideEditOption = true,
}) => {
  const theme = useTheme();

  const ref = React.createRef();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: fileTypes,
    validator: validator ?? defaultValidator,
    getFilesFromEvent: customFileHandler ?? defaultFileGetter,
    onDrop: onDrop,
  });

  const removeAll = () => {
    (receivedFiles ?? acceptedFiles).length = 0;
    (receivedFiles ?? acceptedFiles).splice(0, (receivedFiles ?? acceptedFiles).length);
    removeAllFiles();
  };

  const removeFile = (file: File) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    removeSpecificFile(file);
  };

  const inputField = () => {
    return <input {...getInputProps()} />;
  };
  useEffect(() => {
    onChange?.(acceptedFiles);
  }, [acceptedFiles]);

  if (disabled === true)
    return (
      <Box
        style={{
          width: width,
          height: height,
          border: style?.border ?? `1px solid ${color}`,
          borderRadius: style?.borderRadius ?? '4px',
          opacity: '0.6',
          background: style?.background ?? theme.palette.background.default,
          padding: '0px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            opacity: '0.6',
            cursor: 'pointer',
            width: width,
            height: height,
          }}
        >
          <Box style={{ height: '64px', width: '64px', padding: '0px' }}>
            <UploadIcon />
          </Box>
        </Box>
      </Box>
    );
  return (
    <Box
      style={{
        width: width,
        height: height,
        border: style?.border ?? `1px solid ${color}`,
        borderRadius: style?.borderRadius ?? '4px',
        background: style?.background ?? theme.palette.background.default,
        padding: '0px',
      }}
      {...getRootProps({ ref })}
    >
      {!hideEditOption && (receivedFiles ?? acceptedFiles).length > 0 ? (
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'scroll',
            width: width,
            height: height,
          }}
        >
          <Box
            style={{ width: width, display: 'flex', flexDirection: 'row-reverse', margin: '2px' }}
          >
            <Typography
              fontSize={'10px'}
              fontWeight={'600'}
              style={{ cursor: 'pointer' }}
              color={'#EE2A28'}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                removeAll();
              }}
            >
              {t('Remove all')}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              overflow: 'scroll',
              width: width,
              height: height,
              justifyContent: 'center',
            }}
          >
            {(receivedFiles ?? acceptedFiles).map((file: any, index) => (
              <Box
                key={index}
                style={{
                  width: '72px',
                  height: '72px',
                  margin: '4px',
                }}
              >
                <img
                  src={file.preview}
                  alt={file.name}
                  style={{ width: '64px', height: '64px', borderRadius: '8px' }}
                />
                <Box
                  style={{
                    position: 'relative',
                    height: '8px',
                    width: '8px',
                    bottom: '72px',
                    left: '34px',
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeFile(file);
                  }}
                >
                  <MinusIcon />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            cursor: 'pointer',
            width: width,
            height: height,
          }}
        >
          {disabled ? <></> : inputField()}
          <Box style={{ height: '64px', width: '64px', padding: '0px' }}>
            <UploadIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DropAreaV3;
