export enum ErrorActions {
  UPDATE_ERROR = 'UPDATE_ERROR',
}

export interface UpdateErrorAction {
  type: ErrorActions;
  payload: any;
}

export type ErrorActionTypes = UpdateErrorAction;
