import { ExpressionsActions } from '../Actions/Expressions';
import { ExpressionsState, InitialExpressionsState } from '../../Models/Expressions';
import { GazesActions } from '../Actions/Gaze';
import { GazeState, InitialGazeState } from '../../Models/Gaze';

export interface GazeAction {
  type: GazesActions;
  payload?: any;
}

export const GazeReducer = (state: GazeState = InitialGazeState, action: GazeAction): GazeState => {
  switch (action.type) {
    case GazesActions.SET_GAZE_MASK_LOADING:
      return { ...state, gazeMaskLoading: action.payload };
    default:
      return state;
  }
};
