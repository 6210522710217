import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { ReactComponent as LoaderIcon } from '../../Assets/Icons/LoaderIcon.svg';

function ImageCard({
  border,
  imageUrl,
  onClick,
}: {
  border?: boolean;
  imageUrl: string | undefined;
  onClick?: (characterUrl: string) => void;
}) {
  const [isHover, setIsHover] = useState<boolean>(false);

  const theme = useTheme();
  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClick?.(imageUrl ?? '')}
      style={{
        width: '10rem',
        height: '10rem',
        backgroundColor: theme.palette.grey[800],
        display: 'flex',
        filter: isHover === true ? `brightness(50%)` : `brightness(100%)`,
        margin: '0.5rem',
        cursor: 'pointer',
        borderRadius: '0.75rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {imageUrl !== undefined && (
        <img
          src={imageUrl}
          style={{
            width: '10rem',
            height: '10rem',
            borderRadius: '0.75rem',
            border:
              border === true ? `2px solid ${theme.palette.primary.main}` : `2px solid transparent`,
          }}
        ></img>
      )}
      {imageUrl === undefined && <LoaderIcon />}
    </Box>
  );
}

export default ImageCard;
