import { TLShapeId } from '@dashtoon/tldraw';
import { ShortComicPrompt } from '../../../Models/FTUE';
import {
  FirstWalkthroughStateActions,
  FirstWalkthroughStateActionTypes,
} from '../Actions/FirstWalkthroughActions';
import { UserMetadataType } from '../../../Models/UserMetadata';
import { AppStateActions } from '../../../Redux/Actions/AppState';

// state relevant to first walkthrough - frameIds, pageId, comic_prompt
export interface FirstWalkthroughState {
  frameId1: TLShapeId | undefined;
  frameId2: TLShapeId | undefined;
  pageId: TLShapeId | undefined;
  prompt?: ShortComicPrompt | undefined;
  userId: string;
  isFTUE: boolean;
  textAdded: boolean;
  speechBubbleAdded: boolean;
}

export const initialFirstWalkthroughState: FirstWalkthroughState = {
  frameId1: undefined,
  frameId2: undefined,
  pageId: undefined,
  prompt: undefined,
  isFTUE: false,
  textAdded: false,
  speechBubbleAdded: false,
  userId: '',
};

export const FirstWalkthroughReducer = (
  state: FirstWalkthroughState = initialFirstWalkthroughState,
  action: FirstWalkthroughStateActionTypes
): FirstWalkthroughState => {
  switch (action.type) {
    case FirstWalkthroughStateActions.SET_FRAME_ID1:
      return { ...state, frameId1: action.payload.frameId1 };
    case FirstWalkthroughStateActions.SET_FRAME_ID2:
      return { ...state, frameId2: action.payload.frameId2 };
    case FirstWalkthroughStateActions.SET_PAGE_ID:
      return { ...state, pageId: action.payload.pageId };
    case FirstWalkthroughStateActions.SET_PROMPT:
      return { ...state, prompt: action.payload.prompt };
    case FirstWalkthroughStateActions.SET_USER_ID:
      return { ...state, userId: action.payload.userId };
    case FirstWalkthroughStateActions.SET_IS_FTUE:
      return { ...state, isFTUE: action.payload };
    case FirstWalkthroughStateActions.SET_TEXT_ADDED:
      return { ...state, textAdded: action.payload.textAdded };
    case FirstWalkthroughStateActions.SET_SPEECH_BUBBLE_ADDED:
      return { ...state, speechBubbleAdded: action.payload.speechBubbleAdded };
    default:
      return state;
  }
};
