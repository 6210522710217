import { isProduction } from '../Config/Config';

export enum IMAGE_EXPORT_FORMAT {
  PNG = 'png',
  JPEG = 'jpeg',
  WEBP = 'webp',
}

export const DEFAULT_IMAGE_EXPORT_FORMAT = isProduction
  ? IMAGE_EXPORT_FORMAT.WEBP.toString()
  : IMAGE_EXPORT_FORMAT.PNG.toString();

export const DEFAULT_IMAGE_EXPORT_QUALITY = 1.0;

export const WATERMARK_IMAGE_URL =
  'https://dashtoon.ai/cdn-cgi/image/fit=scale-down/https://content.dashtoon.ai/user-uploaded-files/UPLoIeM4lR0BdIWVRKNQg0J61bdQFEw5m8e.webp';
