import { CollectionActions } from '../Actions/Collection';
import { AddToCollectionState, ImageCollectionLibrary } from '../../Models/ImageCollectionLibrary';
import { SaveImageResponse } from '../../Models/SaveImage';

export interface CollectionState {
  allCollections: ImageCollectionLibrary[];
  userCollections: ImageCollectionLibrary[];
  addToCollection: AddToCollectionState[];
  createNewCollection?: SaveImageResponse;
}

export const CollectionInitialState: CollectionState = {
  allCollections: [],
  userCollections: [],
  addToCollection: [],
  createNewCollection: undefined,
};

export interface CollectionActionRequest {
  type: CollectionActions;
  payload: any;
}

export const CollectionReducer = (
  state: CollectionState = CollectionInitialState,
  action: CollectionActionRequest
): CollectionState => {
  switch (action.type) {
    case CollectionActions.UPDATE_COLLECTIONS:
      return { ...state, allCollections: action.payload };
    case CollectionActions.SET_USER_COLLECTIONS:
      return { ...state, userCollections: action.payload };
    case CollectionActions.SET_ADD_TO_COLLECTION_BY_ID:
      return { ...state, addToCollection: action.payload };
    case CollectionActions.SET_CREATE_NEW_COLLECTION_IMAGE:
      return { ...state, createNewCollection: action.payload };
    default:
      return state;
  }
};
