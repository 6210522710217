import { ShortcutsActions, ShortcutsActionTypes } from '../Actions/Shortcuts';

export interface ShortcutsState {
  isShortcutsModalOpen: boolean;
}

const initialState: ShortcutsState = {
  isShortcutsModalOpen: false,
};

export const ShortcutsReducer = (
  state: ShortcutsState = initialState,
  action: ShortcutsActionTypes
): ShortcutsState => {
  switch (action.type) {
    case ShortcutsActions.SET_SHORTCUTS_MODAL_OPEN:
      return { ...state, isShortcutsModalOpen: action.payload };

    default:
      return state;
  }
};
