import { UserActions } from '../Actions/User';
import { getUserInitState, UserState } from '../../Models/User';

export interface UserAction {
  type: UserActions;
  payload?: any;
}

export const UserReducer = (
  state: UserState = getUserInitState(),
  action: UserAction
): UserState => {
  switch (action.type) {
    case UserActions.RESET_WALLET_BALANCE:
      return { ...state, walletBalance: 0 };
    case UserActions.SET_WALLET_BALANCE:
      return { ...state, walletBalance: action.payload! };
    case UserActions.USER_QUOTA_LOADING:
      return { ...state, quota: { ...state.quota, loading: true, error: '' } };
    case UserActions.USER_QUOTA_SUCCESS:
      return { ...state, quota: { loading: false, data: action.payload!, error: '' } };
    case UserActions.USER_QUOTA_ERROR:
      return { ...state, quota: { ...state.quota, loading: false, error: action.payload! } };
    case UserActions.USER_SUBSCRIPTION_LOADING:
      return {
        ...state,
        subscriptionDetails: { ...state.subscriptionDetails, loading: true, error: '' },
      };
    case UserActions.USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionDetails: {
          plans: action.payload!.plans,
          subscribedPlans: action.payload!.subscribedPlans,
          loading: false,
          error: '',
        },
      };
    case UserActions.REDUCE_GENERATION_QUOTA_COUNT:
      const finalValue = state.quota.data.generations.quotaLeft - action.payload;
      return {
        ...state,
        quota: {
          ...state.quota,
          data: {
            ...state.quota.data,
            generations: {
              ...state.quota.data.generations,
              quotaLeft: finalValue < 0 ? 0 : finalValue,
            },
          },
        },
      };
    case UserActions.USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          loading: false,
          error: action.payload!,
        },
      };
    default:
      return state;
  }
};
