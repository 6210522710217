import { TabSelectorActions } from '../Actions/TabSelector';
import { getTabSelectorInitState, TabSelectorState } from '../../Models/TabSelector';

export interface TabSelectorAction {
  type: TabSelectorActions;
  payload?: any;
}

export const TabSelectorReducer = (
  state: TabSelectorState = getTabSelectorInitState(),
  action: TabSelectorAction
): TabSelectorState => {
  switch (action.type) {
    case TabSelectorActions.SET_IMAGE_GENERATION_TAB:
      return { ...state, imageGenerationTab: action.payload };
    case TabSelectorActions.SET_CONTROLNET_TAB_SELECTOR:
      return { ...state, controlNetTabSelector: action.payload };
    case TabSelectorActions.SET_MULTI_INPAINT_OPEN:
      return { ...state, isMultiInpaintopen: action.payload };
    case TabSelectorActions.SET_MULTI_INPAINT_SELECTION:
      return { ...state, multiInpaintSelection: action.payload };
    case TabSelectorActions.SET_CAROUSEL_MODAL_OPEN:
      return { ...state, carouselModalOpen: action.payload };
    case TabSelectorActions.SET_MULTI_INPAINT_DRAW:
      return { ...state, multiInpaintDraw: action.payload };
    case TabSelectorActions.SHOW_INPAINT_MODEL:
      return { ...state, showInpaintModel: action.payload };
    case TabSelectorActions.CAN_EDIT_INPAINT_MODEL:
      return { ...state, canEditInpaintModel: action.payload };
    case TabSelectorActions.UPDATE_ON_FOCUS:
      return { ...state, onFocus: action.payload };
    case TabSelectorActions.UPDATE_LIBRARY_SEARCH_TAB:
      return { ...state, imageLibraryOpen: action.payload };
    case TabSelectorActions.UPDATE_CANVAS_STATE:
      return { ...state, saveCanvasState: action.payload };
    case TabSelectorActions.UPDATE_SHOW_DETAILS_MODAL_TAB:
      return { ...state, showDetailsModalTab: action.payload };
    case TabSelectorActions.UPDATE_DASHBOARD_SEARCH:
      return { ...state, dashboardSearch: action.payload };
    case TabSelectorActions.OPEN_CREATE_COLLECTION:
      return { ...state, openCreateCollection: action.payload };
    default:
      return state;
  }
};
