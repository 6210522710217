import React, { createContext, useEffect, useMemo } from 'react';
import useAuth, { AuthState } from '../Hooks/useAuth';
import { AppStateActions } from '../Redux/Actions/AppState';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../Hooks/useTypedDispatch';
import { fetchSubscriptionDetails, fetchUserQuota } from '../Redux/Actions/UserQuotaSubscription';

interface AuthContextType extends AuthState {}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const dispatchTyped = useAppDispatch();

  useEffect(() => {
    dispatch({
      type: AppStateActions.OPEN_LOGIN_MODAL,
      payload: !auth.currentUser,
    });
    if (auth.isVerified && auth.currentUser) {
      dispatchTyped(fetchUserQuota());
      dispatchTyped(fetchSubscriptionDetails());
    }
  }, [auth.currentUser, auth.isVerified, dispatch, dispatchTyped]);

  const contextValue = useMemo(
    () => ({
      ...auth,
    }),
    [auth]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
