import React, { useState } from 'react';
import './ProgressBar.css';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';
import { color } from '../../Constants/Colors';

interface ProgressBarProps {
  fileName: string;
  progress: number;
  isError?: boolean;
  onDelete: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ fileName, progress, isError, onDelete }) => {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();
  const mode = useSelector((state: ReduxState) => state.theme.mode);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const barColor = isHovering ? theme.palette.error.main : theme.palette.primary.main;
  const barStyle = {
    width: `${progress}%`,
    backgroundColor: barColor,
  };

  const closeButtonStyle = {
    color: isHovering
      ? progress > 0
        ? color['100']
        : color.red
      : progress > 0
      ? color['100']
      : theme.palette.text.primary,
    position: 'absolute',
  };

  const textStyle = {
    color: isHovering
      ? progress > 0
        ? color['100']
        : color.red
      : progress > 0
      ? color['100']
      : theme.palette.text.primary,
  };

  return (
    <div
      className="progress-bar-container"
      style={{ background: mode === 'dark' ? color['20'] : color['80'] }}
    >
      <div className="progress-bar-fill" style={barStyle} />
      <span style={textStyle} className="file-name">
        {fileName}
      </span>
      <IconButton
        className="close-button"
        sx={closeButtonStyle}
        onClick={() => onDelete()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CloseIcon sx={{ width: '16px', height: '16px' }} />
      </IconButton>
    </div>
  );
};

export default ProgressBar;
