import React, { useState } from 'react';
import { Character, CharacterQCStatus, CharacterStatus } from '../../../../../Models/Character';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../../Assets/Icons/delete_dustbin.svg';
import { CharactersVisible, ShowDetailsCharacterView } from '../ShowDetailsCharacters';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import useCharacters from '../../../../../Hooks/useCharacter';
import { VisibilityOutlined } from '@mui/icons-material';
import { ShowDetailsCharacterActions } from '../../../../../Redux/Actions/ShowDetailsCharacter';
import { trackEvent } from '../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../Constants/TrackingEvents';
import { ReduxState } from '../../../../../Redux/ReduxInterface';

export interface CharacterComponentProps {
  showId: string;
  character: Character;
  showName?: string;
  setCharacterHovered: React.Dispatch<React.SetStateAction<string>>;
  currentCharacterQCState?: string;
  setCurrentCharacterQCState?: (currentCharacterQCState: string) => void;
  handleOnClick: (character: Character) => void;
  imageUrl: string;
  currentCharacterView: CharactersVisible;
  isGlobal: boolean;
  getShowCharacters: () => Promise<Character[] | void>;
  userCanEdit: boolean;
  setCurrentCharacter: (character: Character) => void;
  setCharacterView: () => void;
  isShowCharacter: (characterId: string) => boolean;
  isTrainedCharacter: (characterId: string) => boolean;
  setUpdateCharacterView: (character: Character) => void;
  removeCharacterFromShow: (characterId: string, characterName: string) => Promise<void>;
  addCharacterToShow: (characterId: string, characterName: string) => Promise<void>;
  isHovered: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
}
const CharacterComponent: React.FC<CharacterComponentProps> = ({
  character,
  setCurrentCharacter,
  showName,
  showId,
  handleOnClick,
  setCurrentCharacterQCState,
  currentCharacterQCState,
  imageUrl,
  currentCharacterView,
  isGlobal,
  setCharacterView,
  isShowCharacter,
  isTrainedCharacter,
  showEdit,
  showDelete,
  setCharacterHovered,
  userCanEdit,
}) => {
  const characters = useCharacters();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hover, setHovered] = useState<boolean>(false);
  const theme = useTheme();
  const [isDeletePressed, setIsDeletePressed] = useState<boolean>(false);
  const [isReviewPass, setIsReviewPass] = useState<String>('NONE');
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const characterLibraryViewState = useSelector(
    (state: ReduxState) => state.character.characterModalLibraryViewState
  );
  const selectedStyle = useSelector((state: ReduxState) => state.canvasState.selectedStyle);
  const isAvailableForCurrentStyle = characters.isCharacterAvailableInStyle(character);
  const makeBordersForTabs = (character: Character) => {
    if (currentCharacterView === CharactersVisible.PUBLISHED) {
      if (hover) {
        return `3px solid ${theme.palette.primary.main}`;
      } else {
        return `0px`;
      }
    } else if (currentCharacterView === CharactersVisible.MY_CHARACTERS) {
      if (character.state === CharacterStatus.FAILED) {
        return `3px solid ${theme.palette.review.failed_review}`;
      }
      if (character.state === CharacterStatus.QC_CREATED)
        return `3px solid ${theme.palette.review.passed_review}`;
      else if (character.state === CharacterStatus.REVIEWED) {
        if (!hover) {
          if (isReviewPass === CharacterQCStatus.PASS) return `3px solid transparent`;
          else if (isReviewPass === CharacterQCStatus.FAIL)
            return `3px solid ${theme.palette.review.failed_review}`;
          else return `0px`;
        } else {
          if (isShowCharacter(character.id)) {
            return `3px solid ${theme.palette.review.failed_review}`;
          } else {
            if (isReviewPass == CharacterQCStatus.PASS)
              return `3px solid ${theme.palette.primary.main}`;
            else return `3px solid ${theme.palette.review.failed_review}`;
          }
        }
      } else if (isShowCharacter(character.id)) {
        if (hover) return `3px solid ${theme.palette.review.failed_review}`;
        else return `3px solid ${theme.palette.primary.main}`;
      } else return `0px`;
    } else {
      if (isShowCharacter(character.id)) {
        if (hover) {
          return `3px solid ${theme.palette.review.failed_review}`;
        } else {
          return `3px solid ${theme.palette.primary.main}`;
        }
      } else {
        if (hover) {
          return `3px solid ${theme.palette.primary.main}`;
        } else {
          return `0px`;
        }
      }
    }
  };

  const renderBadge = (character: Character) => {
    if (currentCharacterView === CharactersVisible.PUBLISHED) return <></>;
    else if (isShowCharacter(character.id)) {
      return (
        <Typography
          fontSize={'10px'}
          fontWeight={'600'}
          padding={'0px'}
          style={{
            backgroundColor: theme.palette.primary.main,
            textAlign: 'center',
            borderRadius: '5px',
            padding: '2px',
            width: '100%',
            color: 'white',
          }}
        >
          {t('Added to Dashtoon')}
        </Typography>
      );
    } else if (
      isReviewPass === CharacterQCStatus.FAIL &&
      currentCharacterView !== CharactersVisible.GLOBAL_CHARACTERS
    ) {
      return (
        <Typography
          fontSize={'10px'}
          fontWeight={'600'}
          padding={'0px'}
          style={{
            backgroundColor: theme.palette.review.failed_review,
            textAlign: 'center',
            borderRadius: '5px',
            width: '85px',
            color: 'white',
          }}
        >
          {t('Failed Review')}
        </Typography>
      );
    } else if (character.state === CharacterStatus.FAILED) {
      return (
        <Typography
          fontSize={'10px'}
          fontWeight={'600'}
          padding={'0px'}
          style={{
            backgroundColor: theme.palette.review.failed_review,
            textAlign: 'center',
            borderRadius: '5px',
            width: '85px',
            color: 'white',
          }}
        >
          {t('Failed')}
        </Typography>
      );
    } else if (!isAvailableForCurrentStyle && !isGlobal) {
      return (
        <Typography
          fontSize={'10px'}
          fontWeight={'600'}
          py={'2px'}
          style={{
            backgroundColor: theme.palette.warning.main,
            textAlign: 'center',
            width: '100%',
            color: 'white',
          }}
        >
          {t('N/A For Current Style')}{' '}
          {character?.styles?.length === 1 && (
            <Box>Use {characters.getStyleNameById(character?.styles[0].styleId)?.name}</Box>
          )}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  const hoverText = (character: Character) => {
    if (isShowCharacter(character.id)) {
      return t('Remove');
    }
    switch (character.state) {
      case CharacterStatus.CREATED:
      case CharacterStatus.IN_TRAINING:
      case CharacterStatus.COMFY_GENERATING:
      case CharacterStatus.TRAINING_ENQUEUED:
      case CharacterStatus.DATASET_GENERATION_ENQUEUED:
      case CharacterStatus.VALIDATION:
        return t('Training');
      case CharacterStatus.COMFY_GENERATED:
        return t('Select Dataset');
      case CharacterStatus.FAILED:
        return t('Failed');

      case CharacterStatus.TRAINED:
      case CharacterStatus.PUBLISHED:
      case CharacterStatus.REVIEWED:
      case CharacterStatus.QC_CREATED:
        if (!isAvailableForCurrentStyle && !isGlobal) return t('N/A');
        return t('Add to Show');

      default:
        return t('Add to Show');
    }
  };

  const hoverColor = (character: Character) => {
    if (isShowCharacter(character.id)) {
      // Remove to be seen
      return theme.palette.review.failed_review;
    } else {
      if (
        character.state === CharacterStatus.IN_TRAINING ||
        character.state === CharacterStatus.TRAINED ||
        character.state === CharacterStatus.TRAINING_ENQUEUED
      )
        return theme.palette.review.passed_review;
      else if (character.state === CharacterStatus.COMFY_GENERATED)
        return theme.palette.review.in_review;
      else if (
        character.state === CharacterStatus.COMFY_GENERATING ||
        character.state === CharacterStatus.TRAINING_ENQUEUED
      )
        return theme.palette.review.in_review;
      else if (character.state === CharacterStatus.FAILED)
        return theme.palette.review.failed_review;
      else return theme.palette.primary.main;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return (
      <Skeleton
        style={{ width: '10rem', height: '10rem', margin: '0px 4px 8px 4px' }}
        variant="rounded"
      />
    );
  }

  return (
    <Box
      className="my-characters-box"
      style={{
        width: '10rem',
        height: '10rem',
        margin: '0px 4px 8px 4px',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (
          ![
            CharacterStatus.TRAINED,
            CharacterStatus.PUBLISHED,
            CharacterStatus.REVIEWED,
            CharacterStatus.QC_CREATED,
          ].includes(character.state as CharacterStatus)
        ) {
          return;
        }
        // manage these better, needs proper cleanup for listing pages. Do it along with get-characters api
        if (!isAvailableForCurrentStyle && !isGlobal) return;
        setIsLoading(true);
        const isShowCharacterBoolean = isShowCharacter(character.id);
        if (isShowCharacterBoolean) {
          characters.removeCharacterFromShow(character.id, character.name).then(() => {
            setIsLoading(false);
          });
        } else {
          characters.addCharacterToShow(character.id, character.name).then(() => {
            setIsLoading(false);
          });
        }
        trackEvent(
          {
            event: isShowCharacterBoolean
              ? TrackingEvents.characterRemovedFromShow
              : TrackingEvents.charactedAddedToShow,
            properties: {
              characterId: character.id,
              characterName: character.name,
              baseModel: character.baseModel,
              currentStyle: selectedStyle,
              showId: showId,
              from: characterLibraryViewState.toString(),
            },
          },
          'CREATOR'
        );
      }}
      onMouseOver={() => {
        setHovered(true);
        setCharacterHovered(character.id);
      }}
      onMouseOut={() => {
        setHovered(false);
        setCharacterHovered(character.id);
      }}
      onTouchStart={() => {
        setHovered(true);
        setCharacterHovered(character.id);
      }}
      onTouchEnd={() => {
        setHovered(false);
        setCharacterHovered(character.id);
      }}
    >
      <img
        className="my-characters-img"
        src={imageUrl}
        alt={character.name}
        style={{
          width: '10rem',
          height: '10rem',
          borderRadius: '8px',
          border: makeBordersForTabs(character),
          filter: hover ? 'brightness(50%)' : 'brightness(100%)',
          cursor: 'pointer',
          objectFit: 'cover',
        }}
      />
      <Box
        style={{
          position: 'relative',
          padding: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '10rem',
          height: '10rem',
          bottom: '166px',
        }}
      >
        <Box
          style={{
            width: '10rem',
            height: '128px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {showEdit && (
              <Box
                style={{
                  height: '20px',
                  width: '20px',
                  marginLeft: '12px',
                  marginTop: '12px',
                  cursor: 'pointer',
                }}
                onClick={e => {
                  dispatch({
                    type: ShowDetailsCharacterActions.CHARACTER_VIEW,
                    payload: ShowDetailsCharacterView.CHARACTER_VIEW,
                  });
                  setCurrentCharacter(character);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <VisibilityOutlined style={{ fill: theme.palette.primary.main }} />
              </Box>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Warning!'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button
                  variant={'contained'}
                  onClick={e => {
                    setIsLoading(true);
                    characters
                      .removeTrainedCharacterForUser(character.id, character.name)
                      .then(() => {
                        setIsLoading(false);
                      });
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                    handleClose();
                  }}
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            {showDelete && (
              <Box
                style={{
                  display: 'flex',
                  justifySelf: 'space-between',
                  height: '20px',
                  width: '20px',
                  marginRight: '12px',
                  marginTop: '12px',
                  cursor: 'pointer',
                }}
                onClick={handleClickOpen}
              >
                <DeleteIcon style={{ fill: theme.palette.review.failed_review }} />
              </Box>
            )}
          </div>
          <div>
            {hover ? (
              <Typography
                style={{
                  color: hoverColor(character),
                  fontSize: '17px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                {hoverText(character)}
              </Typography>
            ) : (
              ''
            )}
          </div>
        </Box>
        <Box
          style={{
            width: '100%',
            height: '32px',
            padding: '0px',
            display: 'flex',
            borderRadius: '0 0 4px 4px',
            background: 'black',
            position: 'relative',
            bottom: 0,
            // set  background opacity 0.5 black
          }}
        >
          <Box
            style={{
              alignSelf: 'center',
              position: 'absolute',
              width: '100%',
              bottom: '2rem',
            }}
          >
            {renderBadge(character)}
          </Box>
          <Box
            style={{
              alignSelf: 'center',
              padding: '4px',
              width: '100%',
            }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={'600'}
              padding={'0px'}
              color={theme.palette.common.white}
            >
              {showName ?? character?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterComponent;
