import {
  EpisodeChunkDTO,
  StoryCrafterEntity,
  StoryCrafterEntityAssociation,
  StoryType,
} from '../../Models/StoryMode/StoryCrafter';
import {
  StoryCrafterStateActionV2,
  StoryCrafterStateV2ActionRequest,
} from '../Actions/StoryCrafterStateV2';
import { EpisodeChunk } from '../../Models/NovelPanel';
import {
  defaultBulkEditingState,
  StoryCrafterBlock,
  StoryCrafterBulkEditingState,
} from '../../Models/StoryMode/StoryCrafterBlocks';
import { PROMPT2STORYOptionType } from '../../Components/StoryMode/Story/InitialOptionComponent/InitialOptionComponent';

export interface StoryCrafterStateV2 {
  storyChunks: EpisodeChunkDTO[];
  episodeChunks: EpisodeChunk[];
  entities: StoryCrafterEntity[];
  associations: StoryCrafterEntityAssociation[];
  characterExtractionLoading: boolean;
  screenplayLoading: boolean;
  fetchingChunks: boolean;
  addPanelsFromScratchLoading: boolean;
  addPanelsLoading: boolean;
  characterModalOpen: boolean;
  deletingEntity: boolean;
  blocks: StoryCrafterBlock[];
  newBlocks: string[];
  updatedBlocks: string[];
  updatedDialogs: string[];
  shapeAssociationMap: Record<string, string>;
  expandAll: boolean;
  storyType: StoryType;
  story: string | null;
  selectedOption: PROMPT2STORYOptionType | null;
  storyLoading: boolean;
  storyError: string;
  lastBlockSeen: boolean;
  lastImageGenerated: boolean;
  bulkEditingState: StoryCrafterBulkEditingState;
  copiedBlock: StoryCrafterBlock | null;
}

export const initialStoryCrafterStateV2: StoryCrafterStateV2 = {
  storyChunks: [],
  episodeChunks: [],
  entities: [],
  associations: [],
  characterExtractionLoading: false,
  screenplayLoading: false,
  fetchingChunks: false,
  addPanelsFromScratchLoading: false,
  addPanelsLoading: false,
  characterModalOpen: false,
  deletingEntity: false,
  blocks: [],
  newBlocks: [],
  updatedBlocks: [],
  updatedDialogs: [],
  shapeAssociationMap: {},
  expandAll: false,
  storyType: StoryType.USER_STORY,
  story: null,
  selectedOption: null,
  storyLoading: false,
  storyError: '',
  lastBlockSeen: false,
  lastImageGenerated: false,
  bulkEditingState: defaultBulkEditingState,
  copiedBlock: null,
};

export const StoryCrafterStateV2Reducer = (
  state: StoryCrafterStateV2 = initialStoryCrafterStateV2,
  action: StoryCrafterStateV2ActionRequest
): StoryCrafterStateV2 => {
  switch (action.type) {
    case StoryCrafterStateActionV2.SET_STORY_CHUNKS:
      return { ...state, storyChunks: action.payload.storyChunks };
    case StoryCrafterStateActionV2.SET_EPISODE_CHUNKS:
      return { ...state, episodeChunks: action.payload.episodeChunks };
    case StoryCrafterStateActionV2.SET_ENTITIES:
      return { ...state, entities: action.payload.entities };
    case StoryCrafterStateActionV2.SET_ASSOCIATIONS:
      return { ...state, associations: action.payload.associations };
    case StoryCrafterStateActionV2.SET_CHARACTER_EXTRACTION_LOADING:
      return { ...state, characterExtractionLoading: action.payload.characterExtractionLoading };
    case StoryCrafterStateActionV2.SET_SCREENPLAY_LOADING:
      return { ...state, screenplayLoading: action.payload.screenplayLoading };
    case StoryCrafterStateActionV2.SET_ADD_PANELS_FROM_SCRATCH_LOADING:
      return { ...state, addPanelsFromScratchLoading: action.payload.addPanelsFromScratchLoading };
    case StoryCrafterStateActionV2.SET_ADD_PANELS_LOADING:
      return { ...state, addPanelsLoading: action.payload.addPanelsLoading };
    case StoryCrafterStateActionV2.SET_CHARACTER_MODAL_OPEN:
      return { ...state, characterModalOpen: action.payload.characterModalOpen };
    case StoryCrafterStateActionV2.SET_DELETING_ENTITY:
      return { ...state, deletingEntity: action.payload.deletingEntity };
    case StoryCrafterStateActionV2.SET_BLOCKS:
      return { ...state, blocks: action.payload.blocks };
    case StoryCrafterStateActionV2.SET_NEW_BLOCKS:
      return { ...state, newBlocks: action.payload.newBlocks };
    case StoryCrafterStateActionV2.ADD_TO_UPDATED_BLOCKS:
      return { ...state, updatedBlocks: [...state.updatedBlocks, ...action.payload.updatedBlocks] };
    case StoryCrafterStateActionV2.REMOVE_FROM_UPDATED_BLOCKS:
      return {
        ...state,
        updatedBlocks: state.updatedBlocks.filter(
          blockId => !action.payload.updatedBlocks.includes(blockId)
        ),
      };
    case StoryCrafterStateActionV2.ADD_TO_UPDATED_DIALOGS:
      return {
        ...state,
        updatedDialogs: [...state.updatedDialogs, ...action.payload.updatedDialogs],
      };
    case StoryCrafterStateActionV2.SET_SHAPE_ASSOCIATION_MAP:
      return { ...state, shapeAssociationMap: action.payload.shapeAssociationMap };
    case StoryCrafterStateActionV2.SET_EXPAND_ALL:
      return { ...state, expandAll: action.payload.expandAll };
    case StoryCrafterStateActionV2.RESET_STORYCRAFTER_STATE:
      return initialStoryCrafterStateV2;
    case StoryCrafterStateActionV2.SET_STORY_TYPE:
      return { ...state, storyType: action.payload.storyType };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_STORY:
      return { ...state, story: action.payload.story };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.payload.selectedOption,
      };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_STORY_LOADING:
      return { ...state, storyLoading: action.payload.storyLoading };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_STORY_ERROR:
      return { ...state, storyError: action.payload.storyError };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_LAST_BLOCK_SEEN:
      return { ...state, lastBlockSeen: action.payload.lastBlockSeen };
    case StoryCrafterStateActionV2.SET_STORYCRAFTER_LAST_IMAGE_GENERATED:
      return { ...state, lastImageGenerated: action.payload.lastImageGenerated };
    case StoryCrafterStateActionV2.SET_FETCHING_CHUNKS:
      return { ...state, fetchingChunks: action.payload.fetchingChunks };
    case StoryCrafterStateActionV2.SET_BULK_EDITING_STATE:
      return { ...state, bulkEditingState: action.payload.bulkEditingState };
    case StoryCrafterStateActionV2.SET_COPIED_BLOCK:
      return { ...state, copiedBlock: action.payload.copiedBlock };
    default:
      return state;
  }
};
