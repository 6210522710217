import {TextEnhancerActions} from '../Actions/TextEnhancer';

export interface TextEnhancerState {
  shapeId: string;
  textEnhancerStack: string[];
  textStackIndex: number;
  type: "prompt" | "negativePrompt" | "text";
}

export interface TextEnhancerReduxState {
  textEnhancer: TextEnhancerState[];
}

export const TextEnhancerInitialState: TextEnhancerReduxState = {
  textEnhancer: [],
};

export interface TextEnhancerActionRequest {
  type: TextEnhancerActions;
  payload: TextEnhancerState;
}

export const TextEnhancerReducer = (
  state: TextEnhancerReduxState = TextEnhancerInitialState,
  action: TextEnhancerActionRequest
): TextEnhancerReduxState => {
  switch (action.type) {
    case TextEnhancerActions.ADD_TEXT_ENHANCER:
      const currentTextEnhancer = state.textEnhancer.find(
        (textEnhancer) => textEnhancer.shapeId === action.payload.shapeId && textEnhancer.type === action.payload.type
      );
      if (currentTextEnhancer) {
        return {
          ...state,
          textEnhancer: state.textEnhancer.map((textEnhancer) => {
            if (textEnhancer.shapeId === action.payload.shapeId && textEnhancer.type === action.payload.type) {
              return {
                ...textEnhancer,
                textEnhancerStack: action.payload.textEnhancerStack,
                textStackIndex: action.payload.textStackIndex,
              };
            }
            return textEnhancer;
          }),
        };
      }
      return {...state, textEnhancer: [...state.textEnhancer, action.payload]};
    default:
      return state;
  }
};
