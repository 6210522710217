import React, { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Link, Modal, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CrossIconBase } from '../../../../Assets/Icons/cross.svg';
import OopsIcon from '../../../../Assets/Images/oops-emoji.png';

import { t } from 'i18next';
import { AuthContext } from '../../../../Provider/AuthProvider';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import { trackEvent } from '../../../../Utils/Analytics';
import useNotification from '../../../../Hooks/useNotification';
import { useAppDispatch } from '../../../../Hooks/useTypedDispatch';
import { fetchUserQuota } from '../../../../Redux/Actions/UserQuotaSubscription';
import { UserSubscriptionPlanEnum } from '../../../../Models/User';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';

const SubscriptionPaymentFailureModal: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const visible = searchParams.get('failure') === 'true';
  const planType = searchParams.get('planType') as UserSubscriptionPlanEnum;

  const notify = useNotification();
  const auth = useContext(AuthContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    height: '512px',
    width: '480px',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: 24,
    border: '1px solid',
    borderColor: theme.palette.surface.tertiary,
    '&:focus': {
      outline: 'none',
    },
  };

  const handleModalClose = () => {
    setSearchParams({}, { replace: true });
  };

  useEffect(() => {
    if (visible) {
      notify.message('error', 'You subscription couldn’t be completed!');
      auth.currentUser && setTimeout(() => dispatch(fetchUserQuota()), 2000);
      trackEvent(
        {
          event:
            planType === UserSubscriptionPlanEnum.TOP_UP
              ? TrackingEvents.paymentTopupRedirectFailure
              : TrackingEvents.paymentSubscriptionRedirectFailure,
          properties: {},
        },
        'CREATOR'
      );
    }
  }, [auth.currentUser?.uid]);

  return (
    <Modal open={visible} hideBackdrop={true}>
      <Stack sx={style}>
        <Stack
          px={3}
          pt={3}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Box flexGrow={1}>
            <Typography variant={'h4'}>{t('Oops!')}</Typography>
          </Box>
          <CrossIconBase onClick={handleModalClose} />
        </Stack>
        <Stack
          spacing={2}
          direction={'column'}
          justifyContent={'space-between'}
          sx={{ height: '100%', backgroundColor: theme.palette.background.default }}
          px={3}
          pb={4}
        >
          <Stack flexGrow={1} spacing={2} direction={'row'} justifyContent={'space-between'}>
            <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
              <Box position={'relative'}>
                <img src={OopsIcon} alt={'Oops Icon'} />
              </Box>
            </Stack>
            <Stack flex={1} gap={1.5} direction={'column'} justifyContent={'center'}>
              <Box>
                <Typography fontSize={'12px'}>Hi {auth.currentUser?.displayName},</Typography>
              </Box>
              <Box>
                <Typography fontSize={'24px'} fontWeight={600} lineHeight={'28px'}>
                  {t("We couldn't complete your purchase.")}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack px={4} pb={4} pt={4}>
          <Typography fontSize="16px">
            {t('Please try again or check your payment method. If the problem persists, ')}

            <Link
              sx={{
                textDecoration: 'none',
              }}
              href="mailto:help@dashtoon.com?subject=Payment Issues"
            >
              {t('please let us know.')}
            </Link>
          </Typography>
          <Box mt={3}>
            <CustomButton
              width={'100%'}
              height={'48px'}
              foregroundColor={'white'}
              hasBorder={false}
              text={t('Retry')}
              onClick={handleModalClose}
              variant={'contained'}
            ></CustomButton>
          </Box>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default SubscriptionPaymentFailureModal;
