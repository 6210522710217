import { ShowDetailsCharacterView } from "../../Screens/Studio/Canvas/ShowDetails/ShowDetailsCharacters";

export enum ShowDetailsCharacterActions {
    LIBRARY = "LIBRARY",
    ADD_CHARACTER = "ADD_CHARACTER",
    TRAINING_EVAL = "TRAINING_EVAL",
    CHARACTER_VIEW = "CHARACTER_VIEW",
    TRAINING_IN_PROGRESS = "TRAINING_IN_PROGRESS",
    TRAINING_FAILED = "TRAINING_FAILED",
    TRAININED_COMPLETED = "TRAININED_COMPLETED",
    REVIEW = "REVIEW",
    REVIEW_PAGE = "REVIEW_PAGE",
}

export enum CharacterFromCanvasActions {
  CHARACTER_FROM_CANVAS = 'CHARACTER_FROM_CANVAS',
  LOADING_CHARACTER = 'LOADING_CHARACTER',
}

export interface CharacterFromCanvasActionTypes {
    type: CharacterFromCanvasActions,
    payload: boolean
}

export interface ShowDetailsCharacterActionTypes {
    type: ShowDetailsCharacterActions,
    payload: any
}