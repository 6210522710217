import { t } from 'i18next';

export interface GenMode {
  id: string;
  name: string;
  description: string;
}

export const GenModes: GenMode[] = [
  {
    id: 't2i',
    name: 'Text2Image',
    description: 'Generate images from a text prompt',
  },
  {
    id: 'inpaint',
    name: 'InPaint',
    description: 'Edit selected image parts',
  },
  {
    id: 's2c',
    name: 'Storyboard2Comic',
    description: 'Create images from storyboards',
  },
  {
    id: 'i2i',
    name: 'Image2Image',
    description: 'Create variations of an existing image',
  },
  {
    id: 'e2c',
    name: 'Edge2Comic',
    description: 'Create images from reference photos',
  },
];

export enum GenModeEnum {
  T2I = 't2i',
  INPAINT = 'inpaint',
  S2C = 's2c',
  LIVE = 'live',
  I2I = 'i2i',
  E2C = 'e2c',
}
