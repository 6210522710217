import { ReactElement, createContext } from 'react';
import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';

interface NotificationProviderProps {
  children: React.ReactNode;
}

interface NotificationContextProps {
  messageApi?: MessageInstance;
  contextHolder?: ReactElement
}

const NotificationContext = createContext<NotificationContextProps>({});

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <NotificationContext.Provider value={{ messageApi, contextHolder }}>
      <>{contextHolder}</>
      <>{children}</>
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
