import React from 'react';
import { Typography } from '@mui/material';
import './Dashboard.css';
import Box from '@mui/material/Box';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import CustomTextFieldWithIcon from '../../../Components/CustomTextFieldWithIcon/CustomTextFieldWithIcon';
import { t } from 'i18next';

function SearchBar(props: any) {
  const theme = useTheme();
  return (
    <Box
      style={{
        display: 'flex',
        // CustomTextFieldWithIcon has a margin Top
        marginTop: '-12px',
      }}
      ml={{
        xs: '-12px',
        sm: '0px',
      }}
    >
      <CustomTextFieldWithIcon
        id={'search'}
        label=""
        inputProps={{
          value: props.search ?? '',
        }}
        placeholder={t(props.placeholder ?? 'Search')}
        multiline={false}
        onChange={props.handleSearch}
        icon={<Search sx={{ fontSize: '20px' }} />}
      />
    </Box>
  );
}

export default SearchBar;
