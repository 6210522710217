import { User } from 'firebase/auth';
import { config } from '../Config/Config';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { TrackingProperties } from '../Constants/TrackingProperties';
import posthog from 'posthog-js';
import { TimedTrackingEvents } from '../Constants/TimedTrackingEvents';
import { dashTimedEventsDictionary } from './DashAnalytics';

export const posthogInit = () => {
  posthog.init(config.publicPosthogKey!, {
    api_host: config.posthogApiHost,
  });
};

export const registerUserProfile = (user: User) => {
  posthog.identify(user.uid, {
    userId: user.uid,
    userEmail: user.email,
    userName: user.displayName,
  });
};

export const trackPostHogEvent = (event: TrackingEvents, properties: TrackingProperties) => {
  // @ts-ignore
  posthog.capture(event, { ...properties, tenant_id: config.posthogTenant });
};

export const trackTimedPostHogEvent = (
  event: TimedTrackingEvents,
  properties: TrackingProperties,
  uuid: string,
  eventName?: string
) => {
  properties = {
    ...properties,
    time_duration: (
      new Date().getTime() - (dashTimedEventsDictionary[uuid]?.startTime ?? new Date().getTime())
    ).toString(),
    // @ts-ignore
    new_duration:
      new Date().getTime() - (dashTimedEventsDictionary[uuid]?.startTime ?? new Date().getTime()),
    tenant_id: config.posthogTenant ?? 'studio_dev',
  };
  posthog.capture(eventName ? eventName : event, properties);
};
