import HttpClient from '../../Utils/HttpClient';
import { imageActionPrefix, studioApiPrefix } from '../../Constants/Constants';

export const addDownloadAction = async (imageId: number, showId: String) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${imageActionPrefix}/${imageId}/DOWNLOADED`,
      {},
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Error in adding download action for image ${imageId} for show ${showId}`);
    return null;
  }
};

export const addPinnedAction = async (imageId: number, showId: String) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${imageActionPrefix}/${imageId}/PINNED`,
      {},
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Error in adding pinned action for image ${imageId} for show ${showId}`);
    return null;
  }
};

export const removePinnedAction = async (imageId: number, showId: String) => {
  try {
    return HttpClient.delete(
      `${studioApiPrefix}${imageActionPrefix}/${imageId}/PINNED`,
      {},
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Error in removing pinned action for image ${imageId} for show ${showId}`);
    return null;
  }
};

export const addDraggedImage = async (imageId: number, showId: String) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${imageActionPrefix}/${imageId}/USED`,
      {},
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Error in adding dragged action for image ${imageId} for show ${showId}`);
    return null;
  }
};

export const getAllImageActions = async (showId: String) => {
  try {
    return HttpClient.get(`${studioApiPrefix}${imageActionPrefix}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Error in fetching all image actions for show ${showId}`);
    return null;
  }
};
