import React from 'react';
import { t } from 'i18next';
import { ReactComponent as BugIcon } from '../../../../Assets/Icons/bug-report.svg';
import { ReactComponent as FaqIcon } from '../../../../Assets/Icons/faq.svg';
import { ReactComponent as MailIcon } from '../../../../Assets/Icons/mail.svg';
import { IconHolder, WidgetContainer, WidgetItem } from '../CommonStyledComponents';
import { useTheme } from '@mui/material/styles';

interface Props {
  toggleModal: () => void;
}

const ReportBugAndFAQWidget = ({ toggleModal }: Props) => {
  const theme = useTheme();
  return (
    <WidgetContainer theme={theme}>
      <WidgetItem
        theme={theme}
        onClick={() => {
          window.open('mailto:help@dashtoon.com?subject=Contact Dashtoon Studio');
        }}
      >
        <IconHolder className={'widget-item-child'} theme={theme}>
          <MailIcon />
        </IconHolder>
        <div className={'widget-item-child'}>{t('Contact Dashtoon Studio')}</div>
      </WidgetItem>

      <WidgetItem theme={theme} onClick={toggleModal}>
        <IconHolder className={'widget-item-child'} theme={theme}>
          <BugIcon />
        </IconHolder>
        <div className={'widget-item-child'}>{t('Report a Bug')}</div>
      </WidgetItem>

      <WidgetItem
        theme={theme}
        onClick={() =>
          window.open('https://dashtoon.notion.site/FAQ-37e51aa439ee4546b5db5f95931bfa3c ')
        }
      >
        <IconHolder className={'widget-item-child'} theme={theme}>
          <FaqIcon />
        </IconHolder>
        <div className={'widget-item-child'}>{t('Frequently Asked Questions')}</div>
      </WidgetItem>
    </WidgetContainer>
  );
};

export default ReportBugAndFAQWidget;
