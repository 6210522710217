import { Show } from '../../Models/Show';
import { AllShowsActions } from '../Actions/AllShows';
import { TrainingStatusActions } from '../Actions/TrainingStatus';

export interface TrainingStatusState {
  trainingState: String;
}

export const TrainingStatusInitialState: TrainingStatusState = {
  trainingState: '0000',
};

export interface TrainingStateActionRequest {
  type: TrainingStatusActions;
  payload: any;
}

export const TrainingStateReducer = (
  state: TrainingStatusState = TrainingStatusInitialState,
  action: TrainingStateActionRequest
): TrainingStatusState => {
  switch (action.type) {
    case TrainingStatusActions.DONE_LOADING:
      return {
        ...state,
        trainingState: action.payload
      };
    case TrainingStatusActions.LOADING:
        return {
            ...state,
            trainingState: action.payload
        }
    default:
      return state;
  }
};
