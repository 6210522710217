import { User } from 'firebase/auth';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { ReduxState } from '../../Redux/ReduxInterface';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';
import { TrackingProperties } from '../../Constants/TrackingProperties';
import UserSettings from './UserSettings';
import discordDark from '../../Assets/Images/discord_dark.png';
import discordLight from '../../Assets/Images/discord_light.png';
import { Button } from '@mui/material';
import { checkOS, checkURLIncludes } from '../../Utils/CommonUtils';
import { ShortcutsActions } from '../../Redux/Actions/Shortcuts';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { ReactComponent as CoinsIcon } from '../../Assets/Icons/dashcoin.svg';
import MapsUgcSharpIcon from '@mui/icons-material/MapsUgcSharp';
import { StudioCommentsActions } from '../../Redux/Actions/StudioComments';
import { AppStateActions } from '../../Redux/Actions/AppState';
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import CustomButton from '../CustomButton/CustomButton';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import { useEffect } from 'react';
import { ReactComponent as SaveIcon } from '../../Assets/Icons/save.svg';
import { ReactComponent as NotificationIcon } from '../../Assets/Icons/notification.svg';
import { ReactComponent as SuccessIcon } from '../../Assets/Icons/success.svg';
import { color } from '../../Constants/Colors';
import usePing from '../../Hooks/usePing';
import NetworkAlert from '../NetworkAlert/NetworkAlert';

interface UserInformationProps {
  user: User | null;
  anchorElUser: HTMLElement | null;
  transparentBackground: boolean;
  handleOpenTopUpModal: () => void;
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseUserMenu: () => void;
  handleLogout: () => void;
  isCanvas: boolean;
}

const UserInformation: React.FC<UserInformationProps> = ({
  user,
  anchorElUser,
  transparentBackground,
  handleOpenTopUpModal,
  handleOpenUserMenu,
  handleCloseUserMenu,
  handleLogout,
  isCanvas,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const walletBalance = useSelector((state: ReduxState) => state.user.walletBalance);
  const saveCanvasState = useSelector((state: ReduxState) => state.tabSelector.saveCanvasState);
  const isCanvasStateSaving = useSelector(
    (state: ReduxState) => state.canvasState.isCanvasStateSaving
  );
  const isGenerationStateSaving = useSelector(
    (state: ReduxState) => state.canvasState.isGenerationStateSaving
  );
  const hasCanvasStateChanges = useSelector(
    (state: ReduxState) => state.canvasState.hasCanvasStateChanges
  );
  const hasGenerationStateChanges = useSelector(
    (state: ReduxState) => state.canvasState.hasGenerationStateChanges
  );
  const userEnabledFeatures = useSelector((state: ReduxState) => state.userEnabledFeatures);

  const ping = usePing(1000 * 30, isCanvas);

  const handleSaveClicked = () => {
    dispatch({
      type: TabSelectorActions.UPDATE_CANVAS_STATE,
      payload: !saveCanvasState,
    });
  };

  useEffect(() => {
    const handleSave = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        handleSaveClicked();
      }
    };
    window.addEventListener('keydown', handleSave);
    return () => {
      window.removeEventListener('keydown', handleSave);
    };
  }, [handleSaveClicked]);

  const openPaymentsModal = () => {
    const enabledCanvasV3 = userEnabledFeatures.enableCanvasV3;
    dispatch({
      type: enabledCanvasV3
        ? AppStateActions.OPEN_PAYMENTS_MODAL_V3
        : AppStateActions.OPEN_PAYMENTS_MODAL,
      payload: true,
    });
  };

  return transparentBackground ? (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex' }}></Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignContent: 'center',
          height: '36px',
          alignItems: 'center',
        }}
      >
        {isCanvas && ping && (
          <div style={{ marginRight: '8px', color: 'white' }}>
            <NetworkAlert
              networkStatus={ping < 1000 ? 'good' : ping > 1000 && ping < 2000 ? 'average' : 'bad'}
            />
          </div>
        )}
        {isCanvas && (
          <div style={{ marginRight: '8px' }}>
            <CustomButton
              variant={'outlined'}
              text={
                isCanvasStateSaving || isGenerationStateSaving
                  ? 'Saving'
                  : !hasCanvasStateChanges && !hasGenerationStateChanges
                  ? 'Saved'
                  : 'Save'
              }
              onClick={() => handleSaveClicked()}
              hasIcon={true}
              height={'32px'}
              icon={
                !hasCanvasStateChanges && !hasGenerationStateChanges ? (
                  <SuccessIcon />
                ) : (
                  <SaveIcon />
                )
              }
              isLoading={isCanvasStateSaving || isGenerationStateSaving}
              foregroundColor={
                isCanvasStateSaving || isGenerationStateSaving ? color.green : color.blue
              }
            />
          </div>
        )}
        <Box
          style={{
            height: '36px',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <UserSettings
            user={user}
            anchorElUser={anchorElUser}
            handleOpenUserMenu={handleOpenUserMenu}
            handleCloseUserMenu={handleCloseUserMenu}
            handleLogout={handleLogout}
            isCanvas={isCanvas}
          />
        </Box>
      </Box>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        width: '400px',
        justifyContent: 'end',
      }}
    >
      <Box sx={{ marginRight: '1%' }}>
        <img
          src={mode === 'dark' ? discordDark : discordLight}
          alt="Discord Logo"
          style={{
            height: '25px',
            marginRight: '8px',
            marginTop: '4px',
            cursor: 'pointer',
          }}
          onClick={() => {
            trackEvent(
              {
                event: TrackingEvents.discordButtonClicked,
                properties: {} as TrackingProperties,
              },
              'CREATOR'
            );
            window.open('https://discord.gg/wpdFYkRHzd', '_blank');
          }}
        />
      </Box>
      <Box sx={{ marginRight: '1%' }}>
        <Button
          variant="text"
          style={{ textTransform: 'none', color: theme.palette.text.primary }}
          onClick={() =>
            window.open(
              'https://dashtoon.notion.site/a3f4a24ad056478cb7baaf54080f8aca?v=91b27dc7e32f4ea19e1cfa351d9a8429',
              '_blank'
            )
          }
        >
          <Typography>{t('Docs')}</Typography>
        </Button>
      </Box>
      {checkURLIncludes('/generate') && (
        <Box sx={{ marginRight: '1%' }}>
          <Button
            variant="text"
            style={{ textTransform: 'none', color: theme.palette.text.primary }}
            onClick={() =>
              dispatch({
                type: ShortcutsActions.SET_SHORTCUTS_MODAL_OPEN,
                payload: true,
              })
            }
          >
            <Typography>
              {t('Shortcuts')}
              {checkOS() === 'MacOS' ? '⌘K' : '^K'}
            </Typography>
          </Button>
        </Box>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '1%',
          cursor: 'pointer',
        }}
        onClick={openPaymentsModal}
      >
        <div
          style={{
            display: 'flex',
            padding: '4px 6px',
            alignItems: 'center',
          }}
        >
          <MonetizationOnOutlinedIcon
            sx={{ color: mode === 'dark' ? 'yellow' : 'rgb(228, 171, 3)' }}
          />
          <Typography
            textAlign="center"
            sx={{
              marginLeft: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('Coins')}:{' '}
            {walletBalance === -1 ? (
              <span style={{ fontSize: '20px', paddingLeft: '4px' }}>∞</span>
            ) : (
              walletBalance
            )}
          </Typography>
        </div>
      </div>
      <UserSettings
        user={user}
        anchorElUser={anchorElUser}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        handleLogout={handleLogout}
        isCanvas={isCanvas}
      />
    </div>
  );
};

export default UserInformation;
