import { LocaleType } from '../Screens/Studio/Settings/Settings';
import { TranslateTextLanguage } from '../Models/GenerateImage';

export const mapBrowserLanguageToSupported = (browserLang: string): keyof typeof LocaleType => {
  if (browserLang.startsWith('zh')) {
    return browserLang.includes('-TW') || browserLang.includes('Hant') ? 'zh_TW' : 'zh_CN';
  }
  if (browserLang.startsWith('ja')) return 'ja';
  if (browserLang.startsWith('ko')) return 'ko';
  if (browserLang.startsWith('en')) return 'en';

  // Default to English if the browser language is not supported
  return 'en';
};

export const getLanguageFromKey = (key: keyof typeof LocaleType): TranslateTextLanguage => {
  switch (key) {
    case 'en':
      return TranslateTextLanguage.ENGLISH;
    case 'zh_CN':
      return TranslateTextLanguage.CHINESE;
    case 'zh_TW':
      return TranslateTextLanguage.CHINESE_TRADITIONAL;
    case 'ja':
      return TranslateTextLanguage.JAPANESE;
    case 'ko':
      return TranslateTextLanguage.KOREAN;
    default:
      return TranslateTextLanguage.ENGLISH;
  }
};
