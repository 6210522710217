import { User } from 'firebase/auth';
import * as React from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { setThemeMode } from '../../Redux/Actions/Theme';
import Typography from '@mui/material/Typography';
import { trackEvent } from '../../Utils/Analytics';
import { TrackingEvents } from '../../Constants/TrackingEvents';
import { TrackingProperties } from '../../Constants/TrackingProperties';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as DocsIcon } from '../../Assets/Icons/docs.svg';
import { ReactComponent as DiscordIcon } from '../../Assets/Icons/discord.svg';
import { ReactComponent as ThemeIcon } from '../../Assets/Icons/theme.svg';
import { ReactComponent as LogOut } from '../../Assets/Icons/logout.svg';
import { ReactComponent as LogIn } from '../../Assets/Icons/login.svg';
import { ReactComponent as VectorIcon } from '../../Assets/Icons/billing-vector.svg';
import { checkURLIncludes } from '../../Utils/CommonUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Settings from '../../Screens/Studio/Settings/Settings';
import { t } from 'i18next';
import CustomButton from '../CustomButton/CustomButton';
import usePhoneLayout from '../../Hooks/usePhoneLayout';
import { AppStateActions } from '../../Redux/Actions/AppState';
import Chip from '@mui/material/Chip';
import { AuthContext } from '../../Provider/AuthProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserSubscriptionPlanEnum } from '../../Models/User';
import AvatarWIthChip from './Components/AvatarWIthChip';

const settings = ['Logout'];

interface UserSettingsProps {
  user: User | null;
  anchorElUser: HTMLElement | null;
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseUserMenu: () => void;
  handleLogout: () => void;
  isCanvas: boolean;
}

const UserSettings: React.FC<UserSettingsProps> = ({
  user,
  anchorElUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
  handleLogout,
  isCanvas,
}) => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const theme = useTheme();
  const isV2 = checkURLIncludes('/generatev2') || checkURLIncludes('/generateV2');
  const isV1 = checkURLIncludes('/generate');
  const navigate = useNavigate();
  const { showId, episodeId } = useParams();
  const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
  const { isSmallScreen } = usePhoneLayout();
  const quotaState = useSelector((state: ReduxState) => state.quotaState);
  const userPlan = quotaState.data.currentPlan;

  return !user ? (
    <>
      <CustomButton
        text={'Login'}
        onClick={() => {
          dispatch({
            type: AppStateActions.OPEN_LOGIN_MODAL,
            payload: true,
          });
        }}
        variant={'text'}
        icon={
          <LogIn
            style={{ marginRight: 8, height: 24, width: 24 }}
            color={theme.palette.object.primary}
          />
        }
        hasBorder={false}
        hasIcon={true}
        foregroundColor={theme.palette.object.primary}
        style={{
          fontWeight: 600,
          fontSize: '16px',
        }}
      />
    </>
  ) : (
    <>
      <Box>
        <Tooltip title={t('Open settings')}>
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0, display: 'flex', gap: '8px', alignItems: 'center', margin: 0 }}
          >
            <AvatarWIthChip />
            {!isCanvas && !isSmallScreen && (
              <div style={{ color: theme.palette.object.primary, fontSize: '16px' }}>
                {user?.displayName == null ? 'John Doe' : user.displayName}
              </div>
            )}
          </IconButton>
        </Tooltip>
        <Menu
          sx={{
            mt: '45px',
            '& .MuiMenu-list': {
              background: theme.palette.background.paper,
              padding: '0',
            },
            '& .MuiPopover-paper': {
              borderRadius: '16px',
              border: '1px solid',
              borderColor: theme.palette.divider,
              background: theme.palette.background.paper,
              padding: '0',
            },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            key="Docs"
            onClick={() =>
              window.open(
                'https://dashtoon.notion.site/a3f4a24ad056478cb7baaf54080f8aca?v=91b27dc7e32f4ea19e1cfa351d9a8429',
                '_blank'
              )
            }
          >
            <DocsIcon style={{ marginRight: '15px' }} fill={theme.palette.text.primary} />
            <Typography fontSize={'small'} textAlign="center">
              {t('Docs')}
            </Typography>
          </MenuItem>
          <MenuItem
            key="Discord"
            onClick={() => {
              trackEvent(
                {
                  event: TrackingEvents.discordButtonClicked,
                  properties: {} as TrackingProperties,
                },
                'CREATOR'
              );
              window.open('https://discord.gg/wpdFYkRHzd', '_blank');
            }}
          >
            <DiscordIcon style={{ marginRight: '15px' }} fill={theme.palette.text.primary} />

            <Typography fontSize={'small'} textAlign="center">
              {t('Discord')}
            </Typography>
          </MenuItem>
          {mode === 'light' ? (
            <MenuItem
              key="Dark mode"
              onClick={() => {
                localStorage.setItem('theme', 'dark');
                dispatch(setThemeMode('dark'));
              }}
            >
              {' '}
              <ThemeIcon style={{ marginRight: '18px', marginLeft: '5px' }} />{' '}
              <Typography fontSize={'small'} textAlign="center">
                {t('Dark mode')}
              </Typography>{' '}
            </MenuItem>
          ) : (
            <MenuItem
              key="Light mode"
              onClick={() => {
                localStorage.setItem('theme', 'light');
                dispatch(setThemeMode('light'));
              }}
            >
              {' '}
              <ThemeIcon style={{ marginRight: '18px', marginLeft: '5px' }} />{' '}
              <Typography fontSize={'small'} textAlign="center">
                {t('Light mode')}
              </Typography>{' '}
            </MenuItem>
          )}

          <MenuItem
            key="Settings"
            onClick={() => {
              setOpenSettingsDialog(true);
              handleCloseUserMenu();
            }}
          >
            <SettingsIcon style={{ marginRight: '15px' }} fill={theme.palette.text.primary} />

            <Typography fontSize={'small'} textAlign="center">
              {t('Settings')}
            </Typography>
          </MenuItem>
          {auth.isVerified && userPlan !== UserSubscriptionPlanEnum.ADMIN && (
            <MenuItem
              key="Billing"
              onClick={() => {
                trackEvent(
                  {
                    event: TrackingEvents.userSettingsBillingButtonClicked,
                    properties: {
                      userPlan: userPlan,
                    } as TrackingProperties,
                  },
                  'CREATOR'
                );
                navigate('/plans');
                handleCloseUserMenu();
              }}
            >
              <VectorIcon
                style={{ marginRight: '18px', marginLeft: '5px' }}
                fill={theme.palette.text.primary}
              />

              <Typography fontSize={'small'} textAlign="center">
                {t('Billing')}
              </Typography>
            </MenuItem>
          )}
          {settings.map(_ => (
            <MenuItem key="Logout" onClick={handleLogout}>
              <LogOut style={{ marginRight: '13px', marginLeft: '2px' }} />{' '}
              <Typography fontSize={'small'} textAlign="center">
                {t('Logout')}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Dialog
        open={openSettingsDialog}
        onClose={() => setOpenSettingsDialog(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
          borderRadius: '8px',
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <b>{t('Settings')}</b>
        </DialogTitle>
        <DialogContent>
          <Settings />
        </DialogContent>
        <DialogActions>
          <Button sx={{ position: 'absolute', top: '5px', right: '5px' }}>
            <CloseIcon onClick={() => setOpenSettingsDialog(false)} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserSettings;
