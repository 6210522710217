import { ExpressionsActions } from '../Actions/Expressions';
import { ExpressionsState, InitialExpressionsState } from '../../Models/Expressions';
import { CanvasStateActions } from '../Actions/Canvas';
import { InitialCanvasState, CanvasState, ShowDetailsModalSize, InitialShowDetailsModalState } from '../../Models/CanvasState';
import { ShowDetailsModalSizeActions } from '../Actions/ShowDetailsModalSize';

export interface ShowDetailsModalSizeActionsInterface {
  type: ShowDetailsModalSizeActions;
  payload?: any;
}

export const ShowDetailsModalSizeReducer = (
    state: ShowDetailsModalSize = InitialShowDetailsModalState,
    action: ShowDetailsModalSizeActionsInterface
): ShowDetailsModalSize => {
    switch(action.type) {
        case ShowDetailsModalSizeActions.SET_BIG_MODAL:
            return {...state, isModalSizeBig: action.payload}
        case ShowDetailsModalSizeActions.SET_SMALL_MODAL:
            return {...state, isModalSizeBig: action.payload}
        default:
            return state
    }
}