import { UpdateTextualEmbeddingAction } from './TextualEmbedding';

export enum GenerationActions {
  SET_IS_GENERATING = 'SET_IS_GENERATING',
  SET_BATCH_COUNT = 'SET_BATCH_COUNT',
}

export interface SetIsGeneratingAction {
  type: GenerationActions.SET_IS_GENERATING;
  payload: boolean;
}

export interface SetBatchCountAction {
  type: GenerationActions.SET_BATCH_COUNT;
  payload: number;
}

export const setIsGenerating = (isGenerating: boolean): SetIsGeneratingAction => {
  return {
    type: GenerationActions.SET_IS_GENERATING,
    payload: isGenerating,
  };
};

export const setBatchCount = (batchCount: number): SetBatchCountAction => {
  return {
    type: GenerationActions.SET_BATCH_COUNT,
    payload: batchCount,
  };
};

export type GenerateActionTypes = SetIsGeneratingAction | SetBatchCountAction;
