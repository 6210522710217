import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { UserSubscriptionPlanEnum } from '../../../Models/User';
import Chip from '@mui/material/Chip';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../Redux/ReduxInterface';
import useAuth from '../../../Hooks/useAuth';
import { useContext } from 'react';
import { AuthContext } from '../../../Provider/AuthProvider';

const AvatarWIthChip = () => {
  const quotaState = useSelector((state: ReduxState) => state.quotaState);
  const userPlan = quotaState.data.currentPlan;
  const auth = useContext(AuthContext);
  const user = auth.currentUser;

  return (
    <Box position={'relative'}>
      <Avatar
        style={{ maxWidth: '28px', maxHeight: '28px', marginTop: '3px' }}
        alt={user?.displayName == null ? 'John Doe' : user.displayName}
        src={user?.photoURL == null ? 'avatar' : user.photoURL}
      />
      <Box
        position={'absolute'}
        top={'10px'}
        left={'50%'}
        style={{ transform: 'translateX(-50%)' }}
      >
        {userPlan && userPlan === UserSubscriptionPlanEnum.PRO && (
          <Chip
            variant={'filled'}
            color={'primary'}
            label={userPlan}
            size={'small'}
            sx={{
              borderRadius: '2px',
              fontSize: '8px',
              fontWeight: 900,
              color: 'white',
              lineHeight: '10px',
              height: '10px',
              '& .MuiChip-label': {
                padding: '2px', // Set this to '0px' to eliminate padding, or to another value to adjust it
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AvatarWIthChip;
