import React, { ChangeEventHandler, KeyboardEventHandler, useRef, useState } from 'react';
import { FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';

interface CustomTextFieldProps {
  id?: string;
  label: string;
  subLabel?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  type?: string;
  noOfLines?: number;
  width?: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>; // add inputProps prop
  enableAutofill?: boolean; // add enableAutofill prop
  tooltip?: string;
  showToggle?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  isV2?: boolean;
  icon?: React.ReactNode;
  border?: string;
  margin?: string;
  role?: string;
}

const CustomTextFieldWithIcon: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  placeholder,
  required,
  multiline,
  type,
  onChange,
  onKeyDown,
  onBlur,
  noOfLines,
  width,
  inputProps,
  subLabel,
  enableAutofill = false, // set default value for enableAutofill prop
  tooltip,
  showToggle = false,
  ref,
  isV2 = false,
  icon,
  border,
  margin,
  role = 'input',
  ...rest
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTabPress: KeyboardEventHandler<HTMLDivElement> = event => {
    if (
      enableAutofill &&
      event.key === 'Tab' &&
      inputRef.current &&
      inputRef.current.value === ''
    ) {
      event.preventDefault();
      inputRef.current.value = placeholder || '';
    }
  };

  return (
    <div style={{ pointerEvents: 'all' }}>
      <div style={{ marginTop: '12px', marginLeft: '12px', height: '36px' }}>
        <TextField
          id={id}
          value={inputProps?.value}
          autoComplete="no"
          minRows={multiline ? noOfLines ?? 5 : 1}
          multiline={multiline}
          ref={ref}
          onChange={onChange}
          onFocus={() => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: true,
            });
          }}
          onBlur={item => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: false,
            });
            onBlur && onBlur(item);
          }}
          onKeyDown={event => {
            onKeyDown && onKeyDown(event);
            handleTabPress(event);
          }}
          sx={{
            width: multiline ? width ?? '400px' : width ?? '300px',
            height: '36px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px !important',
            borderWidth: '0px',
            '& .MuiOutlinedInput-root': {
              paddingLeft: '8px',
              '& > fieldset': { borderRadius: '8px', borderWidth: '0px' },
            },
            border: border,
          }}
          placeholder={placeholder}
          inputProps={{
            style: { padding: multiline ? '0px' : '6px 10px 6px 0px' },
            ref: inputRef, // Assign ref to inputRef
            ...inputProps,
          }} // add custom styles to inputProps
          type={showToggle ? (!showPassword ? 'password' : 'text') : type}
          {...rest}
          InputProps={{
            // display toggle button conditionally
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
          }}
          role={role}
          aria-label={id}
          aria-description={placeholder}
        />
      </div>
    </div>
  );
};

export default CustomTextFieldWithIcon;
