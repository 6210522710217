import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageCard from './ImageCard';
import { fetchComfyUILinks } from '../../Requests/Studio/Character';

function NewTrainingWorkflowGenerated({
  comfyChosenLinks,
  setChosenLinksComfy,
  characterId,
}: {
  comfyChosenLinks: string[];
  setChosenLinksComfy: React.Dispatch<React.SetStateAction<string[]>>;
  characterId: string;
}) {
  const [generatedDatset, setGenederatedDataset] = useState<string[]>([]);

  const handleComfySelection = (characterUrl: string) => {
    if (comfyChosenLinks.length === 0) {
      setChosenLinksComfy((prevArray: string[]) => {
        const newArray = [characterUrl];
        return newArray;
      });
    } else {
      setChosenLinksComfy((prevArray: string[]) => {
        const index = prevArray.indexOf(characterUrl);
        const newArray = [...prevArray];
        if (index === -1) newArray.push(characterUrl);
        else newArray.splice(index, 1);
        return newArray;
      });
    }
  };

  const isChosen = (imageUrl: string) => {
    const isChosenBool = comfyChosenLinks.some(link => link === imageUrl);
    return isChosenBool;
  };


  useEffect(() => {
    fetchComfyUILinks(characterId).then(response =>{
      return setGenederatedDataset(response.generatedDataSet)
    })
  }, []);

  return (
    <Box
      style={{
        width: '100%',
        height: '25rem',
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'scroll',
      }}
    >
      {generatedDatset.map(images => {
        return (
            <ImageCard key={images} border={isChosen(images)} onClick={handleComfySelection} imageUrl={images} />
        );
      })}
    </Box>
  );
}

export default NewTrainingWorkflowGenerated;
