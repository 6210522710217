import { Box, Theme, Typography } from "@mui/material";
import { Character } from "../../../../../../../Models/Character";
import { t } from "i18next";

const CharacterNameAndStatus = ({ character, theme }: {
    character: Character;
    theme: Theme
  }) => (
    <Box style={{ width: '100%', height: "auto", display: 'flex' }}>
      <Typography style={{ fontSize: '3rem', fontWeight: 600, color: theme.palette.object.primary }}>
        {character?.name}
      </Typography>
      {/* Training Status Box */}
      <Box
        style={{
          width: '35%',
          height: '40px',
          backgroundColor: theme.palette.constant.green,
          marginTop: '20px',
          marginLeft: '10px',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography style={{ color: theme.palette.common.white, fontWeight: 'bold' }}>
          {t(`Training Complete`)}
        </Typography>
      </Box>
    </Box>
  );
  
  export default CharacterNameAndStatus;