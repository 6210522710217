import { useState } from 'react';
import { uploadFileRequest } from '../Requests/Studio/Canvas';
import toast from 'react-hot-toast';

export interface UploadFileType {
  file: File;
  progress: number;
  isError?: boolean;
  url?: string;
  tempId: string;
}

export const useUpload = (
  maxFiles: number,
  maxSizeMB: number,
  nsfwFilter: boolean,
  onChangeCallback?: (newState: string[]) => void
) => {
  const [uploadFiles, setUploadFiles] = useState<UploadFileType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleDropFileList = (fileList: FileList) => {
    const files: File[] = [];
    setLoading(true);
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        files.push(file);
      }
    }
    handleDropFiles(files).then(() => {
      setLoading(false);
    });
  };
  const handleDropFiles = async (files: File[]) => {
    // Filter out files larger than 100MB
    const validFiles = files.filter(file => file.size / 1024 / 1024 <= (maxSizeMB || 100));
    const invalidFilesCount = files.length - validFiles.length;

    // Show error message if there are files that are too large
    if (invalidFilesCount > 0) {
      //   notify
      toast.error(`You can't upload files larger than ${maxSizeMB}MB`);
    }

    // Calculate the number of files that can be added
    const filesCanBeAdded = maxFiles - uploadFiles.length;

    // Check if adding the new files will exceed the limit
    if (validFiles.length > filesCanBeAdded) {
      // notify
      toast.error(`You can't upload more than ${maxFiles} files`);
      // Slice the valid files to only include as many as can be added
      validFiles.splice(filesCanBeAdded, validFiles.length - filesCanBeAdded);
    }

    // Update the state with the valid files
    setUploadFiles(prevState => [
      ...prevState,
      ...validFiles.map(file => ({
        file,
        progress: 0,
        tempId: Date.now().toString() + Math.random().toString(),
      })),
    ]);

    // Proceed with the upload logic for the valid files
    let index = 1;
    for (const file of validFiles) {
      try {
        // Upload the file and update progress
        toast.loading(`Uploading ${index}/${validFiles.length}`, { id: 'uploading' });
        const response = await uploadFileRequest(
          file,
          progress => {
            setUploadFiles(prevState => {
              const fileIndex = prevState.findIndex(f => f.file === file);
              if (fileIndex === -1) {
                return prevState;
              }
              const newState = [...prevState];
              newState[fileIndex] = {
                ...prevState[fileIndex],
                progress,
              };
              return newState;
            });
          },
          nsfwFilter
        );

        // Update state with the response URL of the uploaded file
        setUploadFiles(prevState => {
          const fileIndex = prevState.findIndex(f => f.file === file);
          if (fileIndex === -1) {
            return prevState;
          }
          const newState = [...prevState];
          newState[fileIndex] = {
            ...prevState[fileIndex],
            url: response.url,
          };
          onChangeCallback && onChangeCallback(getImageUrls(newState));
          return newState;
        });

        // Optionally, you can keep track of the uploaded files in an array
        // if you need to use them after all uploads are done.
        index++;
      } catch (error) {
        console.error('There was an error uploading a file:', file, error);
        // Handle individual file upload error here
        // Decide whether to break the loop (stop uploading the next files) or continue
      }
    }

    // After all files are uploaded, you can do something here, like showing a notification
    toast.dismiss('uploading');
    toast.success(`Uploaded ${validFiles.length} files`);
  };

  const handleDelete = (uploadFile: UploadFileType) => {
    setUploadFiles(prevState => {
      const fileIndex = prevState.findIndex(f => f.file === uploadFile.file);
      if (fileIndex === -1) {
        return prevState;
      }
      const newState = [...prevState.slice(0, fileIndex), ...prevState.slice(fileIndex + 1)];
      onChangeCallback && onChangeCallback(getImageUrls(newState));
      return newState;
    });
  };

  // More functions as needed...
  const getImageUrls = (files: UploadFileType[]) => {
    return files.map(file => file.url ?? '').filter(url => !!url);
  };
  const imageUrls: string[] = uploadFiles.map(file => file.url ?? '').filter(url => !!url);

  return {
    uploadFiles,
    handleDropFiles,
    handleDelete,
    imageUrls,
    handleDropFileList,
    loading,
    // Any other state or functions you want to expose
  };
};
