export enum StoryCrafterStateAction {
  SET_GENERATION_DETAILS = 'SET_GENERATION_DETAILS',
  SET_THUMBNAIL_UPDATED = 'SET_THUMBNAIL_UPDATED',
  SET_GENERATING = 'SET_GENERATING',
  SET_DONE = 'SET_DONE',
  RESET = 'RESET',
}

export interface UpdateStoryCrafterStateAction {
  type: StoryCrafterStateAction;
  payload: any;
}

export type StoryCrafterStateActionTypes = UpdateStoryCrafterStateAction;
