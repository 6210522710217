import { Stack, Box } from '@mui/material';
import {
  Text12R,
  Text14R,
  Text16R,
  Text24SB,
  Text40SB,
} from '../../../../../Components/CustomTypography/CustomTypography';
import CustomButton from '../../../../../Components/CustomButton/CustomButton';
import { useTheme, alpha } from '@mui/material/styles';
import { t } from 'i18next';
import { useState } from 'react';

interface PlanDescriptionProps {
  planImage: string;
  title: string;
  price: string;
  strikedPrice: string;
  planFrequency: 'one time' | 'per month' | 'per year';
  ctaText: string;
  totalCredits: string;
  subtitle: string;
  onClick: () => void;
  minWidth?: string;
  recommended?: boolean;
  dashed: boolean;
  loading: boolean;
  disabled?: boolean;
}
const PlanDescription: React.FC<PlanDescriptionProps> = ({
  planImage,
  title,
  price,
  strikedPrice,
  planFrequency,
  ctaText,
  totalCredits,
  subtitle,
  onClick,
  minWidth,
  recommended,
  dashed,
  loading,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      minWidth={minWidth}
      px={6}
      pb={6}
      pt={3.75}
      borderColor={`${theme.palette.surface.tertiary} !important`}
      border={dashed ? '1px dashed rgba(128, 128, 128, 1) !important' : '1px solid'}
      borderRadius={8}
      sx={{
        backgroundColor: dashed ? undefined : alpha(theme.palette.surface.secondary, 0.3),
        //   hover state
        '&:hover': {
          backgroundColor: alpha(theme.palette.surface.secondary, 0.6),
          '& button': {
            backgroundColor: theme.palette.constant.blue,
            color: theme.palette.constant.white,
          },
        },
      }}
    >
      {/* image */}
      <Box
        py={'2px'}
        px={1}
        borderRadius={'8px'}
        sx={{
          backgroundColor: theme.palette.constant.green.toString(),
          visibility: recommended ? 'visible' : 'hidden',
        }}
      >
        <Text12R color={theme.palette.constant.white}>{t('Recommended')}</Text12R>
      </Box>
      <img src={planImage} alt={title} width={'128px'} height={'128px'} />
      {/* price */}
      <Box pt={1}>
        <Text24SB>{title}</Text24SB>
      </Box>
      <Box minHeight={'1.5rem'} mt={1.5}>
        <Text16R
          color={`${theme.palette.constant.grey.toString()} !important`}
          style={{
            textDecoration: 'line-through',
            textDecorationColor: theme.palette.constant.grey.toString(),
          }}
        >
          {strikedPrice ?? ' '}
        </Text16R>
      </Box>
      <Box>
        <Text40SB>{price}</Text40SB>
      </Box>
      {/* plan frequency */}
      <Box>
        <Text16R color={theme.palette.constant.grey.toString()}>{planFrequency}</Text16R>
      </Box>
      {/* CTA */}
      <Box pt={3} width={'100%'}>
        <CustomButton
          width={'100%'}
          foregroundColor={theme.palette.object.primary}
          backgroundColor={theme.palette.surface.tertiary}
          hasBorder={false}
          text={ctaText}
          onClick={onClick}
          variant={'contained'}
          padding={'8px 16px'}
          isLoading={loading}
          disabled={disabled}
        />
      </Box>
      {/* total credits */}
      <Box pt={3}>
        <Text14R color={theme.palette.object.tertiary}>{totalCredits}</Text14R>
      </Box>
      {/* hr */}
      <hr
        style={{
          border: 'none',
          height: '1px',
          width: '100%',
          background:
            'linear-gradient(to right, rgba(128, 128, 128, 0), rgba(128, 128, 128, 1), rgba(128, 128, 128, 0))',
        }}
      />
      {/* subtitle */}
      <Box>
        <Text12R color={theme.palette.constant.grey.toString()}>{subtitle}</Text12R>
      </Box>
    </Stack>
  );
};

export default PlanDescription;
