export interface ErrorState {
  errorCode: number | null;
  error: string | null;
}

export const getErrorInitState = (): ErrorState => {
  return {
    error: null,
    errorCode: null
  };
};
