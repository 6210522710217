import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormHelperText, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import { t } from 'i18next';

interface CustomTextFieldProps {
  id?: string;
  label: string;
  name?: string;
  subLabel?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  type?: string;
  noOfLines?: number;
  style?: Object;
  width?: string;
  height?: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>; // add inputProps prop
  enableAutofill?: boolean; // add enableAutofill prop
  tooltip?: string;
  showToggle?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  isV2?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  role?: string;
  autoFocus?: boolean;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  placeholder,
  required,
  multiline,
  type,
  onChange,
  onKeyDown,
  onBlur,
  noOfLines,
  style,
  width,
  height,
  inputProps,
  subLabel,
  enableAutofill = false, // set default value for enableAutofill prop
  tooltip,
  showToggle = false,
  ref,
  isV2 = false,
  disabled = false,
  backgroundColor,
  name,
  role = 'input',
  autoFocus = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTabPress: KeyboardEventHandler<HTMLDivElement> = event => {
    if (
      enableAutofill &&
      event.key === 'Tab' &&
      inputRef.current &&
      inputRef.current.value === ''
    ) {
      event.preventDefault();
      inputRef.current.value = placeholder || '';
    }
  };

  return (
    <div style={{ padding: isV2 ? '0' : '10px 0', pointerEvents: 'all' }}>
      {tooltip ? (
        <Tooltip title={t(tooltip)}>
          <InputLabel
            role={'label'}
            htmlFor={id}
            aria-label={label}
            aria-description={placeholder}
            style={{ fontSize: isV2 ? '12px' : '16px', fontWeight: isV2 ? '600' : '500' }}
          >
            {t(label)} {required && <span style={{ color: 'red' }}>*</span>}
          </InputLabel>
        </Tooltip>
      ) : (
        <InputLabel
          htmlFor={id}
          role={'label'}
          aria-label={label}
          aria-description={placeholder}
          style={{ fontSize: isV2 ? '12px' : '16px', fontWeight: isV2 ? '600' : '500' }}
        >
          {t(label)} {required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <div style={{ width: isV2 ? width : 350 }}>
        <InputLabel
          htmlFor={id}
          aria-label={label}
          aria-description={placeholder}
          style={{ fontSize: isV2 ? '12px' : '14px', fontWeight: '400' }}
        >
          {subLabel}
        </InputLabel>
      </div>
      <div style={{ marginTop: '5px' }}>
        <TextField
          disabled={disabled}
          id={id}
          name={name}
          value={inputProps?.value}
          minRows={multiline ? noOfLines ?? 5 : 1}
          maxRows={multiline ? noOfLines ?? 5 : 1}
          multiline={multiline}
          ref={ref}
          onChange={onChange}
          autoFocus={autoFocus}
          onFocus={() => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: true,
            });
          }}
          onBlur={item => {
            dispatch({
              type: TabSelectorActions.UPDATE_ON_FOCUS,
              payload: false,
            });
            onBlur && onBlur(item);
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation();
            }
            onKeyDown && onKeyDown(event);
            handleTabPress(event);
          }}
          sx={{
            width: multiline ? width ?? '400px' : width ?? '300px',
            backgroundColor: backgroundColor ?? theme.palette.background.paper,
            height: height,
            '& .MuiInputBase-root': {
              height: height,
            },
          }}
          placeholder={placeholder}
          inputProps={{
            style: {
              padding: multiline ? '0px' : '6px 10px',
              WebkitUserSelect: 'text',
              MozUserSelect: 'text',
              ...style,
              // MsUserSelect: 'text',
              // UserSelect: 'text',
            },
            ref: inputRef, // Assign ref to inputRef
            ...inputProps,
            'aria-label': label,
            'aria-description': placeholder,
          }} // add custom styles to inputProps
          type={showToggle ? (!showPassword ? 'password' : 'text') : type}
          {...rest}
          InputProps={{
            endAdornment: showToggle && ( // display toggle button conditionally
              <InputAdornment position="end">
                <IconButton
                  role={'button'}
                  aria-label={'Show Password'}
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              WebkitUserSelect: 'text',
              MozUserSelect: 'text',
              ...style,
            },
          }}
        />
      </div>
    </div>
  );
};

export default CustomTextField;
