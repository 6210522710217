import {
  GenerateImageType,
  getInitialImageGenerationState,
  ImageGenerationState,
} from '../../Models/GenerateImage';
import { GenerateImageActions } from '../Actions/GenerateImage';
import { defaultWeight } from '../../Constants/StyleConstants';

export interface Action {
  type: GenerateImageActions;
  generateImageType: GenerateImageType;
  payload?: any;
}

export const Text2ImageReducer = (
  state: ImageGenerationState = getInitialImageGenerationState(GenerateImageType.TXT2IMG),
  action: Action
) => {
  return ImageGenerationReducer(state, action);
};

export const Image2ImageReducer = (
  state: ImageGenerationState = getInitialImageGenerationState(GenerateImageType.IMG2IMG),
  action: Action
) => {
  return ImageGenerationReducer(state, action);
};

export const InpaintReducer = (
  state: ImageGenerationState = getInitialImageGenerationState(GenerateImageType.INPAINT),
  action: Action
) => {
  return ImageGenerationReducer(state, action);
};

export const ImageGenerationReducer = (
  state: ImageGenerationState,
  action: Action
): ImageGenerationState => {
  if (action.type === GenerateImageActions.RESET_ALL_IMAGE_GENERATION) {
    return getInitialImageGenerationState(state.type);
  }
  if (action && action.generateImageType === state.type) {
    switch (action.type) {
      // Define your actions here
      case GenerateImageActions.UPDATE_PROMPT:
        return { ...state, prompt: action.payload };
      case GenerateImageActions.UPDATE_PREV_PROMPT:
        return { ...state, prevPrompt: action.payload };
      case GenerateImageActions.UPDATE_EDIT_TYPE:
        return { ...state, editType: action.payload };
      case GenerateImageActions.UPDATE_NEGATIVE_PROMPT:
        return { ...state, negativePrompt: action.payload };
      case GenerateImageActions.UPDATE_SEED:
        return { ...state, seed: action.payload };
      case GenerateImageActions.UPDATE_CFG:
        return { ...state, cfg: action.payload };
      case GenerateImageActions.UPDATE_STEPS:
        return { ...state, steps: action.payload };
      case GenerateImageActions.UPDATE_BATCH_SIZE:
        return { ...state, batchSize: action.payload };
      case GenerateImageActions.UPDATE_WIDTH:
        return { ...state, width: action.payload };
      case GenerateImageActions.UPDATE_HEIGHT:
        return { ...state, height: action.payload };
      case GenerateImageActions.UPDATE_STYLE:
        return { ...state, style: action.payload };
      case GenerateImageActions.UPDATE_CHARACTER_NAME:
        return { ...state, characterName: action.payload };
      case GenerateImageActions.UPDATE_CHARACTER_ID:
        return { ...state, characterId: action.payload };
      case GenerateImageActions.UPDATE_CONTROL_NET:
        return { ...state, controlNet: action.payload };
      case GenerateImageActions.UPDATE_INIT_IMAGE:
        return { ...state, initImage: action.payload };
      case GenerateImageActions.UPDATE_MASK_IMAGE:
        return { ...state, maskImage: action.payload };
      case GenerateImageActions.UPDATE_IS_GENERATING:
        return { ...state, isGenerating: action.payload };
      case GenerateImageActions.UPDATE_AUTO_FACE_FIX:
        return { ...state, autoFixFace: action.payload };
      case GenerateImageActions.UPDATE_SELECTED_CHARACTER_PROMPT:
        return { ...state, selectedCharacterPrompt: action.payload };
      case GenerateImageActions.UPDATE_STRENGTH:
        return { ...state, strength: action.payload };
      case GenerateImageActions.UPDATE_BULK:
        return { ...state, ...action.payload };
      case GenerateImageActions.UPDATE_INPAINTED_IMAGE:
        return { ...state, combinedMaskImage: action.payload };
      case GenerateImageActions.UPDATE_INPAINT_FILL_TYPE:
        return { ...state, inpaintFillType: action.payload };
      case GenerateImageActions.UPDATE_INPAINT_TYPE:
        return { ...state, inpaintType: action.payload };
      case GenerateImageActions.UPDATE_IMAGE_SEGMENT:
        return { ...state, imageSegment: action.payload };
      case GenerateImageActions.UPDATE_COLOR_CONTEXT:
        return { ...state, colorContext: action.payload };
      case GenerateImageActions.UPDATE_INIT_IMAGE_CROP_BOUNDS:
        return { ...state, initImageCropBounds: action.payload };
      case GenerateImageActions.UPDATE_CHARACTER_WEIGHT:
        return { ...state, characterWeight: action.payload };
      case GenerateImageActions.UPDATE_STYLE_WEIGHT:
        return { ...state, styleWeight: action.payload };
      case GenerateImageActions.UPDATE_ALLOW_STYLE_WEIGHT:
        return { ...state, allowStyleWeight: action.payload };
      case GenerateImageActions.UPDATE_DEFAULT_STYLE_WEIGHT:
        return { ...state, defaultStyleWeight: action.payload };
      case GenerateImageActions.UPDATE_GREEN_MASK:
        return { ...state, greenMaskImage: action.payload };
      case GenerateImageActions.RESET_IMAGE_GENERATION:
        return {
          ...state,
          ...getInitialImageGenerationState(state.type),
          style: state.style,
          styleWeight: state.defaultStyleWeight ?? defaultWeight,
          textualEmbeddings: state.textualEmbeddings,
        };
      case GenerateImageActions.UPDATE_TEXTUAL_EMBEDDING:
        return { ...state, textualEmbeddings: action.payload };
      case GenerateImageActions.UPDATE_A100_INFERENCE:
        return { ...state, enableDiffusersInpaint: action.payload };
      case GenerateImageActions.UPDATE_ENABLE_CLIP_SKIP:
        return { ...state, enableClipSkip: action.payload };
      case GenerateImageActions.UPDATE_ENABLE_HIGH_RES_FIX:
        return { ...state, enableHighResFix: action.payload };
      case GenerateImageActions.UPDATE_ENABLE_HIGH_RES_FIX_DENOISE:
        return { ...state, highResFixStrength: action.payload };
      case GenerateImageActions.UPDATE_T2I_ADAPTERS:
        return { ...state, t2iAdapters: action.payload };
      case GenerateImageActions.UPDATE_IS_T2I_ADAPTERS_SELECTED:
        return { ...state, t2iAdaptersSelected: action.payload };
      default:
        return state;
    }
  } else {
    return state;
  }
};
