import { useEffect, useState } from 'react';
import { auth } from '../firebaseConfig';
import { sendEmailVerification, User } from 'firebase/auth';
import { resetTracking, trackEvent } from '../Utils/Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';

export interface AuthState {
  currentUser: User | null;
  loading: boolean;
  email: string;
  anonymous: boolean;
  isVerified: boolean;
  isAuth: boolean;
  sendVerificationEmail: () => Promise<void>;
  signOut: () => Promise<void>;
}

const useAuth = (): AuthState => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const email = currentUser?.email || '';
  const anonymous = currentUser?.isAnonymous || false;
  const isVerified = currentUser?.emailVerified || false;
  const isAuth = currentUser !== null && !anonymous && email !== '';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!isAuth && !loading) {
      auth.signOut();
    }
  }, [isAuth, loading, currentUser]);

  const sendVerificationEmail = async () => {
    if (currentUser && !isVerified) {
      await sendEmailVerification(currentUser);
      trackEvent(
        {
          event: TrackingEvents.verificationEmailSent,
          properties: {
            email: currentUser.email ?? '',
          },
        },
        'CREATOR'
      );
    } else {
      console.warn('User is not logged in or already verified');
    }
  };

  const signOut = async () => {
    await auth.signOut();
    setCurrentUser(null);
    resetTracking();

    // Refresh the page
    window.location.reload();
  };

  return {
    currentUser,
    loading,
    email,
    anonymous,
    isVerified,
    isAuth,
    sendVerificationEmail,
    signOut,
  };
};

export default useAuth;
