import React, { useEffect } from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ShowDetailsModalSizeActions } from '../../../../../Redux/Actions/ShowDetailsModalSize';
import { Character } from '../../../../../Models/Character';
import { t } from 'i18next';
import CharacterInTrainingPNG from '../../../../../Assets/Images/character_in_training.png';
import Banner from '../../../../../Components/Banner/Banner';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface CharacterInTrainingProps {
  character: Character | null;
  setCharacterViewIsOpen?: (characterViewIsOpen: boolean) => void;
}

// Smaller component for Character Metadata
const CharacterMetadata = ({ label, value }: { label?: string; value?: string }) => (
  <Card style={{ width: '180px', margin: '10px 10px 0 0', padding: '12px' }}>
    <Typography style={{ fontWeight: 'bold' }}>{value}</Typography>
    <Typography style={{ fontSize: '12px', color: 'grey' }}>{label}</Typography>
  </Card>
);

const CharacterInTraining: React.FC<CharacterInTrainingProps> = ({ character }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ShowDetailsModalSizeActions.SET_BIG_MODAL, payload: true });
    return () => {
      dispatch({ type: ShowDetailsModalSizeActions.SET_SMALL_MODAL, payload: false });
    };
  }, [dispatch]);

  const metadata = [
    { label: t('Casting'), value: character?.generationMetadata?.split(',')[6] },
    { label: t('Age'), value: character?.generationMetadata?.split(',')[1]?.replace(/_/g, ' ') },
    {
      label: t('Ethnicity'),
      value: character?.generationMetadata?.split(',')[2]?.replace(/_/g, ' '),
    },
    {
      label: t('Hair Style'),
      value: character?.generationMetadata?.split(',')[3]?.replace('hair', ''),
    },
    {
      label: t('Hair Color'),
      value: character?.generationMetadata?.split(',')[4]?.replace('hair color', ''),
    },
    {
      label: t('Eye Color'),
      value: character?.generationMetadata?.split(',')[5]?.replace('eye color', ''),
    },
  ];
  if (!character) {
    return null;
  }
  const { characterTrainingTimeLeftMs } = character;
  const timeLeft =
    characterTrainingTimeLeftMs && characterTrainingTimeLeftMs > 0
      ? dayjs.duration(characterTrainingTimeLeftMs, 'milliseconds').humanize()
      : null;

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
      }}
    >
      {/* Character Name and Training Status */}
      <Box display={'flex'} flexDirection={'column'}>
        <CharacterNameAndStatus character={character} theme={theme} timeLeft={timeLeft} />

        {/* Character Description */}
        <CharacterDescription character={character} theme={theme} />
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Box display={'flex'} flexDirection={'column'} flex={3} gap={2} width="100%">
          {/* Character Metadata */}
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {metadata.map((item, index) => (
              <CharacterMetadata key={index} label={item.label} value={item.value} />
            ))}
          </Box>

          {/* Additional Characterisitics */}
          <AdditionalCharacteristics character={character} theme={theme} />
          <Banner
            message="Your character is currently training. We will notify you by email once training is complete. You can then review the character after which you will be able to use it in your show."
            type="warning"
          />
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1} width="100%">
          {/* Character Image */}
          <CharacterImage image={character?.uploadedImage ?? ''} />
        </Box>
      </Box>
    </Box>
  );
};

const CharacterNameAndStatus = ({
  character,
  theme,
  timeLeft,
}: {
  character: Character;
  theme: any;
  timeLeft: string | null;
}) => (
  <Box style={{ width: '100%', height: 'auto', display: 'flex' }}>
    <Typography style={{ fontSize: '3rem', fontWeight: 600, color: theme.palette.object.primary }}>
      {character?.name}
    </Typography>
    {/* Training Status Box */}
    <Box
      style={{
        width: '35%',
        height: '40px',
        backgroundColor: theme.palette.constant.grey,
        marginTop: '20px',
        marginLeft: '10px',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography style={{ color: theme.palette.object.primary, fontWeight: 'bold' }}>
        {t(`In Training`)} {timeLeft && `- ${timeLeft} more`}
      </Typography>
    </Box>
  </Box>
);

const CharacterDescription = ({ character, theme }: { character: Character; theme: any }) => (
  <Box style={{ width: '100%', height: '5%', display: 'flex' }}>
    <Typography style={{ fontSize: '14px', color: theme.palette.object.primary }}>
      {character?.gender}
    </Typography>
    <Box style={{ display: 'flex', width: '50%', marginLeft: '10px' }}>
      <li style={{ fontSize: '14px', marginTop: '1px' }}>{character?.description}</li>
    </Box>
  </Box>
);

const AdditionalCharacteristics = ({ character, theme }: { character: Character; theme: any }) => (
  <Box style={{ width: '100%', height: '15%', marginTop: '10px' }}>
    <Card
      style={{
        width: '100%',
        height: '100%',
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography style={{ fontWeight: 'bold', color: theme.palette.object.primary }}>
        {character?.generationMetadata?.split(',')[7]}
      </Typography>
      <Typography style={{ fontSize: '12px', color: theme.palette.constant.grey }}>
        {t(`Additional Characteristics`)}
      </Typography>
    </Card>
  </Box>
);

const CharacterImage = ({ image: image }: { image?: string }) => (
  <Box style={{ width: '100%', height: '100%', marginBottom: '20px' }}>
    <img
      src={image || CharacterInTrainingPNG}
      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
    />
  </Box>
);

export default CharacterInTraining;
