import { SaveImageResponse } from './SaveImage';
import { TLShape, TLShapeId } from '@dashtoon/tldraw';
import { Page } from './Page';
import { PageShape } from '../Screens/Studio/Canvas/PageShape/PageShape';
import { SpeechBubble } from './SpeechBubble';
import { PanelTemplate } from './PanelTemplate';
import { config } from '../Config/Config';
import { Format } from './Format';
import { PatternImageProps } from './PatternImages';
import { StyleLiveConfigDTO } from './Styles';
import { RightBarTabs } from '../Hooks/useRIghtBarConfig';

export interface CanvasStateResponse {
  canvasId: string;
  episodeId: string;
  showId: string;
  canvasState: string;
  generationState: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface CanvasStateRequest {
  episodeId: string;
  showId: string;
  canvasState: string;
}

export interface CanvasStateDiffRequest {
  episodeId: string;
  showId: string;
  diff: string;
}

export interface GenerationStateRequest {
  episodeId: string;
  showId: string;
  generationState: string | null;
}

export interface ShowDetailsModalSize {
  isModalSizeBig?: boolean;
}

export interface CharacterFromCanvas {
  isCharacterFromCanvas?: boolean;
  characterImageLink?: string;
  isLoading?: boolean;
}

export interface CanvasState {
  copiedShapeIds?: TLShapeId[];
  currentSelectedImage?: SaveImageResponse;
  isCanvasStateSaving?: boolean;
  isGenerationStateSaving?: boolean;
  selectedStyle: string;
  selectedStyleName: string;
  styleLiveConfig: StyleLiveConfigDTO[];
  currentFrames: TLShape[] | undefined;
  selectedFrames: TLShape[] | undefined;
  selectedComments: TLShape[] | undefined;
  tabValue?: number;
  minimapExpanded?: boolean;
  publishPages: IPublishScreenProps[];
  isPublishScreenLoading: boolean;
  hasCanvasStateChanges?: boolean;
  hasGenerationStateChanges?: boolean;
  isRightMenuOpen: boolean;
  selectedShapes: TLShape[] | undefined;
  isReferenceLibraryOpen?: boolean;
  currentStrokeColor?: string;
  currentMaskStrokeWidth?: string;
  currentBrushStrokeWidth?: string;
  currentMagicEraseStrokeWidth?: string;
  currentPaintStrokeWidth?: string;
  currentEraserWidth?: string;
  currentOpacity?: string;
  last9Colors?: string[];
  canvasStatus?: CanvasStatus;
  allSpeechBubbles?: SpeechBubble[];
  isShortcutsModalOpen: boolean;
  panelTemplates: PanelTemplate[];
  isPanelTemplatesOpen: boolean;
  currentSelectedRightMenuTool: string;
  currentBrushType?: string;
  currentBrushDistance?: number;
  currentBrushHardness?: number;
  patternImages?: PatternImageProps[];
  isPickerOpen?: boolean;
  activeRightTab?: RightBarTabs;
  isFocusMode?: boolean;
  isPreviousEpisodePublished?: boolean;
  showEmptyFrameWarning?: boolean;
}

export enum CanvasStatus {
  CREATED = 'CREATED',
  LOADED = 'LOADED',
}

export const InitialShowDetailsModalState: ShowDetailsModalSize = {
  isModalSizeBig: false,
};

const rootStyles = getComputedStyle(document.documentElement);

export const InitialCharacterFromCanvasState: CharacterFromCanvas = {
  isCharacterFromCanvas: false,
  characterImageLink: '',
  isLoading: false,
};

export const InitialCanvasState: CanvasState = {
  currentSelectedImage: undefined,
  isGenerationStateSaving: false,
  isCanvasStateSaving: false,
  selectedStyle: '',
  styleLiveConfig: [],
  currentFrames: undefined,
  selectedFrames: undefined,
  selectedComments: undefined,
  tabValue: 0,
  publishPages: [],
  isRightMenuOpen: true,
  selectedShapes: undefined,
  currentPaintStrokeWidth: rootStyles.getPropertyValue('--current-paint-stroke-width'),
  currentBrushStrokeWidth: rootStyles.getPropertyValue('--current-brush-stroke-width'),
  currentEraserWidth: rootStyles.getPropertyValue('--current-eraser-width'),
  currentMaskStrokeWidth: rootStyles.getPropertyValue('--current-mask-stroke-width'),
  currentMagicEraseStrokeWidth: rootStyles.getPropertyValue('--current-magic-eraser-stroke-width'),
  currentStrokeColor: undefined,
  currentOpacity: undefined,
  last9Colors: [],
  allSpeechBubbles: [],
  selectedStyleName: config.defaultStyleName,
  isShortcutsModalOpen: false,
  panelTemplates: [],
  isPanelTemplatesOpen: false,
  currentSelectedRightMenuTool: '',
  currentBrushType: 'hard',
  currentBrushDistance: 0,
  currentBrushHardness: 100,
  patternImages: [],
  isPublishScreenLoading: false,
  activeRightTab: RightBarTabs.SETTINGS,
  isFocusMode: false,
  showEmptyFrameWarning: false,
};

export interface IPublishScreenProps {
  pageId: string;
  name: string;
  image: string;
  isVisible: boolean;
  isCurrentPage: boolean;
}
