import { NotificationInstance } from "antd/es/notification/interface";
import { StatesObjectInterface } from "../Components/NewTrainingWorkflow/NewTrainingWorkflowMetadata";
import { TrackingEvents } from "../Constants/TrackingEvents";
import { Character, ShowCharacterMetadataUpdateRequest } from "../Models/Character"
import { createCharacterWithMetadata, sendCharacterForDatasetGenerations, trainCharacterUsingId, trainCharacterUsingImgUrls, updateCharacterMetadata, updateChosenLinksComfy} from "../Requests/Studio/Character";
import { trackEvent } from "./Analytics";
import { openNotificationWithIcon } from "./Notification";


export const trainCharacterOldWorkflow = async (showId: string,api:NotificationInstance, {characterName, characterAge, characterGender, characterCasting, characterDescription, characterHairColor, characterEyeColor, characterHairStyle, characterRace, additionalCharacteristics}: StatesObjectInterface, imageFiles: File[]) => {
    var trainingCharacter: Character = {
      uploadedImage: '',
      baseModel:'',
        createdAtEpoch: 0,
        description: '',
        gender: '',
        id: '',
        imageUrl: '',
        name: '',
        state: '',
        updatedAtEpoch: 0,
    };
    try {
        trainingCharacter = await createCharacterEntry(api, showId,
        {characterName, characterAge, characterGender, characterCasting, characterDescription, characterHairColor, characterEyeColor, characterHairStyle, characterRace, additionalCharacteristics}, imageFiles, false, ''
        )
        const response = await trainCharacterUsingId(showId, trainingCharacter.id)
        return response
    } catch (e) {
        const error = e as Error;
        console.error(error);
        openNotificationWithIcon(api, 'error', 'bottomRight', error.message);
    }
    
    trackEvent(
        {
        event: TrackingEvents.characterTrained,
        properties: {
            characterId: trainingCharacter.id,
            isV2: true.toString(),
        },
        },
        'CREATOR'
    );
}

const convertToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result ? reader.result.toString() : '');
      };

      reader.onerror = function () {
        reject('Failed to load image');
      };
    });
  };

const validateTrainCharacter = ({characterName, characterAge, characterGender, characterCasting, characterDescription, characterHairColor, characterEyeColor, characterHairStyle, characterRace, additionalCharacteristics}: StatesObjectInterface, imageFiles: File[]) => {
    return (
        characterName !== '' &&
        characterDescription !== '' &&
        characterGender !== '' &&
        characterEyeColor !== '' &&
        characterAge !== '' &&
        characterEyeColor !== '' &&
        characterRace !== '' &&
        characterCasting !== '' &&
        characterHairStyle !== '' &&
        characterHairColor !== '' &&
        imageFiles.length !== 0
    );
};


const createCharacterEntry = async (api:NotificationInstance, showId: string,{characterName, characterAge, characterGender, characterCasting, characterDescription, characterHairColor, characterEyeColor, characterHairStyle, characterRace, additionalCharacteristics}: StatesObjectInterface, imageFiles: File[], isCharacterFromCanvas:boolean, characterImageLink: string) => {
    if(isCharacterFromCanvas !== true){
        validateTrainCharacter({characterName, characterAge, characterGender, characterCasting, characterDescription, characterHairColor, characterEyeColor, characterHairStyle, characterRace, additionalCharacteristics}, imageFiles);
        var newCharacter: Character = {
          createdAtEpoch: 0,
          description: '',
          gender: '',
          id: '',
          imageUrl: '',
          uploadedImage: '',
          baseModel: '',
          name: '',
          state: '',
          updatedAtEpoch: 0,
        };
        try {
          const b64Images = await Promise.all(imageFiles.map(file => convertToBase64(file)));
          newCharacter = (await createCharacterWithMetadata({
            name: characterName,
            description: characterDescription,
            // @ts-ignore
            gender: characterGender,
            images: b64Images.map(base64String =>
              base64String.substring(base64String.indexOf(',') + 1)
            ),
            // @ts-ignore
            casting: characterCasting,
            // @ts-ignore
            age: characterAge,
            // @ts-ignore
            ethnicity: characterRace,
            hairStyle: characterHairStyle,
            hairColor: characterHairColor,
            eyeColor: characterEyeColor,
            additionalCharacteristics: additionalCharacteristics,
            showId: showId,
          })) as Character;
        } catch (e) {
          const error = e as Error;
          console.error(error);
          openNotificationWithIcon(api, 'error', 'bottomRight', error.message);
          
        }
        
        openNotificationWithIcon(api, 'success', 'bottomRight', 'Successfully saved character');
        trackEvent(
          {
            event: TrackingEvents.characterAdded,
            properties: {
              characterId: newCharacter.id,
              characterName: newCharacter.name,
              characterDescription: newCharacter.description,
              characterGender: newCharacter.gender,
            },
          },
          'CREATOR'
        );
        return newCharacter;
    } else {
    var newCharacter: Character = {
      createdAtEpoch: 0,
      description: '',
      gender: '',
      baseModel: '',
      uploadedImage: '',
      id: '',
      imageUrl: '',
      name: '',
      state: '',
      updatedAtEpoch: 0,
    };
    try {
      const b64Images = await Promise.all(imageFiles.map(file => convertToBase64(file)));
      newCharacter = (await createCharacterWithMetadata({
        name: characterName,
        description: characterDescription,
        // @ts-ignore
        gender: characterGender,
        images: b64Images.map(base64String =>
          base64String.substring(base64String.indexOf(',') + 1)
        ),
        // @ts-ignore
        casting: characterCasting,
        // @ts-ignore
        age: characterAge,
        // @ts-ignore
        ethnicity: characterRace,
        hairStyle: characterHairStyle,
        hairColor: characterHairColor,
        eyeColor: characterEyeColor,
        additionalCharacteristics: additionalCharacteristics,
        showId: showId,
      }, characterImageLink)) as Character;
    } catch (e) {
      const error = e as Error;
      console.error(error);
      openNotificationWithIcon(api, 'error', 'bottomRight', error.message);
      
    }
    
    openNotificationWithIcon(api, 'success', 'bottomRight', 'Successfully saved character');
    trackEvent(
      {
        event: TrackingEvents.characterAdded,
        properties: {
          characterId: newCharacter.id,
          characterName: newCharacter.name,
          characterDescription: newCharacter.description,
          characterGender: newCharacter.gender,
        },
      },
      'CREATOR'
    );
    return newCharacter;
    }
    
};

export const generateDataset = async (api:NotificationInstance ,showId: string, stateObject: StatesObjectInterface, imageFiles: File[], isCharacterFromCanvas: boolean, characterImageLink: string) => {
    var trainingCharacter: Character = {
        createdAtEpoch: 0,
        description: '',
        gender: '',
        baseModel:'',
        uploadedImage:'',
        id: '',
        imageUrl: '',
        name: '',
        state: '',
        updatedAtEpoch: 0,
    }
    try{
        trainingCharacter = await createCharacterEntry(api, showId, stateObject, imageFiles, isCharacterFromCanvas, characterImageLink)
        const response = await sendCharacterForDatasetGenerations(trainingCharacter.id)
        if(response !== null) return response
        else throw "Unable to generate dataset"
    } catch(error){
        const e = error as Error
        console.error(e)
        openNotificationWithIcon(api, 'error', 'bottomRight', e.message)
        return null
    }
}

export const updateChosenLinksFromDataset = async (characterId: string,chosenLinks: string[]) => {
    try{
        const response = await updateChosenLinksComfy(characterId, chosenLinks)
        return response
    }catch(error){
        const e = error as Error
        console.error(e)
    }
}

export const trainCharacterNewWorkflow = async (characterId: string) => {
    try {
        const response = await trainCharacterUsingImgUrls(characterId)
        return response
    } catch(error) {
        const e = error as Error
        console.error(e)
        return null
    }
}

export const validateCharacter = () => {
    return null
}

export const updateCharacter = async (showId:string, character: Character, api:NotificationInstance) => {
    try{
        const response = await updateCharacterMetadata(showId, {
            showId: showId,
            characterId: character.id,
            name: character.name, 
            description: character.description
        } as ShowCharacterMetadataUpdateRequest)
        return response
    }catch(error){
        const e = error as Error
        console.error(e)
        openNotificationWithIcon(api, 'error', 'bottomRight', e.message)
        return null
    }
}

export const returnAgeString = (age: string) => {
  switch (age) {
    case 'Young Adult':
      return 'YOUNG_ADULT';
    case 'Middle Age':
      return 'MIDDLE_AGE';
    default:
      return age;
  }
};

export const returnEthnicityString = (ethnicity: string) => {
  switch (ethnicity) {
    case 'African American':
      return 'AFRICAN_AMERICAN';
    case 'Latin American':
      return 'LATIN_AMERICAN';
    case 'Asian American':
      return 'ASIAN_AMERICAN';
    case 'Middle Eastern':
      return 'MIDDLE_EASTERN';
    default:
      return ethnicity;
  }
};