export enum DashverseActions {
  UPDATE_UNIVERSE_TYPE = 'UPDATE_UNIVERSE_TYPE',
  UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT',
  UPDATE_AVAILABLE_SHOW_FILTERS = 'UPDATE_AVAILABLE_SHOW_FILTERS',
  UPDATE_SELECTED_SHOW_GENRE_FILTERS = 'UPDATE_SELECTED_SHOW_GENRE_FILTER',
  UPDATE_SELECTED_SHOW_CREATOR_FILTERS = 'UPDATE_SELECTED_SHOW_CREATOR_FILTER',
  UPDATE_SELECTED_SHOW_MODEL_FILTERS = 'UPDATE_SELECTED_SHOW_MODEL_FILTER',
  UPDATE_SELECTED_SHOW_STYLE_FILTERS = 'UPDATE_SELECTED_SHOW_STYLE_FILTER',
  UPDATE_AVAILABLE_CHARACTER_FILTERS = 'UPDATE_AVAILABLE_CHARACTER_FILTERS',
  UPDATE_SELECTED_CHARACTER_IS_FILTER = 'UPDATE_SELECTED_CHARACTER_IS_FILTER',
  UPDATE_SELECTED_CHARACTER_GENDER_FILTERS = 'UPDATE_SELECTED_CHARACTER_GENDER_FILTERS',
  UPDATE_SELECTED_CHARACTER_AGE_FILTERS = 'UPDATE_SELECTED_CHARACTER_AGE_FILTERS',
  UPDATE_SELECTED_CHARACTER_HAIRSTYLE_FILTERS = 'UPDATE_SELECTED_CHARACTER_HAIRSTYLE_FILTERS',
  UPDATE_SELECTED_CHARACTER_RACE_FILTERS = 'UPDATE_SELECTED_CHARACTER_RACE_FILTERS',
  UPDATE_SELECTED_CHARACTER_BASE_MODEL_FILTERS = 'UPDATE_SELECTED_CHARACTER_BASE_MODEL_FILTERS',
  UPDATE_SELECTED_CHARACTER_CASTING_FILTERS = 'UPDATE_SELECTED_CHARACTER_CASTING_FILTERS',
  UPDATE_SELECTED_CHARACTER_EDITORSCHOICE_FILTERS = 'UPDATE_SELECTED_CHARACTER_EDITORSCHOICE_FILTERS',
  UPDATE_SELECTED_CHARACTER_GENERATIONS_FILTERS = 'UPDATE_SELECTED_CHARACTER_GENERATIONS_FILTERS',
  UPDATE_SELECTED_ORGANISATION_FILTERS = 'UPDATE_SELECTED_ORGANISATION_FILTERS',
  RESET_CHARACTER_FILTERS = 'RESET_CHARACTER_FILTERS',
}

export interface UpdateDashverseAction {
  type: DashverseActions;
  payload: any;
}

export type DashverseActionTypes = UpdateDashverseAction;
