import React, { useContext } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { t } from 'i18next';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { IconHolder, NavigationItem } from '../CommonStyledComponents';
import { HomeScreenTab, TabName, useHomeScreenTab } from '../../../../Hooks/useHomeScreenTab';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import { AuthContext } from '../../../../Provider/AuthProvider';
import { useDispatch } from 'react-redux';
import { AppStateActions } from '../../../../Redux/Actions/AppState';

const ScreensMenu = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentTab, setCurrentTab, allTabs } = useHomeScreenTab();
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();

  const changeScreen = (screen: HomeScreenTab) => {
    trackEvent(
      {
        event: TrackingEvents.homeScreenTabClicked,
        properties: {
          tab: screen.id,
        },
      },
      'CREATOR'
    );
    if (screen.id === TabName.TUTORIALS) {
      window.open('https://www.youtube.com/playlist?list=PLf5_EN2BltjD9DTK5rxaGQLnjurSdcVOy');
    } else {
      if (screen.id === TabName.MY_SHOWS && !currentUser) {
        dispatch({
          type: AppStateActions.OPEN_LOGIN_MODAL,
          payload: true,
        });
      }
      setCurrentTab(screen);
      navigate(screen.navigateTo);
    }
  };

  return (
    <nav style={{ gap: '8px', listStyleType: 'none', padding: 0, margin: 0 }}>
      {allTabs.map(screen => {
        return (
          <li key={screen.id.toString()}>
            <NavigationItem
              theme={theme}
              selected={currentTab.id === screen.id}
              style={{
                textDecoration: 'none',
              }}
              to={screen.navigateTo}
              key={screen.id.toString()}
              target={screen.id === TabName.TUTORIALS ? '_blank' : ''}
              onClick={e => {
                if (screen.id === TabName.MY_SHOWS && !currentUser) {
                  e.preventDefault();
                  dispatch({
                    type: AppStateActions.OPEN_LOGIN_MODAL,
                    payload: true,
                  });
                }
              }}
            >
              <IconHolder
                theme={theme}
                selected={currentTab.id === screen.id}
                className={'nav-item-child'}
              >
                <screen.icon />
              </IconHolder>
              <Typography
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                className={'nav-item-child'}
              >
                {t(screen.display)}
              </Typography>
            </NavigationItem>
          </li>
        );
      })}
    </nav>
  );
};

export default ScreensMenu;
