import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export enum CharacterCreationType {
  PROMPT = 'PROMPT',
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}
export interface CharacterCreationOption {
  type: CharacterCreationType;
  tag?: string;
  tagColor?: string;
  title: string;
  icon: ReactJSXElement;
  subtitle: string;
  description: string;
  credits: number;
  trainingRequired: boolean;
  trainingEstimate: string;
  navigateTo: string;
  disabled: boolean;
}

export enum CharacterCreationTabs {
  BASIC_DETAILS = 'Basic Details',
  INPUT_IMAGES = 'Input Images',
  CHARACTER_FEATURES = 'Character Features',
}

export enum CharacterSpeciesType {
  HUMAN = 'Human',
  HUMANOID = 'Humanoid',
  NON_HUMAN = 'Non Human',
}

export enum CharacterGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum CharacterAge {
  INFANT = 'Infant',
  CHILD = 'Child',
  TEENAGER = 'Teenager',
  ADULT = 'Adult',
  OLD = 'Old',
}

export enum CharacterHairColor {
  BLACK = 'Black',
  BROWN = 'Brown',
  GOLDEN = 'Golden',
  GREY = 'Grey',
  WHITE = 'White',
  RED = 'Red',
  BLUE = 'Blue',
  GREEN = 'Green',
}

export enum CharacterHairLength {
  BALD = 'Bald',
  SHORT = 'Short',
  MEDIUM = 'Medium',
  LONG = 'Long',
}

export enum CharacterEyeColor {
  BLACK = 'Black',
  BLUE = 'Blue',
  BROWN = 'Brown',
  GOLDEN = 'Golden',
  WHITE = 'White',
  GREEN = 'Green',
  RED = 'Red',
}

export enum CharacterEthnicity {
  AFRICAN = 'African',
  ASIAN = 'Asian',
  CAUCASIAN = 'Caucasian',
  INDIAN = 'Indian',
  MIDDLE_EASTERN = 'Middle Eastern',
  HISPANIC = 'Hispanic',
}

export enum CharacterHairStyle {
  AFRO_STYLE = 'Afro Style',
  BOWL_CUT = 'Bowl Cut',
  BUZZ_CUT = 'Buzz Cut',
  CURLY = 'Curly',
  SINGLE_BUN = 'Single Bun',
  DOUBLE_BUN = 'Double Bun',
  DREADLOCKS = 'Dreadlocks',
  FRIZZLY = 'Frizzly',
  MESSY = 'Messy',
  PIXIE_CUT = 'Pixie Cut',
  PONYTAIL = 'Ponytail',
  QUIFF = 'Quiff',
  STRAIGHT = 'Straight',
  WAVY = 'Wavy',
  SINGLE_BRAID = 'Single Braid',
  TWIN_BRAID = 'Twin Braid',
  SPIKY = 'Spiky',
}

export enum CharacterBodyType {
  SKINNY = 'Skinny',
  MUSCUlAR = 'Muscular',
  FAT = 'Fat',
}
