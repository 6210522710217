import { ExpressionsActions } from '../Actions/Expressions';
import { ExpressionsState, InitialExpressionsState } from '../../Models/Expressions';

export interface ExpressionAction {
  type: ExpressionsActions;
  payload?: any;
}

export const ExpressionsReducer = (
  state: ExpressionsState = InitialExpressionsState,
  action: ExpressionAction
): ExpressionsState => {
  switch (action.type) {
    case ExpressionsActions.SET_EXPRESSIONS:
      return { ...state, expressions: action.payload! };
    default:
      return state;
  }
};
