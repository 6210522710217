import { useEffect, useMemo, useState } from 'react';
import useInternalEditor from './useInternalEditor';
import { useDispatch, useSelector } from 'react-redux';
import { GenerationMenuActions } from '../Redux/Actions/GenerateImageV2';
import { getInitialImageGenerationState } from '../Models/GenerateImageV2';
import { GenerateImageType } from '../Models/GenerateImage';
import { ReduxState } from '../Redux/ReduxInterface';
import { CanvasStateActions } from '../Redux/Actions/Canvas';
import { useEditor } from '@dashtoon/editor';
import { trackEvent } from '../Utils/Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';

export enum RightBarTabs {
  SETTINGS = 'SETTINGS',
  GENERATE = 'GENERATE',
  HISTORY = 'HISTORY',
  PANELS = 'PANELS',
  LAYERS = 'LAYERS',
  REF_LIBRARY = 'REF_LIBRARY',
  COMMENTS = 'COMMENTS',
}

const useRightBarConfig = () => {
  const activeTab =
    useSelector((state: ReduxState) => state.canvasState.activeRightTab) || RightBarTabs.SETTINGS;
  const selectedFrames = useSelector((state: ReduxState) => state.canvasState.selectedFrames) || [];
  const dispatch = useDispatch();
  const editor = useEditor();

  useEffect(() => {
    if (activeTab === RightBarTabs.GENERATE && selectedFrames.length === 0) {
      dispatch({
        type: CanvasStateActions.SET_RIGHT_MENU_TAB,
        payload: RightBarTabs.SETTINGS,
      });
    } else if (
      activeTab === RightBarTabs.SETTINGS &&
      selectedFrames.length > 0 &&
      editor.selectedShapes[0]?.type !== 'customDraw'
    ) {
      dispatch({
        type: CanvasStateActions.SET_RIGHT_MENU_TAB,
        payload: RightBarTabs.GENERATE,
      });
    }
  }, [selectedFrames]);

  const handleSetActiveTab = (tab: RightBarTabs) => {
    trackEvent(
      {
        event: TrackingEvents.rightBarButtonClicked,
        properties: {
          name: tab,
        },
      },
      'CREATOR'
    );
    dispatch({
      type: CanvasStateActions.SET_RIGHT_MENU_TAB,
      payload: tab,
    });
  };

  useEffect(() => {
    const unsubscribe = editor.store.listen(
      history => {
        const addedKeys = Object.values(history.changes.added);
        const addedShapes = addedKeys;
        addedShapes.forEach(addedShape => {
          if (addedShape && addedShape.typeName === 'shape') {
            const shape = editor.getShapeById(addedShape.id);
            if (shape && shape.type === 'frame') {
              handleSetActiveTab(RightBarTabs.GENERATE);
              dispatch({
                type: GenerationMenuActions.ADD_GENERATION_MENU,
                payload: {
                  menuId: shape?.id, // Specify a unique menuId
                  initialProperties: getInitialImageGenerationState(GenerateImageType.TXT2IMG),
                },
              });
            }
          }
        });
      },
      { source: 'user', scope: 'document' }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    activeTab,
    handleSetActiveTab,
  };
};

export default useRightBarConfig;
