import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Modal, Link, Skeleton, Stack, Typography, Button } from '@mui/material';
import { AppStateActions } from '../../../../Redux/Actions/AppState';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../Redux/ReduxInterface';
import { ReactComponent as CrossIcon } from '../../../../Assets/Icons/cross.svg';
import { Link as RouterLink } from 'react-router-dom';

import { getCustomerBillingPortal, startSubscription } from '../../../../Requests/Studio/User';
import {
  SubscriptionPlan,
  UserSubscriptionPaymentResponse,
  UserSubscriptionPlanEnum,
  WalletTopUpResponse,
} from '../../../../Models/User';
import { t } from 'i18next';
import { AuthContext } from '../../../../Provider/AuthProvider';
import BasicPlan from './Components/BasicPlan';
import ProPlan from './Components/ProPlan';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import useAuth from '../../../../Hooks/useAuth';

interface SubscriptionModalProps {
  showPaymentsModal: boolean;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ showPaymentsModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const quotaState = useSelector((state: ReduxState) => state.quotaState);
  const subscriptionState = useSelector((state: ReduxState) => state.subscriptionState);
  const { data, loading: plansLoading } = subscriptionState;
  const { plans: subscriptionPlans, subscribedPlans } = data;

  const showPaymentsModalText = useSelector(
    (state: ReduxState) => state.appState.showPaymentsModalText
  );

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    height: '512px',
    width: '724px',
    overflow: 'none',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: 24,
    p: '24px',
  };

  const handleModalClose = () => {
    dispatch({
      type: AppStateActions.OPEN_PAYMENTS_MODAL,
      payload: false,
    });
    dispatch({
      type: AppStateActions.SET_PAYMENTS_MODAL_TEXT,
      payload: '',
    });
    trackEvent(
      {
        event: TrackingEvents.paymentSubscriptionModalClosed,
        properties: {},
      },
      'CREATOR'
    );
  };

  const handleCreateSubscription = async (plan: SubscriptionPlan) => {
    try {
      // TODO: update these with proper urls
      const paymentSuccessLink = new URL(window.location.href);
      paymentSuccessLink.searchParams.set('success', 'true');
      const paymentCancelLink = new URL(window.location.href);
      paymentCancelLink.searchParams.set('success', 'false');

      const paymentSessionResponse: UserSubscriptionPaymentResponse = await startSubscription({
        amount: plan.price,
        quantity: 1,
        productId: plan.id,
        priceId: plan.priceId,
        paymentSuccessLink: paymentSuccessLink.href,
        paymentCancelLink: paymentCancelLink.href,
        email: auth.currentUser?.email ?? '',
      });

      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionCheckoutInitiated,
          properties: {},
        },
        'CREATOR'
      );

      window.open(paymentSessionResponse.checkoutSessionLink);
    } catch (err) {
      console.error(err);
      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionCheckoutInitiatedFailure,
          properties: {
            error: err instanceof Error ? err.message : 'Unknown error',
          },
        },
        'CREATOR'
      );
    }
  };

  const userPlan = quotaState.data.currentPlan;

  const handleManageSubscription = async () => {
    if (isAdmin) {
      return alert('You are an admin!');
    }
    const response: { url: string } = await getCustomerBillingPortal();
    window.open(response.url);
  };

  const isBasic = userPlan === UserSubscriptionPlanEnum.BASIC;
  const isPro = userPlan === UserSubscriptionPlanEnum.PRO;
  const isAdmin = userPlan === UserSubscriptionPlanEnum.ADMIN;

  useEffect(() => {
    if (showPaymentsModal) {
      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionModalOpened,
          properties: {},
        },
        'CREATOR'
      );
    }
  }, [showPaymentsModal]);

  const proPlan = subscriptionPlans.find(
    plan => plan.metadata.PLAN_TITLE === UserSubscriptionPlanEnum.PRO
  );
  return (
    <Modal open={showPaymentsModal}>
      <Stack sx={style}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ height: '56px', width: '100%' }}
          gap={4}
        >
          <Box>
            <Typography fontSize={'20px'} fontWeight={'600'} color={theme.palette.text.primary}>
              {showPaymentsModalText
                ? showPaymentsModalText
                : `${t('Unleash unlimited creativity with our Pro Plan')}`}
            </Typography>
          </Box>
          <Box
            style={{
              marginLeft: '157px',
              cursor: 'pointer',
              padding: '0px',
            }}
            onClick={handleModalClose}
          >
            <Button
              role={'button'}
              aria-roledescription={'close modal'}
              aria-description={'close modal'}
              aria-label={'close modal'}
            >
              <CrossIcon />
            </Button>
          </Box>
        </Stack>
        <Box mt={4}>
          <Stack direction={'row'} sx={{ height: '288px' }} gap={2}>
            <Box flex={1}>
              <BasicPlan
                onClick={async () => {
                  if (isAdmin) {
                    return alert('You are an admin!');
                  }
                  if (userPlan !== UserSubscriptionPlanEnum.PRO) {
                    return;
                  } else {
                    return handleManageSubscription();
                  }
                }}
                features={[
                  t('100 Image Generations per day'),
                  t('1 Character Training per day'),
                  t('Community Access'),
                ]}
                isActive={isBasic}
              />
            </Box>
            <Box flex={2}>
              {proPlan && (
                <ProPlan
                  isActive={isPro || isAdmin}
                  onClick={async () => {
                    if (isAdmin) {
                      return alert('You are an admin!');
                    }
                    if (userPlan === UserSubscriptionPlanEnum.PRO) {
                      return handleManageSubscription();
                    } else {
                      return handleCreateSubscription(subscriptionPlans[0]);
                    }
                  }}
                  planDetails={proPlan}
                />
              )}
            </Box>
          </Stack>
        </Box>
        <Stack direction={'row'} mt={5} justifyContent={'space-between'} fontSize={'14px'}>
          <Stack>
            <Box>{t('Are you an Enterprise?')} </Box>
            <Box>
              {t('Get in touch with us at ')}
              <Link href="mailto:contact@dashtoon.com?subject=Enterprise Inquiry">
                {t('enterprise@dashtoon.com')}
              </Link>
            </Box>
          </Stack>
          <Stack justifyContent={'space-between'}>
            <Box>
              <RouterLink
                style={{
                  color: theme.palette.constant.grey,
                }}
                to={'/plans'}
                onClick={handleModalClose}
              >
                <Typography fontSize={'12px'}>{t('Learn more')}</Typography>
              </RouterLink>
            </Box>
            {subscribedPlans.length > 0 ? (
              <Box
                justifyContent={'end'}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: theme.palette.constant.grey,
                }}
                onClick={handleManageSubscription}
              >
                <Typography fontSize={'12px'}>{t('Manage my Subscriptions')}</Typography>
              </Box>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default SubscriptionModal;
