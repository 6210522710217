import { DashverseActions, DashverseActionTypes } from '../Actions/Dashverse';
import {
  AvailableCharacterFilters,
  AvailableShowFilters,
  SelectedCharacterFilters,
  SelectedShowFilters,
  UniverseType,
} from '../../Models/Dashverse';

export interface DashverseState {
  universeType: UniverseType;
  searchText: string;
  availableShowFilters: AvailableShowFilters;
  selectedShowFilters: SelectedShowFilters;
  availableCharacterFilters: AvailableCharacterFilters;
  selectedCharacterFilters: SelectedCharacterFilters;
}

const initialState: DashverseState = {
  universeType: UniverseType.SHOWS,
  searchText: '',
  availableShowFilters: {
    genres: [],
    creators: [],
    models: [],
    styles: [],
  },
  selectedShowFilters: { genres: [], creators: [], models: [], styles: [] },
  availableCharacterFilters: {
    genders: [],
    age: [],
    race: [],
    hairStyle: [],
    castingType: [],
    editorsChoice: [],
    generations: [],
    baseModels: [],
    organisations: [],
  },
  selectedCharacterFilters: {
    is: false,
    genders: [],
    age: [],
    race: [],
    hairStyle: [],
    castingType: [],
    editorsChoice: '',
    generations: '',
    baseModel: '',
    organisations: [],
  },
};

export const DashverseReducer = (
  state: DashverseState = initialState,
  action: DashverseActionTypes
): DashverseState => {
  switch (action.type) {
    case DashverseActions.UPDATE_UNIVERSE_TYPE:
      return { ...state, universeType: action.payload };
    case DashverseActions.UPDATE_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    case DashverseActions.UPDATE_AVAILABLE_SHOW_FILTERS:
      return { ...state, availableShowFilters: action.payload };
    case DashverseActions.UPDATE_SELECTED_SHOW_GENRE_FILTERS:
      return {
        ...state,
        selectedShowFilters: { ...state.selectedShowFilters, genres: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_SHOW_CREATOR_FILTERS:
      return {
        ...state,
        selectedShowFilters: { ...state.selectedShowFilters, creators: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_SHOW_MODEL_FILTERS:
      return {
        ...state,
        selectedShowFilters: { ...state.selectedShowFilters, models: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_SHOW_STYLE_FILTERS:
      return {
        ...state,
        selectedShowFilters: { ...state.selectedShowFilters, styles: action.payload },
      };
    case DashverseActions.UPDATE_AVAILABLE_CHARACTER_FILTERS:
      return {
        ...state,
        availableCharacterFilters: action.payload,
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_IS_FILTER:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, is: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_GENDER_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, genders: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_AGE_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, age: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_RACE_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, race: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_BASE_MODEL_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, baseModel: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_HAIRSTYLE_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...state.selectedCharacterFilters, hairStyle: action.payload },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_CASTING_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: {
          ...state.selectedCharacterFilters,
          castingType: action.payload,
        },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_EDITORSCHOICE_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: {
          ...state.selectedCharacterFilters,
          editorsChoice: action.payload,
        },
      };
    case DashverseActions.UPDATE_SELECTED_CHARACTER_GENERATIONS_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: {
          ...state.selectedCharacterFilters,
          generations: action.payload,
        },
      };
    case DashverseActions.UPDATE_SELECTED_ORGANISATION_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: {
          ...state.selectedCharacterFilters,
          organisations: action.payload,
        },
      };
    case DashverseActions.RESET_CHARACTER_FILTERS:
      return {
        ...state,
        selectedCharacterFilters: { ...initialState.selectedCharacterFilters },
      };
    default:
      return state;
  }
};
