export enum UpscalerActionsTypes {
  SET_UPSCALER_IMAGE = 'SET_UPSCALER_IMAGE',
  SET_UPSCALER_TYPE = 'SET_UPSCALER_TYPE',
  SET_UPSCALER_WIDTH = 'SET_UPSCALER_WIDTH',
  SET_UPSCALER_HEIGHT = 'SET_UPSCALER_HEIGHT',
  SET_UPSCALER_SCALE_BY = 'SET_UPSCALER_SCALE_BY',
  SET_UPSCALED_RESULT = 'SET_UPSCALED_RESULT',
  RESET_UPSCALER = 'RESET_UPSCALER',
  SET_UPSCALER_INIT_IMAGE = 'SET_UPSCALER_INIT_IMAGE',
}
