import React, { startTransition, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { User } from 'firebase/auth';

import ProtectedRoutesWrapper from './Routes/ProtectedRoutesWrapper';
import Layout from './Components/Layout/Layout';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import LayoutWithoutSidebar from './Components/LayoutWithoutSidebar/LayoutWithoutSidebar';
import { configureTracking, initTracking, trackEvent } from './Utils/Analytics';
import {
  CircularProgress,
  createTheme,
  CssBaseline,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from './Redux/ReduxInterface';
import { useFlagsmith } from 'flagsmith/react';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_REGISTRY } from './Utils/AnalyticsRegistry';
import { ComponentsProvider } from '@dashtoon/studio-components/dist/ComponentsContextProvider';
import { configureDashUser } from './Utils/DashAnalytics';
import { TrackingEvents } from './Constants/TrackingEvents';
import { TrackingProperties } from './Constants/TrackingProperties';
import usePhoneLayout from './Hooks/usePhoneLayout';
import useUserMetadata from './Hooks/useUserMetadata';
import { getUserData, updateUserData } from './Requests/Studio/User';
import useUserConnectedAccounts from './Hooks/useUserConnectedAccounts';
import { setThemeMode } from './Redux/Actions/Theme';
import { darkModeTheme, lightModeTheme } from './Utils/Themes/themes';
import { useVersionCheck } from './Hooks/useVersionCheck';
import { CanvasMode } from './Models/GenerateImageV2';
import toast, { Toaster } from 'react-hot-toast';
import { getErrorStyle, getLoadingStyle, getSuccessStyle } from './Components/Alert/alerts';
import CustomIcon from './Components/CustomIcon/CustomIcon';
import { mapBrowserLanguageToSupported } from './Utils/translation';
import { t } from 'i18next';
import SubscriptionModal from './Screens/Studio/Canvas/PaymentPlans/SubscriptionModal';
import SubscriptionPaymentSuccessModal from './Screens/Studio/Canvas/PaymentPlans/SubscriptionPaymentSuccessModal';
import SubscriptionPaymentFailureModal from './Screens/Studio/Canvas/PaymentPlans/SubscriptionPaymentFailureModal';
import SubscriptionModalV3 from './Screens/Studio/Canvas/PaymentPlans/SubscriptionModalV3';
import CustomCircularLoader from './Components/CustomCircularLoader/CustomCircularLoader';
import { fetchAndSetUserEnabledFeatures } from './Requests/Auth/UserEnabledFeatures';
import { useAppDispatch } from './Hooks/useTypedDispatch';

const VerifyEmail = React.lazy(() => import('./Screens/Auth/Verify/VerifyEmail'));
const Onboarding = React.lazy(() => import('./Screens/Onboarding/Onboarding'));
const NewDashtoon = React.lazy(() => import('./Screens/NewDashtoon/NewDashtoon'));
const Home = React.lazy(() => import('./Screens/Studio/Home/Home'));
const PopularShows = React.lazy(() => import('./Screens/Studio/Home/PopularShows/PopularShows'));
const Dashboard = React.lazy(() => import('./Screens/Studio/Dashboard/Dashboard'));
const EpisodeScreen = React.lazy(
  () => import('./Screens/Studio/Dashboard/EpisodeScreen/EpisodeScreen')
);
const CreateShow = React.lazy(
  () => import('./Screens/Studio/Dashboard/ShowScreen/CreateShowScreen/CreateShow')
);
const CreateEpisode = React.lazy(
  () => import('./Screens/Studio/Dashboard/EpisodeScreen/CreateEpisodeScreen/CreateEpisode')
);
const UpdateEpisode = React.lazy(
  () => import('./Screens/Studio/Dashboard/EpisodeScreen/UpdateEpisodeScreen/UpdateEpisode')
);
const ShowDetailsScreen = React.lazy(
  () => import('./Screens/Studio/Canvas/ShowDetailsScreen/ShowDetailsScreen')
);
const DashverseDashboard = React.lazy(
  () => import('./Screens/Studio/Dashverse/DashverseDashboard')
);
const GetInspiredScreen = React.lazy(
  () => import('./Screens/Studio/DashExplorer/GetInspiredScreen')
);
const BulkEpisodeUpload = React.lazy(
  () => import('./Screens/Studio/Dashboard/EpisodeScreen/BulkEpisodeUpload/BulkEpisodeUpload')
);
const BulkShowUpload = React.lazy(
  () => import('./Screens/Studio/Dashboard/BulkShowUpload/BulkShowUpload')
);
const SubscriptionPaymentsPage = React.lazy(
  () => import('./Screens/Studio/Plans/SubscriptionPlansPage')
);
const SubscriptionPaymentsCustomPage = React.lazy(
  () => import('./Screens/Studio/Plans/SubscriptionPlansCustomPage')
);
const CanvasV3 = React.lazy(() => import('./Screens/Studio/CanvasV3/CanvasV3'));
const ConfigScreen = React.lazy(() => import('./Screens/Studio/ConfigScreen/ConfigScreen'));
const ErrorScreen = React.lazy(() => import('./Screens/Error/Error'));
const SmallScreenError = React.lazy(() => import('./Screens/Error/SmallScreenError'));
const SocialAuthHandler = React.lazy(() => import('./Screens/SocialAuthHandler/SocialAuthHandler'));
const PageNotFound = React.lazy(() => import('./Screens/PageNotFound/PageNotFound'));
const Settings = React.lazy(() => import('./Screens/Studio/Settings/Settings'));
const InstantComicScreen = React.lazy(() => import('./Screens/InstantComic/InstantComicScreen'));
const MyCharactersScreen = React.lazy(
  () => import('./Screens/Studio/MyCharacters/MyCharactersScreen')
);
const CharacterDetailsScreen = React.lazy(
  () => import('./Screens/Studio/CharacterDetails/CharacterDetailsScreen')
);
const CharacterCreationOptionsScreen = React.lazy(
  () =>
    import(
      './Screens/Studio/CharacterCreation/CharacterCreationOptions/CharacterCreationOptionsScreen'
    )
);
const PromptCharacterCreationScreen = React.lazy(
  () =>
    import(
      './Screens/Studio/CharacterCreation/PromptCharacterCreation/PromptCharacterCreationScreen'
    )
);
const ManualCharacterCreationScreen = React.lazy(
  () =>
    import(
      './Screens/Studio/CharacterCreation/ManualCharacterCreation/ManualCharacterCreationScreen'
    )
);
const AutomatedCharacterCreationScreen = React.lazy(
  () =>
    import(
      './Screens/Studio/CharacterCreation/AutomatedCharacterCreation/AutomatedCharacterCreationScreen'
    )
);
const CharacterDatasetSelectionScreen = React.lazy(
  () =>
    import(
      './Screens/Studio/CharacterCreation/CharacterDatasetSelection/CharacterDatasetSelectionScreen'
    )
);

const PanelToStrip = React.lazy(() => import('./Screens/PanelToStrips/PanelToStrip'));

const App = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const errorState = useSelector((state: ReduxState) => state.error);
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const { i18n } = useTranslation();
  const flagSmith = useFlagsmith();

  const { setIsFTUE, setIsFirstStory, fetchMetadata } = useUserMetadata();
  const { fetchUserConnectedAccounts } = useUserConnectedAccounts();
  const userMetadata = useSelector((state: ReduxState) => state.userMetadataState.userMetadata);
  const userId = useSelector((state: ReduxState) => state.userMetadataState.userId);
  const { isSmallScreen } = usePhoneLayout();
  const dispatch = useDispatch();
  const showPaymentModalState = useSelector(
    (state: ReduxState) => state.appState.showPaymentsModal
  );
  const showPaymentModalStateV3 = useSelector(
    (state: ReduxState) => state.appState.showPaymentsModalV3
  );
  const canvasMode = useSelector((state: ReduxState) => state.generationMenuState.canvasMode);
  const userVersion = useVersionCheck();

  const location = useLocation();
  const dispatchTyped = useAppDispatch();

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(mapBrowserLanguageToSupported(lang));
    }
  }, [i18n]);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      startTransition(() => {
        dispatch(setThemeMode(theme));
      });
    }
  }, []);

  useEffect(() => {
    flagSmith.startListening(10000);

    return () => flagSmith.stopListening();
  }, []);

  useEffect(() => {
    if (user) {
      dispatchTyped(fetchAndSetUserEnabledFeatures());
    }
  }, [user]);

  useEffect(() => {
    if (errorState.error) {
      toast.error(errorState.error);
    }
  }, [errorState]);

  const getDesignTokens = {
    mode,
    ...(mode === 'light' ? lightModeTheme : darkModeTheme),
  };

  const customThemeOptions = (theme: ThemeOptions) =>
    createTheme({
      ...theme,
      palette: getDesignTokens,
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              color: 'transparent !important',
              backgroundColor: 'transparent !important',
              backgroundImage: 'none',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiTab: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            root: ({ _, theme }) => ({
              '&.Mui-selected': {
                color: theme.palette.text.primary,
              },
              padding: '0px 16px 0px 0px',
            }),
          },
        },
      },
    });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      startTransition(() => {
        setUser(user);
        setIsLoading(false);
      });
      if (user !== null) {
        const lang = localStorage.getItem('i18nextLng') || 'en';
        configureTracking(user, lang);
        configureDashUser(user);
        getUserData().then(data => {
          if (data) {
            // check if data.userId is equal to user.uid or data.photoUrl is equal to user.photoURL or data.name is equal to user.displayName then update the user data
            if (
              data.userId !== user.uid ||
              data.photoUrl !== user.photoURL ||
              data.name !== user.displayName
            ) {
              updateUserData({
                userId: user.uid,
                photoUrl: user.photoURL!,
                name: user.displayName!,
              });
            }
          }
        });
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    initTracking();
  }, []);

  useEffect(() => {
    trackEvent(
      {
        event: TrackingEvents.studioOpen,
        properties: {} as TrackingProperties,
      },
      'CREATOR'
    );
  }, []);
  useEffect(() => {
    const cleanup = () => {
      // cleaning up before unload
      trackEvent(
        {
          event: TrackingEvents.studioClose,
          properties: {} as TrackingProperties,
        },
        'CREATOR'
      );
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  useEffect(() => {
    if (user && user.email && user.uid !== userId) {
      if (!user.emailVerified) {
        trackEvent(
          {
            event: TrackingEvents.userIsUnverified,
            properties: {
              userName: user.displayName,
              userEmail: user.email,
              userId: user.uid,
            } as TrackingProperties,
          },
          'CREATOR'
        );
        toast.error(t('Please verify your email to access all features'), {
          duration: Infinity,
        });
      }
      fetchMetadata().then(() => {
        setIsFTUE(userMetadata);
        setIsFirstStory(userMetadata);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid) {
      fetchUserConnectedAccounts();
    }
  }, [user?.uid]);

  const isAuthenticated = Boolean(user);
  const isVerified = user?.emailVerified;

  if (isLoading) {
    return (
      <div>
        <LoadingScreen backgroundColor={getDesignTokens.surface.primary} />
      </div>
    );
  }

  if (
    user &&
    user.metadata.creationTime &&
    user.emailVerified &&
    userMetadata &&
    userMetadata.ONBOARDED !== 'true'
  ) {
    const userCreationDate = new Date(user.metadata.creationTime);
    const onboardingThresholdDate = new Date(2024, 1, 2);
    if (userCreationDate >= onboardingThresholdDate) {
      return (
        <Suspense fallback={<CustomCircularLoader size={40} />}>
          <ThemeProvider theme={customThemeOptions}>
            <Onboarding />
          </ThemeProvider>
        </Suspense>
      );
    }
  }

  return (
    <Suspense fallback={<CustomCircularLoader size={40} />}>
      <ThemeProvider theme={customThemeOptions}>
        <ComponentsProvider analyticsRegistry={ANALYTICS_REGISTRY}>
          <CssBaseline />
          {isAuthenticated && isVerified && (
            <>
              {showPaymentModalState && (
                <SubscriptionModal showPaymentsModal={showPaymentModalState} />
              )}
              {showPaymentModalStateV3 && (
                <SubscriptionModalV3 showPaymentsModal={showPaymentModalStateV3} />
              )}
              <SubscriptionPaymentSuccessModal />
              <SubscriptionPaymentFailureModal />
            </>
          )}
          <Toaster
            toastOptions={{
              loading: {
                icon: <CircularProgress size={16} />,
                style: getLoadingStyle(),
              },
              success: {
                icon: (
                  <div style={{ width: '24px' }}>
                    <CustomIcon name={'success'} size={32} fill={'rgba(0, 192, 66, 1)'} />
                  </div>
                ),
                style: getSuccessStyle(),
              },
              error: {
                icon: (
                  <div style={{ width: '24px' }}>
                    <CustomIcon name={'failure'} size={32} fill={'rgba(250, 33, 72, 1)'} />
                  </div>
                ),
                style: getErrorStyle(),
              },
            }}
            containerStyle={{
              top: 70,
              width: 'auto',
              zIndex: 2147483647,
            }}
          />

          <Suspense fallback={<CustomCircularLoader size={40} />}>
            <Routes>
              {isAuthenticated && isVerified ? (
                <>
                  {errorState.errorCode && errorState.errorCode === 403 ? (
                    <Route
                      path="/"
                      element={
                        <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                          <Layout />
                        </ProtectedRoutesWrapper>
                      }
                    >
                      <Route path="*" index element={<ErrorScreen />} />
                    </Route>
                  ) : (
                    <Route
                      path="/"
                      element={
                        <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                          <Layout />
                        </ProtectedRoutesWrapper>
                      }
                    >
                      <Route index element={<Home />} />
                      <Route path={'/home'} element={<Home />} />
                      <Route path={'/dashverse'} element={<DashverseDashboard />} />
                      <Route path="/dashboard/bulk-show-upload" element={<BulkShowUpload />} />
                      <Route path={'/dashboard/panel-to-strip'} element={<PanelToStrip />} />
                      <Route path="/dashboard/show/:showId" element={<EpisodeScreen />} />
                      <Route path="/dashboard/show/create" element={<CreateShow />} />
                      <Route
                        path="/dashboard/show/:showId/update"
                        element={<ShowDetailsScreen />}
                      />

                      <Route path="/dashboard/console" element={<ConfigScreen />} />

                      <Route path="/get_inspired" element={<GetInspiredScreen />} />
                      <Route path="/popular_shows" element={<PopularShows />} />
                      <Route
                        path="/dashboard/show/:showId/episode/:episodeId/update"
                        element={<UpdateEpisode />}
                      />
                      <Route
                        path="/dashboard/show/:showId/upload-episodes"
                        element={<BulkEpisodeUpload />}
                      />
                      <Route
                        path="/dashboard/show/:showId/episode/:episodeId/create"
                        element={<CreateEpisode />}
                      />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/characters/my-characters" element={<MyCharactersScreen />} />
                      <Route
                        path="/characters/character/:characterId"
                        element={<CharacterDetailsScreen />}
                      />

                      {/* Add more protected routes here */}
                    </Route>
                  )}
                  <Route path="/plans" element={<SubscriptionPaymentsPage sidebarOpen={false} />} />
                  <Route
                    path="/payment-link/:id"
                    element={<SubscriptionPaymentsCustomPage sidebarOpen={false} />}
                  />
                  <Route path="/new-dashtoon" element={<NewDashtoon />} />
                  <Route path="/social_auth_handler" element={<SocialAuthHandler />} />
                  <Route
                    path="/dashboard/show/:showId/episode/:episodeId/generateV3/:mode?"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <LayoutWithoutSidebar
                          isV2={true}
                          showAppBar={false}
                          disableVerticalScroll={canvasMode === CanvasMode.STORY}
                        />
                      </ProtectedRoutesWrapper>
                    }
                  >
                    {/* Routes that don't require the sidebar */}
                    {errorState.error === '403' ? (
                      <Route index element={<ErrorScreen />} />
                    ) : isSmallScreen ? (
                      <Route index element={<SmallScreenError />} />
                    ) : (
                      <Route index element={<CanvasV3 />} />
                    )}
                  </Route>
                  <Route
                    path="/characters/character-creation"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <CharacterCreationOptionsScreen />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/characters/character-creation/prompt"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <PromptCharacterCreationScreen />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/characters/character-creation/manual"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <ManualCharacterCreationScreen />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/characters/character-creation/automated"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <AutomatedCharacterCreationScreen />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/characters/character-dataset-selection/:characterId"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <CharacterDatasetSelectionScreen />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/instant-comic"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <InstantComicScreen isMetaEvent={true} />
                      </ProtectedRoutesWrapper>
                    }
                  />
                  <Route
                    path="/overnight-billionaire"
                    element={
                      <ProtectedRoutesWrapper isAuthenticated={isAuthenticated}>
                        <InstantComicScreen isMetaEvent={false} />
                      </ProtectedRoutesWrapper>
                    }
                  />
                </>
              ) : (
                <>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path={'/home'} element={<Home />} />
                    <Route path="/get_inspired" element={<GetInspiredScreen />} />
                    <Route path="/popular_shows" element={<PopularShows />} />
                  </Route>
                  <Route path="/new-dashtoon" element={<NewDashtoon />} />

                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/dashboard" element={<Navigate to="/home" />} />
                  <Route path="*" element={<Navigate state={{ from: location }} to="/home" />} />
                </>
              )}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </ComponentsProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
