export const processValue = (value: number, min: number, max: number, step: number) => {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return Math.round(value / step) * step;
  }
};

export const processDecimalValue = (
  value: number,
  min: number,
  max: number,
  step: number,
  digits: number = 1
) => {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return parseFloat(((value / step) * step).toFixed(digits));
  }
};

export function roundNumber(value: number): string {
  // This will round the number to two decimal places
  let roundedValue = Math.round(value * 100) / 100;

  // This checks if the rounded value is an integer
  if (roundedValue % 1 === 0) {
    // If it is an integer, return it as a string without decimals
    return roundedValue.toFixed(0);
  } else {
    // If it is not an integer, return it with two decimal places
    return roundedValue.toFixed(2);
  }
}

export function sumValues(obj: { [key: string]: number }): number {
  return Object.values(obj).reduce((sum, current) => sum + current, 0);
}
