import React from 'react';
import './CustomCircularLoader.css'; // Import your custom CSS

const CustomCircularLoader = ({ size = 40 }) => {
  return (
    <div className="loader-container">
      <div
        className="custom-loader"
        style={{
          width: size,
          height: size,
        }}
      ></div>
    </div>
  );
};

export default CustomCircularLoader;
