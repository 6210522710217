import { TextualEmbeddingResponse } from '../../Models/TextualEmbedding';

export enum TextualEmbeddingActions {
  UPDATE_TEXTUAL_EMBEDDINGS = 'UPDATE_TEXTUAL_EMBEDDINGS',
}

export interface UpdateTextualEmbeddingAction {
  type: TextualEmbeddingActions;
  payload: TextualEmbeddingResponse[];
}

export type TextualEmbeddingActionTypes = UpdateTextualEmbeddingAction;
