import React, { useState, useEffect, useContext, createContext } from 'react';

interface CountryCodeContextType {
  countryCode: string | null;
}

interface CountryCodeProviderProps {
  children: React.ReactNode;
}

const CountryCodeContext = createContext<CountryCodeContextType>({ countryCode: null });

export const CountryCodeProvider: React.FC<CountryCodeProviderProps> = ({ children }) => {
  const [countryCode, setCountryCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://dashtoon.ai/cdn-cgi/trace');
        const text = await response.text();
        const match = text.match(/loc=(\w+)/);
        if (match && match[1]) {
          setCountryCode(match[1].toUpperCase());
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    };

    if (!countryCode) {
      fetchCountryCode();
    }
  }, [countryCode]);

  return (
    <CountryCodeContext.Provider value={{ countryCode }}>{children}</CountryCodeContext.Provider>
  );
};

export const useCountryCode = () => useContext(CountryCodeContext);
