export enum ThemeSelectorActions {
  SET_THEME_MODE = 'SET_THEME_MODE',
}

export interface SetThemeModeAction {
  type: ThemeSelectorActions.SET_THEME_MODE;
  payload: string;
}

export const setThemeMode = (mode: string): SetThemeModeAction => {
  return {
    type: ThemeSelectorActions.SET_THEME_MODE,
    payload: mode,
  };
};
