import {
  DEFAULT_HIGH_RES_FIX_STRENGTH,
  GENERATION_SIZE_STEPS,
  MAX_IMAGE_GENERATION_SIZE,
  MIN_IMAGE_GENERATION_SIZE,
  DEFAULT_INFERENCE_STEPS,
  DEFAULT_CHARACTER_WEIGHT,
} from '../../Constants/ImageGeneration';
import { GenerationParamsActions } from '../Actions/GenerationParams';

export interface GenerationParamsState {
  maxGenSize: number;
  minGenSize: number;
  stepGenSize: number;
  highResStrength: number;
  inferenceSteps: number;
  characterWeight: number;
}

export const GenerationParamsInitialState: GenerationParamsState = {
  maxGenSize: MAX_IMAGE_GENERATION_SIZE,
  minGenSize: MIN_IMAGE_GENERATION_SIZE,
  stepGenSize: GENERATION_SIZE_STEPS,
  highResStrength: DEFAULT_HIGH_RES_FIX_STRENGTH,
  inferenceSteps: DEFAULT_INFERENCE_STEPS,
  characterWeight: DEFAULT_CHARACTER_WEIGHT,
};

export interface GenerationParamsActionRequest {
  type: GenerationParamsActions;
  payload: any;
}

export const GenerationParamsReducer = (
  state: GenerationParamsState = GenerationParamsInitialState,
  action: GenerationParamsActionRequest
): GenerationParamsState => {
  switch (action.type) {
    case GenerationParamsActions.UPDATE_GENERATION_PARAMS:
      return { ...state, ...action.payload };
    case GenerationParamsActions.UPDATE_MAX_GENERATION_DIMENSION:
      return { ...state, maxGenSize: action.payload };
    case GenerationParamsActions.UPDATE_DEFAULT_GENERATION_STEP:
      return { ...state, inferenceSteps: action.payload };
    case GenerationParamsActions.UPDATE_DEFAULT_CHARACTER_WEIGHT:
      return { ...state, characterWeight: action.payload };
    default:
      return state;
  }
};
