import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { colors, useTheme } from '@mui/material';
import { theme } from 'antd';

// Define the props for the component
interface BannerProps {
  message: string;
  type: 'warning' | 'success' | 'error';
}

// Define a simple object to map types to background colors and icons
const typeAttributes = {
  warning: { color: '#FFD583', Icon: ErrorIcon },
  success: { color: '#4CAF50', Icon: CheckCircleIcon },
  error: { color: '#F44336', Icon: ErrorIcon },
};

// The Banner component
const Banner: React.FC<BannerProps> = ({ message, type }) => {
  // Determine the background color and icon based on the type
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const { color: backgroundColor, Icon } = typeAttributes[type];

  // Define the styles for the banner
  const style = {
    backgroundColor,
    color: '#363636',
    display: 'flex',
    alignItems: 'center',
    gap: '10px', // Add a gap between items
    // px - 16, py - 14
    padding: '14px 16px',
    borderRadius: '12px',
    margin: '0',
    textAlign: 'left' as const, // Type assertion for textAlign style
    fontSize: '14px',
  };

  // Icon style to prevent it from shrinking
  const iconStyle = {
    flexShrink: 0,
  };

  return (
    <div style={style}>
      <Icon style={iconStyle} />
      <span
        style={{
          whiteSpace: 'pre-line',
        }}
      >
        {message}
      </span>
    </div>
  );
};

export default Banner;
