import { ShowCategory } from '../../Models/Show';
import HttpClient from '../../Utils/HttpClient';
import { apiPrefix, studioApiPrefix, v2Prefix } from '../../Constants/Constants';
import { AddCommentRequest } from '../../Models/CommentRequests';
import {UserMetadata, UserMetadataRequest} from '../../Models/UserMetadata';

export const getFTUEPrompts = async () => {
  try {
    return await HttpClient.get(`${apiPrefix}${v2Prefix}/chat/prompts`, {});
  } catch (e) {
    console.log('Error while fetching popular shows');
    throw e;
  }
};

export const addUserMetadata = async (userId: string, userMetadataRequest: UserMetadataRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}/users/${userId}/metadata`,
      userMetadataRequest,
      {}
    );
  } catch (error) {
    console.error(`Unable to add user metadata`);
    return null;
  }
};

export const getUserMetadata = async (userId: string) => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}/users/${userId}/metadata`, {});
  } catch (error) {
    console.error(`Unable to get user metadata`);
    return null;
  }
};

export const updateUserMetadata = async (userId: string, userMetadataRequest: UserMetadataRequest) => {
  try {
    return HttpClient.put(
      `${apiPrefix}${v2Prefix}/users/${userId}/metadata`,
      userMetadataRequest,
      {},
    );
  } catch (error) {
    console.error(`Unable to update user metadata`);
    return null;
  }
};
