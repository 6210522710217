import { color } from '../../Constants/Colors';
import { ReduxState } from '../../Redux/ReduxInterface';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

interface NetworkAlertProps {
  networkStatus: 'good' | 'average' | 'bad';
}

const NetworkAlert: React.FC<NetworkAlertProps> = ({ networkStatus }) => {
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  return (
    <Tooltip
      title={
        networkStatus === 'good'
          ? 'Good Network'
          : networkStatus === 'average'
          ? 'Average Network'
          : 'Bad Network'
      }
    >
      <div
        style={{
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
          padding: '8px',
        }}
      >
        <div
          style={{
            width: '4px',
            height: '8px',
            background:
              networkStatus === 'good'
                ? color.green
                : networkStatus === 'average'
                ? color.yellow
                : networkStatus === 'bad'
                ? color.red
                : mode === 'light'
                ? color['80']
                : color['20'],
            borderRadius: '8px',
          }}
        ></div>
        <div
          style={{
            width: '4px',
            height: '12px',
            background:
              networkStatus === 'good'
                ? color.green
                : networkStatus === 'average'
                ? color.yellow
                : mode === 'light'
                ? color['80']
                : color['20'],
            borderRadius: '8px',
          }}
        ></div>
        <div
          style={{
            width: '4px',
            height: '16px',
            background:
              networkStatus === 'good' ? color.green : mode === 'light' ? color['80'] : color['20'],
            borderRadius: '8px',
          }}
        ></div>
      </div>
    </Tooltip>
  );
};

export default NetworkAlert;
