import React, { useEffect, useState } from 'react';
import {
  Chip,
  MenuItem,
  Select,
  Theme,
  createTheme,
  ThemeProvider,
  InputLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';

interface CustomMultiSelectProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  options: string[];
  onChange?: (selectedOptions: string) => void;
  selectedValues?: string; // New prop for selected values
  isV2?: boolean;
  hasLabel?: boolean;
  maxOptions?: number;
  placeHolderColor?: string;
  style?: React.CSSProperties;
  ariaLabel?: string;
  id?: string;
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: '8px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      height: '24px',
      margin: '0px 5px !important',
    },
  })
);

const theme = createTheme({
  spacing: 8,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
  label,
  placeholder,
  required,
  options,
  onChange,
  selectedValues = null,
  isV2 = false,
  hasLabel = true,
  maxOptions,
  placeHolderColor,
  id,
  ariaLabel,
  style,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const themes = useTheme();

  useEffect(() => {
    if (selectedValues === null) return;
    const initialSelected = selectedValues!.split(',').map(value => value.trim());
    setSelected(initialSelected);
  }, [selectedValues]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newSelected = event.target.value as string[];
    if (newSelected.length > (maxOptions ?? 2)) {
      event.stopPropagation();
      setOpen(false);
    } else {
      setSelected(newSelected);
      if (onChange) {
        onChange(newSelected.join(', ')); // Call the callback with the selected options as a comma-separated string
      }
      if (newSelected.length === (maxOptions ?? 2)) {
        setOpen(false);
      }
    }
  };

  const handleDelete = (option: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelected(prevSelected => prevSelected.filter(item => item !== option));
  };

  return !isV2 ? (
    <div>
      <InputLabel
        role={'label'}
        htmlFor={id}
        aria-label={ariaLabel}
        aria-description={placeholder}
        aria-roledescription={'label'}
      >
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </InputLabel>
      <div style={{ marginTop: '5px' }}>
        <Select
          id={id}
          open={open}
          onSelect={() => setOpen(true)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          displayEmpty={true}
          required={required}
          placeholder={placeholder}
          sx={{
            width: '400px',
            backgroundColor: themes.palette.background.paper,
            color: placeHolderColor,
          }}
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={selected => {
            if (selected.length === 0) {
              return <span>{placeholder}</span>;
            }

            return (
              <div className={classes.chips}>
                {(selected as string[]).map(value => (
                  <Chip
                    sx={{ backgroundColor: themes.palette.constant.chip }}
                    key={value}
                    label={t(value)}
                    onDelete={handleDelete(value)}
                    className={classes.chip}
                    onMouseDown={event => {
                      event.stopPropagation();
                    }}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                    aria-live="polite"
                    aria-atomic="true"
                    tabIndex={0}
                    role="button"
                  />
                ))}
              </div>
            );
          }}
          aria-labelledby="select-multiple-options-label"
          aria-label="Select multiple options"
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
      <InputLabel
        role={'label'}
        htmlFor={id}
        aria-label={ariaLabel}
        aria-description={placeholder}
        aria-roledescription={'label'}
        style={{ fontSize: '12px' }}
      >
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </InputLabel>
      <div style={{ marginLeft: hasLabel ? '45px' : '0px', width: '100%' }}>
        <Select
          open={open}
          id={id}
          onSelect={() => setOpen(true)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          displayEmpty={true}
          required={required}
          MenuProps={MenuProps}
          multiple
          placeholder={placeholder}
          sx={{
            width: '100%',
            backgroundColor: themes.palette.background.paper,
            borderRadius: '8px',
            padding: '4px',
            fontSize: '14px',

            '& .MuiOutlinedInput-input': {
              padding: '2px',
              ...style,
            },
          }}
          value={selected}
          onChange={handleChange}
          renderValue={selected => {
            if (selected.length === 0) {
              return <span style={{ color: placeHolderColor }}>{placeholder}</span>;
            }

            return (
              <div className={classes.chips}>
                {(selected as string[]).map(value => (
                  <Chip
                    sx={{ backgroundColor: themes.palette.constant.chip }}
                    key={value}
                    label={t(value)}
                    onDelete={handleDelete(value)}
                    className={classes.chip}
                    onMouseDown={event => {
                      event.stopPropagation();
                    }}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  />
                ))}
              </div>
            );
          }}
          aria-role={'select'}
          aria-labelledby="select-multiple-options-label"
          aria-label="Select multiple options"
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CustomMultiSelect;
