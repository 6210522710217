import HttpClient from '../../Utils/HttpClient';
import { collectionPrefix, studioApiPrefix } from '../../Constants/Constants';
import {
  CollectionSearchByFiltersRequest,
  CollectionSearchByFiltersResponse,
  CollectionTagsResponse,
  CreateCollectionRequest,
  ImageCollectionLibrary,
  ImageCollectionResponse,
} from '../../Models/ImageCollectionLibrary';

export const getAllAvailableCollections = async (
  showId: string
): Promise<ImageCollectionLibrary[]> => {
  try {
    return HttpClient.get(`${studioApiPrefix}${collectionPrefix}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to get collections`);
    throw error;
  }
};

export const createCollection = async (
  createCollectionRequest: CreateCollectionRequest,
  showId: string
): Promise<ImageCollectionLibrary> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${collectionPrefix}`, createCollectionRequest, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to create collection`);
    throw error;
  }
};

export const addImageToCollection = async (
  collectionId: string,
  imageId: number,
  showId: string
) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${collectionPrefix}/image`,
      {
        collectionId: collectionId,
        imageId: imageId,
      },
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to add image to collection`);
    throw error;
  }
};

export const addImageToCollectionBulk = async (
  collectionId: string,
  imageIds: number[],
  showId: string
) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${collectionPrefix}/image/bulk`,
      {
        collectionId: collectionId,
        imageIds: imageIds,
      },
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to add image to collection`);
    throw error;
  }
};

export const getAllCollectionTagsAvailable = async (): Promise<CollectionTagsResponse> => {
  try {
    return HttpClient.get(`${studioApiPrefix}${collectionPrefix}/tags`);
  } catch (error) {
    console.error(`Unable to get collection tags`);
    throw error;
  }
};

export const getCollectionById = async (
  collectionId: string,
  showId: string
): Promise<ImageCollectionResponse> => {
  try {
    return HttpClient.get(`${studioApiPrefix}${collectionPrefix}/${collectionId}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to get collection by id`);
    throw error;
  }
};

export const searchByFilters = async (
  request: CollectionSearchByFiltersRequest,
  showId: string
): Promise<CollectionSearchByFiltersResponse> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${collectionPrefix}/search`, request, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to search by filters`);
    throw error;
  }
};
