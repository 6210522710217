import { Box, Typography } from "@mui/material";
import NewTrainingWorkflowGenerated from "./NewTrainingWorkflowGenerated";
import { useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import { Character } from "../../Models/Character";
import useCharacters from "../../Hooks/useCharacter";
import { useDispatch } from "react-redux";
import { AppStateActions } from "../../Redux/Actions/AppState";
import { ShowDetailsCharacterActions } from "../../Redux/Actions/ShowDetailsCharacter";
import { ShowDetailsCharacterView } from "../../Screens/Studio/Canvas/ShowDetails/ShowDetailsCharacters";

interface ManualWorkflowCharacterComponentProps {
    character: Character
}

const ManualWorkflowCharacterComponent: React.FC<ManualWorkflowCharacterComponentProps> = ({
    character
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comfyChosenLinks, setChosenLinksComfy] = useState<string[]>([]);
    const characterHook = useCharacters()
    const dispatch = useDispatch()

    const handleClick = () => {
        setIsLoading(true)
        characterHook.selectImagesAndTrainCharacters(character.id, comfyChosenLinks)
            .then(() => {
                setIsLoading(false)
                dispatch({
                    type: AppStateActions.OPEN_SHOW_DETAILS_MODAL,
                    payload: false,
                })
                dispatch({
                    type: ShowDetailsCharacterActions.LIBRARY,
                    payload: ShowDetailsCharacterView.LIBRARY,
                });
            })
    }

    return (
        <Box display={"flex"} flexDirection={"row"} mt={3}>
            <Box display={"flex"} flexDirection={"column"} gap={3} flex={3}>
                <Box>Selected {comfyChosenLinks.length} / 30</Box>
                <NewTrainingWorkflowGenerated
                    comfyChosenLinks={comfyChosenLinks}
                    setChosenLinksComfy={setChosenLinksComfy}
                    characterId={character.id}
                />
            </Box>
            <Box mt={4} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"} flex={1} p={2}>
                <Box style={{ borderRadius: "12px" }}>
                    <img src={character.imageUrl || character?.uploadedImage} alt={"Character"} style={{ width: '100%', height: 'auto' }} />
                    <Box>
                        <Typography variant="caption">
                            Character Name: {character.name}
                        </Typography>
                    </Box>
                </Box>
                <CustomButton
                    width={'100%'}
                    height={'32px'}
                    text={'Train'}
                    onClick={handleClick}
                    disabled={comfyChosenLinks.length < 30}
                    isLoading={isLoading}
                    variant={'contained'}
                    foregroundColor={'#FFFFFF'}
                />
            </Box>
        </Box>
    )
}

export default ManualWorkflowCharacterComponent