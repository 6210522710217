import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../Config/Config';

const GATracker: React.FC = () => {
  const id = config.googleAnalytics
  if(!id){
    return null
  }
  const googleAnalyticsScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${id}');
  `;

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${id}`}></script>
      <script>{googleAnalyticsScript}</script>
    </Helmet>
  );
};

export default GATracker;
