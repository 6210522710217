export enum UserMetadataType {
  IS_FTUE = 'IS_FTUE',
  FTUE_SHOW_ID = 'FTUE_SHOW_ID',
  ONBOARDED = 'ONBOARDED',
  PROFESSION = 'PROFESSION',
  INTENT = 'INTENT',
  SOURCE = 'SOURCE',
  IS_FIRST_STORY = 'IS_FIRST_STORY',
  IS_FIRST_SKIP = 'IS_FIRST_SKIP',
  SEEN_PAYMENTS_UPDATE_MODAL = 'SEEN_PAYMENTS_UPDATE_MODAL',
  SEEN_V3_UPDATE_MODAL = 'SEEN_V3_UPDATE_MODAL',
  SEEN_NEW_DASHTOON_PLANS_OCT_24 = 'SEEN_NEW_DASHTOON_PLANS_OCT_24',
}

export interface UserMetadata {
  userId: string;
  emailId: string;
  metadata: Record<UserMetadataType, string>;
  createdAt: Date;
}

export interface UserMetadataRequest {
  metadata: Partial<Record<UserMetadataType, string>>;
}

export interface UserMetadataResponse {
  userMetadata: UserMetadata | null;
}
