export const color = {
  100: '#FCFCFC',
  90: '#E7E7E7',
  80: '#CFCFCF',
  50: '#808080',
  20: '#303030',
  10: '#181818',
  0: '#030303',
  blue: '#3090FC',
  red: '#FA2148',
  green: '#00C042',
  lightGreen: '#6CBF85',
  yellow: '#D98F19',
  purple: '#5330FC',
  lightYellow: '#D2A1064D',
  warningColor: '#FFD583',
  darkGrey: '#363636',
  orange: '#BA4E00',
  yellow10: '#D98F191A',
  lightOrange: '#FF832B',
  springGreen: '#42BE65',
  lightSlate: '#78A9FF',
  chrome: '#D98F19',
};

export const DASHVERSE_COLOR_LIST = [
  '#DA1E28',
  '#D02670',
  '#FF7EB6',
  '#BE95FF',
  '#8A3FFC',
  '#0F62FE',
  '#0072C3',
  '#33B1FF',
  '#08BDBA',
  '#007D79',
  '#6CBF85',
  '#198038',
  '#42BE65',
  '#B9BF6C',
  '#D2A106',
  '#8E6A00',
  '#684B3F',
  '#784B4B',
  '#BA4E00',
  '#FF832B',
  '#FF8389',
  '#78A9FF',
  '#A2A9B0',
  '#697077',
];

export const FEEDBACK_COLOR = {
  yellow: 'rgba(185, 191, 108, 0.3)',
};

export const GradientColors = {
  light:
    'linear-gradient(180deg, rgba(252, 252, 252, 0.00) 0%, rgba(252, 252, 252, 0.60) 24%, #FCFCFC 60%)',
  dark: 'linear-gradient(180deg, rgba(3, 3, 3, 0.00) 0%, rgba(3, 3, 3, 0.60) 24%, #030303 60%)',
  primary: 'linear-gradient(90deg, #3090FC 0%, #5330FC 100%)',
};
export const AlertsColor = {
  success: '#00C04299',
  warning: '#D98F1999',
  danger: '#FA214899',
  info: '#3090FC',
};
