import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Character, CharacterQCStatus } from '../../../../../Models/Character';
import { Typography } from 'antd';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as EditCharacterIcon } from '../../../../../Assets/Icons/edit_pencil.svg';
import { ReactComponent as ReviewCharacterIcon } from '../../../../../Assets/Icons/in_progress.svg';
import { ReactComponent as DeleteIcon } from '../../../../../Assets/Icons/delete_dustbin.svg';
import { ReactComponent as CrossIcon } from '../../../../../Assets/Icons/cross.svg';
import { DEFAULT_IMAGE_URL } from '../../../../../Constants/Constants';
import { CharacterViewType, ShowDetailsCharacterView } from '../ShowDetailsCharacters';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { ShowDetailsModalSizeActions } from '../../../../../Redux/Actions/ShowDetailsModalSize';

interface CharacterTrainingFailedProps {
  character: Character | null;
  characterQCState?: String;
  setUpdateCharacterView?: (character: Character, characterView?: CharacterViewType) => void;
  setCharacterView?: (currentView?: ShowDetailsCharacterView) => void;
  setCharacterViewIsOpen?: (characterViewIsOpen: boolean) => void;
}

const CharacterTrainingFailed: React.FC<CharacterTrainingFailedProps> = ({
  character,
  characterQCState,
  setCharacterView,
  setUpdateCharacterView,
  setCharacterViewIsOpen,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: ShowDetailsModalSizeActions.SET_BIG_MODAL, payload: true });

    return () => {
      dispatch({ type: ShowDetailsModalSizeActions.SET_SMALL_MODAL, payload: false });
    };
  }, []);

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        marginLeft: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box style={{ width: '100%', height: '12%', display: 'flex' }}>
        <Typography
          style={{ fontSize: '3rem', fontWeight: 600, color: theme.palette.object.primary }}
        >
          {character?.name}
        </Typography>
        <Box
          style={{
            width: '20%',
            height: '30px',
            backgroundColor:
              characterQCState === 'PASS'
                ? theme.palette.review.passed_review
                : theme.palette.review.failed_review,
            marginTop: '25px',
            marginLeft: '10px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              width: '100%',
              textAlign: 'center',
              color: theme.palette.object.primary,
              fontWeight: 'bold',
            }}
          >
            {characterQCState === 'PASS' ? 'Training Complete' : 'Training Failed'}
          </Typography>
        </Box>
      </Box>
      <Box style={{ width: '100%', height: '5%', display: 'flex', marginTop: '20px' }}>
        <Box>
          <Typography style={{ fontSize: '14px', color: theme.palette.object.primary }}>
            {character?.gender}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', width: '80%', marginLeft: '10px' }}>
          <li style={{ fontSize: '14px', marginTop: '1px' }}>{character?.description}</li>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          height: '80%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              height: '85%',
              width: '100%',
            }}
          >
            <Box
              style={{
                width: '180px',
                marginRight: '10px',
                height: '89px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '70%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[6]}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t(`Casting`)}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '180px',
                marginRight: '10px',
                height: '89px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '80%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[1]?.replace(/_/g, ' ')}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t(`Age`)}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '180px',
                marginRight: '10px',
                height: '89px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '80%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[2].replace(/_/g, ' ')}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t(`Ethnicity`)}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '180px',
                height: '89px',
                marginRight: '10px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '70%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[3]?.replace('hair', '')}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t('Hair style')}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '180px',
                height: '89px',
                marginRight: '10px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '70%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[4]?.replace('hair color', '')}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t(`Hair Color`)}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '180px',
                height: '89px',
                marginRight: '10px',
                border: `1px solid ${theme.palette.surface.tertiary}`,
                borderRadius: '10px',
              }}
            >
              <Box
                style={{
                  marginLeft: '12px',
                  width: '70%',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    paddingTop: '37px',
                    fontWeight: 'bold',
                    color: theme.palette.object.primary,
                  }}
                >
                  {character?.generationMetadata?.split(',')[5]?.replace('eye color', '')}
                </Typography>
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: theme.palette.constant.grey,
                  }}
                >
                  {t(`Eye Color`)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ width: '100%', height: '80%', marginTop: '10px' }}>
            <Box
              style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  border: `1px solid ${theme.palette.surface.tertiary}`,
                  marginRight: '10px',
                }}
              >
                <Box
                  style={{
                    marginLeft: '12px',
                    width: '70%',
                    height: '100%',
                  }}
                >
                  <Typography
                    style={{
                      paddingTop: '0px',
                      fontWeight: 'bold',
                      color: theme.palette.object.primary,
                    }}
                  >
                    {character?.generationMetadata?.split(',')[7]}
                  </Typography>
                  <Typography
                    style={{
                      paddingTop: '4px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: theme.palette.constant.grey,
                    }}
                  >
                    {t(`Additional Characteristics`)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={{ width: '40%', height: '74%', marginBottom: '20px' }}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              background: `linear-gradient(to bottom, ${theme.palette.review.failed_review} 20%, ${theme.palette.background.default} 80%)`,
            }}
          >
            <Box
              style={{
                width: '40px',
                height: '40px',
                border: `3px solid white`,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CrossIcon />
            </Box>
          </Box>
          <Box
            style={{
              width: '100%',
              height: '30%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterTrainingFailed;
