import { Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../Assets/Icons/cross.svg';
import usePhoneLayout from '../../Hooks/usePhoneLayout';
import { AuthContext } from '../../Provider/AuthProvider';
import { AppStateActions } from '../../Redux/Actions/AppState';
import LoginForm from './Components/LoginForm';
import NotVerified from './Components/NotVerified';
import SignupForm from './Components/SignupForm';

interface LoginModalProps {
  open: boolean;
}
const LoginModal = ({ open }: LoginModalProps) => {
  const auth = useContext(AuthContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mode, setMode] = useState<'login' | 'signup' | 'not-verified'>('login');
  const { isSmallScreen } = usePhoneLayout();
  const closeModal = () => {
    dispatch({
      type: AppStateActions.OPEN_LOGIN_MODAL,
      payload: false,
    });
  };

  useEffect(() => {
    if (auth.email && auth.currentUser && !auth.anonymous) {
      if (auth.isVerified) closeModal();
      else mode !== 'not-verified' && setMode('not-verified');
    }
  }, [auth.currentUser]);
  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
      }}
      aria-modal={open}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: isSmallScreen ? '90%' : '60%',
          maxWidth: '724px',
          border: `1px solid ${theme.palette.surface.tertiary}`,
          borderRadius: '16px',
          overflow: 'hidden',
          outline: 'none',
        }}
      >
        <div
          style={{
            flex: isSmallScreen ? 1 : 0.52,
            backgroundColor: theme.palette.surface.secondary,
            padding: '24px',
            color: theme.palette.object.primary,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {mode === 'login' && <LoginForm setMode={setMode} closeModal={closeModal} />}
          {mode === 'signup' && <SignupForm setMode={setMode} closeModal={closeModal} />}
          {mode === 'not-verified' && <NotVerified />}
        </div>

        {!isSmallScreen && (
          <div
            style={{
              flex: 0.48,
              background:
                mode === 'login'
                  ? `url(${require('../../Assets/Images/login.png')}) center/cover`
                  : `url(${require('../../Assets/Images/signup.png')}) center/cover`,

              padding: '24px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CloseIcon
              height={24}
              width={24}
              color={theme.palette.constant.grey}
              onClick={() => closeModal()}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LoginModal;
