import { Show } from '../../Models/Show';
import { AllShowsActions } from '../Actions/AllShows';

export interface AllShowsState {
  allShows: Show[];
}

export const AllShowsInitialState: AllShowsState = {
  allShows: [],
};

export interface AllShowsActionRequest {
  type: AllShowsActions;
  payload: any;
}

export const AllShowsReducer = (
  state: AllShowsState = AllShowsInitialState,
  action: AllShowsActionRequest
): AllShowsState => {
  switch (action.type) {
    case AllShowsActions.ADD_SHOW:
      return {
        ...state,
        allShows: [...state.allShows, action.payload],
      };
    case AllShowsActions.DELETE_SHOW:
      return {
        ...state,
        allShows: state.allShows.filter(show => show.id !== action.payload),
      };
    case AllShowsActions.UPDATE_SHOW:
      return {
        ...state,
        allShows: [...state.allShows.filter(show => show.id !== action.payload.id), action.payload],
      };
    default:
      return state;
  }
};
