import React, {ReactNode} from "react";
import {useTheme} from "@mui/material/styles";
import {t} from 'i18next'
import { TrackingRequest } from "../../Utils/Analytics";
import CustomButton from "../CustomButton/CustomButton";

interface SidebarWidgetProps {
  title: string;
  imageComponent: ReactNode;
  description: string;
  buttonTitle: string;
  onClick: () => void;
  backgroundColor?: string;
}
const SidebarWidget = ({
  title,
  imageComponent,
  description,
  buttonTitle,
  onClick,
  backgroundColor,
}: SidebarWidgetProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: backgroundColor ?? theme.palette.surface.secondary,
        display: 'flex',
        flexDirection: 'column',
        width: '208px',
        border: `1px solid ${theme.palette.surface.tertiary}`,
        borderRadius: '8px',
        padding: '12px',
      }}
    >
      <div
        style={{
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: 8,
        }}
      >
        {t(title)}
      </div>
      {imageComponent}
      <p
        style={{
          fontSize: '12px',
        }}
      >
        {t(description)}
      </p>
      <CustomButton
        text={buttonTitle}
        onClick={onClick}
        variant={'outlined'}
        foregroundColor={'white'}
        backgroundColor={'#303030'}
        hasBorder={false}
        height={'24px'}
      />
    </div>
  );
};

export default SidebarWidget