import { SavedImagesActions, SavedImagesActionTypes } from '../Actions/SavedImages';
import { SaveImageResponse } from '../../Models/SaveImage';

export interface SavedImagesState {
  savedImages: SaveImageResponse[];
}

const initialState: SavedImagesState = {
  savedImages: [],
};

export const SavedImagesReducer = (
  state: SavedImagesState = initialState,
  action: SavedImagesActionTypes
): SavedImagesState => {
  switch (action.type) {
    case SavedImagesActions.APPEND_SAVED_IMAGES:
      return { ...state, savedImages: [action.payload, ...state.savedImages] };
    case SavedImagesActions.APPEND_BULK_SAVED_IMAGES:
      return {
        ...state,
        savedImages: [
          ...action.payload.filter(
            image => !state.savedImages.find(savedImage => savedImage.id === image.id)
          ),
          ...state.savedImages,
        ],
      };
    case SavedImagesActions.UPDATE_SAVED_IMAGES:
      return { ...state, savedImages: action.payload };
    case SavedImagesActions.SET_SAVED_IMAGES:
      return {
        ...state,
        savedImages: action.payload,
      };
    case SavedImagesActions.UPDATE_SAVED_IMAGE:
      return {
        ...state,
        savedImages: state.savedImages.map(image =>
          image.id === action.payload.id ? action.payload : image
        ),
      };
    default:
      return state;
  }
};
