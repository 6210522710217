import thunk from 'redux-thunk';
import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { Image2ImageReducer, InpaintReducer, Text2ImageReducer } from './Reducers/GenerateImage';
import { ReduxState } from './ReduxInterface';
import { SavedImagesReducer } from './Reducers/SavedImages';
import { CharacterReducer } from './Reducers/Character';
import { InpaintImageReducer } from './Reducers/Inpaint';
import { UpscalerReducer } from './Reducers/Upscaler';
import { TabSelectorReducer } from './Reducers/TabSelector';
import { ThemeReducer } from './Reducers/Theme';
import { UserEnabledFeaturesReducer } from './Reducers/UserEnabledFeatures';
import { UserReducer } from './Reducers/User';
import { ErrorReducer } from './Reducers/Error';
import { DrawingReducer } from './Reducers/Drawing';
import { TextualEmbeddingReducer } from './Reducers/TextualEmbedding';
import { GenerationReducer } from './Reducers/Generation';
import { ShortcutsReducer } from './Reducers/Shortcuts';
import { GenerationMenuReducer } from './Reducers/GenerateImageV2';
import { PollImageReducer } from './Reducers/PollImages';
import { GenerationParamsReducer } from './Reducers/GenerationParams';
import { LibrarySearchReducer } from './Reducers/LibrarySearch';
import { AppStateReducer } from './Reducers/AppState';
import { ExpressionsReducer } from './Reducers/Expressions';
import { CanvasStateReducer } from './Reducers/Canvas';
import { StudioCommentsReducer } from './Reducers/StudioComments';
import { AllShowsReducer } from './Reducers/AllShows';
import { AllEpisodesReducer } from './Reducers/AllEpisodes';
import { TrainingStateReducer } from './Reducers/TrainingStatus';
import { DashverseReducer } from './Reducers/Dashverse';
import { CollectionReducer } from './Reducers/Collection';
import { ControlNetReducer } from './Reducers/ControlNet';
import { ShowDetailsModalSizeReducer } from './Reducers/ShowDetailsModalSize';
import { LiveLCMReducer } from './Reducers/LiveLCMReducer';
import {
  CharacterFromCanvasReducer,
  ShowDetailsCharacterReducer,
} from './Reducers/ShowDetailsCharacter';
import { ShowDetailsReducer } from './Reducers/ShowDetails';
import { RightClickMenuReducer } from './Reducers/RightClickMenuState';
import { WalkthroughReducer } from '../FTUE/Redux/Reducers/WalkthroughReducer';
import { FirstWalkthroughReducer } from '../FTUE/Redux/Reducers/FirstWalkthroughReducer';
import { TextEnhancerReducer } from './Reducers/TextEnhancer';
import { StoryStateReducer } from './Reducers/StoryState';
import { ThumbnailReducer } from './Reducers/Thumbnail';
import { ConnectedAccountsReducer } from './Reducers/ConnectedAccountsState';
import { PromptBoxCharactersReducer } from './Reducers/PromptBoxCharacters';
import { UserMetadataReducer } from './Reducers/UserMetadataState';
import { StoryCrafterStateReducer } from './Reducers/StoryCrafterState';
import { ShowUsersReducer } from './Reducers/ShowUsersState';
import { StoryCrafterStateV2Reducer } from './Reducers/StoryCrafterStateV2';
import { QuotaReducer, SubscriptionReducer } from './Reducers/UserQuotaSubscription';
import { VideoModalStateReducer } from './Reducers/VideoModalState';
import { UploadStateReducer } from './Reducers/UploadState';
import { GenerationStepsReducer } from './Reducers/GenerationState';
import { GazeReducer } from './Reducers/Gaze';

// Combine your reducers
const rootReducer = combineReducers({
  showDetailsModalSizeState: ShowDetailsModalSizeReducer,
  trainingStatusState: TrainingStateReducer,
  text2ImageGeneration: Text2ImageReducer,
  image2ImageGeneration: Image2ImageReducer,
  inpaintGeneration: InpaintReducer,
  savedImages: SavedImagesReducer,
  character: CharacterReducer,
  inpaintImage: InpaintImageReducer,
  upscaler: UpscalerReducer,
  tabSelector: TabSelectorReducer,
  theme: ThemeReducer,
  userEnabledFeatures: UserEnabledFeaturesReducer,
  user: UserReducer,
  error: ErrorReducer,
  drawing: DrawingReducer,
  textualEmbedding: TextualEmbeddingReducer,
  generation: GenerationReducer,
  shortcuts: ShortcutsReducer,
  generationMenuState: GenerationMenuReducer,
  pollImages: PollImageReducer,
  generationParams: GenerationParamsReducer,
  librarySearch: LibrarySearchReducer,
  appState: AppStateReducer,
  expressions: ExpressionsReducer,
  canvasState: CanvasStateReducer,
  studioCommentsState: StudioCommentsReducer,
  allShowsState: AllShowsReducer,
  allEpisodesState: AllEpisodesReducer,
  dashverseState: DashverseReducer,
  collectionState: CollectionReducer,
  controlNetState: ControlNetReducer,
  liveLCMState: LiveLCMReducer,
  rightClickMenuState: RightClickMenuReducer,
  showDetailsCharacterViewState: ShowDetailsCharacterReducer,
  showDetailsState: ShowDetailsReducer,
  characterFromCanvasState: CharacterFromCanvasReducer,
  walkthroughState: WalkthroughReducer,
  firstWalkthroughState: FirstWalkthroughReducer,
  textEnhancerState: TextEnhancerReducer,
  storyState: StoryStateReducer,
  storyCrafterState: StoryCrafterStateReducer,
  thumbnailState: ThumbnailReducer,
  connectedAccountsState: ConnectedAccountsReducer,
  promptBoxCharactersState: PromptBoxCharactersReducer,
  userMetadataState: UserMetadataReducer,
  showUsersState: ShowUsersReducer,
  storyCrafterStateV2: StoryCrafterStateV2Reducer,
  subscriptionState: SubscriptionReducer,
  quotaState: QuotaReducer,
  videoModalState: VideoModalStateReducer,
  uploadState: UploadStateReducer,
  generationSteps: GenerationStepsReducer,
  gazeState: GazeReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// Create your Redux store
export function createReduxStore(initialState: ReduxState): Store<ReduxState, AnyAction> {
  // @ts-ignore
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
}
