import * as React from 'react';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { auth } from '../../firebaseConfig';
import { User } from 'firebase/auth';
import { resetTracking, trackEvent } from '../../Utils/Analytics';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../Redux/ReduxInterface';
import { UserEnabledFeaturesActions } from '../../Redux/Actions/UserEnabledFeatures';
import { UserActions } from '../../Redux/Actions/User';
import { fetchUserWalletBalance } from '../../Requests/Studio/User';
import { useTheme } from '@mui/material/styles';
import { ErrorActions } from '../../Redux/Actions/Error';
import { TrackingEvents } from '../../Constants/TrackingEvents';
import { TrackingProperties } from '../../Constants/TrackingProperties';
import AppToolBar from './AppToolBar';
import CustomContextMenu from '../../Screens/Studio/Canvas/ContextMenu/ContextMenu';
import { downloadImage } from '../../Utils/imageProcess';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AddToCollectionState, ImageCollectionLibrary } from '../../Models/ImageCollectionLibrary';
import { addImageToCollection } from '../../Requests/Studio/Collection';
import { message } from 'antd';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import { CollectionActions } from '../../Redux/Actions/Collection';

const settings = ['Logout'];

interface ResponsiveAppBarProps {
  isCanvas: boolean | false;
  filter: boolean | false;
  search: string;
  handleSearch: (e: any) => void;
}

function ResponsiveAppBar(props: ResponsiveAppBarProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();

  const { isCanvas, search, handleSearch } = props;
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<User | null>(null);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);

  const selectedImage = useSelector((state: ReduxState) => state.canvasState.currentSelectedImage);
  const allCollection = useSelector((state: ReduxState) => state.collectionState.allCollections);
  const [messageApi, contextHolder] = message.useMessage();
  const isV3 = window.location.pathname.toLowerCase().endsWith('/generatev2');
  const navigate = useNavigate();

  const fetchAndSetUserWalletBalance = async (user: User | null) => {
    if (!user) {
      dispatch({ type: UserActions.RESET_WALLET_BALANCE });
    } else {
      try {
        const walletBalanceResponse = await fetchUserWalletBalance();
        if (!walletBalanceResponse.balance) {
          if (walletBalanceResponse.status === 403) {
            dispatch({
              type: ErrorActions.UPDATE_ERROR,
              payload: { errorCode: 403, error: walletBalanceResponse.json().message },
            });
          }
        } else {
          dispatch({
            type: UserActions.SET_WALLET_BALANCE,
            payload: walletBalanceResponse.balance,
          });
        }
      } catch (error) {
        dispatch({ type: UserActions.RESET_WALLET_BALANCE });
      }
    }
  };

  const handleOpenTopUpModal = async () => {
    trackEvent(
      {
        event: TrackingEvents.buyCoinsButtonClicked,
        properties: {} as TrackingProperties,
      },
      'CREATOR'
    );
    setOpenTopUpModal(true);
  };

  const handleCloseTopUpModal = () => setOpenTopUpModal(false);

  useEffect(() => {
    // ComponentWillUnmount equivalent cleanup function
    return () => {
      dispatch({ type: ErrorActions.UPDATE_ERROR, payload: { error: null, errorCode: null } });
      // Clean up any resources or subscriptions here
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchAndSetUserWalletBalance(user).then();
  }, [user]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Reset the user and other state in Redux
      setUser(null);
      dispatch({ type: UserEnabledFeaturesActions.RESET_USER_FEATURE_ENABLED });
      resetTracking();
      handleCloseUserMenu();

      // Refresh the page
      navigate('/home', { replace: true });
    });
  };

  return !isV3 ? (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: isCanvas ? '' : theme.palette.surface.primary,
        color: isCanvas ? '' : theme.palette.text.primary,
        height: '4rem',
        boxShadow: isCanvas ? 'none' : 'none',
        // backdropFilter: isCanvas ? 'blur(10px)' : '',
        // zIndex:'-1',
      }}
    >
      {contextHolder}
      {/*<PaymentsModal visible={showPaymentModalState} />*/}
      <AppToolBar
        user={user}
        anchorElUser={anchorElUser}
        isCanvas={isCanvas}
        filter={props.filter}
        handleOpenTopUpModal={handleOpenTopUpModal}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        handleLogout={handleLogout}
        search={search}
        handleSearch={handleSearch}
      />

      <CustomContextMenu
        id={'library-context-menu'}
        items={[
          ...[
            {
              id: 'download',
              label: t('Download'),
              onClick: () => {
                if (selectedImage) {
                  downloadImage(
                    selectedImage?.imageURL,
                    selectedImage?.id,
                    selectedImage?.id,
                    showId!
                  );
                }
              },
            },
          ],
          ...(allCollection.length > 0
            ? [
                {
                  id: 'send_to_collection',
                  label: t('Add to Collection'),
                  onClick: () => {},
                  items: allCollection.map((collection: ImageCollectionLibrary) => ({
                    id: 'add_to_collection',
                    label: collection.name,
                    onClick: async () => {
                      try {
                        await addImageToCollection(collection.id, selectedImage?.id!, showId!);
                        messageApi.destroy();
                        messageApi.open({
                          type: 'success',
                          content: `Added to collections`,
                          duration: 1,
                        });
                        trackEvent(
                          {
                            event: TrackingEvents.clickSendToLibrary,
                            properties: {
                              showId: showId!,
                              episodeId: episodeId!,
                              imageId: selectedImage?.id?.toString() ?? '',
                              collectionId: collection.id,
                              collectionName: collection.name,
                            },
                          },
                          'CREATOR'
                        );
                        dispatch({
                          type: CollectionActions.SET_ADD_TO_COLLECTION_BY_ID,
                          payload: [
                            {
                              collectionId: collection.id,
                              image: selectedImage,
                            } as AddToCollectionState,
                          ],
                        });
                      } catch (error) {
                        messageApi.destroy();
                        messageApi.open({
                          type: 'error',
                          content: `Failed to add to collection`,
                          duration: 1,
                        });
                      }
                    },
                  })),
                },
              ]
            : []),
          {
            id: 'create_collection',
            label: t('Create Collection'),
            onClick: () => {
              dispatch({
                type: TabSelectorActions.OPEN_CREATE_COLLECTION,
                payload: true,
              });
              dispatch({
                type: CollectionActions.SET_CREATE_NEW_COLLECTION_IMAGE,
                payload: selectedImage,
              });
              trackEvent(
                {
                  event: TrackingEvents.clickSendToCreateLibrary,
                  properties: {
                    showId: showId!,
                    episodeId: episodeId!,
                    imageId: selectedImage?.id?.toString() ?? '',
                  },
                },
                'CREATOR'
              );
            },
          },
        ]}
      />
    </AppBar>
  ) : (
    <div></div>
  );
}

export default ResponsiveAppBar;
