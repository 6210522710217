import React from 'react';
import SidebarWidget from '../../../SidebarWidget/SidebarWidget';
import { getCDNImageUrl } from '../../../../Utils/cdnImage';
import { t } from 'i18next';
import { trace } from 'console';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';

const CreatorProgramWidget = () => {
  const imageUrl = getCDNImageUrl(
    'https://dashtoon.ai/cdn-cgi/image/fit=scale-down/https://content.dashtoon.ai/user-uploaded-files/UPLbtGphnmkEXFZTR4BO2DlyFattD34va5E.webp?retry=1',
    '300'
  );
  return (
    <SidebarWidget
      title={t('Create! Publish & Earn!')}
      imageComponent={
        <div
          style={{
            background: `linear-gradient(80deg, #080808 0%, rgba(5, 5, 5, 0) 100%), url(${imageUrl})`,
            width: '184px',
            height: '96px',
            borderRadius: '8px',
            overflow: 'hidden',
            fontSize: '24px',
            fontWeight: 900,
            lineHeight: '20px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'white',
          }}
        >
          {t('Creator’s Program')}
        </div>
      }
      description={t('Monetize your content and reach a global audience')}
      buttonTitle={t('Apply Now')}
      onClick={() => {
        trackEvent({ event: TrackingEvents.creatorWidgetClicked, properties: {} }, 'CREATOR');
        window.open('https://dashtoon.com/creators', '_blank');
      }}
      backgroundColor="#A2A9B04D"
    />
  );
};

export default CreatorProgramWidget;
