import { useContext } from 'react';
import { NotificationContext } from '../Provider/NotificationProvider';
import { message as antdMessage } from 'antd';

interface useNotificationProps {
  custom?: boolean;
}

function useNotification<useNotificationProps>({ custom = false } = {}) {
  const { messageApi } = useContext(NotificationContext);

  const [customMessageApi, customContextApi] = antdMessage.useMessage();

  const api = custom ? customMessageApi : messageApi;

  const message = (
    type: 'success' | 'error' | 'info' | 'warning' | 'loading',
    content: string,
    duration?: number
  ) => {
    duration = duration || 2;
    if (!api) return null;
    api.destroy();
    api.open({
      type,
      content,
      duration: 4,
    });
  };

  const destroy = () => {
    if (!api) return null;
    api.destroy();
  };

  return { message, destroy, customContext: customContextApi };
}

export default useNotification;
